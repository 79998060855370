/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import EconomicActivityActionTypes from './economic-activity.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addEconomicActivityDetailsResponse: null,
	updateEconomicActivityDetailsResponse: null,
	getEconomicActivityListResponse: null,
	getEconomicActivityListArr: [],
	fetchEconomicActivityDetailsResponse: null,
	fetchEconomicActivityDetailsArr: [],
	deleteEconomicActivityDetailsResponse: null,
	fetchAllEconomicActivitysResponse: null,
	fetchAllEconomicActivitysArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_SUCCESS:
			return { ...state, addEconomicActivityDetailsResponse: action.payload };

		case EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_FAILURE:
			return { ...state, addEconomicActivityDetailsResponse: action.payload };

		case EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED:
			return { ...state, addEconomicActivityDetailsResponse: null };

		case EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_SUCCESS:
			return { ...state, updateEconomicActivityDetailsResponse: action.payload };

		case EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_FAILURE:
			return { ...state, updateEconomicActivityDetailsResponse: action.payload };

		case EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateEconomicActivityDetailsResponse: null };

		case EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_SUCCESS:
			return {
				...state,
				getEconomicActivityListResponse: action.payload,
				getEconomicActivityListArr: docodeResponse(action.payload, 'GetEconomicActivityListKey'),
			};

		case EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_FAILURE:
			return { ...state, getEconomicActivityListResponse: action.payload };

		case EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_RESPONSE_CHANGED:
			return { ...state, getEconomicActivityListResponse: null };

		case EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_SUCCESS:
			return {
				...state,
				fetchEconomicActivityDetailsResponse: action.payload,
				fetchEconomicActivityDetailsArr: docodeResponse(
					action.payload,
					'FetchEconomicActivityDetailsKey',
				),
			};

		case EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_FAILURE:
			return { ...state, fetchEconomicActivityDetailsResponse: action.payload };

		case EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchEconomicActivityDetailsResponse: null,
				fetchEconomicActivityDetailsArr: [],
			};

		case EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_SUCCESS:
			return { ...state, deleteEconomicActivityDetailsResponse: action.payload };

		case EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_FAILURE:
			return { ...state, deleteEconomicActivityDetailsResponse: action.payload };

		case EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteEconomicActivityDetailsResponse: null };

		case EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_SUCCESS:
			return {
				...state,
				fetchAllEconomicActivitysResponse: action.payload,
				fetchAllEconomicActivitysArr: docodeResponse(action.payload, 'FetchAllEconomicActivitysKey'),
			};

		case EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_FAILURE:
			return { ...state, fetchAllEconomicActivitysResponse: action.payload };

		case EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_RESPONSE_CHANGED:
			return { ...state, fetchAllEconomicActivitysResponse: null };

		default:
			return state;
	}
};
