/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BusinessLoanProposalActionTypes from './business-loan-propsal.types';
import {
	createNewBusinessLoan,
	updateBusinessLoan,
	businessLoanProposalListFetch,
	businessLoanProposalApprove,
	businessLoanProposalDetailsFetch,
	businessLoanProposalDelete,
} from './business-loan-proposal.service';

import {
	addBusinessLoanPropsalSuccess,
	addBusinessLoanPropsalFailure,
	addBusinessLoanPropsalResponseChanged,
	updateBusinessLoanProposalSuccess,
	updateBusinessLoanProposalFailure,
	updateBusinessLoanProposalResponseChanged,
	getBusinessLoanPropsalListSuccess,
	getBusinessLoanPropsalListFailure,
	getBusinessLoanPropsalListResponseChanged,
	approveBusinessProposalSuccess,
	approveBusinessProposalFailure,
	approveBusinessProposalResponseChanged,
	fetchBusinessLoanProposalSuccess,
	fetchBusinessLoanProposalFailure,
	fetchBusinessLoanProposalResponseChanged,
	deleteBusinessLoanProposalSuccess,
	deleteBusinessLoanProposalFailure,
	deleteBusinessLoanProposalResponseChanged,
} from './business-loan-proposal.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS LOAN PROPOSAL   ******************** */
export function* craeteNewLoanProposal({ payload }) {
	try {
		const key = 'AddBusinessLoanProposalKey';
		const jsonData = {
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupOfficerID: payload.officerID,
			BusinessID: payload.businessID,
			LoanProposalDate: payload.loanProposalDate,
			LoanStartDate: payload.loanStartDate,
			LoanEndDate: payload.loanEndDate,
			MeetUpLocation: payload.memberMeetUpLocation,
			MemberMeetupLat: payload.meetUpLat,
			MemberMeetupLong: payload.meetUpLog,
			MeetUpTime: payload.meetUpTime,
			ProductID: payload.productID,
			LoanIndexType: payload.loanIndexType,
			ProductAmount: payload.principalAmount,
			ProductPrincipal: payload.interestAmount,
			ProductInterest: payload.interestPercentage,
			ProductROI: payload.productROI,
			InstalmentType: payload.instalmentType,
			NumberOfInstalment: payload.numberOfInstalment,
			AmountOutsanding: payload.amountOutsanding,
			InstallmentMethod: payload.installmentMethod,
			InstallmentReturnType: payload.installmentReturnType,
			NoOfInstallment: payload.noOfInstallment,
			DocumentCharges: payload.documentCharges,
			ProcessingFees: payload.processingFees,
			ProcessingFeesPercentage: payload.ProcessingFeesPerc,
			InsurenseFeePercentage: payload.InsuranceFeesPerc,
			CGST: payload.cgst,
			SGST: payload.sgst,
			verificationFee: payload.verificationFee,
			HPFee: payload.hpFee,
			InsurenseFee: payload.insurenseFee,
			Noofstartday: payload.noOfStartday,
			LoanType: payload.loanType,
			PurposeOfLoanID: payload.purposeOfLoanID,
			AmountReceived: payload.productAmountReceived,
			AmountOutStanding: payload.productOutstanding,
			CreatedUser: payload.userID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createNewBusinessLoan, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessLoanPropsalFailure(responseData.data));
		} else {
			yield put(addBusinessLoanPropsalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessLoanPropsalFailure(error));
	}
}

export function* resetAddBusinessLoanProposalResponse() {
	yield put(addBusinessLoanPropsalResponseChanged());
}

export function* onCreateBusinessLoanProposal() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_START,
		craeteNewLoanProposal,
	);
}

export function* onCreateBusinessLoanProposalResponseReset() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
		resetAddBusinessLoanProposalResponse,
	);
}
/** *********************  END ADD BUSINESS LOAN PROPOSAL  ******************** */

/** *********************  START UPDATE BUSINESS LOAN PROPOSAL   ******************** */
export function* businessLoanProposal({ payload }) {
	try {
		const key = 'UpdateBusinessLoanProposalKey';
		const jsonData = {
			LoanIndexID: payload.businessLoanProposalID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			GroupOfficerID: payload.officerID,
			BusinessID: payload.businessID,
			LoanProposalDate: payload.loanProposalDate,
			LoanStartDate: payload.loanStartDate,
			LoanEndDate: payload.loanEndDate,
			MeetUpLocation: payload.memberMeetUpLocation,
			MemberMeetupLat: payload.meetUpLat,
			MemberMeetupLong: payload.meetUpLog,
			MeetUpTime: payload.meetUpTime,
			ProductID: payload.productID,
			LoanIndexType: payload.loanIndexType,
			ProductAmount: payload.principalAmount,
			ProductPrincipal: payload.interestAmount,
			ProductInterest: payload.interestPercentage,
			ProductROI: payload.productROI,
			InstalmentType: payload.instalmentType,
			NumberOfInstalment: payload.numberOfInstalment,
			AmountOutsanding: payload.amountOutsanding,
			InstallmentMethod: payload.installmentMethod,
			InstallmentReturnType: payload.installmentReturnType,
			NoOfYears: payload.noOfYears,
			DocumentCharges: payload.documentCharges,
			ProcessingFees: payload.processingFees,
			ProcessingFeesPercentage: payload.ProcessingFeesPerc,
			InsurenseFeePercentage: payload.InsuranceFeesPerc,
			CGST: payload.cgst,
			SGST: payload.sgst,
			verificationFee: payload.verificationFee,
			HPFee: payload.hpFee,
			InsurenseFee: payload.insurenseFee,
			Noofstartday: payload.noOfStartday,
			LoanType: payload.loanType,
			PurposeOfLoanID: payload.purposeOfLoanID,
			AmountReceived: payload.productAmountReceived,
			AmountOutStanding: payload.productOutstanding,
			LoanDisbursementDate: payload.loanDisbursementDate,
			CreatedUser: payload.userID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateBusinessLoan, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessLoanProposalFailure(responseData.data));
		} else {
			yield put(updateBusinessLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessLoanProposalFailure(error));
	}
}

export function* resetUpdateBusinessLoanProposalResponse() {
	yield put(updateBusinessLoanProposalResponseChanged());
}

export function* onUpdateBusinessLoanProposal() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_START,
		businessLoanProposal,
	);
}

export function* onUpdateBusinessLoanProposalResponseReset() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
		resetUpdateBusinessLoanProposalResponse,
	);
}
/** *********************  END UPDATE BUSINESS LOAN PROPOSAL  ******************** */

/** *********************  START GET BUSINESS LOAN PROPOSAL  ******************** */
export function* businessLoanProposalListGet({ payload }) {
	try {
		const key = 'GetBusinessLoanProposalListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			LoanIndexType: payload.loanIndexType,
			DatabaseName: payload.databaseName,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessLoanProposalListFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBusinessLoanPropsalListFailure(responseData.data));
		} else {
			yield put(getBusinessLoanPropsalListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBusinessLoanPropsalListFailure(error));
	}
}

export function* resetGetBusinessLoanProposalListResponse() {
	yield put(getBusinessLoanPropsalListResponseChanged());
}

export function* onGetBusinessLoanProposal() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_START,
		businessLoanProposalListGet,
	);
}

export function* onGetBusinessLoanProposalListResponseReset() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_RESPONSE_RESET,
		resetGetBusinessLoanProposalListResponse,
	);
}
/** *********************  END GET BUSINESS LOAN PROPOSAL ******************** */

/** *********************  START APPROVE BUSINESS LOAN PROPOSAL  ******************** */
export function* approveBusinessProposalData({ payload }) {
	try {
		const key = 'BusinessLoanProposalApprovalKey';
		const jsonData = {
			LoanIndexID: payload.loanIndexID,
			AppraisalLevel: payload.appraisalLevel,
			AppraisalDoneBy: payload.userID,
			AppraisalDoneByUserName: payload.userName,
			AppraisalApproveSetupID: payload.appraisalApproveSetupID,
			AppraisalApproveID: payload.appraisalApproveID,
			IsAppraisalApproveNeeded: payload.isAppraisalApproveNeeded,
			IsAllAppraisalApproved: payload.isAllApproved,
			NoOfAppraisalApproval: payload.noOfAppraisalApproval,
			AppraisalNotes: payload.appraisalNotes,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessLoanProposalApprove, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(approveBusinessProposalFailure(responseData.data));
		} else {
			yield put(approveBusinessProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(approveBusinessProposalFailure(error));
	}
}

export function* resetApproveBusinessProposalResponse() {
	yield put(approveBusinessProposalResponseChanged());
}

export function* onApproveBusinessProposalDetails() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_START,
		approveBusinessProposalData,
	);
}

export function* onApproveBusinessProposalResponseReset() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_RESPONSE_RESET_START,
		resetApproveBusinessProposalResponse,
	);
}
/** *********************  END APPROVE BUSINESS LOAN PROPOSAL  ******************** */

/** *********************  START FETCH BUSINESS LOAN PROPOSAL  ******************** */
export function* fetchBusinessLoanProposalData({ payload }) {
	try {
		const key = 'FetchBusinessLoanProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			LoanIndexID: payload.loanIndexID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessLoanProposalDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessLoanProposalFailure(responseData.data));
		} else {
			yield put(fetchBusinessLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessLoanProposalFailure(error));
	}
}

export function* resetFetchBusinessLoanProposalResponse() {
	yield put(fetchBusinessLoanProposalResponseChanged());
}

export function* onFetchBusinessLoanProposalDetails() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_START,
		fetchBusinessLoanProposalData,
	);
}

export function* onFetchBusinessLoanProposalResponseReset() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
		resetFetchBusinessLoanProposalResponse,
	);
}

/** *********************  END FETCH BUSINESS LOAN PROPOSAL  ******************** */

/** *********************  START DELETE BUSINESS LOAN PROPOSAL   ******************** */
export function* deleteLoanProposalBusiness({ payload }) {
	try {
		const key = 'DeleteBusinessLoanProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			LoanIndexID: payload.loanIndexID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessLoanProposalDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBusinessLoanProposalFailure(responseData.data));
		} else {
			yield put(deleteBusinessLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBusinessLoanProposalFailure(error));
	}
}

export function* resetDeleteBusinessLoanProposalResponse() {
	yield put(deleteBusinessLoanProposalResponseChanged());
}

export function* onDeleteBusinessLoanProposalDetail() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_START,
		deleteLoanProposalBusiness,
	);
}

export function* onDeleteBusinessLoanProposalResponseReset() {
	yield takeLatest(
		BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
		resetDeleteBusinessLoanProposalResponse,
	);
}
/** *********************  END DELETE BUSINESS LOAN PROPOSAL  ******************** */

/** *********************  END FETCH BUSINESS LOAN PROPOSAL  ******************** */


export function* businessLoanProposalSaga() {
	yield all([
		call(onCreateBusinessLoanProposal),
		call(onCreateBusinessLoanProposalResponseReset),
		call(onUpdateBusinessLoanProposal),
		call(onUpdateBusinessLoanProposalResponseReset),
		call(onGetBusinessLoanProposal),
		call(onGetBusinessLoanProposalListResponseReset),
		call(onApproveBusinessProposalDetails),
		call(onApproveBusinessProposalResponseReset),
		call(onFetchBusinessLoanProposalDetails),
		call(onFetchBusinessLoanProposalResponseReset),
		call(onDeleteBusinessLoanProposalDetail),
		call(onDeleteBusinessLoanProposalResponseReset),
		call(onGetBusinessLoanProposalListResponseReset),
	]);
}
