/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const verificationDetailsAdd = (verificationData) => {
	const data = {
		AddBusinessTokenVerification: verificationData,
	};
	const url = `${API_URL}addBusinessVerification`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchVerificationDetails = (fetchData) => {
	const data = {
		GetBusinessTokenVerify: fetchData,
	};
	const url = `${API_URL}getBusinessVerification`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateVerificationDetails = (updateData) => {
	const data = {
		UpdateVerificationDetailsToken: updateData,
	};
	const url = `${API_URL}updateBusinessVerification`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
