/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ProductActionTypes from './product.types';
import {
	productDetailsAdd,
	productDetailsUpdate,
	productListGet,
	productDetailsFetch,
	productDetailsDelete,
} from './product.service';

import {
	addProductDetailsSuccess,
	addProductDetailsFailure,
	addProductDetailsResponseChanged,
	updateProductDetailsSuccess,
	updateProductDetailsFailure,
	updateProductDetailsResponseChanged,
	getProductListSuccess,
	getProductListFailure,
	getProductListResponseChanged,
	fetchProductDetailsSuccess,
	fetchProductDetailsFailure,
	fetchProductDetailsResponseChanged,
	deleteProductDetailsSuccess,
	deleteProductDetailsFailure,
	deleteProductDetailsResponseChanged,
} from './product.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD PRODUCT DETAILS  ******************** */
export function* createProduct({ payload }) {
	try {
		const key = 'AddProductDetailKey';
		const jsonData = {
			ProductName: payload.productName,
			ProductLevel: payload.productLevel,
			IsFunder: payload.isFunder,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductDetailsFailure(responseData.data));
		} else {
			yield put(addProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductDetailsFailure(error));
	}
}

export function* resetAddProductResponse() {
	yield put(addProductDetailsResponseChanged());
}

export function* onCreateProductDetails() {
	yield takeLatest(ProductActionTypes.ADD_PRODUCT_DETAILS_START, createProduct);
}

export function* onCreateProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_RESET,
		resetAddProductResponse,
	);
}
/** *********************  END ADD PRODUCT DETAILS ******************** */

/** *********************  START UPDATE PRODUCT DETAILS  ******************** */
export function* productUpdate({ payload }) {
	try {
		const key = 'UpdateProductDetailsKey';
		const jsonData = {
			ProductID: payload.productID,
			ProductName: payload.productName,
			ProductLevel: payload.productLevel,
			OldProductName: payload.oldProductName,
			OldProductLevel: payload.oldProductLevel,
			IsFunder: payload.isFunder,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProductDetailsFailure(responseData.data));
		} else {
			yield put(updateProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProductDetailsFailure(error));
	}
}

export function* resetUpdateProductDetailsResponse() {
	yield put(updateProductDetailsResponseChanged());
}

export function* onUpdateProductDetails() {
	yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_DETAILS_START, productUpdate);
}

export function* onUpdateProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_RESET,
		resetUpdateProductDetailsResponse,
	);
}
/** *********************  END UPDATE PRODUCT DETAILS ******************** */

/** *********************  START GET PRODUCT LIST  ******************** */
export function* productDetailsListGet({ payload }) {
	try {
		const key = 'GetProductListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getProductListFailure(responseData.data));
		} else {
			yield put(getProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProductListFailure(error));
	}
}

export function* resetGetProductListResponse() {
	yield put(getProductListResponseChanged());
}

export function* onGetProductListDetails() {
	yield takeLatest(ProductActionTypes.GET_PRODUCT_LIST_START, productDetailsListGet);
}

export function* onGetProductListResponseReset() {
	yield takeLatest(
		ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_RESET,
		resetGetProductListResponse,
	);
}
/** *********************  END GET PRODUCT LIST ******************** */

/** *********************  START FETCH PRODUCT DETAILS  ******************** */
export function* fetchProductData({ payload }) {
	try {
		const key = 'FetchProductDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductDetailsFailure(responseData.data));
		} else {
			yield put(fetchProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductDetailsFailure(error));
	}
}

export function* resetFetchProductDetailsResponse() {
	yield put(fetchProductDetailsResponseChanged());
}

export function* onFetchProductDetails() {
	yield takeLatest(ProductActionTypes.FETCH_PRODUCT_DETAILS_START, fetchProductData);
}

export function* onFetchProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_RESET,
		resetFetchProductDetailsResponse,
	);
}
/** *********************  END FETCH PRODUCT DETAILS ******************** */

/** *********************  START DELETE PRODUCT DETAILS  ******************** */
export function* productDelete({ payload }) {
	try {
		const key = 'DeleteProductDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ProductID: payload.productID,
			ProductName: payload.productName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteProductDetailsFailure(responseData.data));
		} else {
			yield put(deleteProductDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteProductDetailsFailure(error));
	}
}

export function* resetDeleteProductDetailsResponse() {
	yield put(deleteProductDetailsResponseChanged());
}

export function* onDeleteProductDetail() {
	yield takeLatest(ProductActionTypes.DELETE_PRODUCT_DETAILS_START, productDelete);
}

export function* onDeleteProductResponseReset() {
	yield takeLatest(
		ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_RESET,
		resetDeleteProductDetailsResponse,
	);
}
/** *********************  END DELETE PRODUCT DETAILS ******************** */

export function* productMasterSaga() {
	yield all([
		call(onCreateProductDetails),
		call(onCreateProductResponseReset),
		call(onUpdateProductDetails),
		call(onUpdateProductResponseReset),
		call(onGetProductListDetails),
		call(onGetProductListResponseReset),
		call(onFetchProductDetails),
		call(onFetchProductResponseReset),
		call(onDeleteProductDetail),
		call(onDeleteProductResponseReset),
	]);
}
