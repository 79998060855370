/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import repaymentCollectionReportTypes from './RepaymentCollectionReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	FetchGroupMemberListSelectorStatus: '',
	FetchGroupMemberListSelectorResponse: null,
	fetchPostRepaymentCollectionResponse: null,
	fetchPostRepaymentCollectionArr: [],
	fetchPostRepaymentCollectionExcelResponse: null,
	fetchPostRepaymentCollectionExcelArr: [],
};

const repaymentCollectionReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_SUCCESS:
			return {
				...state,
				FetchGroupMemberListSelectorResponse: action.payload,
				FetchGroupMemberListSelectorStatus: 'Success',
			};

		case repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_FAILURE:
			return {
				...state,
				FetchGroupMemberListSelectorResponse: null,
				FetchGroupMemberListSelectorStatus: action.payload,
			};

		case repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_CHANGED:
			return {
				...state,
				FetchGroupMemberListSelectorResponse: null,
				FetchGroupMemberListSelectorStatus: null,
			};

		case repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_RESPONSE_CHANGED:
			return {
				...state,
				FetchGroupMemberListSelectorStatus: null,
				FetchGroupMemberListSelectorResponse: null,
			};

		case repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_RESET:
			return { ...state, INITIAL_STATE };

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_SUCCESS:
			return {
				...state,
				fetchPostRepaymentCollectionResponse: action.payload,
				fetchPostRepaymentCollectionArr: docodeResponse(
					action.payload,
					'RepaymentCollectionListKey',
				),
			};

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_FAILURE:
			return {
				...state,
				fetchPostRepaymentCollectionResponse: null,
				fetchPostRepaymentCollectionArr: action.payload,
			};

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_CHANGED:
			return {
				...state,
				fetchPostRepaymentCollectionResponse: null,
			};

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_RESPONSE_CHANGED:
			return {
				...state,
				fetchPostRepaymentCollectionResponse: null,
			};

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_RESET:
			return { ...state, INITIAL_STATE };

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_SUCCESS:
			return {
				...state,
				fetchPostRepaymentCollectionExcelResponse: action.payload,
				fetchPostRepaymentCollectionExcelArr: docodeResponse(
					action.payload,
					'RepaymentCollectionExcelKey',
				),
			};

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_FAILURE:
			return {
				...state,
				fetchPostRepaymentCollectionExcelResponse: null,
				fetchPostRepaymentCollectionExcelArr: action.payload,
			};

		case repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_RESET:
			return { ...state, INITIAL_STATE };

		default:
			return state;
	}
};

export default repaymentCollectionReportReducer;
