/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import regularDemandReportTypes from './regular-demand-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getRegularDemandDetailResponse: null,
	getRegularDemandDetailArr: [],
	loanAmount: 0,
	loanOSAmount: 0,
	demandAmount: 0,
	overdueAmount: 0,

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_SUCCESS:
			return {
				...state,
				getRegularDemandDetailResponse: action.payload,
				getRegularDemandDetailArr: docodeResponse(action.payload, 'GetRegularDemandReportKey'),
				loanAmount: action.payload.TotalLoanAmount,
				loanOSAmount: action.payload.TotalLoanOLBAmount,
				demandAmount: action.payload.TotalDemandAmount,
				overdueAmount: action.payload.TotalLoanAmountArrear,
			};

		case regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_FAILURE:
			return { ...state, getRegularDemandDetailResponse: action.payload };

		case regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_RESPONSE_CHANGED:
			return { ...state, getRegularDemandDetailResponse: null };

		default:
			return state;
	}
};
