/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import BusinessFundActionTypes from './business-fund.types';

/** ***************** ADD BUSINESS FUND DETAILS START *********************** */
export const addBusinessFundDetailsStart = (addData) => ({
	type: BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_START,
	payload: addData,
});

export const addBusinessFundDetailsSuccess = (successData) => ({
	type: BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_SUCCESS,
	payload: successData,
});

export const addBusinessFundDetailsFailure = (failureData) => ({
	type: BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_FAILURE,
	payload: failureData,
});

export const addBusinessFundDetailsResponseResetStart = () => ({
	type: BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_RESPONSE_RESET,
});

export const addBusinessFundDetailsResponseChanged = () => ({
	type: BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS FUND DETAILS END *********************** */

/** ***************** UPDATE BUSINESS FUND DETAILS START *********************** */
export const updateBusinessFundDetailsStart = (updateData) => ({
	type: BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_START,
	payload: updateData,
});

export const updateBusinessFundDetailsSuccess = (successData) => ({
	type: BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_SUCCESS,
	payload: successData,
});

export const updateBusinessFundDetailsFailure = (failureData) => ({
	type: BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_FAILURE,
	payload: failureData,
});

export const updateBusinessFundDetailsResponseResetStart = () => ({
	type: BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_RESPONSE_RESET,
});

export const updateBusinessFundDetailsResponseChanged = () => ({
	type: BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE BUSINESS FUND DETAILS END *********************** */

/** ***************** GET BUSINESS FUND LIST START *********************** */
export const getBusinessFundListStart = (getData) => ({
	type: BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_START,
	payload: getData,
});

export const getBusinessFundListSuccess = (successData) => ({
	type: BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_SUCCESS,
	payload: successData,
});

export const getBusinessFundListFailure = (failureData) => ({
	type: BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_FAILURE,
	payload: failureData,
});

export const getBusinessFundListResponseResetStart = () => ({
	type: BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_RESPONSE_RESET,
});

export const getBusinessFundListResponseChanged = () => ({
	type: BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_RESPONSE_CHANGED,
});

/** ***************** GET BUSINESS FUND LIST END *********************** */

/** ***************** FETCH BUSINESS FUND DETAILS START *********************** */
export const fetchBusinessFundDetailsStart = (fetchData) => ({
	type: BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_START,
	payload: fetchData,
});

export const fetchBusinessFundDetailsSuccess = (successData) => ({
	type: BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchBusinessFundDetailsFailure = (failureData) => ({
	type: BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchBusinessFundDetailsResponseResetStart = () => ({
	type: BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_RESPONSE_RESET,
});

export const fetchBusinessFundDetailsResponseChanged = () => ({
	type: BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH BUSINESS FUND DETAILS END *********************** */

/** ***************** DELETE BUSINESS FUND DETAILS START *********************** */
export const deleteBusinessFundDetailsStart = (deleteData) => ({
	type: BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_START,
	payload: deleteData,
});

export const deleteBusinessFundDetailsSuccess = (successData) => ({
	type: BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteBusinessFundDetailsFailure = (failureData) => ({
	type: BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteBusinessFundDetailsResponseResetStart = () => ({
	type: BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_RESPONSE_RESET,
});

export const deleteBusinessFundDetailsResponseChanged = () => ({
	type: BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE BUSINESS FUND DETAILS END *********************** */

/** ***************** FETCH ALL BUSINESS FUND LIST START *********************** */
export const fetchAllBusinessFundListStart = (fetchBusinessFunds) => ({
	type: BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_START,
	payload: fetchBusinessFunds,
});

export const fetchAllBusinessFundListSuccess = (successData) => ({
	type: BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_SUCCESS,
	payload: successData,
});

export const fetchAllBusinessFundListFailure = (failureData) => ({
	type: BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_FAILURE,
	payload: failureData,
});

export const fetchAllBusinessFundListResponseResetStart = () => ({
	type: BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_RESPONSE_RESET,
});

export const fetchAllBusinessFundListResponseChanged = () => ({
	type: BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL BUSINESS FUND LIST END *********************** */
