/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import loginActionTypes from './login.types';
import { decodeApiResponse } from '../common/common.utils';
import generalTypesAction from '../genera.types';
import { getCurrencyList } from './login.utils';

const INITIAL_STATE = {
	loginStatus: '',
	loginResponse: null,
	loginDeocodedResponse: null,
	currencySymbols: '',
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case loginActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				loginResponse: action.payload,
				loginStatus: 'Success',
				loginDeocodedResponse: decodeApiResponse(
					action.payload.response,
					generalTypesAction.LOGIN_KEY,
				),
				currencySymbols: getCurrencyList('INR'),
			};

		case loginActionTypes.SIGN_IN_FAILURE:
			return {
				...state,
				loginResponse: action.payload,
				loginStatus: null,
				loginDeocodedResponse: decodeApiResponse(
					action.payload.response,
					generalTypesAction.LOGIN_KEY,
				),
			};

		case loginActionTypes.LOGIN_USER_RESPONSE_CHANGED:
			return {
				...state,
				loginResponse: null,
				loginStatus: null,
				loginDeocodedResponse: null,
			};

		case loginActionTypes.SIGN_IN_CHANGED:
			return {
				...state,
				loginStatus: null,
				loginResponse: null,
				loginDeocodedResponse: null,
				// isLogin: 0,
				// companyID: null,
				// userID: null,
				// userType: null,
			};

		case loginActionTypes.SIGN_IN_LOGOUT:
			return { ...state, INITIAL_STATE };

		default:
			return state;
	}
};

export default userReducer;
