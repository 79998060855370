/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import AccountDayBookReportActionTypes from './accountDayBookReport.types';

/** ***************** GET ACCOUNT DAY BOOK REPORT START *********************** */
export const fetchAccountDayBookReportStart = (getData) => ({
	type: AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_START,
	payload: getData,
});

export const getAccountDayBookReportSuccess = (successData) => ({
	type: AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_SUCCESS,
	payload: successData,
});

export const getAccountDayBookReportFailure = (failureData) => ({
	type: AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_FAILURE,
	payload: failureData,
});

export const fetchAccountDayBookReportResponseResetStart = () => ({
	type: AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
});

export const getAccountDayBookReportResponseChanged = () => ({
	type: AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_CHANGED,
});
/** ***************** GET ACCOUNT DAY BOOK REPORT END *********************** */
