/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ProductTypeActionTypes from './product-type.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addProductTypeDetailsResponse: null,
	updateProductTypeDetailsResponse: null,
	getProductTypeListResponse: null,
	getProductTypeListArr: [],
	fetchProductTypeDetailsResponse: null,
	fetchProductTypeDetailsArr: [],
	deleteProductTypeDetailsResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_SUCCESS:
			return { ...state, addProductTypeDetailsResponse: action.payload };

		case ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_FAILURE:
			return { ...state, addProductTypeDetailsResponse: action.payload };

		case ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED:
			return { ...state, addProductTypeDetailsResponse: null };

		case ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_SUCCESS:
			return { ...state, updateProductTypeDetailsResponse: action.payload };

		case ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_FAILURE:
			return { ...state, updateProductTypeDetailsResponse: action.payload };

		case ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateProductTypeDetailsResponse: null };

		case ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_SUCCESS:
			return {
				...state,
				getProductTypeListResponse: action.payload,
				getProductTypeListArr: docodeResponse(action.payload, 'GetProductTypeListKey'),
			};

		case ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_FAILURE:
			return { ...state, getProductTypeListResponse: action.payload };

		case ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_RESPONSE_CHANGED:
			return { ...state, getProductTypeListResponse: null };

		case ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_SUCCESS:
			return {
				...state,
				fetchProductTypeDetailsResponse: action.payload,
				fetchProductTypeDetailsArr: docodeResponse(
					action.payload,
					'FetchProductTypeDetailsKey',
				),
			};

		case ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_FAILURE:
			return { ...state, fetchProductTypeDetailsResponse: action.payload };

		case ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchProductTypeDetailsResponse: null,
				fetchProductTypeDetailsArr: [],
			};

		case ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_SUCCESS:
			return { ...state, deleteProductTypeDetailsResponse: action.payload };

		case ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_FAILURE:
			return { ...state, deleteProductTypeDetailsResponse: action.payload };

		case ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteProductTypeDetailsResponse: null };

		default:
			return state;
	}
};
