/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

// export const getStaffAllList = () => {
// 	const url = `${API_URL}GetAllStaffList`;
// 	return axios.get(url, { headers: { 'Content-type': 'application/json' } });
// };

export const getStaffAllList = (staffListData) => {
	const data = {
		FetchStaffWithFilterToken: staffListData,
	};
	const url = `${API_URL}GetAllStaffList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
