/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchCollectionReportActionTypes from './branchCollectionReport.types';

import {
    branchCollectionReportListGet,
} from './branchCollectionReport.service';

import {
    getBranchCollectionReportDetailsSuccess,
    getBranchCollectionReportDetailsFailure,
    getBranchCollectionReportDetailsResponseChanged,

} from './branchCollectionReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET BRANCH COLLECTION REPORT DETAILS  ******************** */
export function* getBranchCollectionReportList({ payload }) {
	try {
		const key = 'GetBranchCollectionReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
			userID: payload.userID,
			userlevel: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchCollectionReportListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchCollectionReportDetailsFailure(responseData.data));
		} else {
			yield put(getBranchCollectionReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchCollectionReportDetailsFailure(error));
	}
}

export function* resetgetBranchCollectionReportListResponse() {
	yield put(getBranchCollectionReportDetailsResponseChanged());
}

export function* onGetBranchCollectionReportListDetails() {
	yield takeLatest(BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_START, getBranchCollectionReportList);
}

export function* onGetBranchCollectionReportListResponseReset() {
	yield takeLatest(BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_RESPONSE_RESET, resetgetBranchCollectionReportListResponse);
}
/** *********************  END GET BRANCH COLLECTION REPORT DETAILS ******************** */

export function* branchCollectionReportSaga() {
	yield all([
		call(onGetBranchCollectionReportListDetails),
		call(onGetBranchCollectionReportListResponseReset),
	]);
}
