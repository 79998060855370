/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ActiveMemberActionTypes from './active-member.types';

import {
    ActiveMemberListGet,
    officerBranchListGet,
	ActiveMembeExcelListGet,

} from './active-member.service';

import {
    getActiveMemberDetailsSuccess,
    getActiveMemberDetailsFailure,
    getActiveMemberDetailsResponseChanged,
    getOfficerBranchDetailsSuccess,
    getOfficerBranchDetailsFailure,
    getOfficerBranchDetailsResponseChanged,

	fetchActiveMemberReportExcelFailure,
	fetchActiveMemberReportExcelSuccess,
	fetchActiveMemberReportResponseExcelResetChanged,

} from './active-member.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getActiveMemberList({ payload }) {
	try {
		const key = 'GetActiveMemberReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
            GroupID: payload.groupID,
            FunderID: payload.funderID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
			TillDate: payload.TillDate,
            IsThriftActive: payload.IsThriftActive,
			SearchType: payload.SearchType,
			UserLevel: payload.userLevel,
			UserRole: payload.UserRoleName,
			UserID: payload.userID,
            Limit: payload.Limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(ActiveMemberListGet, encodeData);
        debugger;
		if (responseData.data.status === '100') {
			yield put(getActiveMemberDetailsFailure(responseData.data));
		} else {
			yield put(getActiveMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getActiveMemberDetailsFailure(error));
	}
}

export function* resetGetActiveMemberListResponse() {
	yield put(getActiveMemberDetailsResponseChanged());
}

export function* onGetActiveMemberListDetails() {
	yield takeLatest(ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_START, getActiveMemberList);
}

export function* onGetActiveMemberListResponseReset() {
	yield takeLatest(ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_RESPONSE_RESET, resetGetActiveMemberListResponse);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_START, getOfficerBranchList);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET, resetGetActiveMemberListResponse);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */



/** *********************  START GET ACTIVE MEMBER EXCEL REPORT DETAILS  ******************** */
export function* getActiveMemberReportExcelList({ payload }) {
	try {
		const key = 'GetActiveMemberReportExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
            GroupID: payload.groupID,
            FunderID: payload.funderID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
			TillDate: payload.TillDate,
            IsThriftActive: payload.IsThriftActive,
			SearchType: payload.SearchType,
			UserLevel: payload.userLevel,
			UserRole: payload.UserRoleName,
			UserID: payload.userID,
			RequestID: payload.RequestID
		};
		const encodeData = encodeJson(jsonData, key);
        debugger;
		const responseData = yield call(ActiveMembeExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchActiveMemberReportExcelFailure(responseData.data));
		} else {
			yield put(fetchActiveMemberReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchActiveMemberReportExcelFailure(error));
	}
}

export function* resetActiveMemberReportExcelListResponse() {
	yield put(fetchActiveMemberReportResponseExcelResetChanged());
}

export function* onGetActiveMemberExcelListDetails() {
	yield takeLatest(
		ActiveMemberActionTypes.FETCH_ACTIVE_MEMBER_REPORT_EXCEL_START,
		getActiveMemberReportExcelList,
	);
}

export function* onGetActiveMemberExcelListResponseReset() {
	yield takeLatest(
		ActiveMemberActionTypes.FETCH_ACTIVE_MEMBER_REPORT_RESPONSE_EXCEL_RESET_START,
		resetActiveMemberReportExcelListResponse,
	);
}
/** *********************  END GET ACTIVE MEMBER EXCEL REPORT LIST ******************** */


export function* reportActiveMemberSaga() {
	yield all([
		call(onGetActiveMemberListDetails),
		call(onGetActiveMemberListResponseReset),
        call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
		call(onGetActiveMemberExcelListDetails),
		call(onGetActiveMemberExcelListResponseReset),
	]);
}
