/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import kycSourcedReportTypes from "./kyc-sourced-report.types";

/** ***************** FETCH SANCTION REPORT DETAILS START *********************** */
export const getKYCSourcedReportStart = (addData) => ({
	type: kycSourcedReportTypes.GET_KYC_SOURCED_REPORT_START,
	payload: addData,
});

export const getKYCSourcedReportSuccess = (successData) => ({
	type: kycSourcedReportTypes.GET_KYC_SOURCED_REPORT_SUCCESS,
	payload: successData,
});

export const getKYCSourcedReportFailure = (failureData) => ({
	type: kycSourcedReportTypes.GET_KYC_SOURCED_REPORT_FAILURE,
	payload: failureData,
});

export const getKYCSourcedReportResponseResetStart = () => ({
	type: kycSourcedReportTypes.GET_KYC_SOURCED_REPORT_RESPONSE_RESET_START,
});

export const getKYCSourcedReportResponseResetChanged = () => ({
	type: kycSourcedReportTypes.GET_KYC_SOURCED_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH SANCTION REPORT DETAILS END *********************** */
