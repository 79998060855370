/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import KYCVerificationActionTypes from './kyc-verification.types';
// import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	callKYCaadharResponse: null,
	callKYCaadharotpResponse: null,
	callKYCpanResponse: null,
	callKYCgstResponse: null,
	callKYCcinResponse: null,
	callKYCitrResponse: null,
	callKYCmcaResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case KYCVerificationActionTypes.CALL_KYC_ADDHAR_SUCCESS:
			return { ...state, callKYCaadharResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_ADDHAR_FAILURE:
			return { ...state, callKYCaadharResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_ADDHAR_RESPONSE_CHANGED:
			return { ...state, callKYCaadharResponse: null };

		case KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_SUCCESS:
			return { ...state, callKYCaadharotpResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_FAILURE:
			return { ...state, callKYCaadharotpResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_RESPONSE_CHANGED:
			return { ...state, callKYCaadharotpResponse: null };

		case KYCVerificationActionTypes.CALL_KYC_PAN_SUCCESS:
			return { ...state, callKYCpanResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_PAN_FAILURE:
			return { ...state, callKYCpanResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_GST_SUCCESS:
			return { ...state, callKYCgstResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_GST_FAILURE:
			return { ...state, callKYCgstResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_GST_RESPONSE_CHANGED:
			return { ...state, callKYCgstResponse: null };

		case KYCVerificationActionTypes.CALL_KYC_CIN_SUCCESS:
			return { ...state, callKYCcinResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_CIN_FAILURE:
			return { ...state, callKYCcinResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_CIN_RESPONSE_CHANGED:
			return { ...state, callKYCcinResponse: null };

		case KYCVerificationActionTypes.CALL_KYC_ITR_SUCCESS:
			return { ...state, callKYCitrResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_ITR_FAILURE:
			return { ...state, callKYCitrResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_ITR_RESPONSE_CHANGED:
			return { ...state, callKYCitrResponse: null };

		case KYCVerificationActionTypes.CALL_KYC_MCA_SUCCESS:
			return { ...state, callKYCmcaResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_MCA_FAILURE:
			return { ...state, callKYCmcaResponse: action.payload };

		case KYCVerificationActionTypes.CALL_KYC_MCA_RESPONSE_CHANGED:
			return { ...state, callKYCmcaResponse: null };

		default:
			return state;
	}
};
