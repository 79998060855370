const GroupActionTypes = {
	ADD_GROUP_DETAILS_START: 'add_group_details_start',
	ADD_GROUP_DETAILS_SUCCESS: 'add_group_details_success',
	ADD_GROUP_DETAILS_FAILURE: 'add_group_details_failure',
	ADD_GROUP_DETAILS_RESPONSE_RESET: 'add_group_details_response_reset',
	ADD_GROUP_DETAILS_RESPONSE_CHANGED: 'add_group_details_response_changed',

	GET_GROUP_LIST_START: 'get_group_group_start',
	GET_GROUP_LIST_SUCCESS: 'get_group_group_success',
	GET_GROUP_LIST_FAILURE: 'get_group_group_failure',
	GET_GROUP_LIST_RESPONSE_RESET: 'get_group_group_response_reset',
	GET_GROUP_LIST_RESPONSE_CHANGED: 'get_group_group_response_changed',

	// All Group List
	FETCH_ALL_GROUP_START: 'fetch_all_group_start',
	FETCH_ALL_GROUP_SUCCESS: 'fetch_all_group_success',
	FETCH_ALL_GROUP_FAILURE: 'fetch_all_group_failure',
	FETCH_ALL_GROUP_RESPONSE_RESET_START: 'fetch_all_group_response_reset',
	FETCH_ALL_GROUP_RESPONSE_CHANGED: 'fetch_all_group_response_changed',

	// All Approved Member List
	FETCH_ALL_APPROVED_MEMBER_START: 'fetch_all_approved_member_start',
	FETCH_ALL_APPROVED_MEMBER_SUCCESS: 'fetch_all_approved_member_success',
	FETCH_ALL_APPROVED_MEMBER_FAILURE: 'fetch_all_approved_member_failure',
	FETCH_ALL_APPROVED_MEMBER_RESPONSE_RESET_START: 'fetch_all_approved_member_response_reset',
	FETCH_ALL_APPROVED_MEMBER_RESPONSE_CHANGED: 'fetch_all_approved_member_response_changed',

	// All Officer List
	GET_ALL_OFFICER_START: 'get_all_officer_start',
	GET_ALL_OFFICER_SUCCESS: 'get_all_officer_success',
	GET_ALL_OFFICER_FAILURE: 'get_all_officer_failure',
	GET_ALL_OFFICER_RESPONSE_RESET: 'get_all_officer_response_reset',
	GET_ALL_OFFICER_RESPONSE_CHANGED: 'get_all_officer_response_changed',

	// All Group Details
	GET_ALL_GROUP_DETAILS_START: 'get_all_group_details_start',
	GET_ALL_GROUP_DETAILS_SUCCESS: 'get_all_group_details_success',
	GET_ALL_GROUP_DETAILS_FAILURE: 'get_all_group_details_failure',
	GET_ALL_GROUP_DETAILS_RESPONSE_RESET: 'get_all_group_details_response_reset',
	GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED: 'get_all_group_details_response_changed',

	// branch Officer List
	GET_BRANCH_OFFICER_START: 'GET_BRANCH_OFFICER_START',
	GET_BRANCH_OFFICER_SUCCESS: 'GET_BRANCH_OFFICER_SUCCESS',
	GET_BRANCH_OFFICER_FAILURE: 'GET_BRANCH_OFFICER_FAILURE',
	GET_BRANCH_OFFICER_RESPONSE_RESET: 'GET_BRANCH_OFFICER_RESPONSE_RESET',
	GET_BRANCH_OFFICER_RESPONSE_CHANGED: 'GET_BRANCH_OFFICER_RESPONSE_CHANGED',
};
export default GroupActionTypes;
