/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import JWT from 'expo-jwt';
import { decode } from 'js-base64';
import { decodeTokenJson } from '../enode-decode';

// Decode
export const getDecodedJsonData = (jsonData, key) => {
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};

export const decodeApiResponse = (jsonData, key) => {
	const decodedData = decodeTokenJson(jsonData, key);
	return decodedData;
};

export const docodeResponse = (response, key) => {
	const docodeVal = decodeTokenJson(response.response, key);
	return docodeVal.data;
};

// Decode Token with Base64
export const decodeBase64Response = (reposneToken, decodeKey) => {
	let decodeResponse = decodeTokenJson(reposneToken.response, decodeKey);
	if(decodeResponse) {
	  decodeResponse = decode(decodeResponse.data);
	  decodeResponse = JSON.parse(decodeResponse);
	}
	return decodeResponse;
  };
  

export const langData = [
	{
		key: 0,
		isSelected: false,
		label: 'English',
		value: 'ENGLISH',
	},
	{
		key: 1,
		isSelected: false,
		label: 'Tamil',
		value: 'TAMIL',
	},
	{
		key: 2,
		isSelected: false,
		label: 'Telugu',
		value: 'TELUGU',
	},
	{
		key: 3,
		isSelected: false,
		label: 'Kannada',
		value: 'KANNADA',
	},
	{
		key: 4,
		isSelected: false,
		label: 'Malayalam',
		value: 'MALAYALAM',
	},
	{
		key: 5,
		isSelected: false,
		label: 'Hindi',
		value: 'HINDI',
	},
];

export const dateDiffInDays = (date1, date2) => {
	// Parse the input dates into Date objects
	const d1 = new Date(date1);
	const d2 = new Date(date2);

	// Calculate the time difference in milliseconds
	const timeDiff = d2 - d1;

	// Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
	const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));

	return daysDiff + 1;
};

export const getCurrentDate = () => {
	const newDate = new Date();
	const date = newDate.getDate();
	const month = newDate.getMonth() + 1;
	const year = newDate.getFullYear();
	const separator = '-';
	return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
};
