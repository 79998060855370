const BankActionTypes = {
	ADD_BUSINESS_BANK_START: 'add_business_bank_start',
	ADD_BUSINESS_BANK_SUCCESS: 'add_business_bank_success',
	ADD_BUSINESS_BANK_FAILURE: 'add_business_bank_failure',
	ADD_BUSINESS_BANK_RESPONSE_RESET: 'add_business_bank_response_reset',
	ADD_BUSINESS_BANK_RESPONSE_CHANGED: 'add_business_bank_response_changed',

	FETCH_BUSINESS_BANK_START: 'fetch_business_bank_start',
	FETCH_BUSINESS_BANK_SUCCESS: 'fetch_business_bank_success',
	FETCH_BUSINESS_BANK_FAILURE: 'fetch_business_bank_failure',
	FETCH_BUSINESS_BANK_RESPONSE_RESET: 'fetch_business_bank_response_reset',
	FETCH_BUSINESS_BANK_RESPONSE_CHANGED: 'fetch_business_bank_response_changed',

	UPDATE_BUSINESS_BANK_START: 'update_business_bank_start',
	UPDATE_BUSINESS_BANK_SUCCESS: 'update_business_bank_success',
	UPDATE_BUSINESS_BANK_FAILURE: 'update_business_bank_failure',
	UPDATE_BUSINESS_BANK_RESPONSE_RESET: 'update_business_bank_response_reset',
	UPDATE_BUSINESS_BANK_RESPONSE_CHANGED: 'update_business_bank_response_changed',
};
export default BankActionTypes;
