/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import kycSourcedReportTypes from './kyc-sourced-report.types';

import { getKYCSourcedReportDetails } from './kyc-sourced-report.service';

import {
    getKYCSourcedReportSuccess,
    getKYCSourcedReportFailure,
    getKYCSourcedReportResponseResetChanged 
} from './kyc-sourced-report.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET KYC SOURCED DETAILS  ******************** */
export function* getKYCReport({ payload }) {
	try {
		const key = 'KYCSourcedReportDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CenterID: payload.CenterID,
			FromDate: payload.FromDate,
            ToDate: payload.ToDate,
			BranchID: payload.branchID,
            OfficerID: payload.OfficerID
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getKYCSourcedReportDetails, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(getKYCSourcedReportFailure(responseData.data));
		} else {
			yield put(getKYCSourcedReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getKYCSourcedReportFailure(error));
	}
}

export function* resetGetKYCSourcedReportResponse() {
	yield put(getKYCSourcedReportResponseResetChanged());
}

export function* onGetKYCSourcedReportDetails() {
	yield takeLatest(kycSourcedReportTypes.GET_KYC_SOURCED_REPORT_START, getKYCReport);
}

export function* onGetKYCSourcedReportResponseReset() {
	yield takeLatest(kycSourcedReportTypes.GET_KYC_SOURCED_REPORT_RESPONSE_RESET_START, resetGetKYCSourcedReportResponse);
}
/** *********************  END GET KYC SOURCED DETAILS ******************** */

export function* kycSourcedReportSaga() {
	yield all([call(onGetKYCSourcedReportDetails), 
        call(onGetKYCSourcedReportResponseReset)]);
}
