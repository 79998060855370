import PartnerDetailsActionTypes from './partner-details.types';

/** ***************** ADD BUSINESS PARTNER START *********************** */
export const addBusinessPartnerStart = (partnerData) => ({
	type: PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_START,
	payload: partnerData,
});

export const addBusinessPartnerSuccess = (successValue) => ({
	type: PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_SUCCESS,
	payload: successValue,
});

export const addBusinessPartnerFailure = (failureData) => ({
	type: PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_FAILURE,
	payload: failureData,
});

export const addBusinessPartnerResponseResetStart = () => ({
	type: PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_RESPONSE_RESET,
});

export const addBusinessPartnerResponseChanged = () => ({
	type: PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS PARTNER END *********************** */

/** ***************** UPDATE BUSINESS PARTNER START *********************** */
export const updateBusinessPartnerStart = (updateData) => ({
	type: PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_START,
	payload: updateData,
});

export const updateBusinessPartnerSuccess = (successData) => ({
	type: PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_SUCCESS,
	payload: successData,
});

export const updateBusinessPartnerFailure = (failureData) => ({
	type: PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_FAILURE,
	payload: failureData,
});

export const updateBusinessPartnerResponseResetStart = () => ({
	type: PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_RESPONSE_RESET,
});

export const updateBusinessPartnerResponseChanged = () => ({
	type: PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_RESPONSE_CHANGED,
});

/** ***************** UPDATE BUSINESS PARTNER END *********************** */

/** ***************** FETCH BUSINESS PARTNER START *********************** */
export const fetchBusinessPartnerStart = (fetchData) => ({
	type: PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_START,
	payload: fetchData,
});

export const fetchBusinessPartnerSuccess = (successData) => ({
	type: PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_SUCCESS,
	payload: successData,
});

export const fetchBusinessPartnerFailure = (failureData) => ({
	type: PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_FAILURE,
	payload: failureData,
});

export const fetchBusinessPartnerResponseResetStart = () => ({
	type: PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_RESPONSE_RESET,
});

export const fetchBusinessPartnerResponseChanged = () => ({
	type: PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS PARTNER END *********************** */
