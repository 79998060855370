/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import InActiveMemberActionTypes from './in-active-member.types';

import {
    InActiveMemberListGet,
    officerBranchListGet,

} from './in-active-member.service';

import {
    getInActiveMemberDetailsSuccess,
    getInActiveMemberDetailsFailure,
    getInActiveMemberDetailsResponseChanged,
    getOfficerBranchDetailsSuccess,
    getOfficerBranchDetailsFailure,
    getOfficerBranchDetailsResponseChanged,

} from './in-active-member.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET INACTIVE MEMBER REPORT DETAILS  ******************** */
export function* getInActiveMemberList({ payload }) {
	try {
		const key = 'GetInActiveMemberReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			// OfficerID: payload.officerID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
            GroupID: payload.groupID,
            FunderID: payload.funderID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
            // IsThriftActive: payload.IsThriftActive,
			UserLevel: payload.userLevel,
            Limit: payload.Limit,
		};
		const encodeData = encodeJson(jsonData, key);
        debugger;
		const responseData = yield call(InActiveMemberListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getInActiveMemberDetailsFailure(responseData.data));
		} else {
			yield put(getInActiveMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getInActiveMemberDetailsFailure(error));
	}
}

export function* resetGetInActiveMemberListResponse() {
	yield put(getInActiveMemberDetailsResponseChanged());
}

export function* onGetInActiveMemberListDetails() {
	yield takeLatest(InActiveMemberActionTypes.GET_INACTIVE_MEMBER_LIST_START, getInActiveMemberList);
}

export function* onGetInActiveMemberListResponseReset() {
	yield takeLatest(InActiveMemberActionTypes.GET_INACTIVE_MEMBER_LIST_RESPONSE_RESET, resetGetInActiveMemberListResponse);
}
/** *********************  END GET INACTIVE MEMBER REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(InActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_START, getOfficerBranchList);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(InActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET, resetGetInActiveMemberListResponse);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */

export function* reportInActiveMemberSaga() {
	yield all([
		call(onGetInActiveMemberListDetails),
		call(onGetInActiveMemberListResponseReset),
        call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
	]);
}
