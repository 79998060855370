import KYCVerificationActionTypes from './kyc-verification.types';

/** ***************** CALL KYC ADDHAR DETAILS START *********************** */
export const callKYCAadharStart = (bankData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_START,
	payload: bankData,
});

export const callKYCAadharSuccess = (successValue) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_SUCCESS,
	payload: successValue,
});

export const callKYCAadharFailure = (failureData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_FAILURE,
	payload: failureData,
});

export const callKYCAadharResponseResetStart = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_RESPONSE_RESET,
});

export const callKYCAadharResponseChanged = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_RESPONSE_CHANGED,
});

/** ***************** CALL KYC ADDHAR DETAILS END *********************** */

/** ***************** CALL KYC ADDHAR OTP DETAILS START *********************** */
export const callKYCAadharotpStart = (bankData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_START,
	payload: bankData,
});

export const callKYCAadharotpSuccess = (successValue) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_SUCCESS,
	payload: successValue,
});

export const callKYCAadharotpFailure = (failureData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_FAILURE,
	payload: failureData,
});

export const callKYCAadharotpResponseResetStart = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_RESPONSE_RESET,
});

export const callKYCAadharotpResponseChanged = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_RESPONSE_CHANGED,
});

/** ***************** CALL KYC ADDHAR OTP DETAILS END *********************** */

/** ***************** CALL KYC PAN DETAILS START *********************** */
export const callKYCPanStart = (fetchData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_PAN_START,
	payload: fetchData,
});

export const callKYCPanSuccess = (successData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_PAN_SUCCESS,
	payload: successData,
});

export const callKYCPanFailure = (failureData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_PAN_FAILURE,
	payload: failureData,
});

export const callKYCPanResponseResetStart = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_PAN_RESPONSE_RESET,
});

export const callKYCPanResponseChanged = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_PAN_RESPONSE_CHANGED,
});

/** ***************** CALL KYC PAN DETAILS END *********************** */

/** ***************** CALL KYC GST DETAILS START *********************** */
export const callKYCgstStart = (bankData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_GST_START,
	payload: bankData,
});

export const callKYCgstSuccess = (successValue) => ({
	type: KYCVerificationActionTypes.CALL_KYC_GST_SUCCESS,
	payload: successValue,
});

export const callKYCgstFailure = (failureData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_GST_FAILURE,
	payload: failureData,
});

export const callKYCgstResponseResetStart = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_GST_RESPONSE_RESET,
});

export const callKYCgstResponseChanged = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_GST_RESPONSE_CHANGED,
});

/** ***************** CALL KYC GST DETAILS END *********************** */


/** ***************** CALL KYC CIN DETAILS START *********************** */
export const callKYCcinStart = (bankData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_CIN_START,
	payload: bankData,
});

export const callKYCcinSuccess = (successValue) => ({
	type: KYCVerificationActionTypes.CALL_KYC_CIN_SUCCESS,
	payload: successValue,
});

export const callKYCcinFailure = (failureData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_CIN_FAILURE,
	payload: failureData,
});

export const callKYCcinResponseResetStart = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_CIN_RESPONSE_RESET,
});

export const callKYCcinResponseChanged = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_CIN_RESPONSE_CHANGED,
});

/** ***************** CALL KYC CIN DETAILS END *********************** */

/** ***************** CALL KYC ITR DETAILS START *********************** */
export const callKYCitrStart = (bankData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ITR_START,
	payload: bankData,
});

export const callKYCitrSuccess = (successValue) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ITR_SUCCESS,
	payload: successValue,
});

export const callKYCitrFailure = (failureData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_ITR_FAILURE,
	payload: failureData,
});

export const callKYCitrResponseResetStart = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_ITR_RESPONSE_RESET,
});

export const callKYCitrResponseChanged = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_ITR_RESPONSE_CHANGED,
});

/** ***************** CALL KYC ITR DETAILS END *********************** */

/** ***************** CALL KYC MCA DETAILS START *********************** */
export const callKYCmcaStart = (bankData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_MCA_START,
	payload: bankData,
});

export const callKYCmcaSuccess = (successValue) => ({
	type: KYCVerificationActionTypes.CALL_KYC_MCA_SUCCESS,
	payload: successValue,
});

export const callKYCmcaFailure = (failureData) => ({
	type: KYCVerificationActionTypes.CALL_KYC_MCA_FAILURE,
	payload: failureData,
});

export const callKYCmcaResponseResetStart = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_MCA_RESPONSE_RESET,
});

export const callKYCmcaResponseChanged = () => ({
	type: KYCVerificationActionTypes.CALL_KYC_MCA_RESPONSE_CHANGED,
});

/** ***************** CALL KYC MCA DETAILS END *********************** */

