/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import repaymentCollectionReportTypes from './RepaymentCollectionReport.types';

export const fetchGroupMemberListSuccess = (user) => ({
	type: repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_SUCCESS,
	payload: user,
});

export const fetchGroupMemberListFailure = (error) => ({
	type: repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_FAILURE,
	payload: error,
});

export const fetchGroupMemberListStart = (userCredentials) => ({
	type: repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_START,
	payload: userCredentials,
});

export const fetchGroupMemberListReset = () => ({
	type: repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_RESET,
});

export const fetchGroupMemberListChanged = () => ({
	type: repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_CHANGED,
});

export const fetchPostRepaymentCollectionSuccess = (user) => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_SUCCESS,
	payload: user,
});

export const fetchPostRepaymentCollectionFailure = (error) => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_FAILURE,
	payload: error,
});

export const fetchPostRepaymentCollectionStart = (user) => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_START,
	payload: user,
});

export const fetchPostRepaymentCollectionReset = () => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_RESET,
});

export const fetchPostRepaymentCollectionChanged = () => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_CHANGED,
});

export const fetchPostRepaymentCollectionExcelStart = (user) => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_START,
	payload: user,
});

export const fetchPostRepaymentCollectionExcelSuccess = (user) => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_SUCCESS,
	payload: user,
});

export const fetchPostRepaymentCollectionExcelFailure = (error) => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_FAILURE,
	payload: error,
});

export const fetchPostRepaymentCollectionExcelReset = () => ({
	type: repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_RESET,
});
