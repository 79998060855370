const PartnerDetailsActionTypes = {
	ADD_BUSINESS_PARTNER_START: 'add_business_partner_start',
	ADD_BUSINESS_PARTNER_SUCCESS: 'add_business_partner_success',
	ADD_BUSINESS_PARTNER_FAILURE: 'add_business_partner_failure',
	ADD_BUSINESS_PARTNER_RESPONSE_RESET: 'add_business_partner_response_reset',
	ADD_BUSINESS_PARTNER_RESPONSE_CHANGED: 'add_business_partner_response_changed',
	
	UPDATE_BUSINESS_PARTNER_START: 'update_business_partner_start',
	UPDATE_BUSINESS_PARTNER_SUCCESS: 'update_business_partner_success',
	UPDATE_BUSINESS_PARTNER_FAILURE: 'update_business_partner_failure',
	UPDATE_BUSINESS_PARTNER_RESPONSE_RESET: 'update_business_partner_response_reset',
	UPDATE_BUSINESS_PARTNER_RESPONSE_CHANGED: 'update_business_partner_response_changed',
	
	FETCH_BUSINESS_PARTNER_START: 'fetch_business_partner_start',
	FETCH_BUSINESS_PARTNER_SUCCESS: 'fetch_business_partner_success',
	FETCH_BUSINESS_PARTNER_FAILURE: 'fetch_business_partner_failure',
	FETCH_BUSINESS_PARTNER_RESPONSE_RESET: 'fetch_business_partner_response_reset',
	FETCH_BUSINESS_PARTNER_RESPONSE_CHANGED: 'fetch_business_partner_response_changed',
};
export default PartnerDetailsActionTypes;
