/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import AuditTrailReportActionTypes from './auditTrailReport.types';

import {
	auditTrailReportGet,
	AuditTrailReportExcelget,
	auditTrailReportJSONGet,
} from './auditTrailReport.service';

import {
	getAuditTrailReportSuccess,
	getAuditTrailReportFailure,
	getAuditTrailReportResponseChanged,
	getAuditTrailReportExcelSuccess,
	getAuditTrailReportExcelFailure,
	getAuditTrailReportExcelResponseChanged,
	getAuditTrailReportJsonFailure,
	getAuditTrailReportJsonSuccess,
	getAuditTrailReportJsonResponseChanged,
} from './auditTrailReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET BRANCH COLLECTION REPORT DETAILS  ******************** */
export function* getAuditTrailReportList({ payload }) {
	try {
		const key = 'AuditTrailReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			SearchLog: payload.filterData,
			ReportMode: payload.ReportMode,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(auditTrailReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAuditTrailReportFailure(responseData.data));
		} else {
			yield put(getAuditTrailReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAuditTrailReportFailure(error));
	}
}

export function* resetgetAuditTrailReportListResponse() {
	yield put(getAuditTrailReportResponseChanged());
}

export function* ongetAuditTrailReportListDetails() {
	yield takeLatest(
		AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_START,
		getAuditTrailReportList,
	);
}

export function* ongetAuditTrailReportListResponseReset() {
	yield takeLatest(
		AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_RESET,
		resetgetAuditTrailReportListResponse,
	);
}
/** *********************  AUDIT TRAIL REPORT REPORT******************** */
/** ***************** AUDIT TRAIL REPORT REPORT EXCEL  *********************** */

export function* getAuditTrailReportExcel({ payload }) {
	try {
		const key = 'AuditTrailReportExcelKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			BranchID: payload.BranchID,
			SearchLog: payload.filterData,
			CountLimit: payload.Limit,
			UserID: payload.UserID,
			ReportMode: payload.ReportMode,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(AuditTrailReportExcelget, encodeData);
		if (responseData.data.statusCode === '500') {
			yield put(getAuditTrailReportExcelFailure(responseData.data));
		} else {
			yield put(getAuditTrailReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAuditTrailReportExcelFailure(error));
	}
}
export function* resetgetAuditTrailExcelListResponse() {
	yield put(getAuditTrailReportExcelResponseChanged());
}

export function* ongetAuditTrailReportExcelListDetails() {
	yield takeLatest(
		AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_START,
		getAuditTrailReportExcel,
	);
}

export function* ongetAuditTrailReportExcelListResponseReset() {
	yield takeLatest(
		AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_RESET,
		resetgetAuditTrailExcelListResponse,
	);
}

/** ***************** AUDIT TRAIL REPORT REPORT JSON  *********************** */

export function* getAuditTrailReportJSON({ payload }) {
	try {
		const key = 'AuditTrailReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			PaySprintLogID: payload.paySprintLogID,
			Type: payload.type,
			ToDate: payload.ToDate,
			BranchID: payload.BranchID,
			SearchLog: payload.filterData,
			CountLimit: payload.Limit,
			UserID: payload.UserID,
			ReportMode: payload.ReportMode,
			RequestID: payload.RequestID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(auditTrailReportJSONGet, encodeData);
		if (responseData.data.statusCode === '500') {
			yield put(getAuditTrailReportJsonFailure(responseData.data));
		} else {
			yield put(getAuditTrailReportJsonSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAuditTrailReportJsonFailure(error));
	}
}
export function* resetgetAuditTrailJSONListResponse() {
	yield put(getAuditTrailReportJsonResponseChanged());
}

export function* ongetAuditTrailReportJSONListDetails() {
	yield takeLatest(
		AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_START,
		getAuditTrailReportJSON,
	);
}

export function* ongetAuditTrailReportJSONListResponseReset() {
	yield takeLatest(
		AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_RESET,
		resetgetAuditTrailJSONListResponse,
	);
}

export function* auditTrailReportSaga() {
	yield all([
		call(ongetAuditTrailReportListDetails),
		call(ongetAuditTrailReportListResponseReset),
		call(ongetAuditTrailReportExcelListDetails),
		call(ongetAuditTrailReportExcelListResponseReset),
		call(ongetAuditTrailReportJSONListDetails),
		call(ongetAuditTrailReportJSONListResponseReset),
	]);
}
