/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import EconomicActivityActionTypes from './economic-activity.types';

/** ***************** ADD PRODUCT TYPE DETAILS START *********************** */
export const addEconomicActivityDetailsStart = (addData) => ({
	type: EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_START,
	payload: addData,
});

export const addEconomicActivityDetailsSuccess = (successData) => ({
	type: EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_SUCCESS,
	payload: successData,
});

export const addEconomicActivityDetailsFailure = (failureData) => ({
	type: EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_FAILURE,
	payload: failureData,
});

export const addEconomicActivityDetailsResponseResetStart = () => ({
	type: EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
});

export const addEconomicActivityDetailsResponseChanged = () => ({
	type: EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD PRODUCT TYPE DETAILS END *********************** */

/** ***************** UPDATE PRODUCT TYPE DETAILS START *********************** */
export const updateEconomicActivityDetailsStart = (updateData) => ({
	type: EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_START,
	payload: updateData,
});

export const updateEconomicActivityDetailsSuccess = (successData) => ({
	type: EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_SUCCESS,
	payload: successData,
});

export const updateEconomicActivityDetailsFailure = (failureData) => ({
	type: EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_FAILURE,
	payload: failureData,
});

export const updateEconomicActivityDetailsResponseResetStart = () => ({
	type: EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
});

export const updateEconomicActivityDetailsResponseChanged = () => ({
	type: EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE PRODUCT TYPE DETAILS END *********************** */

/** ***************** GET PRODUCT TYPE LIST START *********************** */
export const getEconomicActivityListStart = (getData) => ({
	type: EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_START,
	payload: getData,
});

export const getEconomicActivityListSuccess = (successData) => ({
	type: EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_SUCCESS,
	payload: successData,
});

export const getEconomicActivityListFailure = (failureData) => ({
	type: EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_FAILURE,
	payload: failureData,
});

export const getEconomicActivityListResponseResetStart = () => ({
	type: EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_RESPONSE_RESET,
});

export const getEconomicActivityListResponseChanged = () => ({
	type: EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT TYPE LIST END *********************** */

/** ***************** FETCH PRODUCT TYPE DETAILS START *********************** */
export const fetchEconomicActivityDetailsStart = (fetchData) => ({
	type: EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_START,
	payload: fetchData,
});

export const fetchEconomicActivityDetailsSuccess = (successData) => ({
	type: EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchEconomicActivityDetailsFailure = (failureData) => ({
	type: EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchEconomicActivityDetailsResponseResetStart = () => ({
	type: EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
});

export const fetchEconomicActivityDetailsResponseChanged = () => ({
	type: EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PRODUCT TYPE DETAILS END *********************** */

/** ***************** DELETE PRODUCT TYPE DETAILS START *********************** */
export const deleteEconomicActivityDetailsStart = (deleteData) => ({
	type: EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_START,
	payload: deleteData,
});

export const deleteEconomicActivityDetailsSuccess = (successData) => ({
	type: EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteEconomicActivityDetailsFailure = (failureData) => ({
	type: EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteEconomicActivityDetailsResponseResetStart = () => ({
	type: EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
});

export const deleteEconomicActivityDetailsResponseChanged = () => ({
	type: EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE PRODUCT TYPE DETAILS END *********************** */

/** ***************** FETCH ALL PRODUCT TYPE START *********************** */
export const fetchAllEconomicActivityStart = (fetchEconomicActivitys) => ({
	type: EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_START,
	payload: fetchEconomicActivitys,
});

export const fetchAllEconomicActivitySuccess = (successData) => ({
	type: EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_SUCCESS,
	payload: successData,
});

export const fetchAllEconomicActivityFailure = (failureData) => ({
	type: EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_FAILURE,
	payload: failureData,
});

export const fetchAllEconomicActivityResponseResetStart = () => ({
	type: EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_RESPONSE_RESET,
});

export const fetchAllEconomicActivityResponseChanged = () => ({
	type: EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL PRODUCT TYPE END *********************** */
