import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './login/login.reducer';
// import staffDashboardReducer from './staffDashboard/staffDashboard.reducer';
import staffReducer from './operations/staff/staff.reducer';
import executiveReducer from './operations/executive/executive.reducer';
import teamReducer from './operations/team/team.reducer';
import repaymentCollectionReportReducer from './reports/RepaymentCollectionReport/RepaymentCollectionReport.reducer';
import progressReportReducer from './reports/ProgressReport/ProgressReport.reducer';
import GeneralReducer from './general/general.reducer';
import BusinessDetailsReducer from './operations/business/business-details/business-details.reducer';
import BrachDetailsReducer from './operations/business/branch-details/branch-details.reducer';
import PartnerDetailsReducer from './operations/business/partner-details/partner-details.reducer';
import BankDetailsReducer from './operations/business/bank-details/bank-details.reducer';
import ProofDetailsReducer from './operations/business/proof-details/proof-details.reducer';
import VerificationDetailsReducer from './operations/business/verification-details/verification-details.reducer';
import RelatedPartiesReducer from './operations/business/related-parties/related-parties.reducer';
/** ******************* MASTERS ************************** */
import BranchReducer from './masters/branch/branch.reducer';
import MemberReducer from './masters/member/member.reducer';
import CenterReducer from './masters/center/center.reducer';
import loanApplicationReducer from './masters/loanApplication/loanApplication.reducer';
import RoleReducer from './masters/role/role.reducer';
import StaffReducer from './masters/staff/staff.reducer';
import GroupReducer from './masters/group/group.reducer';
import ThriftReducer from './masters/thrift/thrift.reducer';
import ProductTypeReducer from './masters/product-type/product-type.reducer';
import InsuranceCompanyReducer from './masters/insurance-company/insurance-company.reducer';
import ProductReducer from './masters/product/product.reducer';
import EconomicActivityReducer from './masters/economic-activity/economic-activity.reducer';
import EconomicActivityTypeReducer from './masters/economic-activity-type/economic-activity-type.reducer';
import purposeofloanReducer from './masters/purpose-of-loan/purposeofloan.reducer';
import businessfundReducer from './masters/business-fund/business-fund.reducer';
import FunderReducer from './masters/funder/funder.reducer';
import QuestionnaireReducer from './masters/questionnaire/questionnaire.reducer';

/** ******************* OPERATIONS - COLLECTION ************************** */
import collectionReducer from './operations/collection/collection.reducer';
import memberCollectionReducer from './operations/collection/memberCollection/memberCollection.reducer';
import advanceCollectionReducer from './operations/collection/advanceCollection/advanceCollection.reducer';
import todayCollectionReducer from './operations/todayCollection/todayCollection.reducer';

/** ******************* BUSINESS LOAN PROPOSAL ************************** */
import businessLoanProposalReducer from './operations/loan-proposal/business-loan-proposal/business-loan-proposal.reducer';

/** ******************* GOLD LOAN PROPOSAL ************************** */
import goldLoanProposalReducer from './operations/loan-proposal/goldLoanProposal/goldLoanProposal.reducer';

/** ******************* BUSINESS LOAN DISBURSEMENT ************************** */
import businessLoanDisbursementReducer from './operations/loan-disbursement/business-loan-disbursment/business-loan-disbmt.reducer';

/** ******************* REPORTS ************************** */
import TatReportReducer from './reports/tat-report/tat-report.reducer';
import RequestReportReducer from './reports/requestReport/requestReport.reducer';
import AttendanceReportReducer from './reports/attendanceReport/attendanceReport.reducer';
import ProgressReportReducer from './reports/reportProgress/reportProgresst.reducer';
import MonthlyProgressReducer from './reports/monthlyProgress/monthlyProgress.reducer';
import BranchReportReducer from './reports/branchReport/branchReport.reducer';
import BranchProfileReducer from './reports/branchProfileReport/branchProfileReport.reducer';
import DailyCollectionReportReducer from './reports/dailyCollectionReport/dailyCollectionReport.reducer';
import PostAuditingReportReducer from './reports/postauditingReport/postauditingReport.reducer';
import PreCloseReportReducer from './reports/preclose-loan/preclose-loan.reducer';
import ClosedLoanDetailsReducer from './reports/closed-loan-details/closed-loan-details.reducer';
import WalletReportReducer from './reports/walletReport/walletReport.reducer';
import BranchCollectionReportReducer from './reports/branchCollectionReport/branchCollectionReport.reducer';
import futureDemandReportReducer from './reports/future-demand-report/future-demand-report.reducer';
import demandCollectionReportReducer from './reports/demand-collection-report/demand-collection-report.reducer';
import demandFollowupReportReducer from './reports/demand-followup-report/demand-followup-report.reducer';
import regularDemandReportReducer from './reports/regular-demand-report/regular-demand-report.reducer';
import sanctionReportReducer from './reports/sanction-report/sanction-report.reducer';
import kycSourcedReportReducer from './reports/kyc-sourced-report/kyc-sourced-report.reducer';
import insuranceReportReducer from './reports/insurance-report/insurance-report.reducer';
import LTVReportReducer from './reports/ltv-report/ltv-report.reducer';
import NPAReportReducer from './reports/npa-report/npa-report.reducer';
import PortFolioAtRiskReducer from './reports/portflioAtRistReport/portflioAtRiskReport.reducer';
import ActiveMemberReducer from './reports/active-member/active-member.reducer';
import InActiveMemberReducer from './reports/in-active-member/in-active-member.reducer';
import DataVerificationReducer from './reports/data-verification/data-verification.reducer';
import AuditingToolReducer from './reports/auditing-tool/auditing-tool.reducer';
import ChangeMeetingReducer from './reports/change-meeting/change-meeting.reducer';
import MonthWiseInterestReducer from './reports/MonthWiseInterestReport/MonthWiseInterestReport.reducer';
import LoanDisbursementReducer from './reports/loan-disbursement/loan-disbursement.reducer';
import AuditTrailReportReducer from './reports/auditTrailReport/auditTrailReport.reducer';
import overDueReportReducer from './reports/overDueReport/overDueReport.reducer';
import staffReportReducer from './reports/staffReport/staffReport.reducer';
import accountDayBookReportReducer from './reports/accountDayBookReport/accountDayBookReport.reducer';
import branchDCBReportReducer from './reports/branchDCBReport/branchDCBReport.reducer';
// verifications

import kycVerificationReducer from './operations/business/kyc-verification/kyc-verification.reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['login'],
};
const rootReducer = combineReducers({
	login: userReducer,
	teamPage: teamReducer,
	staffPage: staffReducer,
	executivePage: executiveReducer,
	repaymentCollection: repaymentCollectionReportReducer,
	progressReportSelector: progressReportReducer,
	general: GeneralReducer,
	business_details: BusinessDetailsReducer,
	branch_details: BrachDetailsReducer,
	partner_details: PartnerDetailsReducer,
	bank_details: BankDetailsReducer,
	proof_details: ProofDetailsReducer,
	verify_details: VerificationDetailsReducer,
	parties_details: RelatedPartiesReducer,

	// Masters
	branch_master: BranchReducer,
	member_master: MemberReducer,
	center_master: CenterReducer,
	loan_master: loanApplicationReducer,
	role_master: RoleReducer,
	staff_master: StaffReducer,
	purpose_of_loan: purposeofloanReducer,
	group_master: GroupReducer,
	thrift_master: ThriftReducer,
	product_type: ProductTypeReducer,
	insurance_company: InsuranceCompanyReducer,
	product_master: ProductReducer,
	economic_activity: EconomicActivityReducer,
	economic_activity_type: EconomicActivityTypeReducer,
	businessfund_master: businessfundReducer,
	funder_master: FunderReducer,
	questionnaire: QuestionnaireReducer,

	// operation - collection
	collection: collectionReducer,
	todayCollection: todayCollectionReducer,
	memberCollection: memberCollectionReducer,
	advanceCollection: advanceCollectionReducer,

	// Loan Proposal
	business_loan_proposal: businessLoanProposalReducer,
	gold_loan_proposal: goldLoanProposalReducer,

	// Loan Disbursement
	business_loan_disbursment: businessLoanDisbursementReducer,
	// Reports
	tat_report: TatReportReducer,
	request_report: RequestReportReducer,
	attendance_report: AttendanceReportReducer,
	progress_report: ProgressReportReducer,
	monthly_progress: MonthlyProgressReducer,
	branch_report: BranchReportReducer,
	branch_profile_report: BranchProfileReducer,
	daily_collection_report: DailyCollectionReportReducer,
	post_auditing_report: PostAuditingReportReducer,
	preclose_report: PreCloseReportReducer,
	closed_loan_details: ClosedLoanDetailsReducer,
	wallet_report: WalletReportReducer,
	branch_collection_report: BranchCollectionReportReducer,
	future_demand_report: futureDemandReportReducer,
	demand_collection: demandCollectionReportReducer,
	demand_followup: demandFollowupReportReducer,
	regular_demand_report: regularDemandReportReducer,
	sanction_report: sanctionReportReducer,
	kyc_sourced_report: kycSourcedReportReducer,
	insurance_report: insuranceReportReducer,
	LTV_report: LTVReportReducer,
	NPA_report: NPAReportReducer,
	portfolio_at_risk_report: PortFolioAtRiskReducer,
	active_member: ActiveMemberReducer,
	inactive_member: InActiveMemberReducer,
	data_verification: DataVerificationReducer,
	auditing_tool: AuditingToolReducer,
	change_meeting: ChangeMeetingReducer,
	month_wise_interest: MonthWiseInterestReducer,
	loan_disbursement: LoanDisbursementReducer,
	overDueReport: overDueReportReducer,
	audit_trail_report: AuditTrailReportReducer,
	Staff_Details: staffReportReducer,
	accountDayBookReport: accountDayBookReportReducer,
	branchDCBReport: branchDCBReportReducer,
	// verifications

	kycverification_details: kycVerificationReducer,
});

export default persistReducer(persistConfig, rootReducer);
 