/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const overdueReportDetailsGet = (fetchData, serviceName) => {
	const data = {
		GetoverdueAtRiskReportToken: fetchData,
	};
	const url = `${API_URL + serviceName}`;
	console.log('dvsd', url, data);
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchOverDueExcel = (postData, serviceName) => {
	const params = new URLSearchParams();
	params.append('FetchOverDueReportToken', postData);
	console.log('FetchOverDueReportToken', postData);
	const url = `${API_URL}Report/Excel/${serviceName}.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
