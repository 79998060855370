/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import collectionActionTypes from './collection.types';

/** ***************** GET BRANCH OFFICER CENTERS LIST START *********************** */
export const fetchOfficerListCentersStart = (getData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_START,
	payload: getData,
});

export const getOfficerListCentersSuccess = (successData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_SUCCESS,
	payload: successData,
});

export const getOfficerListCentersFailure = (failureData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_FAILURE,
	payload: failureData,
});

export const fetchOfficerListCentersResponseResetStart = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_RESET,
});

export const getOfficerListCentersResponseChanged = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER CENTERS LIST END *********************** */

/** ***************** GET BRANCH OFFICER ARREAR CENTERS LIST START *********************** */
export const fetchOfficerListArrearCentersStart = (getData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_START,
	payload: getData,
});

export const getOfficerListArrearCentersSuccess = (successData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS,
	payload: successData,
});

export const getOfficerListArrearCentersFailure = (failureData) => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE,
	payload: failureData,
});

export const fetchOfficerListArrearCentersResponseResetStart = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET,
});

export const getOfficerListArrearCentersResponseChanged = () => ({
	type: collectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER ARREAR CENTERS LIST END *********************** */

/** ***************** GET BRANCH COLLECTION USERS LIST START *********************** */
export const fetchBranchCollectionUsersListStart = (getData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_START,
	payload: getData,
});

export const getBranchCollectionUsersListSuccess = (successData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS,
	payload: successData,
});

export const getBranchCollectionUsersListFailure = (failureData) => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchCollectionUsersListResponseResetStart = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET,
});

export const getBranchCollectionUsersListResponseChanged = () => ({
	type: collectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER ARREAR CENTERS LIST END *********************** */

/** ***************** GET CENTER LIST OFFICER COLLECTIONS START *********************** */
export const fetchCenterListOfficerCollectionsStart = (getData) => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_START,
	payload: getData,
});

export const getCenterListOfficerCollectionsSuccess = (successData) => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS,
	payload: successData,
});

export const getCenterListOfficerCollectionsFailure = (failureData) => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE,
	payload: failureData,
});

export const fetchCenterListOfficerCollectionsResponseResetStart = () => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET,
});

export const getCenterListOfficerCollectionsResponseChanged = () => ({
	type: collectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED,
});
/** ***************** GET CENTER LIST OFFICER COLLECTIONS END *********************** */
