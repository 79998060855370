/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import advanceCollectionActionTypes from './advanceCollection.types';

/** ***************** GET MEMBER LOAN INFO START *********************** */
export const fetchAdvanceMemberLoanInfoStart = (getData) => ({
	type: advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_START,
	payload: getData,
});

export const fetchAdvanceMemberLoanInfoSuccess = (successData) => ({
	type: advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const fetchAdvanceMemberLoanInfoFailure = (failureData) => ({
	type: advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const fetchAdvanceMemberLoanInfoResponseResetStart = () => ({
	type: advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_RESET,
});

export const fetchAdvanceMemberLoanInfoResponseChanged = () => ({
	type: advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** GET MEMBER LOAN INFO END *********************** */

/** ***************** UPDATE ADVANCE COLLECTION START *********************** */
export const updateAdvanceCollectionStart = (getData) => ({
	type: advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_START,
	payload: getData,
});

export const updateAdvanceCollectionSuccess = (successData) => ({
	type: advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_SUCCESS,
	payload: successData,
});

export const updateAdvanceCollectionFailure = (failureData) => ({
	type: advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_FAILURE,
	payload: failureData,
});

export const updateAdvanceCollectionResponseResetStart = () => ({
	type: advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_RESPONSE_RESET,
});

export const updateAdvanceCollectionResponseChanged = () => ({
	type: advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_RESPONSE_CHANGED,
});
/** ***************** UPDATE ADVANCE COLLECTION END *********************** */
