/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import PostAuditingReportActionTypes from './postauditingReport.types';

import {
    postauditingReportListGet,
} from './postauditingReport.service';

import {
    getPostAuditingReportDetailsSuccess,
    getPostAuditingReportDetailsFailure,
    getPostAuditingReportDetailsResponseChanged,

} from './postauditingReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET POST_AUDITING REPORT DETAILS  ******************** */
export function* getPostAuditingReportList({ payload }) {
	try {
		const key = 'GetPostAuditingToolReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
            CenterID: payload.locationID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
            AuditType: payload.AuditType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(postauditingReportListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPostAuditingReportDetailsFailure(responseData.data));
		} else {
			yield put(getPostAuditingReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPostAuditingReportDetailsFailure(error));
	}
}

export function* resetGetPostAuditingReportListResponse() {
	yield put(getPostAuditingReportDetailsResponseChanged());
}

export function* onGetPostAuditingReportListDetails() {
	yield takeLatest(PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_START, getPostAuditingReportList);
}

export function* onGetPostAuditingReportListResponseReset() {
	yield takeLatest(PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_RESPONSE_RESET, resetGetPostAuditingReportListResponse);
}
/** *********************  END GET POST_AUDITING REPORT LIST ******************** */

export function* postAuditReportSaga() {
	yield all([
		call(onGetPostAuditingReportListDetails),
		call(onGetPostAuditingReportListResponseReset),
	]);
}
