/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import MonthWiseInterestActionTypes from './MonthWiseInterestReport.types';

/** ***************** GET MONTH WISE INTEREST REPORT DETAILS START *********************** */
export const getMonthWiseInterestDetailsStart = (getData) => ({
	type: MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_START,
	payload: getData,
});

export const getMonthWiseInterestDetailsSuccess = (successData) => ({
	type: MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_SUCCESS,
	payload: successData,
});

export const getMonthWiseInterestDetailsFailure = (failureData) => ({
	type: MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_FAILURE,
	payload: failureData,
});

export const getMonthWiseInterestDetailsResponseResetStart = () => ({
	type: MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_RESPONSE_RESET,
});

export const getMonthWiseInterestDetailsResponseChanged = () => ({
	type: MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_RESPONSE_CHANGED,
});
/** ***************** GET MONTH WISE INTEREST REPORT DETAILS END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

