/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const branchDetailsAdd = (branchData) => {
	const data = {
		AddBusinessTokenBranch: branchData,
	};
	const url = `${API_URL}addBusinessBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchDetails = (fetchData) => {
	const data = {
		GetBusinessTokenBranch: fetchData,
	};
	const url = `${API_URL}getBusinessBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateBranchDetails = (updateData) => {
	const data = {
		UpdateBranchDetailsToken: updateData,
	};
	const url = `${API_URL}updateBusinessBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
