/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ClosedLoanDetailsActionTypes from './closed-loan-details.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getClosedLoanDetailsListResponse: null,
	getClosedLoanDetailsListArr: [],

    getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_SUCCESS:
			return {
				...state,
				getClosedLoanDetailsListResponse: action.payload,
				getClosedLoanDetailsListArr: docodeResponse(action.payload, 'GetMemberClosedLoanDetailsKey'),
			};

		case ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_FAILURE:
			return { ...state, getClosedLoanDetailsListResponse: action.payload };

		case ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_RESPONSE_CHANGED:
			return { ...state, getClosedLoanDetailsListResponse: null };

        case ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
            return {
                ...state,
                getOfficerBranchListResponse: action.payload,
                getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
            };

        case ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
            return { ...state, getOfficerBranchListResponse: action.payload };

        case ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
            return { ...state, getOfficerBranchListResponse: null };
        default:
            return state;
    }
}