/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const partiesDetailsAdd = (partiesData) => {
	const data = {
		AddBusinessTokenReletedParties: partiesData,
	};
	const url = `${API_URL}addRelatedParties`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchPartiesDetails = (fetchData) => {
	const data = {
		GetBusinessTokenReletedParties: fetchData,
	};
	const url = `${API_URL}getRelatedParties`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updatePartiesDetails = (updateData) => {
	const data = {
		UpdateReletedPartiesDetailsToken: updateData,
	};
	const url = `${API_URL}updateRelatedParties`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
