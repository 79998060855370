/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import BranchReportActionTypes from './branchReport.types';

/** ***************** GET BRANCH DCB REPORT START *********************** */
export const getBranchReportDetailsStart = (getData) => ({
	type: BranchReportActionTypes.GET_BRANCH_REPORT_LIST_START,
	payload: getData,
});

export const getBranchReportDetailsSuccess = (successData) => ({
	type: BranchReportActionTypes.GET_BRANCH_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getBranchReportDetailsFailure = (failureData) => ({
	type: BranchReportActionTypes.GET_BRANCH_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getBranchReportDetailsResponseResetStart = () => ({
	type: BranchReportActionTypes.GET_BRANCH_REPORT_LIST_RESPONSE_RESET,
});

export const getBranchReportDetailsResponseChanged = () => ({
	type: BranchReportActionTypes.GET_BRANCH_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH DCB REPORT END *********************** */
