import GeneralActionTypes from './general.types';

export const resetAllReducerStateStart = () => ({
	type: GeneralActionTypes.RESET_ALL_REDUCER_STATE_START,
});

export const isEditDetailsStart = (isEdit) => ({
	type: GeneralActionTypes.IS_EDIT_DETAILS_START,
	payload: isEdit,
});

export const isOpenCanvasStart = (isOpen) => ({
	type: GeneralActionTypes.IS_OPEN_OFFCANVAS_START,
	payload: isOpen,
});

/** ***************** GET COUNTRY START *********************** */
export const getCountryListStart = () => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_START,
});

export const getCountryListSuccess = (successData) => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_SUCCESS,
	payload: successData,
});

export const getCountryListFailure = (failureData) => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_FAILURE,
	payload: failureData,
});

export const getCountryListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_RESET,
});

export const getCountryListResponseChanged = () => ({
	type: GeneralActionTypes.GET_COUNTRY_LIST_RESPONSE_CHANGED,
});
/** ***************** GET COUNTRY END *********************** */

/** ***************** GET STATE START *********************** */
export const getStateListStart = (stateData) => ({
	type: GeneralActionTypes.GET_STATE_LIST_START,
	payload: stateData,
});

export const getStateListSuccess = (successData) => ({
	type: GeneralActionTypes.GET_STATE_LIST_SUCCESS,
	payload: successData,
});

export const getStateListFailure = (failureData) => ({
	type: GeneralActionTypes.GET_STATE_LIST_FAILURE,
	payload: failureData,
});

export const getStateListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_STATE_LIST_RESPONSE_RESET,
});

export const getStateListResponseChanged = () => ({
	type: GeneralActionTypes.GET_STATE_LIST_RESPONSE_CHANGED,
});
/** ***************** GET STATE END *********************** */

/** ***************** GET CITY START *********************** */
export const getCityListStart = (cityData) => ({
	type: GeneralActionTypes.GET_CITY_LIST_START,
	payload: cityData,
});

export const getCityListSuccess = (successData) => ({
	type: GeneralActionTypes.GET_CITY_LIST_SUCCESS,
	payload: successData,
});

export const getCityListFailure = (failureData) => ({
	type: GeneralActionTypes.GET_CITY_LIST_FAILURE,
	payload: failureData,
});

export const getCityListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_CITY_LIST_RESPONSE_RESET,
});

export const getCityListResponseChanged = () => ({
	type: GeneralActionTypes.GET_CITY_LIST_RESPONSE_CHANGED,
});
/** ***************** GET CITY END *********************** */

/** ***************** GET PINCODE START *********************** */
export const getPinCodeListStart = (cityData) => ({
	type: GeneralActionTypes.GET_PINCODE_LIST_START,
	payload: cityData,
});

export const getPinCodeListSuccess = (successData) => ({
	type: GeneralActionTypes.GET_PINCODE_LIST_SUCCESS,
	payload: successData,
});

export const getPinCodeListFailure = (failureData) => ({
	type: GeneralActionTypes.GET_PINCODE_LIST_FAILURE,
	payload: failureData,
});

export const getPinCodeListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_PINCODE_LIST_RESPONSE_RESET,
});

export const getPinCodeListResponseChanged = () => ({
	type: GeneralActionTypes.GET_PINCODE_LIST_RESPONSE_CHANGED,
});
/** ***************** GET PINCODE END *********************** */

/** ***************** GET DISTRICT START *********************** */
export const getDistrictListStart = (districtData) => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_START,
	payload: districtData,
});

export const getDistrictListSuccess = (successData) => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_SUCCESS,
	payload: successData,
});

export const getDistrictListFailure = (failureData) => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_FAILURE,
	payload: failureData,
});

export const getDistrictListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_RESET,
});

export const getDistrictListResponseChanged = () => ({
	type: GeneralActionTypes.GET_DISTRICT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET DISTRICT END *********************** */

/** ***************** GET ALL INDUSTRY LIST START *********************** */
export const getAllIndustryListStart = (fetchData) => ({
	type: GeneralActionTypes.GET_INDUSTRY_LIST_START,
	payload: fetchData,
});

export const getAllIndustryListSuccess = (fetchSuccess) => ({
	type: GeneralActionTypes.GET_INDUSTRY_LIST_SUCCESS,
	payload: fetchSuccess,
});

export const getAllIndustryListFailure = (fetchFailure) => ({
	type: GeneralActionTypes.GET_INDUSTRY_LIST_FAILURE,
	payload: fetchFailure,
});

export const getAllIndustryListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_INDUSTRY_LIST_RESPONSE_RESET_START,
});

export const getAllIndustryListResponseChanged = () => ({
	type: GeneralActionTypes.GET_INDUSTRY_LIST_RESPONSE_CHANGED,
});
/** ***************** GET ALL INDUSTRY LIST END *********************** */

/** ***************** GET ALL SUB INDUSTRY LIST START *********************** */
export const getSubIndustryListStart = (fetchData) => ({
	type: GeneralActionTypes.GET_SUB_INDUSTRY_LIST_START,
	payload: fetchData,
});

export const getSubIndustryListSuccess = (fetchSuccess) => ({
	type: GeneralActionTypes.GET_SUB_INDUSTRY_LIST_SUCCESS,
	payload: fetchSuccess,
});

export const getSubIndustryListFailure = (fetchFailure) => ({
	type: GeneralActionTypes.GET_SUB_INDUSTRY_LIST_FAILURE,
	payload: fetchFailure,
});

export const getSubIndustryListResponseResetStart = () => ({
	type: GeneralActionTypes.GET_SUB_INDUSTRY_LIST_RESPONSE_RESET_START,
});

export const getSubIndustryListResponseChanged = () => ({
	type: GeneralActionTypes.GET_SUB_INDUSTRY_LIST_RESPONSE_CHANGED,
});
/** ***************** GET ALL SUB INDUSTRY LIST END *********************** */

/** ***************** FETCH ALL BRANCHES START *********************** */
export const fetchAllBranchesStart = (fetchBranches) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_START,
	payload: fetchBranches,
});

export const fetchAllBranchesSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_SUCCESS,
	payload: successData,
});

export const fetchAllBranchesFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_FAILURE,
	payload: failureData,
});

export const fetchAllBranchesResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_RESET_START,
});

export const fetchAllBranchesResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCHES_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL BRANCHES END *********************** */

/** ***************** FETCH ALL PURPOSE OF LOAN LIST START *********************** */
export const fetchAllPurposeOfLoanListStart = (purposeOfLoanList) => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_START,
	payload: purposeOfLoanList,
});

export const fetchAllPurposeOfLoanListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const fetchAllPurposeOfLoanListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const fetchAllPurposeOfLoanListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_RESPONSE_RESET_START,
});

export const fetchAllPurposeOfLoanListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL PURPOSE OF LOAN LIST END *********************** */

/** ***************** FETCH ALL PURPOSE OF LOAN TYPE START *********************** */
export const fetchAllPurposeOfLoanTypeStart = (fetchLoanPurposeType) => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_START,
	payload: fetchLoanPurposeType,
});

export const fetchAllPurposeOfLoanTypeSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_SUCCESS,
	payload: successData,
});

export const fetchAllPurposeOfLoanTypeFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_FAILURE,
	payload: failureData,
});

export const fetchAllPurposeOfLoanTypeResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_RESET_START,
});

export const fetchAllPurposeOfLoanTypeResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL PURPOSE OF LOAN TYPE END *********************** */

/** ***************** FETCH ALL BUSINESS LIST START *********************** */
export const fetchAllBusinessListStart = (fetchBusinessList) => ({
	type: GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_START,
	payload: fetchBusinessList,
});

export const fetchAllBusinessListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_SUCCESS,
	payload: successData,
});

export const fetchAllBusinessListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_FAILURE,
	payload: failureData,
});

export const fetchAllBusinessListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_RESPONSE_RESET_START,
});

export const fetchAllBusinessListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_BUSINESS_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL BUSINESS LIST END *********************** */

/** ***************** FETCH ALL PRODUCT TYPE START *********************** */
export const fetchAllProductTypeStart = (fetchProductTypes) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_START,
	payload: fetchProductTypes,
});

export const fetchAllProductTypeSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_SUCCESS,
	payload: successData,
});

export const fetchAllProductTypeFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_FAILURE,
	payload: failureData,
});

export const fetchAllProductTypeResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_RESPONSE_RESET,
});

export const fetchAllProductTypeResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_TYPE_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL PRODUCT TYPE END *********************** */

/** ***************** FETCH ALL PRODUCT LIST START *********************** */
export const fetchAllProductListStart = (fetchProducts) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_START,
	payload: fetchProducts,
});

export const fetchAllProductListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_SUCCESS,
	payload: successData,
});

export const fetchAllProductListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_FAILURE,
	payload: failureData,
});

export const fetchAllProductListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_RESET,
});

export const fetchAllProductListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_PRODUCT_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL PRODUCT LIST END *********************** */

/** ***************** FETCH ALL OFFICERS BASESD ON BRANCH START *********************** */
export const fetchAllBranchOfficerStart = (fetchBranchOfficer) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_START,
	payload: fetchBranchOfficer,
});

export const fetchAllBranchOfficerSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_SUCCESS,
	payload: successData,
});

export const fetchAllBranchOfficerFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_FAILURE,
	payload: failureData,
});

export const fetchAllBranchOfficerResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_RESPONSE_RESET_START,
});

export const fetchAllBranchOfficerResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_BRANCH_OFFICER_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL OFFICERS BASESD ON BRANCH END *********************** */

/** ***************** FETCH ALL CENTERS START *********************** */
export const fetchAllCenterListStart = (fetchCenter) => ({
	type: GeneralActionTypes.FETCH_ALL_CENTERS_START,
	payload: fetchCenter,
});

export const fetchAllCenterListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_CENTERS_SUCCESS,
	payload: successData,
});

export const fetchAllCenterListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_CENTERS_FAILURE,
	payload: failureData,
});

export const fetchAllCenterListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_CENTERS_RESPONSE_RESET_START,
});

export const fetchAllCenterListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_CENTERS_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL CENTERS END *********************** */

/** ***************** FETCH CENTER GROUPS START *********************** */
export const fetchCenterGroupListStart = (fetchGroup) => ({
	type: GeneralActionTypes.FETCH_CENTER_GROUPS_START,
	payload: fetchGroup,
});

export const fetchCenterGroupListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_CENTER_GROUPS_SUCCESS,
	payload: successData,
});

export const fetchCenterGroupListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_CENTER_GROUPS_FAILURE,
	payload: failureData,
});

export const fetchCenterGroupListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_CENTER_GROUPS_RESPONSE_RESET_START,
});

export const fetchCenterGroupListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_CENTER_GROUPS_RESPONSE_CHANGED,
});
/** ***************** FETCH CENTER GROUPS END *********************** */

/** ***************** FETCH GROUP MEMBER START *********************** */
export const fetchGroupMemberListStart = (fetchGroupMembers) => ({
	type: GeneralActionTypes.FETCH_GROUP_MEMBERS_START,
	payload: fetchGroupMembers,
});

export const fetchGroupMemberListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_GROUP_MEMBERS_SUCCESS,
	payload: successData,
});

export const fetchGroupMemberListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_GROUP_MEMBERS_FAILURE,
	payload: failureData,
});

export const fetchGroupMemberListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_GROUP_MEMBERS_RESPONSE_RESET_START,
});

export const fetchGroupMemberListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_GROUP_MEMBERS__RESPONSE_CHANGED,
});
/** ***************** FETCH CENTER GROUPS END *********************** */

/** ***************** FETCH ALL INVESTOR START *********************** */
export const fetchAllInverstorListStart = (fetchCenter) => ({
	type: GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_START,
	payload: fetchCenter,
});

export const fetchAllInverstorListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_SUCCESS,
	payload: successData,
});

export const fetchAllInverstorListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_FAILURE,
	payload: failureData,
});

export const fetchAllInverstorListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_RESPONSE_RESET_START,
});

export const fetchAllInverstorListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_INVESTOR_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL INVESTOR END *********************** */

/** ***************** FETCH ALL GROUP ***************** */
export const fetchAllGroupStart = (getData) => ({
	type: GeneralActionTypes.FETCH_ALL_GROUP_START,
	payload: getData,
});

export const fetchAllGroupSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_ALL_GROUP_SUCCESS,
	payload: successData,
});

export const fetchAllGroupFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_ALL_GROUP_FAILURE,
	payload: failureData,
});

export const fetchAllGroupResponseReset = () => ({
	type: GeneralActionTypes.FETCH_ALL_GROUP_RESPONSE_RESET,
});

export const fetchAllGroupResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED,
});

/** ***************** FETCH BRANCHE ACCOUNT LIST START *********************** */
export const fetchBranchAccountListStart = (fetchBranches) => ({
	type: GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_START,
	payload: fetchBranches,
});

export const fetchBranchAccountListSuccess = (successData) => ({
	type: GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_SUCCESS,
	payload: successData,
});

export const fetchBranchAccountListFailure = (failureData) => ({
	type: GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_FAILURE,
	payload: failureData,
});

export const fetchBranchAccountListResponseResetStart = () => ({
	type: GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_RESET_START,
});

export const fetchBranchAccountListResponseChanged = () => ({
	type: GeneralActionTypes.FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL BRANCHES END *********************** */
