/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import AuditingToolActionTypes from './auditing-tool.types';

import {
    auditingToolListGet,
    officerBranchListGet,

} from './auditing-tool.service';

import {
    getAuditingToolDetailsSuccess,
    getAuditingToolDetailsFailure,
    getAuditingToolDetailsResponseChanged,
    getOfficerBranchDetailsSuccess,
    getOfficerBranchDetailsFailure,
    getOfficerBranchDetailsResponseChanged,

} from './auditing-tool.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getAuditingToolList({ payload }) {
	try {
		const key = 'GetAuditingToolKey';
		const jsonData = {
			CompanyID: payload.companyID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
            IsThriftActive: payload.IsThriftActive,
			UserLevel: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(auditingToolListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAuditingToolDetailsFailure(responseData.data));
		} else {
			yield put(getAuditingToolDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAuditingToolDetailsFailure(error));
	}
}

export function* resetGetAuditingToolListResponse() {
	yield put(getAuditingToolDetailsResponseChanged());
}

export function* onGetAuditingToolListDetails() {
	yield takeLatest(AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_START, getAuditingToolList);
}

export function* onGetAuditingToolListResponseReset() {
	yield takeLatest(AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_RESET, resetGetAuditingToolListResponse);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_START, getOfficerBranchList);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET, resetGetAuditingToolListResponse);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */

export function* reportAuditingToolSaga() {
	yield all([
		call(onGetAuditingToolListDetails),
		call(onGetAuditingToolListResponseReset),
        call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
	]);
}
