/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const kYCaadharAdd = (aadharData) => {
	const data = {
		AadharOtpVerifyToken: aadharData,
	};
	const url = `${API_URL}AadharOtpVerifyDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const kYCaadharAddotp = (aadharotpData) => {
	const data = {
		AADHAROtpVerificationToken: aadharotpData,
	};
	const url = `${API_URL}AadharOTPValidateVerify`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const kYCpanAdd = (panData) => {
	const data = {
		DocumentVerificationToken: panData,
	};
	const url = `${API_URL}VerifyPanDetailedKYCApp`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const kYCgstAdd = (gstData) => {
	const data = {
		UpdateKYCVerificationToken: gstData,
	};
	const url = `${API_URL}updateBusinessBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const kYCcinAdd = (cinData) => {
	const data = {
		UpdateKYCVerificationToken: cinData,
	};
	const url = `${API_URL}updateBusinessBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const kYCitrAdd = (itrData) => {
	const data = {
		UpdateKYCVerificationToken: itrData,
	};
	const url = `${API_URL}updateBusinessBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const kYCmcaAdd = (mcaData) => {
	const data = {
		UpdateKYCVerificationToken: mcaData,
	};
	const url = `${API_URL}updateBusinessBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
