/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import { encodeJson } from '../../enode-decode';
import generalTypesAction from '../../genera.types';
import teamActionTypes from './team.types';

import { getAllTeams, createTeam, updateTeam, deleteTeam, getTeamLeads } from './team.services';
import {
	getAllTeamListSuccess,
	getAllTeamListFailure,
	getAllTeamListChangedAction,
	createTeamSuccess,
	createTeamFailure,
	teamCreateChanged,
	updateTeamSuccess,
	updateTeamFailure,
	teamUpdateChanged,
	deleteTeamSuccess,
	deleteTeamFailure,
	teamDeleteChanged,
	getAllTeamLeadsSuccess,
	getAllTeamLeadsFailure,
	getAllTeamLeadsChangedAction,
} from './team.actions';

// /getAllTeamLeadsList, getAllTeamLeadsSuccess, getAllTeamLeadsFailure, getAllTeamLeadsResetAction, getAllTeamLeadsChangedAction

export function* createNewTeam({ payload: { values } }) {
	try {
		const jsonData = {
			TeamName: values.TeamName,
			ManagerID: values.ManagerID,
			TeamLeadID: values.TeamLeadID,
			NoOfMembers: values.NoOfMembers,
			CreatedBy: values.CreatedBy,
			// Region: values.Region,
		};
		const TeamData = encodeJson(jsonData, generalTypesAction.ADD_NEW_TEAM);
		const responseData = yield call(createTeam, TeamData);
		if (responseData.data.statusCode === '100') {
			yield put(createTeamFailure(responseData.data));
		} else {
			// const DecodeTeamData = JWT.decode(responseData.data.response, generalTypesAction.TEAM);
			yield put(createTeamSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createTeamFailure(error));
	}
}

export function* updateTeamDetails({ payload: { values } }) {
	try {
		const jsonData = {
			TeamName: values.TeamName,
			ManagerID: values.ManagerID,
			TeamLeadID: values.TeamLeadID,
			NoOfMembers: values.NoOfMembers,
			CreatedBy: values.UpdateBy,
			TeamID: values.TeamID,
			// Region: values.Region,
			// StaffList: values.StaffList,
		};
		const TeamData = encodeJson(jsonData, generalTypesAction.UPDATE_EXIST_TEAM_DETAILS);
		const responseData = yield call(updateTeam, TeamData);
		if (responseData.data.statusCode === '100') {
			yield put(updateTeamFailure(responseData.data));
		} else {
			// const DecodeTeamData = JWT.decode(responseData.data.response, generalTypesAction.TEAM);
			yield put(updateTeamSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateTeamFailure(error));
	}
}

export function* deleteTeamDetails({ payload: { TeamID, Status, updatedBy } }) {
	try {
		const jsonData = {
			teamID: TeamID,
			status: Status,
			UpdatedBy: updatedBy,
		};
		const TeamData = encodeJson(jsonData, generalTypesAction.DELETE_EXIST_TEAM);
		const responseData = yield call(deleteTeam, TeamData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteTeamFailure(responseData.data));
		} else {
			// const DecodeTeamData = JWT.decode(
			// 	responseData.data.response,
			// 	generalTypesAction.DELETE_EXIST_TEAM,
			// );
			yield put(deleteTeamSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteTeamFailure(error));
	}
}

// export function* getListOfTeam() {
// 	try {
// 		const responseData = yield call(getAllTeams);
// 		if (responseData.data.statusCode === '100') {
// 			yield put(getAllTeamListFailure(responseData.data));
// 		} else {
// 			yield put(getAllTeamListSuccess(responseData.data));
// 		}
// 	} catch (error) {
// 		yield put(getAllTeamListFailure(error));
// 	}
// }

export function* getListOfTeam({ payload: { teamFilter } }) {
	try {
		const jsonData = {
			region: teamFilter.Region,
		};
		const TeamData = encodeJson(jsonData, generalTypesAction.FETCH_TEAM_LIST);
		const responseData = yield call(getAllTeams, TeamData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllTeamListFailure(responseData.data));
		} else {
			yield put(getAllTeamListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllTeamListFailure(error));
	}
}

export function* getListOfTeamLeads({ payload: { teamList } }) {
	try {
		// const jsonData = { Region };
		const jsonData = {
			region: teamList.Region,
			manager: teamList.Manager,
			managerRegion: teamList.ManagerRegion
		};
		const TeamData = encodeJson(jsonData, generalTypesAction.FETCH_TEAM_LEADER);
		const responseData = yield call(getTeamLeads, TeamData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllTeamLeadsFailure(responseData.data));
		} else {
			yield put(getAllTeamLeadsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllTeamLeadsFailure(error));
	}
}

// export function* getListOfTeamLeads({ payload: { teamList } }) {
// 	try {
// 		const jsonData = {
// 			teamID: teamList.TeamID,
// 		};
// 		const TeamData = encodeJson(jsonData, generalTypesAction.FETCH_TEAM_LEADER);
// 		const responseData = yield call(getTeamLeads, TeamData);
// 		if (responseData.data.statusCode === '100') {
// 			yield put(getAllTeamLeadsFailure(responseData.data));
// 		} else {
// 			yield put(getAllTeamLeadsSuccess(responseData.data));
// 		}
// 	} catch (error) {
// 		yield put(getAllTeamLeadsFailure(error));
// 	}
// }

export function* getTeamLeadsListReset() {
	yield put(getAllTeamLeadsChangedAction());
}

export function* createTeamResponseReset() {
	yield put(teamCreateChanged());
}

export function* updateTeamResponseReset() {
	yield put(teamUpdateChanged());
}

export function* deleteTeamResponseReset() {
	yield put(teamDeleteChanged());
}

export function* getListTeamListReset() {
	yield put(getAllTeamListChangedAction());
}

export function* teamCreateStart() {
	yield takeLatest(teamActionTypes.TEAM_CREATE_REPSONSE_START, createNewTeam);
}

export function* teamCreateResponseReset() {
	yield takeLatest(teamActionTypes.TEAM_CREATE_REPSONSE_RESET, createTeamResponseReset);
}

export function* teamUpdateStart() {
	yield takeLatest(teamActionTypes.TEAM_UPDATE_REPSONSE_START, updateTeamDetails);
}

export function* teamUpdateResponseReset() {
	yield takeLatest(teamActionTypes.TEAM_UPDATE_REPSONSE_RESET, updateTeamResponseReset);
}

export function* teamDeleteStart() {
	yield takeLatest(teamActionTypes.TEAM_DELETE_REPSONSE_START, deleteTeamDetails);
}

export function* teamDeleteResponseReset() {
	yield takeLatest(teamActionTypes.TEAM_DELETE_REPSONSE_RESET, deleteTeamResponseReset);
}

export function* getAllTeamList() {
	yield takeLatest(teamActionTypes.TEAM_LIST_START, getListOfTeam);
}

export function* getAllTeamListReset() {
	yield takeLatest(teamActionTypes.TEAM_LIST_RESET, getListTeamListReset);
}

export function* getAllTeamLeads() {
	yield takeLatest(teamActionTypes.TEAM_GET_TEAM_LEAD_START, getListOfTeamLeads);
}

export function* getAllTeamLeadReset() {
	yield takeLatest(teamActionTypes.TEAM_GET_TEAM_LEAD_RESET, getTeamLeadsListReset);
}
export function* teamSaga() {
	yield all([
		call(getAllTeamList),
		call(getAllTeamListReset),
		call(teamCreateStart),
		call(teamCreateResponseReset),
		call(teamUpdateStart),
		call(teamUpdateResponseReset),
		call(teamDeleteStart),
		call(teamDeleteResponseReset),
		call(getAllTeamLeads),
		call(getAllTeamLeadReset),
	]);
}
