/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DemandCollectionActionTypes from './demand-collection-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	TotalLoanAmount: 0,
	TotalLoanOLBAmount: 0,
	TotalLoanAmountReceived: 0,
	TotalLoanAmountArrear: 0,

	getDemandCollectionListResponse: null,
	getDemandCollectionListArr: [],
	getDemandCollectionListStatus: null,

	getDemandCollectionExcelListResponse: null,
	getDemandCollectionExcelListArr: [],
	getDemandCollectionExcelListStatus: null,

	getDemandCollectionPDFListResponse: null,
	getDemandCollectionPDFListArr: [],
	getDemandCollectionPDFListStatus: null,

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_SUCCESS:
			return {
				...state,
				getDemandCollectionListResponse: action.payload,
				getDemandCollectionListArr: docodeResponse(action.payload, 'DemandCollectionKey'),
				TotalLoanAmount: action.payload.TotalLoanAmount,
				TotalLoanOLBAmount: action.payload.TotalLoanOLBAmount,
				TotalLoanAmountReceived: action.payload.TotalLoanAmountReceived,
				TotalLoanAmountArrear: action.payload.TotalLoanAmountArrear,
				getDemandCollectionListStatus: 'Success',
			};

		case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_FAILURE:
			return { ...state, getDemandCollectionListStatus: action.payload };

		case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_RESPONSE_CHANGED:
			return { ...state, getDemandCollectionListResponse: null };

		case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_RESPONSE_ARRAY_CHANGED:
			return { ...state, getDemandCollectionListArr: null };

		case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_SUCCESS:
			return {
				...state,
				getDemandCollectionExcelListResponse: action.payload,
				getDemandCollectionExcelListArr: docodeResponse(action.payload, 'RepaymentCollectionExcelKey'),
				getDemandCollectionExcelListStatus: 'Success',
			};

		case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_FAILURE:
			return { ...state, getDemandCollectionExcelListStatus: action.payload };

		case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_RESPONSE_CHANGED:
			return { ...state, getDemandCollectionExcelListStatus: null };

			case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_SUCCESS:
				return {
					...state,
					getDemandCollectionPDFListResponse: action.payload,
					getDemandCollectionPDFListArr: docodeResponse(action.payload, 'RepaymentCollectionPDFKey'),
					getDemandCollectionPDFListStatus: 'Success',
				};
	
			case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_FAILURE:
				return { ...state, getDemandCollectionPDFListStatus: action.payload };
	
			case DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_RESPONSE_CHANGED:
				return { ...state, getDemandCollectionPDFListStatus: null };
		default:
			return state;
	}
};
