/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import sanctionReportTypes from './sanction-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getSanctionDetailResponse: null,
	getSanctionDetailArr: [],

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case sanctionReportTypes.GET_SANCTION_REPORT_SUCCESS:
			return {
				...state,
				getSanctionDetailResponse: action.payload,
				getSanctionDetailArr: docodeResponse(action.payload, 'GetSanctionReportDetailsKey'),
			};

		case sanctionReportTypes.GET_SANCTION_REPORT_FAILURE:
			return { ...state, getSanctionDetailResponse: action.payload };

		case sanctionReportTypes.GET_SANCTION_REPORT_RESPONSE_CHANGED:
			return { ...state, getSanctionDetailResponse: null };

		default:
			return state;
	}
};
