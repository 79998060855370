const DemandFollowupActionTypes = {

    GET_DEMAND_FOLLOWUP_LIST_START: 'get_demand_followup_start',
	GET_DEMAND_FOLLOWUP_LIST_SUCCESS: 'get_demand_followup_success',
	GET_DEMAND_FOLLOWUP_LIST_FAILURE: 'get_demand_followup_failure',
	GET_DEMAND_FOLLOWUP_LIST_RESPONSE_RESET: 'get_demand_followup_response_reset',
	GET_DEMAND_FOLLOWUP_LIST_RESPONSE_CHANGED: 'get_demand_followup_response_changed',
	GET_DEMAND_FOLLOWUP_LIST_RESPONSE_ARRAY_CHANGED: 'get_demand_followup_response_array_changed',

}
export default DemandFollowupActionTypes;