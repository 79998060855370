/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import KYCVerificationActionTypes from './kyc-verification.types';
import {
	kYCaadharAdd,
	kYCaadharAddotp,
	kYCpanAdd,
	kYCgstAdd,
	kYCcinAdd,
	kYCitrAdd,
	kYCmcaAdd,
} from './kyc-verification.service';

import {
	callKYCAadharSuccess,
	callKYCAadharFailure,
	callKYCAadharResponseChanged,
	callKYCAadharotpSuccess,
	callKYCAadharotpFailure,
	callKYCAadharotpResponseChanged,
	callKYCPanSuccess,
	callKYCPanFailure,
	callKYCPanResponseChanged,
	callKYCgstSuccess,
	callKYCgstFailure,
	callKYCgstResponseChanged,
	callKYCcinSuccess,
	callKYCcinFailure,
	callKYCcinResponseChanged,
	callKYCitrSuccess,
	callKYCitrFailure,
	callKYCitrResponseChanged,
	callKYCmcaSuccess,
	callKYCmcaFailure,
	callKYCmcaResponseChanged,
} from './kyc-verification.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD KYC VERIFICATION  ******************** */
export function* KYCaadharAdd({ payload }) {
	try {
		const key = 'AadharVerifyKYCKEY';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			AadharNumber: payload.aadharNumber,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kYCaadharAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(callKYCAadharFailure(responseData.data));
		} else {
			yield put(callKYCAadharSuccess(responseData.data));
		}
	} catch (error) {
		yield put(callKYCAadharFailure(error));
	}
}

export function* resetAddKYCaadharResponse() {
	yield put(callKYCAadharResponseChanged());
}

export function* onAddaadharKYCVerification() {
	yield takeLatest(KYCVerificationActionTypes.CALL_KYC_ADDHAR_START, KYCaadharAdd);
}

export function* onAddaadharKYCVerificationResponseReset() {
	yield takeLatest(
		KYCVerificationActionTypes.CALL_KYC_ADDHAR_RESPONSE_RESET,
		resetAddKYCaadharResponse,
	);
}
/** *********************  END ADD KYC VERIFICATION ******************** */

/** *********************  START ADD KYC VERIFICATION  ******************** */
export function* KYCaadharAddotp({ payload }) {
	try {
		const key = 'AadharVerifyKYCKEY';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			AadharotpNumber: payload.partiesAadharotpNumber,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kYCaadharAddotp, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(callKYCAadharotpFailure(responseData.data));
		} else {
			yield put(callKYCAadharotpSuccess(responseData.data));
		}
	} catch (error) {
		yield put(callKYCAadharotpFailure(error));
	}
}

export function* resetAddKYCaadharotpResponse() {
	yield put(callKYCAadharotpResponseChanged());
}

export function* onAddaadharotpKYCVerification() {
	yield takeLatest(KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_START, KYCaadharAddotp);
}

export function* onAddaadharotpKYCVerificationResponseReset() {
	yield takeLatest(
		KYCVerificationActionTypes.CALL_KYC_ADDHAR_OTP_RESPONSE_RESET,
		resetAddKYCaadharotpResponse,
	);
}
/** *********************  END ADD KYC VERIFICATION ******************** */

/** *********************  START ADD KYC VERIFICATION  ******************** */
export function* KYCpanAdd({ payload }) {
	try {
		const key = 'AppVerifyKYCKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			GeneralCompanyID: payload.generalCompanyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			PanNumber: payload.panNumber,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kYCpanAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(callKYCPanFailure(responseData.data));
		} else {
			yield put(callKYCPanSuccess(responseData.data));
		}
	} catch (error) {
		yield put(callKYCPanFailure(error));
	}
}

export function* resetAddKYCpanResponse() {
	yield put(callKYCPanResponseChanged());
}

export function* onAddpanKYCVerification() {
	yield takeLatest(KYCVerificationActionTypes.CALL_KYC_PAN_START, KYCpanAdd);
}

export function* onAddpanKYCVerificationResponseReset() {
	yield takeLatest(
		KYCVerificationActionTypes.CALL_KYC_PAN_RESPONSE_RESET,
		resetAddKYCpanResponse,
	);
}
/** *********************  END ADD KYC VERIFICATION ******************** */

/** *********************  START ADD KYC VERIFICATION  ******************** */
export function* KYCgstAdd({ payload }) {
	try {
		const key = 'BusinessBranchKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBranchList: payload.branchListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kYCgstAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(callKYCgstFailure(responseData.data));
		} else {
			yield put(callKYCgstSuccess(responseData.data));
		}
	} catch (error) {
		yield put(callKYCgstFailure(error));
	}
}

export function* resetAddKYCgstResponse() {
	yield put(callKYCgstResponseChanged());
}

export function* onAddgstKYCVerification() {
	yield takeLatest(KYCVerificationActionTypes.CALL_KYC_GST_START, KYCgstAdd);
}

export function* onAddgstKYCVerificationResponseReset() {
	yield takeLatest(
		KYCVerificationActionTypes.CALL_KYC_GST_RESPONSE_RESET,
		resetAddKYCgstResponse,
	);
}
/** *********************  END ADD KYC VERIFICATION ******************** */

/** *********************  START ADD KYC VERIFICATION  ******************** */
export function* KYCcinAdd({ payload }) {
	try {
		const key = 'BusinessBranchKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBranchList: payload.branchListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kYCcinAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(callKYCcinFailure(responseData.data));
		} else {
			yield put(callKYCcinSuccess(responseData.data));
		}
	} catch (error) {
		yield put(callKYCcinFailure(error));
	}
}

export function* resetAddKYCcinResponse() {
	yield put(callKYCcinResponseChanged());
}

export function* onAddcinKYCVerification() {
	yield takeLatest(KYCVerificationActionTypes.CALL_KYC_CIN_START, KYCcinAdd);
}

export function* onAddcinKYCVerificationResponseReset() {
	yield takeLatest(
		KYCVerificationActionTypes.CALL_KYC_CIN_RESPONSE_RESET,
		resetAddKYCcinResponse,
	);
}
/** *********************  END ADD KYC VERIFICATION ******************** */

/** *********************  START ADD KYC VERIFICATION  ******************** */
export function* KYCitrAdd({ payload }) {
	try {
		const key = 'BusinessBranchKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBranchList: payload.branchListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kYCitrAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(callKYCitrFailure(responseData.data));
		} else {
			yield put(callKYCitrSuccess(responseData.data));
		}
	} catch (error) {
		yield put(callKYCitrFailure(error));
	}
}

export function* resetAddKYCitrResponse() {
	yield put(callKYCitrResponseChanged());
}

export function* onAdditrKYCVerification() {
	yield takeLatest(KYCVerificationActionTypes.CALL_KYC_ITR_START, KYCitrAdd);
}

export function* onAdditrKYCVerificationResponseReset() {
	yield takeLatest(
		KYCVerificationActionTypes.CALL_KYC_ITR_RESPONSE_RESET,
		resetAddKYCitrResponse,
	);
}
/** *********************  END ADD KYC VERIFICATION ******************** */

/** *********************  START ADD KYC VERIFICATION  ******************** */
export function* KYCmcaAdd({ payload }) {
	try {
		const key = 'BusinessBranchKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBranchList: payload.branchListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(kYCmcaAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(callKYCmcaFailure(responseData.data));
		} else {
			yield put(callKYCmcaSuccess(responseData.data));
		}
	} catch (error) {
		yield put(callKYCmcaFailure(error));
	}
}

export function* resetAddKYCmcaResponse() {
	yield put(callKYCmcaResponseChanged());
}

export function* onAddmcaKYCVerification() {
	yield takeLatest(KYCVerificationActionTypes.CALL_KYC_MCA_START, KYCmcaAdd);
}

export function* onAddmcaKYCVerificationResponseReset() {
	yield takeLatest(
		KYCVerificationActionTypes.CALL_KYC_MCA_RESPONSE_RESET,
		resetAddKYCmcaResponse,
	);
}
/** *********************  END ADD KYC VERIFICATION ******************** */
export function* kYCVerificationSaga() {
	yield all([
		call(onAddaadharKYCVerification),
		call(onAddaadharKYCVerificationResponseReset),
		call(onAddaadharotpKYCVerification),
		call(onAddaadharotpKYCVerificationResponseReset),
		call(onAddpanKYCVerification),
		call(onAddpanKYCVerificationResponseReset),
		call(onAddgstKYCVerification),
		call(onAddgstKYCVerificationResponseReset),
		call(onAddcinKYCVerification),
		call(onAddcinKYCVerificationResponseReset),
		call(onAdditrKYCVerification),
		call(onAdditrKYCVerificationResponseReset),
		call(onAddmcaKYCVerification),
		call(onAddmcaKYCVerificationResponseReset),
	]);
}
