const EconomicActivityActionTypes = {
	ADD_ECONOMIC_ACTIVITY_DETAILS_START: 'add_economic_activity_details_start',
	ADD_ECONOMIC_ACTIVITY_DETAILS_SUCCESS: 'add_economic_activity_details_success',
	ADD_ECONOMIC_ACTIVITY_DETAILS_FAILURE: 'add_economic_activity_details_failure',
	ADD_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET: 'add_economic_activity_details_response_reset',
	ADD_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED: 'add_economic_activity_details_response_changed',

	UPDATE_ECONOMIC_ACTIVITY_DETAILS_START: 'update_economic_activity_details_start',
	UPDATE_ECONOMIC_ACTIVITY_DETAILS_SUCCESS: 'update_economic_activity_details_success',
	UPDATE_ECONOMIC_ACTIVITY_DETAILS_FAILURE: 'update_economic_activity_details_failure',
	UPDATE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET: 'update_economic_activity_details_response_reset',
	UPDATE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED: 'update_economic_activity_details_response_changed',

	GET_ECONOMIC_ACTIVITY_LIST_START: 'get_economic_activity_list_start',
	GET_ECONOMIC_ACTIVITY_LIST_SUCCESS: 'get_economic_activity_list_success',
	GET_ECONOMIC_ACTIVITY_LIST_FAILURE: 'get_economic_activity_list_failure',
	GET_ECONOMIC_ACTIVITY_LIST_RESPONSE_RESET: 'get_economic_activity_list_response_reset',
	GET_ECONOMIC_ACTIVITY_LIST_RESPONSE_CHANGED: 'get_economic_activity_list_response_changed',

	FETCH_ECONOMIC_ACTIVITY_DETAILS_START: 'fetch_economic_activity_details_start',
	FETCH_ECONOMIC_ACTIVITY_DETAILS_SUCCESS: 'fetch_economic_activity_details_success',
	FETCH_ECONOMIC_ACTIVITY_DETAILS_FAILURE: 'fetch_economic_activity_details_failure',
	FETCH_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET: 'fetch_economic_activity_details_response_reset',
	FETCH_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED: 'fetch_economic_activity_details_response_changed',

	DELETE_ECONOMIC_ACTIVITY_DETAILS_START: 'delete_economic_activity_details_start',
	DELETE_ECONOMIC_ACTIVITY_DETAILS_SUCCESS: 'delete_economic_activity_details_success',
	DELETE_ECONOMIC_ACTIVITY_DETAILS_FAILURE: 'delete_economic_activity_details_failure',
	DELETE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET: 'delete_economic_activity_details_response_reset',
	DELETE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_CHANGED: 'delete_economic_activity_details_response_changed',

	FETCH_ALL_ECONOMIC_ACTIVITY_START: 'fetch_all_economic_activity_start',
	FETCH_ALL_ECONOMIC_ACTIVITY_SUCCESS: 'fetch_all_economic_activity_success',
	FETCH_ALL_ECONOMIC_ACTIVITY_FAILURE: 'fetch_all_economic_activity_failure',
	FETCH_ALL_ECONOMIC_ACTIVITY_RESPONSE_RESET: 'fetch_all_economic_activity_response_reset',
	FETCH_ALL_ECONOMIC_ACTIVITY_RESPONSE_CHANGED: 'fetch_all_economic_activity_response_changed',
};
export default EconomicActivityActionTypes;
