/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DemandFollowupActionTypes from './demand-followup-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {

	getDemandFollowupListResponse: null,
	getDemandFollowupListArr: [],
	getDemandFollowupListStatus: null,

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_SUCCESS:
			return {
				...state,
				getDemandFollowupListResponse: action.payload,
				getDemandFollowupListArr: docodeResponse(action.payload, 'DemandFollowupKey'),
				getDemandFollowupListStatus: 'Success',
			};

		case DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_FAILURE:
			return { ...state, getDemandFollowupListStatus: action.payload };

		case DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_RESPONSE_CHANGED:
			return { ...state, getDemandFollowupListResponse: null };

		case DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_RESPONSE_ARRAY_CHANGED:
			return { ...state, getDemandFollowupListArr: null };

		default:
			return state;
	}
};
