/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import CollectionActionTypes from './collection.types';
import {
	fetchOfficerListCenters,
	fetchOfficerListArrearCenters,
	fetchBranchCollectionUsersList,
	fetchCenterListOfficerCollections,
} from './collection.service';

import {
	getOfficerListCentersSuccess,
	getOfficerListCentersFailure,
	getOfficerListCentersResponseChanged,
	getOfficerListArrearCentersSuccess,
	getOfficerListArrearCentersFailure,
	getOfficerListArrearCentersResponseChanged,
	getBranchCollectionUsersListSuccess,
	getBranchCollectionUsersListFailure,
	getBranchCollectionUsersListResponseChanged,
	getCenterListOfficerCollectionsSuccess,
	getCenterListOfficerCollectionsFailure,
	getCenterListOfficerCollectionsResponseChanged,
} from './collection.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START OFFICER LIST CENTERS DETAILS  ******************** */
export function* getOfficerListCenters({ payload }) {
	try {
		const key = 'centerListForOfficerKey';
		const jsonData = {
			officerID: payload.officerID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			whenToPay: payload.whenToPay,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchOfficerListCenters, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerListCentersFailure(responseData.data));
		} else {
			yield put(getOfficerListCentersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerListCentersFailure(error));
	}
}

export function* resetGetOfficerListCentersResponse() {
	yield put(getOfficerListCentersResponseChanged());
}

export function* onGetOfficerListCentersDetails() {
	yield takeLatest(CollectionActionTypes.GET_OFFICER_LIST_CENTERS_START, getOfficerListCenters);
}

export function* onGetOfficerListCentersResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_RESET,
		resetGetOfficerListCentersResponse,
	);
}
/** *********************  END GET OFFICER LIST CENTERS  ******************** */

/** *********************  START OFFICER LIST ARREAR CENTERS DETAILS  ******************** */
export function* getOfficerListArrearCenters({ payload }) {
	try {
		const key = 'arrearCenterListForOfficerKey';
		const jsonData = {
			officerID: payload.officerID,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchOfficerListArrearCenters, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerListArrearCentersFailure(responseData.data));
		} else {
			yield put(getOfficerListArrearCentersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerListArrearCentersFailure(error));
	}
}

export function* resetGetOfficerListArrearCentersResponse() {
	yield put(getOfficerListArrearCentersResponseChanged());
}

export function* onGetOfficerListArrearCentersDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_START,
		getOfficerListArrearCenters,
	);
}

export function* onGetOfficerListArrearCentersResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET,
		resetGetOfficerListArrearCentersResponse,
	);
}
/** *********************  END GET OFFICER LIST ARREAR CENTERS  ******************** */

/** *********************  START BRANCH COLLECTION USERS LIST DETAILS  ******************** */
export function* getBranchCollectionUsersList({ payload }) {
	try {
		const key = 'branchCollectionUsersListKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			CollectionDate: payload.CollectionDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchCollectionUsersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchCollectionUsersListFailure(responseData.data));
		} else {
			yield put(getBranchCollectionUsersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchCollectionUsersListFailure(error));
	}
}

export function* resetGetBranchCollectionUsersListResponse() {
	yield put(getBranchCollectionUsersListResponseChanged());
}

export function* onGetBranchCollectionUsersListDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_START,
		getBranchCollectionUsersList,
	);
}

export function* onGetBranchCollectionUsersListResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET,
		resetGetBranchCollectionUsersListResponse,
	);
}
/** *********************  END GET BRANCH COLLECTION USERS LIST  ******************** */

/** *********************  START CENTER LIST FOR OFFICER COLLECTIONS DETAILS  ******************** */
export function* getCenterListOfficerCollections({ payload }) {
	try {
		const key = 'centerListOfficerCollectionsKey';
		const jsonData = {
			officerID: payload.officerID,
			AreaID: payload.AreaID,
			DatabaseName: payload.DatabaseName,
			whenToPay: payload.whenToPay,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCenterListOfficerCollections, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterListOfficerCollectionsFailure(responseData.data));
		} else {
			yield put(getCenterListOfficerCollectionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterListOfficerCollectionsFailure(error));
	}
}

export function* resetGetCenterListOfficerCollectionsResponse() {
	yield put(getCenterListOfficerCollectionsResponseChanged());
}

export function* onGetCenterListOfficerCollectionsDetails() {
	yield takeLatest(
		CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_START,
		getCenterListOfficerCollections,
	);
}

export function* onGetCenterListOfficerCollectionsResponseReset() {
	yield takeLatest(
		CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET,
		resetGetBranchCollectionUsersListResponse,
	);
}
/** *********************  END GET BRANCH COLLECTION USERS LIST  ******************** */

export function* collectionSaga() {
	yield all([
		call(onGetOfficerListCentersDetails),
		call(onGetOfficerListCentersResponseReset),
		call(onGetOfficerListArrearCentersDetails),
		call(onGetOfficerListArrearCentersResponseReset),
		call(onGetBranchCollectionUsersListDetails),
		call(onGetBranchCollectionUsersListResponseReset),
		call(onGetCenterListOfficerCollectionsDetails),
		call(onGetCenterListOfficerCollectionsResponseReset),
	]);
}
