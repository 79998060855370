const progressReportTypes = {
	FETCH_All_OFFICER_USER_BASED_ON_BRANCH_START: 'FETCH_All_OFFICER_USER_BASED_ON_BRANCH_START',
	FETCH_All_OFFICER_USER_BASED_ON_BRANCH_SUCCESS:
		'FETCH_All_OFFICER_USER_BASED_ON_BRANCH_SUCCESS',
	FETCH_All_OFFICER_USER_BASED_ON_BRANCH_FAILURE:
		'FETCH_All_OFFICER_USER_BASED_ON_BRANCH_FAILURE',
	FETCH_All_OFFICER_USER_BASED_ON_BRANCH_CHANGED:
		'FETCH_All_OFFICER_USER_BASED_ON_BRANCH_CHANGED',
	FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESPONSE_CHANGED:
		'FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESPONSE_CHANGED',
	FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESET: 'FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESET',

	FETCH_BRANCH_PROGRESS_REPORT_START: 'FETCH_BRANCH_PROGRESS_REPORT_START',
	FETCH_BRANCH_PROGRESS_REPORT_SUCCESS: 'FETCH_BRANCH_PROGRESS_REPORT_SUCCESS',
	FETCH_BRANCH_PROGRESS_REPORT_FAILURE: 'FETCH_BRANCH_PROGRESS_REPORT_FAILURE',
	FETCH_BRANCH_PROGRESS_REPORT_CHANGED: 'FETCH_BRANCH_PROGRESS_REPORT_CHANGED',
	FETCH_BRANCH_PROGRESS_REPORT_RESPONSE_CHANGED: 'FETCH_BRANCH_PROGRESS_REPORT_RESPONSE_CHANGED',
	FETCH_BRANCH_PROGRESS_REPORT_RESET: 'FETCH_BRANCH_PROGRESS_REPORT_RESET',
};
export default progressReportTypes;
