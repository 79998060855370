/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import RoleActionTypes from './role.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addRoleDetailsResponse: null,
	updateRoleDetailsResponse: null,
	getRoleListResponse: null,
	getRoleListArr: [],
	fetchRoleDetailsResponse: null,
	fetchRoleDetailsArr: [],
	deleteRoleDetailsResponse: null,
	fetchAllRoleListResponse: null,
	fetchAllRoleListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RoleActionTypes.ADD_ROLE_DETAILS_SUCCESS:
			return { ...state, addRoleDetailsResponse: action.payload };

		case RoleActionTypes.ADD_ROLE_DETAILS_FAILURE:
			return { ...state, addRoleDetailsResponse: action.payload };

		case RoleActionTypes.ADD_ROLE_DETAILS_RESPONSE_CHANGED:
			return { ...state, addRoleDetailsResponse: null };

		case RoleActionTypes.UPDATE_ROLE_DETAILS_SUCCESS:
			return { ...state, updateRoleDetailsResponse: action.payload };

		case RoleActionTypes.UPDATE_ROLE_DETAILS_FAILURE:
			return { ...state, updateRoleDetailsResponse: action.payload };

		case RoleActionTypes.UPDATE_ROLE_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateRoleDetailsResponse: null };

		case RoleActionTypes.GET_ROLE_LIST_SUCCESS:
			return {
				...state,
				getRoleListResponse: action.payload,
				getRoleListArr: docodeResponse(action.payload, 'GetRoleListKey'),
			};

		case RoleActionTypes.GET_ROLE_LIST_FAILURE:
			return { ...state, getRoleListResponse: action.payload };

		case RoleActionTypes.GET_ROLE_LIST_RESPONSE_CHANGED:
			return { ...state, getRoleListResponse: null };

		case RoleActionTypes.FETCH_ROLE_DETAILS_SUCCESS:
			return {
				...state,
				fetchRoleDetailsResponse: action.payload,
				fetchRoleDetailsArr: docodeResponse(action.payload, 'FetchRoleDetailsKey'),
			};

		case RoleActionTypes.FETCH_ROLE_DETAILS_FAILURE:
			return { ...state, fetchRoleDetailsResponse: action.payload };

		case RoleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchRoleDetailsResponse: null, fetchRoleDetailsArr: [] };

		case RoleActionTypes.DELETE_ROLE_DETAILS_SUCCESS:
			return { ...state, deleteRoleDetailsResponse: action.payload };

		case RoleActionTypes.DELETE_ROLE_DETAILS_FAILURE:
			return { ...state, deleteRoleDetailsResponse: action.payload };

		case RoleActionTypes.DELETE_ROLE_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteRoleDetailsResponse: null };

		case RoleActionTypes.FETCH_ALL_ROLE_SUCCESS:
			return {
				...state,
				fetchAllRoleListResponse: action.payload,
				fetchAllRoleListArr: docodeResponse(action.payload, 'FetchAllRolesKey'),
			};

		case RoleActionTypes.FETCH_ALL_ROLE_FAILURE:
			return { ...state, fetchAllRoleListResponse: action.payload };

		case RoleActionTypes.FETCH_ALL_ROLE_RESPONSE_CHANGED:
			return { ...state, fetchAllRoleListResponse: null };

		default:
			return state;
	}
};
