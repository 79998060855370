/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import WalletReportActionTypes from './walletReport.types';

/** ***************** GET GROUP MEMBER DETAILS START *********************** */
export const fetchGroupMemberDetailsStart = (getData) => ({
	type: WalletReportActionTypes.FETCH_GROUP_MEMBER_START,
	payload: getData,
});

export const fetchGroupMemberDetailsSuccess = (successData) => ({
	type: WalletReportActionTypes.FETCH_GROUP_MEMBER_SUCCESS,
	payload: successData,
});

export const fetchGroupMemberDetailsFailure = (failureData) => ({
	type: WalletReportActionTypes.FETCH_GROUP_MEMBER_FAILURE,
	payload: failureData,
});

export const fetchGroupMemberDetailsResponseReset = () => ({
	type: WalletReportActionTypes.FETCH_GROUP_MEMBER_RESPONSE_RESET,
});

export const fetchGroupMemberDetailsResponseChanged = () => ({
	type: WalletReportActionTypes.FETCH_GROUP_MEMBER_RESPONSE_CHANGED,
});
/** ***************** GET GROUP MEMBER DETAILS END *********************** */

/** ***************** GET WALLET REPORT DETAILS START *********************** */
export const fetchWalletReportStart = (getData) => ({
	type: WalletReportActionTypes.FETCH_WALLET_REPORT_START,
	payload: getData,
});

export const fetchWalletReportSuccess = (successData) => ({
	type: WalletReportActionTypes.FETCH_WALLET_REPORT_SUCCESS,
	payload: successData,
});

export const fetchWalletReportFailure = (failureData) => ({
	type: WalletReportActionTypes.FETCH_WALLET_REPORT_FAILURE,
	payload: failureData,
});

export const fetchWalletReportResponseReset = () => ({
	type: WalletReportActionTypes.FETCH_WALLET_REPORT_RESPONSE_RESET,
});

export const fetchWalletReportResponseChanged = () => ({
	type: WalletReportActionTypes.FETCH_WALLET_REPORT_RESPONSE_CHANGED,
});

/** ***************** GET WALLET REPORT DETAILS END *********************** */

