/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const getAllExecutive = (executiveData) => {
	const data = {
		FetchExecutiveBasedOnInputToken: executiveData,
	};
	// console.log('GetAllExecutiveBasedOnInput data:', data);
	const url = `${API_URL}GetAllExecutiveBasedOnInput`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllMemberList = (executiveData) => {
	const data = {
		FetchAllocationMemberToken: executiveData,
	};
	const url = `${API_URL}FetchMemberAllocation`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allocateStaffToMember = (executiveData) => {
	const data = {
		AllocateStaffToMemberToken: executiveData,
	};
	const url = `${API_URL}AllocateStaffToMember`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const transferStaffToMember = (executiveData) => {
	const data = {
		TransferMemberToNewStaffToken: executiveData,
	};
	const url = `${API_URL}TransferMemberToOtherStaff`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};