// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PortFlioAtRiskTypes from './portflioAtRiskReport.types';
import { decodeApiResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	totalCount: 0,
	TotalLoanAmount: 0,
	TotalLoanAmountReceived: 0,
	TotalLoanOLBAmount: 0,
	TotalLoanOLBInterestAmount: 0,
	TotalLoanOverAmount: 0,
	TotalLoanOverInterestAmount: 0,

	SevenDays: 0,
	ForteenDays: 0,
	ForteenDaysTotalAmount: 0,
	FortyTwoDays: 0,
	FortyTwoDaysMoreThanTotalAmount: 0,
	FortyTwoDaysTotalAmount: 0,
	MoreThanFortyTwoDays: 0,
	MoreThanSixtyThreeDays: 0,

	SevenDaysTotalAmount: 0,
	SixtyThreeDaysMoreThanTotalAmount: 0,
	ThirtyFiveDays: 0,
	ThirtyFiveDaysTotalAmount: 0,
	TotalBranchAmount: 0,
	TotalLoanAmountArrear: 0,
	TotalLoanPARValue: 0,
	TwentyEightDays: 0,
	TwentyEightDaysTotalAmount: 0,
	TwentyOneDays: 0,
	TwentyOneDaysTotalAmount: 0,

	PortflioAtRiskResponse: null,
	PortflioAtRiskArr: [],
	FetchOverDueExcelSelectorStatus: '',
	FetchOverDueExcelSelectorResponse: null,
	UserDetailsResponse: null,
	UserDetailskArr: [],
	LoanInfoBasedOnMemberResponse: null,
	LoanInfoBasedOnMemberArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PortFlioAtRiskTypes.PORTFLIO_AT_RISK_SUCCESS:
			return {
				...state,
				PortflioAtRiskResponse: action.payload,
				PortflioAtRiskArr: decodeApiResponse(
					action.payload.records,
					'GetPortfolioAtRiskReportKey',
				),
				totalCount: action.payload.totalCount,
				TotalLoanAmount: action.payload.TotalLoanAmount,
				TotalLoanAmountReceived: action.payload.TotalLoanAmountReceived,
				TotalLoanOLBAmount: action.payload.TotalLoanOLBAmount,
				TotalLoanOLBInterestAmount: action.payload.TotalLoanOLBInterestAmount,
				TotalLoanOverAmount: action.payload.TotalLoanOverAmount,
				TotalLoanOverInterestAmount: action.payload.TotalLoanOverInterestAmount,

				SevenDays: action.payload.SevenDays,
				ForteenDays: action.payload.ForteenDays,
				ForteenDaysTotalAmount: action.payload.ForteenDaysTotalAmount,
				FortyTwoDays: action.payload.FortyTwoDays,
				FortyTwoDaysMoreThanTotalAmount: action.payload.FortyTwoDaysMoreThanTotalAmount,
				FortyTwoDaysTotalAmount: action.payload.FortyTwoDaysTotalAmount,
				MoreThanFortyTwoDays: action.payload.MoreThanFortyTwoDays,
				MoreThanSixtyThreeDays: action.payload.MoreThanSixtyThreeDays,

				SevenDaysTotalAmount: action.payload.SevenDaysTotalAmount,
				SixtyThreeDaysMoreThanTotalAmount: action.payload.SixtyThreeDaysMoreThanTotalAmount,
				ThirtyFiveDays: action.payload.ThirtyFiveDays,
				ThirtyFiveDaysTotalAmount: action.payload.ThirtyFiveDaysTotalAmount,
				TotalBranchAmount: action.payload.TotalBranchAmount,
				TotalLoanAmountArrear: action.payload.TotalLoanAmountArrear,
				TotalLoanPARValue: action.payload.TotalLoanPARValue,
				TwentyEightDays: action.payload.TwentyEightDays,
				TwentyEightDaysTotalAmount: action.payload.TwentyEightDaysTotalAmount,
				TwentyOneDays: action.payload.TwentyOneDays,
				TwentyOneDaysTotalAmount: action.payload.TwentyOneDaysTotalAmount,
			};

		case PortFlioAtRiskTypes.PORTFLIO_AT_RISK_FAILURE:
			return { ...state, PortflioAtRiskResponse: action.payload };

		case PortFlioAtRiskTypes.PORTFLIO_AT_RISK_RESPONSE_CHANGED:
			return { ...state, PortflioAtRiskResponse: null };

		case PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_SUCCESS:
			return {
				...state,
				UserDetailsResponse: action.payload,
				UserDetailskArr: decodeApiResponse(action.payload.records, 'userDetailsparKey'),
			};

		case PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_FAILURE:
			return { ...state, UserDetailsResponse: action.payload };

		case PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_RESPONSE_CHANGED:
			return { ...state, UserDetailsResponse: null };

		case PortFlioAtRiskTypes.GET_LOAN_INFO_BASEDON_MEMBER_SUCCESS:
			return {
				...state,
				LoanInfoBasedOnMemberResponse: action.payload,
				LoanInfoBasedOnMemberArr: decodeApiResponse(
					action.payload,
					'LOANINFORBASEDONMEMBERBOTHCLOSEDTOKEN',
				),
			};

		case PortFlioAtRiskTypes.GET_LOAN_INFO_BASEDON_MEMBER_FAILURE:
			return { ...state, LoanInfoBasedOnMemberResponse: action.payload };

		case PortFlioAtRiskTypes.GET_LOAN_INFO_BASEDON_MEMBER_RESPONSE_CHANGED:
			return { ...state, LoanInfoBasedOnMemberResponse: null };
		default:
			return state;
	}
};
