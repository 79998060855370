/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import WalletReportActionTypes from './walletReport.types';

import{
    fetchGroupMember,
    fetchWalletReport,
}   from './walletReport.service';

import{
    fetchGroupMemberDetailsSuccess,
	fetchGroupMemberDetailsFailure,
	fetchGroupMemberDetailsResponseChanged,
    fetchWalletReportSuccess,
    fetchWalletReportFailure,
    fetchWalletReportResponseChanged,
}   from './walletReport.action'

import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH GROUP MEMBER DETAILS ******************** */
export function* fetchGroupMemberData({ payload }) {
	try {
		const key = 'GetGroupMemberListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			GroupID: payload.groupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGroupMember, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGroupMemberDetailsFailure(responseData.data));
		} else {
			yield put(fetchGroupMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGroupMemberDetailsFailure(error));
	}
}

export function* resetFetchGroupMemberResponse() {
	yield put(fetchGroupMemberDetailsResponseChanged());
}

export function* onFetchGroupMemberDetails() {
	yield takeLatest(
		WalletReportActionTypes.FETCH_GROUP_MEMBER_START,
		fetchGroupMemberData,
	);
}

export function* onFetchGroupMemberResponseReset() {
	yield takeLatest(
		WalletReportActionTypes.FETCH_GROUP_MEMBER_RESPONSE_RESET,
		resetFetchGroupMemberResponse,
	);
}
/** ********************* END FETCH GROUP MEMBER DETAILS******************** */

/** *********************  START FETCH WALLET REPORT DETAILS ******************** */
export function* fetchWalletReportDetails({ payload }) {
	try {
		const key = 'GetWalletCollectionReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
            GroupID: payload.groupID,
			DatabaseName: payload.databaseName,
            FunderID: payload.funderID,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
            CenterID: payload.centerID,
            MemberID: payload.memberID,
			
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchWalletReport, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchWalletReportFailure(responseData.data));
		} else {
			yield put(fetchWalletReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchWalletReportFailure(error));
	}
}

export function* resetFetchWalletReportResponse() {
	yield put(fetchWalletReportResponseChanged());
}

export function* onFetchWalletReport() {
	yield takeLatest(
		WalletReportActionTypes.FETCH_WALLET_REPORT_START,
		fetchWalletReportDetails,
	);
}

export function* onFetchWalletReportResponseReset() {
	yield takeLatest(
		WalletReportActionTypes.FETCH_WALLET_REPORT_RESPONSE_RESET,
		resetFetchWalletReportResponse,
	);
}
/** ********************* END FETCH WALLET REPORT DETAILS ******************** */


export function* walletReportSaga() {
	yield all([
		call(onFetchGroupMemberDetails),
		call(onFetchGroupMemberResponseReset),
        call(onFetchWalletReport),
        call(onFetchWalletReportResponseReset),
	]);
}
