/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import executiveActionTypes from './executive.types';

export const createExecutiveSuccess = (Executive) => ({
	type: executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_SUCCESS,
	payload: Executive,
});

export const createExecutiveFailure = (error) => ({
	type: executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createExecutiveStart = (Executive) => ({
	type: executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_START,
	payload: Executive,
});

export const executiveCreateChanged = () => ({
	type: executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_CHANGED,
});

export const executiveCreateReset = () => ({
	type: executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_RESET,
});

export const deleteExecutiveSuccess = (Executive) => ({
	type: executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_SUCCESS,
	payload: Executive,
});

export const deleteExecutiveFailure = (error) => ({
	type: executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteExecutiveStart = (ExecutiveDetails) => ({
	type: executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_START,
	payload: ExecutiveDetails,
});

export const executiveDeleteChanged = () => ({
	type: executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_CHANGED,
});

export const executiveDeleteReset = () => ({
	type: executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_RESET,
});

export const getAllExecutiveListFilter = (EXECUTIVE) => ({
	type: executiveActionTypes.EXECUTIVE_LIST_START,
	payload: EXECUTIVE,
});

export const getAllExecutiveListSuccess = (EXECUTIVE) => ({
	type: executiveActionTypes.EXECUTIVE_LIST_SUCCESS,
	payload: EXECUTIVE,
});

export const getAllExecutiveListFailure = (EXECUTIVE) => ({
	type: executiveActionTypes.EXECUTIVE_LIST_FAILURE,
	payload: EXECUTIVE,
});
export const getAllExecutiveListResetAction = () => ({
	type: executiveActionTypes.EXECUTIVE_LIST_RESET,
});

export const getAllExecutiveListChangedAction = () => ({
	type: executiveActionTypes.EXECUTIVE_LIST_CHANGED,
});

export const getAllMemberAllocationListFilter = (EXECUTIVE) => ({
	type: executiveActionTypes.ALLOCATION_LIST_START,
	payload: EXECUTIVE,
});

export const getAllMemberAllocationListSuccess = (EXECUTIVE) => ({
	type: executiveActionTypes.ALLOCATION_LIST_SUCCESS,
	payload: EXECUTIVE,
});

export const getAllMemberAllocationListFailure = (EXECUTIVE) => ({
	type: executiveActionTypes.ALLOCATION_LIST_FAILURE,
	payload: EXECUTIVE,
});
export const getAllMemberAllocationListResetAction = () => ({
	type: executiveActionTypes.ALLOCATION_LIST_RESET,
});

export const getAllMemberAllocationListChangedAction = () => ({
	type: executiveActionTypes.ALLOCATION_LIST_CHANGED,
});

export const allocationMemberToStaffStart = (EXECUTIVE) => ({
	type: executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_START,
	payload: EXECUTIVE,
});

export const allocationMemberToStaffSuccess = (EXECUTIVE) => ({
	type: executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_SUCCESS,
	payload: EXECUTIVE,
});

export const allocationMemberToStaffFailure = (EXECUTIVE) => ({
	type: executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_FAILURE,
	payload: EXECUTIVE,
});
export const allocationMemberToStaffReset = () => ({
	type: executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_RESET,
});

export const allocationMemberToStaffChangedAction = () => ({
	type: executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_CHANGED,
});

export const transferMemberToStaffStart = (EXECUTIVE) => ({
	type: executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_START,
	payload: EXECUTIVE,
});

export const transferMemberToStaffSuccess = (EXECUTIVE) => ({
	type: executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_SUCCESS,
	payload: EXECUTIVE,
});

export const transferMemberToStaffFailure = (EXECUTIVE) => ({
	type: executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_FAILURE,
	payload: EXECUTIVE,
});
export const transferMemberToStaffReset = () => ({
	type: executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_RESET,
});

export const transferMemberToStaffChangedAction = () => ({
	type: executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_CHANGED,
});
