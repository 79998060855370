/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import TodayCollectionActionTypes from './todayCollection.types';
import { fetchTodayCollectionDetailsList } from './todayCollection.service';

import {
	getTodayCollectionListSuccess,
	getTodayCollectionListFailure,
	getTodayCollectionListResponseChanged,
} from './todayCollection.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START OFFICER LIST CENTERS DETAILS  ******************** */
export function* getTodayCollectionList({ payload }) {
	try {
		const key = 'GetDemandForTodayCollectionsWithToken';
		debugger;
		const jsonData = {
			BranchID: payload.BranchID,
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			whenToPay: payload.whenToPay,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			GroupID: payload.GroupID,
			GroupOfficerID: payload.GroupOfficerID,
			LocationID: payload.LocationID,
			RepayDate: payload.RepayDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchTodayCollectionDetailsList, encodeData);
		debugger;
		if (responseData.data.statusCode === '102') {
			yield put(getTodayCollectionListFailure(responseData.data));
		} else {
			yield put(getTodayCollectionListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getTodayCollectionListFailure(error));
	}
}

export function* resetGetTodayCollectionListResponse() {
	yield put(getTodayCollectionListResponseChanged());
}

export function* onGetTodayCollectionListDetails() {
	yield takeLatest(TodayCollectionActionTypes.GET_TODAY_COLLECTION_LIST_START, getTodayCollectionList);
}

export function* onGetTodayCollectionListResponseReset() {
	yield takeLatest(
		TodayCollectionActionTypes.GET_TODAY_COLLECTION_LIST_RESPONSE_RESET,
		resetGetTodayCollectionListResponse,
	);
}
/** *********************  END GET OFFICER LIST CENTERS  ******************** */


export function* todayCollectionSaga() {
	yield all([
		call(onGetTodayCollectionListDetails),
		call(onGetTodayCollectionListResponseReset),
	]);
}
