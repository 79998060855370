/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import InsuranceCompanyActionTypes from './insurance-company.types';

/** ***************** ADD INSURANCE COMPANY DETAILS START *********************** */
export const addInsuranceCompanyDetailsStart = (addData) => ({
	type: InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_START,
	payload: addData,
});

export const addInsuranceCompanyDetailsSuccess = (successData) => ({
	type: InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_SUCCESS,
	payload: successData,
});

export const addInsuranceCompanyDetailsFailure = (failureData) => ({
	type: InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_FAILURE,
	payload: failureData,
});

export const addInsuranceCompanyDetailsResponseResetStart = () => ({
	type: InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
});

export const addInsuranceCompanyDetailsResponseChanged = () => ({
	type: InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD INSURANCE COMPANY DETAILS END *********************** */

/** ***************** UPDATE INSURANCE COMPANY DETAILS START *********************** */
export const updateInsuranceCompanyDetailsStart = (updateData) => ({
	type: InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_START,
	payload: updateData,
});

export const updateInsuranceCompanyDetailsSuccess = (successData) => ({
	type: InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_SUCCESS,
	payload: successData,
});

export const updateInsuranceCompanyDetailsFailure = (failureData) => ({
	type: InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_FAILURE,
	payload: failureData,
});

export const updateInsuranceCompanyDetailsResponseResetStart = () => ({
	type: InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
});

export const updateInsuranceCompanyDetailsResponseChanged = () => ({
	type: InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE INSURANCE COMPANY DETAILS END *********************** */

/** ***************** GET INSURANCE COMPANY LIST START *********************** */
export const getInsuranceCompanyListStart = (getData) => ({
	type: InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_START,
	payload: getData,
});

export const getInsuranceCompanyListSuccess = (successData) => ({
	type: InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_SUCCESS,
	payload: successData,
});

export const getInsuranceCompanyListFailure = (failureData) => ({
	type: InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_FAILURE,
	payload: failureData,
});

export const getInsuranceCompanyListResponseResetStart = () => ({
	type: InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_RESPONSE_RESET,
});

export const getInsuranceCompanyListResponseChanged = () => ({
	type: InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_RESPONSE_CHANGED,
});

/** ***************** GET INSURANCE COMPANY LIST END *********************** */

/** ***************** FETCH INSURANCE COMPANY DETAILS START *********************** */
export const fetchInsuranceCompanyDetailsStart = (fetchData) => ({
	type: InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_START,
	payload: fetchData,
});

export const fetchInsuranceCompanyDetailsSuccess = (successData) => ({
	type: InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchInsuranceCompanyDetailsFailure = (failureData) => ({
	type: InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchInsuranceCompanyDetailsResponseResetStart = () => ({
	type: InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
});

export const fetchInsuranceCompanyDetailsResponseChanged = () => ({
	type: InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH INSURANCE COMPANY DETAILS END *********************** */

/** ***************** DELETE INSURANCE COMPANY DETAILS START *********************** */
export const deleteInsuranceCompanyDetailsStart = (deleteData) => ({
	type: InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_START,
	payload: deleteData,
});

export const deleteInsuranceCompanyDetailsSuccess = (successData) => ({
	type: InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteInsuranceCompanyDetailsFailure = (failureData) => ({
	type: InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteInsuranceCompanyDetailsResponseResetStart = () => ({
	type: InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
});

export const deleteInsuranceCompanyDetailsResponseChanged = () => ({
	type: InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE INSURANCE COMPANY DETAILS END *********************** */

/** ***************** FETCH ALL INSURANCE COMPANY START *********************** */
export const fetchAllInsuranceCompanyStart = (fetchInsuranceCompanys) => ({
	type: InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_START,
	payload: fetchInsuranceCompanys,
});

export const fetchAllInsuranceCompanySuccess = (successData) => ({
	type: InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_SUCCESS,
	payload: successData,
});

export const fetchAllInsuranceCompanyFailure = (failureData) => ({
	type: InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_FAILURE,
	payload: failureData,
});

export const fetchAllInsuranceCompanyResponseResetStart = () => ({
	type: InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_RESPONSE_RESET,
});

export const fetchAllInsuranceCompanyResponseChanged = () => ({
	type: InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL INSURANCE COMPANY END *********************** */
