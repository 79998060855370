const VerificationDetailsActionTypes = {
	ADD_BUSINESS_VERIFICATION_START: 'add_business_verification_start',
	ADD_BUSINESS_VERIFICATION_SUCCESS: 'add_business_verification_success',
	ADD_BUSINESS_VERIFICATION_FAILURE: 'add_business_verification_failure',
	ADD_BUSINESS_VERIFICATION_RESPONSE_RESET: 'add_business_verification_response_reset',
	ADD_BUSINESS_VERIFICATION_RESPONSE_CHANGED: 'add_business_verification_response_changed',

	UPDATE_BUSINESS_VERIFICATION_START: 'update_business_verification_start',
	UPDATE_BUSINESS_VERIFICATION_SUCCESS: 'update_business_verification_success',
	UPDATE_BUSINESS_VERIFICATION_FAILURE: 'update_business_verification_failure',
	UPDATE_BUSINESS_VERIFICATION_RESPONSE_RESET: 'update_business_verification_response_reset',
	UPDATE_BUSINESS_VERIFICATION_RESPONSE_CHANGED: 'update_business_verification_response_changed',

	FETCH_BUSINESS_VERIFICATION_START: 'fetch_business_verification_start',
	FETCH_BUSINESS_VERIFICATION_SUCCESS: 'fetch_business_verification_success',
	FETCH_BUSINESS_VERIFICATION_FAILURE: 'fetch_business_verification_failure',
	FETCH_BUSINESS_VERIFICATION_RESPONSE_RESET: 'fetch_business_verification_response_reset',
	FETCH_BUSINESS_VERIFICATION_RESPONSE_CHANGED: 'fetch_business_verification_response_changed',
};
export default VerificationDetailsActionTypes;
