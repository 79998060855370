/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BusinessLoanDisbursementActionTypes from './business-loan-disbmt.types';
import {
	fetchBusinessLoanIndex,
	fetchInstructionDetails,
	fetchFinanceBasaedAccountTypeDetails,
	createLoanDisbursement,
	getVoucharCount,
	fetchBasedOnBusinessLoanInfo,
} from './business-loan-disbmt.service';

import {
	fetchBusinessLoanIndexSuccess,
	fetchBusinessLoanIndexFailure,
	fetchBusinessLoanIndexResponseChanged,
	fetchInstumentListSuccess,
	fetchInstumentListFailure,
	fetchInstumentListResponseChanged,
	fetchFinanceBaseAccountTypeSuccess,
	fetchFinanceBaseAccountTypeFailure,
	fetchFinanceBaseAccountTypeResponseChanged,
	businessLoanDisbursmentSuccess,
	businessLoanDisbursmentFailure,
	businessLoanDisbursmentResponseChanged,
	fetchVoucharCountSuccess,
	fetchVoucharCountFailure,
	fetchVoucharCountResponseChanged,
	fetchBusinessLoanInfoSuccess,
	fetchBusinessLoanInfoFailure,
	fetchBusinessLoanInfoResponseChanged,
} from './business-loan-disbmt.actions';
import { encodeJson } from '../../../enode-decode';

/** *********************  START FETCH BUSINESS LOAN INDEX DETAILS  ******************** */
export function* businessLoanIndexDetails({ payload }) {
	try {
		const key = 'FetchBusinessLoanIndexDetails';
		const jsonData = {
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBusinessLoanIndex, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessLoanIndexFailure(responseData.data));
		} else {
			yield put(fetchBusinessLoanIndexSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessLoanIndexFailure(error));
	}
}

export function* resetBusinessLoanIndexeResponse() {
	yield put(fetchBusinessLoanIndexResponseChanged());
}

export function* onFetchBusinessLoanIndexDetails() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_START,
		businessLoanIndexDetails,
	);
}

export function* onBusinessLoanIndexResponseReset() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_RESPONSE_RESET,
		resetBusinessLoanIndexeResponse,
	);
}
/** *********************  END FETCH BUSINESS LOAN INDEX ******************** */

/** *********************  START INSTRUMENT LIST  ******************** */
export function* fetchInsturmentList({ payload }) {
	try {
		const key = 'GetAllInstrumentListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InfogCompanyID: payload.infogCompanyID,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchInstructionDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchInstumentListFailure(responseData.data));
		} else {
			yield put(fetchInstumentListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchInstumentListFailure(error));
	}
}

export function* resetFetchInsturmentListResponse() {
	yield put(fetchInstumentListResponseChanged());
}

export function* onFetchInsturmentListDetails() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_START,
		fetchInsturmentList,
	);
}

export function* onFetchInsturmentListResponseReset() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_RESPONSE_RESET,
		resetFetchInsturmentListResponse,
	);
}
/** *********************  END INSTRUMENT LIST ******************** */

/** *********************  START FINANCE BASED ACCOUNT TYPE LIST  ******************** */
export function* fetchFinanceBasedAccountType({ payload }) {
	try {
		const key = 'GetFinanceBasedTypeListKey';
		const jsonData = {
			TransactionType: payload.typeOfTransaction,
			BranchID: payload.branchID,
			TransactionDate: payload.loanDisburementDate,
			DatabaseName: payload.databaseName,
			DisbursementCashType: 'Cash',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchFinanceBasaedAccountTypeDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchFinanceBaseAccountTypeFailure(responseData.data));
		} else {
			yield put(fetchFinanceBaseAccountTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchFinanceBaseAccountTypeFailure(error));
	}
}

export function* resetFinanceBasedAccountTypeResponse() {
	yield put(fetchFinanceBaseAccountTypeResponseChanged());
}

export function* onFetchFinanceBasedAccountTypeListDetails() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_START,
		fetchFinanceBasedAccountType,
	);
}

export function* onFinanceBasedAccountTypeResponseReset() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_RESPONSE_RESET,
		resetFinanceBasedAccountTypeResponse,
	);
}
/** *********************  END FINANCE BASED ACCOUNT TYPE LIST ******************** */

/** *********************  START BUSINESS LOAN DISBURSEMENT  ******************** */
export function* businessLoanDisbursementCreate({ payload }) {
	try {
		const key = 'CreateBusinessLoanDisbursementKey';
		const jsonData = {
			LoanIndex: payload.loanIndexID,
			LoanIndexStatus: payload.loanStatus,
			LoanDisbursementArray: payload.loanDisbursementArray,
			MemberThrift: payload.memberThriftArray,
			TransactionType: payload.selectedInstrument,
			Loandisbursementdate: payload.loanDisburementDate,
			MeetupTime: payload.loanDisburementTime,
			ProductInvestors: payload.productInvestorArr,
			HOLoanIndex: '',
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createLoanDisbursement, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(businessLoanDisbursmentFailure(responseData.data));
		} else {
			yield put(businessLoanDisbursmentSuccess(responseData.data));
		}
	} catch (error) {
		yield put(businessLoanDisbursmentFailure(error));
	}
}

export function* resetCreateBusinessLoanDisbursementResponse() {
	yield put(businessLoanDisbursmentResponseChanged());
}

export function* onCreateBusinessLoanDisbursement() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_START,
		businessLoanDisbursementCreate,
	);
}

export function* onCreateBusinessLoanDisbursementResponseReset() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_RESPONSE_RESET,
		resetCreateBusinessLoanDisbursementResponse,
	);
}
/** *********************  END BUSINESS LOAN DISBURSEMENT ******************** */

/** *********************  START FETCH VOUCHAR COUNT  ******************** */
export function* voucharCountFetch({ payload }) {
	try {
		const key = 'FetchVoucharCountKey';
		const jsonData = {
			TransactionType: payload.selectedInstrument,
			CreatedFor: payload.createdFor,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getVoucharCount, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchVoucharCountFailure(responseData.data));
		} else {
			yield put(fetchVoucharCountSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchVoucharCountFailure(error));
	}
}

export function* resetVoucharCountResponse() {
	yield put(fetchVoucharCountResponseChanged());
}

export function* onFetchVoucharCount() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_START,
		voucharCountFetch,
	);
}

export function* onFetchVoucharCountResponseReset() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_RESPONSE_RESET,
		resetVoucharCountResponse,
	);
}
/** *********************  END FETCH VOUCHAR COUNT ******************** */

/** *********************  START FETCH VOUCHAR COUNT  ******************** */
export function* fetchBusinessLoanInfoFetch({ payload }) {
	try {
		const key = 'GetBusinessLoanInfoKey';
		const jsonData = {
			MemberID: payload.MemberID,
			LoanIndexID: payload.LoanIndexID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBasedOnBusinessLoanInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessLoanInfoFailure(responseData.data));
		} else {
			yield put(fetchBusinessLoanInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessLoanInfoFailure(error));
	}
}

export function* resetBusinessLoanInfoResponse() {
	yield put(fetchBusinessLoanInfoResponseChanged());
}

export function* onFetchBusinessLoanInfo() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_START,
		fetchBusinessLoanInfoFetch,
	);
}

export function* onBusinessLoanInfoResponseReset() {
	yield takeLatest(
		BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_RESPONSE_RESET,
		resetBusinessLoanInfoResponse,
	);
}
/** *********************  END FETCH VOUCHAR COUNT ******************** */

export function* businessLoanDisbursementSaga() {
	yield all([
		call(onFetchBusinessLoanIndexDetails),
		call(onBusinessLoanIndexResponseReset),
		call(onFetchInsturmentListDetails),
		call(onFetchInsturmentListResponseReset),
		call(onFetchFinanceBasedAccountTypeListDetails),
		call(onFinanceBasedAccountTypeResponseReset),
		call(onCreateBusinessLoanDisbursement),
		call(onCreateBusinessLoanDisbursementResponseReset),
		call(onFetchVoucharCount),
		call(onFetchVoucharCountResponseReset),
		call(onFetchBusinessLoanInfo),
		call(onBusinessLoanInfoResponseReset),
	]);
}
