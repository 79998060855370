/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import overdueReportActionTypes from './overDueReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	overdueReportListResponse: null,
	overdueReportListArr: [],
	FetchOverDueExcelSelectorStatus: '',
	FetchOverDueExcelSelectorResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_SUCCESS:
			return {
				...state,
				overdueReportListResponse: action.payload,
				overdueReportListArr: docodeResponse(action.payload, 'GetoverdueReportKey'),
			};

		case overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_FAILURE:
			return { ...state, overdueReportListResponse: action.payload };

		case overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, overdueReportListResponse: null };

		case overdueReportActionTypes.FETCH_OVERDUE_SUCCESS:
			return {
				...state,
				FetchOverDueExcelSelectorStatus: action.payload,
				FetchOverDueExcelSelectorResponse: 'Success',
			};

		case overdueReportActionTypes.FETCH_OVERDUE_EXCEL_FAILURE:
			return {
				...state,
				FetchOverDueExcelSelectorStatus: null,
				FetchOverDueExcelSelectorResponse: action.payload,
			};

		case overdueReportActionTypes.FETCH_OVERDUE_EXCEL_RESPONSE_CHANGED:
			return {
				...state,
				FetchOverDueExcelSelectorStatus: null,
				FetchOverDueExcelSelectorResponse: null,
			};

		case overdueReportActionTypes.FETCH_OVERDUE_EXCEL_RESPONSE_RESET:
			return { ...state, INITIAL_STATE };
		default:
			return state;
	}
};
