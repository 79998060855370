const ProofDetailsActionTypes = {
	ADD_BUSINESS_PROOF_START: 'add_business_poof_start',
	ADD_BUSINESS_PROOF_SUCCESS: 'add_business_poof_success',
	ADD_BUSINESS_PROOF_FAILURE: 'add_business_poof_failure',
	ADD_BUSINESS_PROOF_RESPONSE_RESET: 'add_business_poof_response_reset',
	ADD_BUSINESS_PROOF_RESPONSE_CHANGED: 'add_business_poof_response_changed',
	
	UPDATE_BUSINESS_PROOF_START: 'update_business_poof_start',
	UPDATE_BUSINESS_PROOF_SUCCESS: 'update_business_poof_success',
	UPDATE_BUSINESS_PROOF_FAILURE: 'update_business_poof_failure',
	UPDATE_BUSINESS_PROOF_RESPONSE_RESET: 'update_business_poof_response_reset',
	UPDATE_BUSINESS_PROOF_RESPONSE_CHANGED: 'update_business_poof_response_changed',
	
	FETCH_BUSINESS_PROOF_START: 'fetch_business_poof_start',
	FETCH_BUSINESS_PROOF_SUCCESS: 'fetch_business_poof_success',
	FETCH_BUSINESS_PROOF_FAILURE: 'fetch_business_poof_failure',
	FETCH_BUSINESS_PROOF_RESPONSE_RESET: 'fetch_business_poof_response_reset',
	FETCH_BUSINESS_PROOF_RESPONSE_CHANGED: 'fetch_business_poof_response_changed',
};
export default ProofDetailsActionTypes;
