/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import DemandCollectionActionTypes from './demand-collection-report.types';

/** ***************** GET LOAN DISBURSEMENT START *********************** */
export const getDemandCollectionDetailsStart = (getData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_START,
	payload: getData,
});

export const getDemandCollectionDetailsSuccess = (successData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_SUCCESS,
	payload: successData,
});

export const getDemandCollectionDetailsFailure = (failureData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_FAILURE,
	payload: failureData,
});

export const getDemandCollectionDetailsResponseResetStart = () => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_RESPONSE_RESET,
});

export const getDemandCollectionDetailsResponseChanged = () => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_RESPONSE_CHANGED,
});

export const getDemandCollectionDetailsResponseArrayChanged = () => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_RESPONSE_ARRAY_CHANGED,
});
/** ***************** GET LOAN DISBURSEMEN END *********************** */


/** ***************** GET LOAN DISBURSEMENT EXCEL START *********************** */
export const getDemandCollectionExcelDetailsStart = (getData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_START,
	payload: getData,
});

export const getDemandCollectionExcelDetailsSuccess = (successData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_SUCCESS,
	payload: successData,
});

export const getDemandCollectionExcelDetailsFailure = (failureData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_FAILURE,
	payload: failureData,
});

export const getDemandCollectionExcelDetailsResponseResetStart = () => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_RESPONSE_RESET,
});

export const getDemandCollectionExcelDetailsResponseChanged = () => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_RESPONSE_CHANGED,
});



/** ***************** GET DEMAND COLLECTION PDF START *********************** */
export const getDemandCollectionPDFDetailsStart = (getData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_START,
	payload: getData,
});

export const getDemandCollectionPDFDetailsSuccess = (successData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_SUCCESS,
	payload: successData,
});

export const getDemandCollectionPDFDetailsFailure = (failureData) => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_FAILURE,
	payload: failureData,
});

export const getDemandCollectionPDFDetailsResponseResetStart = () => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_RESPONSE_RESET,
});

export const getDemandCollectionPDFDetailsResponseChanged = () => ({
	type: DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_RESPONSE_CHANGED,
});
