const PreCloseReportActionTypes = {

    GET_PRECLOSE_REPORT_LIST_START: 'get_preclose_report_start',
	GET_PRECLOSE_REPORT_LIST_SUCCESS: 'get_preclose_report_success',
	GET_PRECLOSE_REPORT_LIST_FAILURE: 'get_preclose_report_failure',
	GET_PRECLOSE_REPORT_LIST_RESPONSE_RESET: 'get_preclose_report_response_reset',
	GET_PRECLOSE_REPORT_LIST_RESPONSE_CHANGED: 'get_preclose_report_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',

	FETCH_PRECLOSE_REPORT_EXCEL_START: 'fetch_preclose_report_excel_start',
	FETCH_PRECLOSE_REPORT_EXCEL_SUCCESS: 'fetch_preclose_report_excel_success',
	FETCH_PRECLOSE_REPORT_EXCEL_FAILURE: 'fetch_active_preclose_excel_failure',
	FETCH_PRECLOSE_REPORT_RESPONSE_EXCEL_RESET_START: 'fetch_preclose_report_response_excel_reset_start',
	FETCH_PRECLOSE_REPORT_RESPONSE_EXCEL_CHANGED: 'fetch_preclose_report_response_excel_changed',
    
}
export default PreCloseReportActionTypes;