/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import staffAllListActionTypes from './staffAllList.types';

export const getStaffAllListFilter = (STAFFALLLIST) => ({
	type: staffAllListActionTypes.STAFFLIST_START,
	payload: STAFFALLLIST,
});

export const getStaffAllListSuccess = (STAFFALLLIST) => ({
	type: staffAllListActionTypes.STAFFLIST_SUCCESS,
	payload: STAFFALLLIST,
});

export const getStaffAllListFailure = (STAFFALLLIST) => ({
	type: staffAllListActionTypes.STAFFLIST_FAILURE,
	payload: STAFFALLLIST,
});
export const getStaffAllListResetAction = () => ({
	type: staffAllListActionTypes.STAFFLIST_RESET,
});

export const getStaffAllListChangedAction = () => ({
	type: staffAllListActionTypes.STAFFLIST_CHANGED,
});
