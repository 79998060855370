/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import LoanActionTypes from './goldLoanProposal.types';

/** ***************** GET GOLD LOAN PROPOSAL START ***************** */
export const getGoldLoanProposalStart = (getData) => ({
	type: LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_START,
	payload: getData,
});

export const getGoldLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const getGoldLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const getGoldLoanProposalResponseReset = () => ({
	type: LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const getGoldLoanProposalListResponseChanged = () => ({
	type: LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** GET GOLD LOAN PROPOSAL END ************ */

/** ***************** FETCH GOLD LOAN PROPOSAL START ********** */
export const fetchGoldLoanProposalStart = (fetchData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_START,
	payload: fetchData,
});

export const fetchGoldLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const fetchGoldLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const fetchGoldLoanProposalResponseResetStart = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const fetchGoldLoanProposalResponseChanged = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ******************* FETCH GOLD LOAN PROPOSAL END ****************** */

/** ***************** DELETE GOLd LOAN PROPOSAL START *********************** */
export const deleteLoanProposalStart = (deleteData) => ({
	type: LoanActionTypes.DELETE_LOAN_PROPOSAL_START,
	payload: deleteData,
});

export const deleteLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.DELETE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const deleteLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.DELETE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const deleteLoanProposalResponseReset = () => ({
	type: LoanActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const deleteLoanProposalResponseChanged = () => ({
	type: LoanActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** DELETE GOLD LOAN PROPOSAL END *********************** */

/** ****************** UPDATE GOLD LOAN PROPOSAL START ********************* */

export const updateLoanProposalStart = (deleteData) => ({
	type: LoanActionTypes.UPDATE_LOAN_PROPOSAL_START,
	payload: deleteData,
});

export const updateLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.UPDATE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const updateLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.UPDATE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const updateLoanProposalResponseReset = () => ({
	type: LoanActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const updateLoanProposalResponseChanged = () => ({
	type: LoanActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** UPDATE GOLD LOAN PROPOSAL END ******************* */

/** ***************** CREATE GOLD LOAN PROPOSAL START *********************** */

export const addGoldLoanProposalStart = (addData) => ({
	type: LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_START,
	payload: addData,
});

export const addGoldLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const addGoldLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const addGoldLoanProposalResponseResetStart = () => ({
	type: LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const addGoldLoanProposalResponseChanged = () => ({
	type: LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** CREATE GOLD LOAN PROPOSAL END *********************** */

/** ***************** APPROVE GOLD LOAN PROPOSAL ***************** */
export const approveLoanProposalStart = (getData) => ({
	type: LoanActionTypes.APPROVE_LOAN_PROPOSAL_START,
	payload: getData,
});

export const approveLoanProposalSuccess = (successData) => ({
	type: LoanActionTypes.APPROVE_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const approveLoanProposalFailure = (failureData) => ({
	type: LoanActionTypes.APPROVE_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const approveLoanProposalResponseReset = () => ({
	type: LoanActionTypes.APPROVE_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const approveLoanProposalResponseChanged = () => ({
	type: LoanActionTypes.APPROVE_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** APPROVE GOLD LOAN PROPOSAL END ************ */

/** ***************** FETCH GOLD LOAN PROPOSAL GROUP OFFICER START ********** */
export const fetchGoldLoanProposalGroupOfficerStart = (fetchData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_START,
	payload: fetchData,
});

export const fetchGoldLoanProposalGroupOfficerSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_SUCCESS,
	payload: successData,
});

export const fetchGoldLoanProposalGroupOfficerFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_FAILURE,
	payload: failureData,
});

export const fetchGoldLoanProposalGroupOfficerResponseReset = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_RESPONSE_RESET,
});

export const fetchGoldLoanProposalGroupOfficerResponseChanged = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_RESPONSE_CHANGED,
});

/** ******************* FETCH GOLD LOAN PROPOSAL GROUP OFFICER END ****************** */

/** ***************** GET GOLD LOAN PROPOSAL GROUP START ***************** */
export const fetchGoldLoanProposalGroupStart = (getData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_START,
	payload: getData,
});

export const fetchGoldLoanProposalGroupSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_SUCCESS,
	payload: successData,
});

export const fetchGoldLoanProposalGroupFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_FAILURE,
	payload: failureData,
});

export const fetchGoldLoanProposalGroupResponseReset = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_RESPONSE_RESET,
});

export const fetchGoldLoanProposalGroupResponseChanged = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_RESPONSE_CHANGED,
});

/** ***************** GET GOLD LOAN PROPOSAL END ************ */

/** ***************** GET GOLD LOAN PROPOSAL GROUP MEMBER START ***************** */
export const fetchGoldLoanProposalGroupMemberStart = (getData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_START,
	payload: getData,
});

export const fetchGoldLoanProposalGroupMemberSuccess = (successData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_SUCCESS,
	payload: successData,
});

export const fetchGoldLoanProposalGroupMemberFailure = (failureData) => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_FAILURE,
	payload: failureData,
});

export const fetchGoldLoanProposalGroupMemberResponseReset = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_RESPONSE_RESET,
});

export const fetchGoldLoanProposalGroupMemberResponseChanged = () => ({
	type: LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_RESPONSE_CHANGED,
});

/** ***************** GET GOLD LOAN PROPOSAL GROUP MEMBER END ************ */


