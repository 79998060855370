/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loginActionTypes from './login.types';

export const signInSuccess = (user) => ({
	type: loginActionTypes.SIGN_IN_SUCCESS,
	payload: user,
});

export const signInFailure = (error) => ({
	type: loginActionTypes.SIGN_IN_FAILURE,
	payload: error,
});

export const signInStart = (userCredentials) => ({
	type: loginActionTypes.SIGN_IN_START,
	payload: userCredentials,
});

export const signInReset = () => ({
	type: loginActionTypes.SIGN_IN_RESET,
});

export const signInChanged = () => ({
	type: loginActionTypes.SIGN_IN_CHANGED,
	// type: loginActionTypes.SIGN_IN_LOGOUT,
});
export const logOut = () => ({
	type: loginActionTypes.SIGN_IN_LOGOUT,
});
