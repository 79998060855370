/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BankDetailsActionTypes from './bank-details.types';
import { bankDetailsAdd, fetchBankDetails, updateBankDetails } from './bank-details.service';

import {
	addBusinessBankSuccess,
	addBusinessBankFailure,
	addBusinessBankResponseChanged,
	fetchBusinessBankSuccess,
	fetchBusinessBankFailure,
	fetchBusinessBankResponseChanged,
	updateBusinessBankSuccess,
	updateBusinessBankFailure,
	updateBusinessBankResponseChanged,
} from './bank-details.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS BANK  ******************** */
export function* businessBankAdd({ payload }) {
	try {
		const key = 'BusinessBankKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBankList: payload.bankListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(bankDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessBankFailure(responseData.data));
		} else {
			yield put(addBusinessBankSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessBankFailure(error));
	}
}

export function* resetAddBusinessBankResponse() {
	yield put(addBusinessBankResponseChanged());
}

export function* onAddBusinessBankDetails() {
	yield takeLatest(BankDetailsActionTypes.ADD_BUSINESS_BANK_START, businessBankAdd);
}

export function* onAddBusinessBankResponseReset() {
	yield takeLatest(
		BankDetailsActionTypes.ADD_BUSINESS_BANK_RESPONSE_RESET,
		resetAddBusinessBankResponse,
	);
}
/** *********************  END ADD BUSINESS BANK ******************** */

/** *********************  START FETCH BUSINESS BANK  ******************** */
export function* businessBankFetch({ payload }) {
	try {
		const key = 'GetBankDetailsKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBankDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessBankFailure(responseData.data));
		} else {
			yield put(fetchBusinessBankSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessBankFailure(error));
	}
}

export function* resetFetchBusinessBankResponse() {
	yield put(fetchBusinessBankResponseChanged());
}

export function* onFetchBusinessBankDetails() {
	yield takeLatest(BankDetailsActionTypes.FETCH_BUSINESS_BANK_START, businessBankFetch);
}

export function* onFetchBusinessBankResponseReset() {
	yield takeLatest(
		BankDetailsActionTypes.FETCH_BUSINESS_BANK_RESPONSE_RESET,
		resetFetchBusinessBankResponse,
	);
}
/** *********************  END FETCH BUSINESS BANK ******************** */

/** *********************  START UPDATE BUSINESS BANK  ******************** */
export function* businessBankUpdate({ payload }) {
	try {
		const key = 'UpdateBankDetailsKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBankList: payload.bankListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateBankDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessBankFailure(responseData.data));
		} else {
			yield put(updateBusinessBankSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessBankFailure(error));
	}
}

export function* resetUpdateBusinessBankResponse() {
	yield put(updateBusinessBankResponseChanged());
}

export function* onUpdateBusinessBankDetails() {
	yield takeLatest(BankDetailsActionTypes.UPDATE_BUSINESS_BANK_START, businessBankUpdate);
}

export function* onUpdateBusinessBankResponseReset() {
	yield takeLatest(
		BankDetailsActionTypes.UPDATE_BUSINESS_BANK_RESPONSE_RESET,
		resetUpdateBusinessBankResponse,
	);
}
/** *********************  END UPDATE BUSINESS BANK ******************** */

export function* bankDetailsSaga() {
	yield all([
		call(onAddBusinessBankDetails),
		call(onAddBusinessBankResponseReset),
		call(onFetchBusinessBankDetails),
		call(onFetchBusinessBankResponseReset),
		call(onUpdateBusinessBankDetails),
		call(onUpdateBusinessBankResponseReset),
	]);
}
