/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import BusinessLoanProposalActionTypes from './business-loan-propsal.types';

/** ***************** CREATE BUSINESS LOAN PROPOSAL START *********************** */
export const addBusinessLoanPropsalStart = (addData) => ({
	type: BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_START,
	payload: addData,
});

export const addBusinessLoanPropsalSuccess = (successData) => ({
	type: BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const addBusinessLoanPropsalFailure = (failureData) => ({
	type: BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const addBusinessLoanPropsalResponseResetStart = () => ({
	type: BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const addBusinessLoanPropsalResponseChanged = () => ({
	type: BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** CREATE BUSINESS LOAN PROPOSAL END *********************** */

/** ***************** UPDATE BUSINESS LOAN PROPOSAL START *********************** */
export const updateBusinessLoanProposalStart = (updateData) => ({
	type: BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_START,
	payload: updateData,
});

export const updateBusinessLoanProposalSuccess = (successData) => ({
	type: BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const updateBusinessLoanProposalFailure = (failureData) => ({
	type: BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const updateBusinessLoanProposalResponseResetStart = () => ({
	type: BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const updateBusinessLoanProposalResponseChanged = () => ({
	type: BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** UPDATE BUSINESS LOAN PROPOSAL END *********************** */

/** ***************** GET BUSINESS LOAN PROPOSAL LIST START *********************** */
export const getBusinessLoanPropsalListStart = (getData) => ({
	type: BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_START,
	payload: getData,
});

export const getBusinessLoanPropsalListSuccess = (successData) => ({
	type: BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_SUCCESS,
	payload: successData,
});

export const getBusinessLoanPropsalListFailure = (failureData) => ({
	type: BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_FAILURE,
	payload: failureData,
});

export const getBusinessLoanPropsalListResponseResetStart = () => ({
	type: BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_RESPONSE_RESET,
});

export const getBusinessLoanPropsalListResponseChanged = () => ({
	type: BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED,
});

/** ***************** GET BUSINESS LOAN PROPOSAL LIST END *********************** */

/** ***************** APPROVE BUSINESS LOAN PROPOSAL START *********************** */
export const approveBusinessProposalStart = (approveData) => ({
	type: BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_START,
	payload: approveData,
});

export const approveBusinessProposalSuccess = (successData) => ({
	type: BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_SUCCESS,
	payload: successData,
});

export const approveBusinessProposalFailure = (failureData) => ({
	type: BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_FAILURE,
	payload: failureData,
});

export const approveBusinessProposalResponseResetStart = () => ({
	type: BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_RESPONSE_RESET_START,
});

export const approveBusinessProposalResponseChanged = () => ({
	type: BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** APPROVE BUSINESS LOAN PROPOSAL END *********************** */

/** ***************** FETCH BUSINESS LOAN PROPOSAL START *********************** */
export const fetchBusinessLoanProposalStart = (fetchData) => ({
	type: BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_START,
	payload: fetchData,
});

export const fetchBusinessLoanProposalSuccess = (successData) => ({
	type: BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const fetchBusinessLoanProposalFailure = (failureData) => ({
	type: BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const fetchBusinessLoanProposalResponseResetStart = () => ({
	type: BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const fetchBusinessLoanProposalResponseChanged = () => ({
	type: BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** FETCH BUSINESS LOAN PROPOSAL END *********************** */

/** ***************** DELETE BUSINESS LOAN PROPOSAL START *********************** */
export const deleteBusinessLoanProposalStart = (deleteData) => ({
	type: BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_START,
	payload: deleteData,
});

export const deleteBusinessLoanProposalSuccess = (successData) => ({
	type: BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_SUCCESS,
	payload: successData,
});

export const deleteBusinessLoanProposalFailure = (failureData) => ({
	type: BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_FAILURE,
	payload: failureData,
});

export const deleteBusinessLoanProposalResponseResetStart = () => ({
	type: BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET,
});

export const deleteBusinessLoanProposalResponseChanged = () => ({
	type: BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED,
});

/** ***************** DELETE BUSINESS LOAN PROPOSAL END *********************** */
