/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import BranchCollectionReportActionTypes from './branchCollectionReport.types';

/** ***************** GET BRANCH COLLECTION REPORT START *********************** */
export const getBranchCollectionReportDetailsStart = (getData) => ({
	type: BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_START,
	payload: getData,
});

export const getBranchCollectionReportDetailsSuccess = (successData) => ({
	type: BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getBranchCollectionReportDetailsFailure = (failureData) => ({
	type: BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getBranchCollectionReportDetailsResponseResetStart = () => ({
	type: BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_RESPONSE_RESET,
});

export const getBranchCollectionReportDetailsResponseChanged = () => ({
	type: BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH COLLECTION REPORT END *********************** */
