import RelatedPartiesActionTypes from './related-parties.types';

/** ***************** ADD BUSINESS REALETED PARTIES START *********************** */
export const addBusinessPartiesStart = (partiesData) => ({
	type: RelatedPartiesActionTypes.ADD_RELATED_PARTIES_START,
	payload: partiesData,
});

export const addBusinessPartiesSuccess = (successValue) => ({
	type: RelatedPartiesActionTypes.ADD_RELATED_PARTIES_SUCCESS,
	payload: successValue,
});

export const addBusinessPartiesFailure = (failureData) => ({
	type: RelatedPartiesActionTypes.ADD_RELATED_PARTIES_FAILURE,
	payload: failureData,
});

export const addBusinessPartiesResponseResetStart = () => ({
	type: RelatedPartiesActionTypes.ADD_RELATED_PARTIES_RESPONSE_RESET,
});

export const addBusinessPartiesResponseChanged = () => ({
	type: RelatedPartiesActionTypes.ADD_RELATED_PARTIES_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS REALETED PARTIES END *********************** */

/** ***************** UPDATE BUSINESS REALETED PARTIES START *********************** */
export const updateBusinessPartiesStart = (updateData) => ({
	type: RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_START,
	payload: updateData,
});

export const updateBusinessPartiesSuccess = (successData) => ({
	type: RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_SUCCESS,
	payload: successData,
});

export const updateBusinessPartiesFailure = (failureData) => ({
	type: RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_FAILURE,
	payload: failureData,
});

export const updateBusinessPartiesResponseResetStart = () => ({
	type: RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_RESPONSE_RESET,
});

export const updateBusinessPartiesResponseChanged = () => ({
	type: RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_RESPONSE_CHANGED,
});

/** ***************** UPDATE BUSINESS REALETED PARTIES END *********************** */

/** ***************** FETCH BUSINESS REALETED PARTIES START *********************** */
export const fetchBusinessPartiesStart = (fetchData) => ({
	type: RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_START,
	payload: fetchData,
});

export const fetchBusinessPartiesSuccess = (successData) => ({
	type: RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_SUCCESS,
	payload: successData,
});

export const fetchBusinessPartiesFailure = (failureData) => ({
	type: RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_FAILURE,
	payload: failureData,
});

export const fetchBusinessPartiesResponseResetStart = () => ({
	type: RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_RESPONSE_RESET,
});

export const fetchBusinessPartiesResponseChanged = () => ({
	type: RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS REALETED PARTIES END *********************** */
