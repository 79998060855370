const overDueReportTypes = {
	GET_OVERDUE_REPORT_LIST_START: 'get_overdue_report_list_start',
	GET_OVERDUE_REPORT_LIST_SUCCESS: 'get_overdue_report_list_success',
	GET_OVERDUE_REPORT_LIST_FAILURE: 'get_overdue_report_list_failure',
	GET_OVERDUE_REPORT_LIST_RESPONSE_RESET: 'get_overdue_report_list_response_reset',
	GET_OVERDUE_REPORT_LIST_RESPONSE_CHANGED: 'get_overdue_report_list_response_changed',

	FETCH_OVERDUE_EXCEL_START: 'FETCH_OVERDUE_EXCEL_START',
	FETCH_OVERDUE_SUCCESS: 'FETCH_OVERDUE_SUCCESS',
	FETCH_OVERDUE_EXCEL_FAILURE: 'FETCH_OVERDUE_EXCEL_FAILURE',
	FETCH_OVERDUE_EXCEL_RESPONSE_CHANGED: 'FETCH_OVERDUE_EXCEL_RESPONSE_CHANGED',
	FETCH_OVERDUE_EXCEL_RESPONSE_RESET: 'FETCH_OVERDUE_EXCEL_RESPONSE_RESET',
};
export default overDueReportTypes;
