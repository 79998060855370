/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BusinessLoanDisbursementActionTypes from './business-loan-disbmt.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getBusinessLoanIndexResponse: null,
	getBusinessLoanIndexArr: [],
	getInstrumentListResponse: null,
	getInstrumentListArr: [],
	getFinanceBasedAccountResponse: null,
	getFinanceBasedAccountArr: [],
	loanDisbursementResponse: null,
	fetchVoucharCountResponse: null,
	fetchVoucharCountArr: [],
	fetchBusinessLoanInfoResponse: null,
	fetchBusinessLoanInfoArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_SUCCESS:
			return {
				...state,
				getBusinessLoanIndexResponse: action.payload,
				getBusinessLoanIndexArr: docodeResponse(
					action.payload,
					'FetchBusinessLoanIndexDetails',
				),
			};

		case BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_FAILURE:
			return { ...state, getBusinessLoanIndexResponse: action.payload };

		case BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_RESPONSE_CHANGED:
			return { ...state, getBusinessLoanIndexResponse: null };

		case BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_SUCCESS:
			return {
				...state,
				getInstrumentListResponse: action.payload,
				getInstrumentListArr: docodeResponse(action.payload, 'GetAllInstrumentListKey'),
			};

		case BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_FAILURE:
			return { ...state, getInstrumentListResponse: action.payload };

		case BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_RESPONSE_CHANGED:
			return { ...state, getInstrumentListResponse: null };

		case BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_SUCCESS:
			return {
				...state,
				getFinanceBasedAccountResponse: action.payload,
				getFinanceBasedAccountArr: docodeResponse(
					action.payload,
					'GetFinanceBasedTypeListKey',
				),
			};

		case BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_FAILURE:
			return { ...state, getFinanceBasedAccountResponse: action.payload };

		case BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_RESPONSE_CHANGED:
			return { ...state, getFinanceBasedAccountResponse: null };

		case BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_SUCCESS:
			return {
				...state,
				loanDisbursementResponse: action.payload,
			};

		case BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_FAILURE:
			return { ...state, loanDisbursementResponse: action.payload };

		case BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_RESPONSE_CHANGED:
			return { ...state, loanDisbursementResponse: null };

		case BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_SUCCESS:
			return {
				...state,
				fetchVoucharCountResponse: action.payload,
				fetchVoucharCountArr: docodeResponse(action.payload, 'FetchVoucharCountKey'),
			};

		case BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_FAILURE:
			return { ...state, getFinanceBasedAccountResponse: action.payload };

		case BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_RESPONSE_CHANGED:
			return { ...state, getFinanceBasedAccountResponse: null };

		case BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_SUCCESS:
			return {
				...state,
				fetchBusinessLoanInfoResponse: action.payload,
				fetchBusinessLoanInfoArr: docodeResponse(action.payload, 'GetBusinessLoanInfoKey'),
			};

		case BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_FAILURE:
			return { ...state, fetchBusinessLoanInfoResponse: action.payload };

		case BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchBusinessLoanInfoResponse: null };

		default:
			return state;
	}
};
