/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import NPAReportTypes from "./npa-report.types";

/** ***************** FETCH NPA DETAILS START *********************** */
export const fetchNPAReportStart = (addData) => ({
	type: NPAReportTypes.FETCH_NPA_REPORT_START,
	payload: addData,
});

export const fetchNPAReportSuccess = (successData) => ({
	type: NPAReportTypes.FETCH_NPA_REPORT_SUCCESS,
	payload: successData,
});

export const fetchNPAReportFailure = (failureData) => ({
	type: NPAReportTypes.FETCH_NPA_REPORT_FAILURE,
	payload: failureData,
});

export const fetchNPAReportResponseResetStart = () => ({
	type: NPAReportTypes.FETCH_NPA_REPORT_RESPONSE_RESET_START,
});

export const fetchNPAReportResponseResetChanged = () => ({
	type: NPAReportTypes.FETCH_NPA_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH NPA DETAILS END *********************** */
