/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import { encodeJson } from '../../enode-decode';
import generalTypesAction from '../../genera.types';

import executiveActionTypes from './executive.types';

import {
	getAllMemberList,
	getAllExecutive,
	allocateStaffToMember,
	transferStaffToMember,
} from './executive.services';
import {
	getAllExecutiveListSuccess,
	getAllExecutiveListFailure,
	getAllExecutiveListChangedAction,
	getAllMemberAllocationListSuccess,
	getAllMemberAllocationListFailure,
	getAllMemberAllocationListChangedAction,
	allocationMemberToStaffSuccess,
	allocationMemberToStaffFailure,
	allocationMemberToStaffChangedAction,
	transferMemberToStaffSuccess,
	transferMemberToStaffFailure,
	transferMemberToStaffChangedAction,
} from './executive.actions';

export function* getListOfExecutive({ payload: { ExecutiveList } }) {
	try {
		const jsonData = {
			Level: ExecutiveList.Level,
			Region: ExecutiveList.Region,
			inputFilter: ExecutiveList.InputFilter,
		};
		const executiveData = encodeJson(
			jsonData,
			generalTypesAction.FETCH_EXECUTIVE_DETAILS_FILTER,
		);
		const responseData = yield call(getAllExecutive, executiveData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllExecutiveListFailure(responseData.data));
		} else {
			yield put(getAllExecutiveListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllExecutiveListFailure(error));
	}
}

export function* getListOfMemberAllocation({ payload: { memberList } }) {
	try {
		const jsonData = {
			region: memberList.Region,
			inputFilter: memberList.InputFilter,
			fromDate: memberList.FromDate,
			toDate: memberList.ToDate,
		};
		const allocationData = encodeJson(jsonData, generalTypesAction.FETCH_ALLOCATION_MEMBER);
		const responseData = yield call(getAllMemberList, allocationData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllMemberAllocationListFailure(responseData.data));
		} else {
			yield put(getAllMemberAllocationListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllMemberAllocationListFailure(error));
	}
}

export function* createAllocateMemberToStaff({ payload: { memberAllocationStaffList } }) {
	try {
		const jsonData = {
			memberList: memberAllocationStaffList.MemberList,
			staffID: memberAllocationStaffList.StaffID,
			loginUserID: memberAllocationStaffList.LoginUserID,
		};
		const allocationData = encodeJson(jsonData, generalTypesAction.ALLOCATE_STAFF_TO_MEMBER);
		const responseData = yield call(allocateStaffToMember, allocationData);
		if (responseData.data.statusCode === '100') {
			yield put(allocationMemberToStaffFailure(responseData.data));
		} else {
			yield put(allocationMemberToStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(allocationMemberToStaffFailure(error));
	}
}

export function* updateTransferMemberToStaff({ payload: { memberAllocationStaffList } }) {
	try {
		const jsonData = {
			memberList: memberAllocationStaffList.MemberList,
			staffID: memberAllocationStaffList.StaffID,
			loginUserID: memberAllocationStaffList.LoginUserID,
		};
		const allocationData = encodeJson(jsonData, generalTypesAction.ASSIGN_MEMBER_TO_NEW_STAFF);
		const responseData = yield call(transferStaffToMember, allocationData);
		if (responseData.data.statusCode === '100') {
			yield put(transferMemberToStaffFailure(responseData.data));
		} else {
			yield put(transferMemberToStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(transferMemberToStaffFailure(error));
	}
}

export function* getAllocationMemberListReset() {
	yield put(getAllMemberAllocationListChangedAction());
}

export function* getListExecutiveListReset() {
	yield put(getAllExecutiveListChangedAction());
}

export function* createAllocationMemberToStaffReset() {
	yield put(allocationMemberToStaffChangedAction());
}

export function* transferMemberToStaffReset() {
	yield put(transferMemberToStaffChangedAction());
}

export function* getAllExecutiveList() {
	yield takeLatest(executiveActionTypes.EXECUTIVE_LIST_START, getListOfExecutive);
}

export function* getAllExecutiveListReset() {
	yield takeLatest(executiveActionTypes.EXECUTIVE_LIST_RESET, getListExecutiveListReset);
}

export function* getAllocationMemberList() {
	yield takeLatest(executiveActionTypes.ALLOCATION_LIST_START, getListOfMemberAllocation);
}

export function* getAllMemberAllocationReset() {
	yield takeLatest(executiveActionTypes.ALLOCATION_LIST_RESET, getAllocationMemberListReset);
}

export function* allocateMemberToStaff() {
	yield takeLatest(
		executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_START,
		createAllocateMemberToStaff,
	);
}

export function* allocateMemberToStaffChange() {
	yield takeLatest(
		executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_RESET,
		createAllocationMemberToStaffReset,
	);
}

export function* transferMemberToStaff() {
	yield takeLatest(
		executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_START,
		updateTransferMemberToStaff,
	);
}

export function* transferMemberToStaffChange() {
	yield takeLatest(
		executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_RESET,
		transferMemberToStaffReset,
	);
}
export function* executiveSaga() {
	yield all([
		// call(getAllExecutiveList),
		// call(getAllExecutiveListReset),
		call(getAllocationMemberList),
		call(getAllMemberAllocationReset),
		call(allocateMemberToStaff),
		call(allocateMemberToStaffChange),
		call(transferMemberToStaff),
		call(transferMemberToStaffChange),
	]);
}
