import VerificationDetailsActionTypes from './verification-details.types';

/** ***************** ADD BUSINESS VERIFICATION START *********************** */
export const addBusinessVerificationStart = (verifuData) => ({
	type: VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_START,
	payload: verifuData,
});

export const addBusinessVerificationSuccess = (successValue) => ({
	type: VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_SUCCESS,
	payload: successValue,
});

export const addBusinessVerificationFailure = (failureData) => ({
	type: VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_FAILURE,
	payload: failureData,
});

export const addBusinessVerificationResponseResetStart = () => ({
	type: VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_RESPONSE_RESET,
});

export const addBusinessVerificationResponseChanged = () => ({
	type: VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS VERIFICATION END *********************** */

/** ***************** UPDATE BUSINESS VERIFICATION START *********************** */
export const updateBusinessVerificationStart = (updateData) => ({
	type: VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_START,
	payload: updateData,
});

export const updateBusinessVerificationSuccess = (successData) => ({
	type: VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_SUCCESS,
	payload: successData,
});

export const updateBusinessVerificationFailure = (failureData) => ({
	type: VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_FAILURE,
	payload: failureData,
});

export const updateBusinessVerificationResponseResetStart = () => ({
	type: VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_RESPONSE_RESET,
});

export const updateBusinessVerificationResponseChanged = () => ({
	type: VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_RESPONSE_CHANGED,
});

/** ***************** UPDATE BUSINESS VERIFICATION END *********************** */

/** ***************** FETCH BUSINESS VERIFICATION START *********************** */
export const fetchBusinessVerificationStart = (fetchData) => ({
	type: VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_START,
	payload: fetchData,
});

export const fetchBusinessVerificationSuccess = (successValue) => ({
	type: VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_SUCCESS,
	payload: successValue,
});

export const fetchBusinessVerificationFailure = (failureData) => ({
	type: VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_FAILURE,
	payload: failureData,
});

export const fetchBusinessVerificationResponseResetStart = () => ({
	type: VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_RESPONSE_RESET,
});

export const fetchBusinessVerificationResponseChanged = () => ({
	type: VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_RESPONSE_CHANGED,
});

/** ***************** FETCH BUSINESS VERIFICATION END *********************** */
