/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ProofDetailsActionTypes from './proof-details.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addBusinessProofResponse: null,
	fetchBusinessProofResponse: null,
	fetchBusinessProofList: [],
	updateBusinessProofResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ProofDetailsActionTypes.ADD_BUSINESS_PROOF_SUCCESS:
			return { ...state, addBusinessProofResponse: action.payload };

		case ProofDetailsActionTypes.ADD_BUSINESS_PROOF_FAILURE:
			return { ...state, addBusinessProofResponse: action.payload };

		case ProofDetailsActionTypes.ADD_BUSINESS_PROOF_RESPONSE_CHANGED:
			return { ...state, addBusinessProofResponse: null };

		case ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_SUCCESS:
			return { ...state, updateBusinessProofResponse: action.payload };

		case ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_FAILURE:
			return { ...state, updateBusinessProofResponse: action.payload };

		case ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_RESPONSE_CHANGED:
			return { ...state, updateBusinessProofResponse: null };

		case ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_SUCCESS:
			return {
				...state,
				fetchBusinessProofResponse: action.payload,
				fetchBusinessProofList: docodeResponse(action.payload, 'GetBusinessProofKey'),
			};

		case ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_FAILURE:
			return { ...state, fetchBusinessProofResponse: action.payload };

		case ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_RESPONSE_CHANGED:
			return { ...state, fetchBusinessProofResponse: null };

		default:
			return state;
	}
};
