/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import questionnaireTypes from './questionnaire.types';

import {
    questionnaireListGet,
    questionnaireDelete,
    questionDelete,
    questionChoiceDelete,
	questionnaireDetailsAdd,
	questionnaireDetailsFetch,
	questionnaireUpdate

} from './questionnaire.service';

import {
    getQuestionnaireSuccess,
    getQuestionnaireFailure,
    getQuestionnaireResponseChanged,
    deleteQuestionnaireSuccess,
    deleteQuestionnaireFailure,
    deleteQuestionnaireResponseChanged,
    deleteQuestionSuccess,
    deleteQuestionFailure,
    deleteQuestionResponseChanged,
    deleteQuestionChoiceSuccess,
    deleteQuestionChoiceFailure,
    deleteQuestionChoiceResponseChanged,
	addQuestionnaireSuccess,
	addQuestionnaireFailure,
	addQuestionnaireResponseChanged,
	fetchQuestionnaireSuccess,
	fetchQuestionnaireFailure,
	fetchQuestionnaireResponseChanged,
	updateQuestionnaireSuccess,
	updateQuestionnaireFailure,
	updateQuestionnaireResponseChanged

} from './questionnaire.action';

import { encodeJson } from '../../enode-decode';


/**   ******************* GET QUESTIONNAIRE START *************** */
export function* questionnaireGet({ payload: { limit, companyID, databaseName, searchVal } }) {
	try {
		const key = 'GetAllQuestionnaireListkey';
		const jsonData = {
            CompanyID:companyID,
            DatabaseName: databaseName,
            Limit: limit,
            SearchData: searchVal,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionnaireListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getQuestionnaireFailure(responseData.data));
		} else {
			yield put(getQuestionnaireSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getQuestionnaireFailure(error));
	}
}

export function* resetGetQuestionnaireResponse() {
	yield put(getQuestionnaireResponseChanged());
}

export function* onGetQuestionnaire() {
	yield takeLatest(questionnaireTypes.GET_QUESTIONNAIRE_START, questionnaireGet);
}

export function* onGetQuestionnaireResponseReset() {
	yield takeLatest(
		questionnaireTypes.GET_QUESTIONNAIRE_RESPONSE_RESET,
		resetGetQuestionnaireResponse,
	);
}


/**   ******************* DELETE QUESTIONNAIRE START *************** */
export function* questionnaireListDelete({ payload }) {
	try {
		const key = 'DeleteQuestionnaireKey';
		const jsonData = {
			CompanyID: payload.companyID,
            DatabaseName:payload.databaseName,
            questionnaireID: payload.questionnaireID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionnaireDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteQuestionnaireFailure(responseData.data));
		} else {
			yield put(deleteQuestionnaireSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteQuestionnaireFailure(error));
	}
}

export function* resetDeleteQuestionnaireResponse() {
	yield put(deleteQuestionnaireResponseChanged());
}

export function* onDeleteQuestionnaire() {
	yield takeLatest(questionnaireTypes.DELETE_QUESTIONNAIRE_START, questionnaireListDelete);
}

export function* onDeleteQuestionnaireResponseReset() {
	yield takeLatest(
		questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_RESET,
		resetDeleteQuestionnaireResponse,
	);
}


/**   ******************* DELETE QUESTION START *************** */
export function* questionGet({ payload }) {
	try {
		const key = 'DeleteQuestionKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			QuestionID: payload.questionID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteQuestionFailure(responseData.data));
		} else {
			yield put(deleteQuestionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteQuestionFailure(error));
	}
}

export function* resetGetQuestionResponse() {
	yield put(deleteQuestionResponseChanged());
}

export function* onDeleteQuestion() {
	yield takeLatest(questionnaireTypes.DELETE_QUESTION_START, questionGet);
}

export function* onDeleteQuestionResponseReset() {
	yield takeLatest(
		questionnaireTypes.DELETE_QUESTION_RESPONSE_RESET,
		resetGetQuestionResponse,
	);
}


/**   ******************* DELETE QUESTIONCHOICE START *************** */
export function* deletequestionChoice({ payload }) {
	try {
		const key = 'DeleteQuestionChoiceKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			QuestionID: payload.questionID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionChoiceDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteQuestionChoiceFailure(responseData.data));
		} else {
			yield put(deleteQuestionChoiceSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteQuestionChoiceFailure(error));
	}
}

export function* resetDeleteQuestionChoiceResponse() {
	yield put(getQuestionnaireResponseChanged());
}

export function* onDeleteQuestionChoice() {
	yield takeLatest(questionnaireTypes.DELETE_QUESTION_CHOICE_START, deletequestionChoice);
}

export function* onDeleteQuestionChoiceResponseReset() {
	yield takeLatest(
		questionnaireTypes.DELETE_QUESTION_CHOICE_RESPONSE_RESET,
		deleteQuestionChoiceResponseChanged,
	);
}

/**   ******************* ADD QUESTIONNAIRE START *************** */
export function* addquestionnaire({ payload: {values} }) {
	try {
		const key = 'QuestionnaireCreateAddKey';
		const jsonData = {
			CompanyID: values.companyID,
			UserID: values.userID,
			Title: values.questionnaireTitle,
			Description: values.questionnaireDescription,
			QuestionArr: JSON.stringify(values.questionnaireQuestions).replace(/"/g, "'"),
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionnaireDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addQuestionnaireFailure(responseData.data));
		} else {
			yield put(addQuestionnaireSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addQuestionnaireFailure(error));
	}
}

export function* resetAddQuestionnaireResponseChanged() {
	yield put(addQuestionnaireResponseChanged());
}

export function* onAddQuestionnaire() {
	yield takeLatest(questionnaireTypes.ADD_QUESTIONNAIRE_START, addquestionnaire);
}

export function* onAddQuestionnaireResponseReset() {
	yield takeLatest(
		questionnaireTypes.ADD_QUESTIONNAIRE_RESPONSE_RESET,
		resetAddQuestionnaireResponseChanged,
	);
}

/**   ******************* FETCH QUESTIONNAIRE START *************** */
export function* fetchquestionnaire({ payload: { fetchData } }) {
	try {
		const key = 'FetchQuestionnaireListkey';
		const jsonData = {
			UserID: fetchData.userID,
			CompanyID: fetchData.companyID,
			QuestionnaireID: fetchData.questionnaireID,
			DatabaseName: fetchData.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionnaireDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchQuestionnaireFailure(responseData.data));
		} else {
			yield put(fetchQuestionnaireSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchQuestionnaireFailure(error));
	}
}

export function* resetFetchQuestionnaireResponse() {
	yield put(fetchQuestionnaireResponseChanged());
}

export function* onFetchQuestionnaire() {
	yield takeLatest(questionnaireTypes.FETCH_QUESTIONNAIRE_START, fetchquestionnaire);
}

export function* onFetchQuestionnaireResponseReset() {
	yield takeLatest(
		questionnaireTypes.FETCH_QUESTIONNAIRE_RESPONSE_RESET,
		resetFetchQuestionnaireResponse,
	);
}


/**   ******************* UPDATE QUESTIONNAIRE START *************** */
export function* updatequestionnaire({ payload: { values } }) {
	try {
		const key = 'QuestionnaireUpdateKey';
		const jsonData = {
			CompanyID: values.companyID,
			UserID: values.userID,
			QuestionnaireID: values.questionnaireID,
			Title: values.questionnaireTitle,
			Description: values.questionnaireDescription,
			QuestionArr: JSON.stringify(values.questionnaireQuestions).replace(/"/g, "'"),
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(questionnaireUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateQuestionnaireFailure(responseData.data));
		} else {
			yield put(updateQuestionnaireSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateQuestionnaireFailure(error));
	}
}

export function* resetUpdateQuestionnaireResponse() {
	yield put(updateQuestionnaireResponseChanged());
}

export function* onUpdateQuestionnaire() {
	yield takeLatest(questionnaireTypes.UPDATE_QUESTIONNAIRE_START, updatequestionnaire);
}

export function* onUpdateQuestionnaireResponseReset() {
	yield takeLatest(
		questionnaireTypes.UPDATE_QUESTIONNAIRE_RESPONSE_RESET,
		resetUpdateQuestionnaireResponse,
	);
}

export function* questionnaireSaga() {
	yield all([
        call(onGetQuestionnaire),
        call(onGetQuestionnaireResponseReset),
        call(onDeleteQuestionnaire),
        call(onDeleteQuestionnaireResponseReset),
        call(onDeleteQuestion),
        call(onDeleteQuestionResponseReset),
        call(onDeleteQuestionChoice),
        call(onDeleteQuestionChoiceResponseReset),
		call(onAddQuestionnaire),
        call(onAddQuestionnaireResponseReset),
		call(onFetchQuestionnaire),
        call(onFetchQuestionnaireResponseReset),
		call(onUpdateQuestionnaire),
        call(onUpdateQuestionnaireResponseReset)

    ]);
}