/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const groupDetailsAdd = (addData) => {
	const data = {
		AddGroupDetailsToken: addData,
	};

	const url = `${API_URL}addGroupDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const groupListGet = (listData) => {
	const data = {
		AllGroupListGetToken: listData,
	};
	const url = `${API_URL}GetGroupListWithPaginaion`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allGroupFetch = (fetchGroupData) => {
	const data = {
		FetchAllGroupKeyToken: fetchGroupData,
	};
	const url = `${API_URL}GetAllGroupOnLocation`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allApprovedMemberFetch = (fetchApprovedMemberData) => {
	const data = {
		GetBranchAllOfficerToken: fetchApprovedMemberData,
	};
	const url = `${API_URL}GetAllApprovedMemberNotInGroup`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allOfficerGet = (listData) => {
	const data = {
		GetAllOfficerListToken: listData,
	};
	const url = `${API_URL}GetAllOfficerUserBasedOnLevel`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allGroupDetailsGet = (listData) => {
	const data = {
		GetAllGroupKeyToken: listData,
	};

	const url = `${API_URL}GetGroupDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const branchOfficerListGet = (listData) => {
	const data = {
		CenterOfficerListBasedOnCompany: listData,
	};

	const url = `${API_URL}GetAllOfficerUserBasedoncompanyWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
