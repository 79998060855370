/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import InsuranceCompanyActionTypes from './insurance-company.types';
import {
	insuranceCompanyDetailsAdd,
	insuranceCompanyDetailsUpdate,
	insuranceCompanyListGet,
	insuranceCompanyDetailsFetch,
	insuranceCompanyDetailsDelete,
	allInsuranceCompanyFetch,
} from './insurance-company.service';

import {
	addInsuranceCompanyDetailsSuccess,
	addInsuranceCompanyDetailsFailure,
	addInsuranceCompanyDetailsResponseChanged,
	updateInsuranceCompanyDetailsSuccess,
	updateInsuranceCompanyDetailsFailure,
	updateInsuranceCompanyDetailsResponseChanged,
	getInsuranceCompanyListSuccess,
	getInsuranceCompanyListFailure,
	getInsuranceCompanyListResponseChanged,
	fetchInsuranceCompanyDetailsSuccess,
	fetchInsuranceCompanyDetailsFailure,
	fetchInsuranceCompanyDetailsResponseChanged,
	deleteInsuranceCompanyDetailsSuccess,
	deleteInsuranceCompanyDetailsFailure,
	deleteInsuranceCompanyDetailsResponseChanged,
	fetchAllInsuranceCompanySuccess,
	fetchAllInsuranceCompanyFailure,
	fetchAllInsuranceCompanyResponseChanged,
} from './insurance-company.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD INSURANCE COMPANY DETAILS  ******************** */
export function* createInsuranceCompany({ payload }) {
	try {
		const key = 'AddInsuranceCompanyDetailKey';
		const jsonData = {
			InsuranceCompanyName: payload.insuranceCompanyName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(insuranceCompanyDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addInsuranceCompanyDetailsFailure(responseData.data));
		} else {
			yield put(addInsuranceCompanyDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addInsuranceCompanyDetailsFailure(error));
	}
}

export function* resetAddInsuranceCompanyResponse() {
	yield put(addInsuranceCompanyDetailsResponseChanged());
}

export function* onCreateInsuranceCompanyDetails() {
	yield takeLatest(
		InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_START,
		createInsuranceCompany,
	);
}

export function* onCreateInsuranceCompanyResponseReset() {
	yield takeLatest(
		InsuranceCompanyActionTypes.ADD_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
		resetAddInsuranceCompanyResponse,
	);
}
/** *********************  END ADD INSURANCE COMPANY DETAILS ******************** */

/** *********************  START UPDATE INSURANCE COMPANY DETAILS  ******************** */
export function* insuranceCompanyUpdate({ payload }) {
	try {
		const key = 'UpdateInsuranceCompanyDetailsKey';
		const jsonData = {
			InsuranceCompanyID: payload.insuranceCompanyID,
			InsuranceCompanyName: payload.insuranceCompanyName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(insuranceCompanyDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateInsuranceCompanyDetailsFailure(responseData.data));
		} else {
			yield put(updateInsuranceCompanyDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateInsuranceCompanyDetailsFailure(error));
	}
}

export function* resetUpdateInsuranceCompanyDetailsResponse() {
	yield put(updateInsuranceCompanyDetailsResponseChanged());
}

export function* onUpdateInsuranceCompanyDetails() {
	yield takeLatest(
		InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_START,
		insuranceCompanyUpdate,
	);
}

export function* onUpdateInsuranceCompanyResponseReset() {
	yield takeLatest(
		InsuranceCompanyActionTypes.UPDATE_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
		resetUpdateInsuranceCompanyDetailsResponse,
	);
}
/** *********************  END UPDATE INSURANCE COMPANY DETAILS ******************** */

/** *********************  START GET INSURANCE COMPANY LIST  ******************** */
export function* insuranceCompanyDetailsListGet({ payload }) {
	try {
		const key = 'GetInsuranceCompanyListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(insuranceCompanyListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getInsuranceCompanyListFailure(responseData.data));
		} else {
			yield put(getInsuranceCompanyListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getInsuranceCompanyListFailure(error));
	}
}

export function* resetGetInsuranceCompanyListResponse() {
	yield put(getInsuranceCompanyListResponseChanged());
}

export function* onGetInsuranceCompanyListDetails() {
	yield takeLatest(
		InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_START,
		insuranceCompanyDetailsListGet,
	);
}

export function* onGetInsuranceCompanyListResponseReset() {
	yield takeLatest(
		InsuranceCompanyActionTypes.GET_INSURANCE_COMPANY_LIST_RESPONSE_RESET,
		resetGetInsuranceCompanyListResponse,
	);
}
/** *********************  END GET INSURANCE COMPANY LIST ******************** */

/** *********************  START FETCH INSURANCE COMPANY DETAILS  ******************** */
export function* fetchInsuranceCompanyData({ payload }) {
	try {
		const key = 'FetchInsuranceCompanyDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			InsuranceCompanyID: payload.insuranceCompanyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(insuranceCompanyDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchInsuranceCompanyDetailsFailure(responseData.data));
		} else {
			yield put(fetchInsuranceCompanyDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchInsuranceCompanyDetailsFailure(error));
	}
}

export function* resetFetchInsuranceCompanyDetailsResponse() {
	yield put(fetchInsuranceCompanyDetailsResponseChanged());
}

export function* onFetchInsuranceCompanyDetails() {
	yield takeLatest(
		InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_START,
		fetchInsuranceCompanyData,
	);
}

export function* onFetchInsuranceCompanyResponseReset() {
	yield takeLatest(
		InsuranceCompanyActionTypes.FETCH_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
		resetFetchInsuranceCompanyDetailsResponse,
	);
}
/** *********************  END FETCH INSURANCE COMPANY DETAILS ******************** */

/** *********************  START DELETE INSURANCE COMPANY DETAILS  ******************** */
export function* insuranceCompanyDelete({ payload }) {
	try {
		const key = 'DeleteInsuranceCompanyDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			InsuranceCompanyID: payload.insuranceCompanyID,
			InsuranceCompanyName: payload.insuranceCompanyName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(insuranceCompanyDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteInsuranceCompanyDetailsFailure(responseData.data));
		} else {
			yield put(deleteInsuranceCompanyDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteInsuranceCompanyDetailsFailure(error));
	}
}

export function* resetDeleteInsuranceCompanyDetailsResponse() {
	yield put(deleteInsuranceCompanyDetailsResponseChanged());
}

export function* onDeleteInsuranceCompanyDetail() {
	yield takeLatest(
		InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_START,
		insuranceCompanyDelete,
	);
}

export function* onDeleteInsuranceCompanyResponseReset() {
	yield takeLatest(
		InsuranceCompanyActionTypes.DELETE_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET,
		resetDeleteInsuranceCompanyDetailsResponse,
	);
}
/** *********************  END DELETE INSURANCE COMPANY DETAILS ******************** */

/** *********************  START FETCH ALL INSURANCE COMPANY LIST  ******************** */
export function* fetchAllInsuranceCompanysList({ payload }) {
	try {
		const key = 'FetchAllInsuranceCompanyKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserInsuranceCompany: payload.userInsuranceCompany,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allInsuranceCompanyFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllInsuranceCompanyFailure(responseData.data));
		} else {
			yield put(fetchAllInsuranceCompanySuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllInsuranceCompanyFailure(error));
	}
}

export function* resetFetchAllInsuranceCompanysResponse() {
	yield put(fetchAllInsuranceCompanyResponseChanged());
}

export function* onFetchAllInsuranceCompanysDetails() {
	yield takeLatest(
		InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_START,
		fetchAllInsuranceCompanysList,
	);
}

export function* onFetchAllInsuranceCompanysResponseReset() {
	yield takeLatest(
		InsuranceCompanyActionTypes.FETCH_ALL_INSURANCE_COMPANY_RESPONSE_RESET,
		resetFetchAllInsuranceCompanysResponse,
	);
}
/** *********************  END FETCH ALL INSURANCE COMPANY LIST ******************** */

export function* insuranceCompanySaga() {
	yield all([
		call(onCreateInsuranceCompanyDetails),
		call(onCreateInsuranceCompanyResponseReset),
		call(onUpdateInsuranceCompanyDetails),
		call(onUpdateInsuranceCompanyResponseReset),
		call(onGetInsuranceCompanyListDetails),
		call(onGetInsuranceCompanyListResponseReset),
		call(onFetchInsuranceCompanyDetails),
		call(onFetchInsuranceCompanyResponseReset),
		call(onDeleteInsuranceCompanyDetail),
		call(onDeleteInsuranceCompanyResponseReset),
		call(onFetchAllInsuranceCompanysDetails),
		call(onFetchAllInsuranceCompanysResponseReset),
	]);
}
