/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import progressReportTypes from './ProgressReport.types';
import { decodeApiResponse } from '../../common/common.utils';
import generalTypesAction from '../../genera.types';

const INITIAL_STATE = {
	FetchAllOfficerUserBasedOnBranchSelectorStatus: '',
	FetchAllOfficerUserBasedOnBranchSelectorResponse: null,
	FetchBranchProgressReportSelectorStatus: '',
	FetchBranchProgressReportSelectorResponse: null,
};

const progressReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_SUCCESS:
			return {
				...state,
				FetchAllOfficerUserBasedOnBranchSelectorResponse: decodeApiResponse(
					action.payload.records,
					generalTypesAction.ALLOFFICERUSERBASEDONBRANCH,
				),
				FetchAllOfficerUserBasedOnBranchSelectorStatus: 'Success',
			};

		case progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_FAILURE:
			return {
				...state,
				// FetchAllOfficerUserBasedOnBranchSelectorResponse: decodeApiResponse(
				// 	action.payload.records,
				// 	generalTypesAction.ALLOFFICERUSERBASEDONBRANCH,
				// ),
				FetchAllOfficerUserBasedOnBranchSelectorResponse: action.payload,
				FetchAllOfficerUserBasedOnBranchSelectorStatus: null,
			};

		case progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_CHANGED:
			return {
				...state,
				FetchAllOfficerUserBasedOnBranchSelectorResponse: null,
				FetchAllOfficerUserBasedOnBranchSelectorStatus: null,
			};

		case progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESPONSE_CHANGED:
			return {
				...state,
				FetchAllOfficerUserBasedOnBranchSelectorResponse: null,
				FetchAllOfficerUserBasedOnBranchSelectorStatus: null,
			};

		case progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESET:
			return { ...state, INITIAL_STATE };

		case progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_SUCCESS:
			return {
				...state,
				FetchBranchProgressReportSelectorResponse: decodeApiResponse(
					action.payload,
					generalTypesAction.FETCHBRANCHPROGRESSREPORT,
				),
				FetchBranchProgressReportSelectorStatus: 'Success',
			};

		case progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_FAILURE:
			return {
				...state,
				FetchBranchProgressReportSelectorResponse: decodeApiResponse(
					action.payload,
					generalTypesAction.FETCHBRANCHPROGRESSREPORT,
				),
				FetchBranchProgressReportSelectorStatus: null,
			};

		case progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_CHANGED:
			return {
				...state,
				FetchBranchProgressReportSelectorResponse: null,
				FetchBranchProgressReportSelectorStatus: null,
			};

		case progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_RESPONSE_CHANGED:
			return {
				...state,
				FetchBranchProgressReportSelectorResponse: null,
				FetchBranchProgressReportSelectorStatus: null,
			};

		default:
			return state;
	}
};

export default progressReportReducer;
