/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import EconomicActivityTypeActionTypes from './economic-activity-type.types';
import {
	economicActivityTypeDetailsAdd,
	economicActivityTypeDetailsUpdate,
	economicActivityTypeListGet,
	economicActivityTypeDetailsFetch,
	economicActivityTypeDetailsDelete,
	allEconomicActivityTypeFetch,
} from './economic-activity-type.service';

import {
	addEconomicActivityTypeDetailsSuccess,
	addEconomicActivityTypeDetailsFailure,
	addEconomicActivityTypeDetailsResponseChanged,
	updateEconomicActivityTypeDetailsSuccess,
	updateEconomicActivityTypeDetailsFailure,
	updateEconomicActivityTypeDetailsResponseChanged,
	getEconomicActivityTypeListSuccess,
	getEconomicActivityTypeListFailure,
	getEconomicActivityTypeListResponseChanged,
	fetchEconomicActivityTypeDetailsSuccess,
	fetchEconomicActivityTypeDetailsFailure,
	fetchEconomicActivityTypeDetailsResponseChanged,
	deleteEconomicActivityTypeDetailsSuccess,
	deleteEconomicActivityTypeDetailsFailure,
	deleteEconomicActivityTypeDetailsResponseChanged,
	fetchAllEconomicActivityTypeSuccess,
	fetchAllEconomicActivityTypeFailure,
	fetchAllEconomicActivityTypeResponseChanged,
} from './economic-activity-type.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD ECONOMIC ACTIVITY TYPE DETAILS  ******************** */
export function* createEconomicActivityType({ payload }) {
	try {
		const key = 'AddEconomicActivityTypeDetailKey';
		const jsonData = {
			EconomicActivityTypeName: payload.economicActivityTypeName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityTypeDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addEconomicActivityTypeDetailsFailure(responseData.data));
		} else {
			yield put(addEconomicActivityTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addEconomicActivityTypeDetailsFailure(error));
	}
}

export function* resetAddEconomicActivityTypeResponse() {
	yield put(addEconomicActivityTypeDetailsResponseChanged());
}

export function* onCreateEconomicActivityTypeDetails() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
		createEconomicActivityType,
	);
}

export function* onCreateEconomicActivityTypeResponseReset() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
		resetAddEconomicActivityTypeResponse,
	);
}
/** *********************  END ADD ECONOMIC ACTIVITY TYPE DETAILS ******************** */

/** *********************  START UPDATE ECONOMIC ACTIVITY TYPE DETAILS  ******************** */
export function* economicActivityTypeUpdate({ payload }) {
	try {
		const key = 'UpdateEconomicActivityTypeDetailsKey';
		const jsonData = {
			EconomicActivityTypeID: payload.economicActivityTypeID,
			EconomicActivityTypeName: payload.economicActivityTypeName,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityTypeDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateEconomicActivityTypeDetailsFailure(responseData.data));
		} else {
			yield put(updateEconomicActivityTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateEconomicActivityTypeDetailsFailure(error));
	}
}

export function* resetUpdateEconomicActivityTypeDetailsResponse() {
	yield put(updateEconomicActivityTypeDetailsResponseChanged());
}

export function* onUpdateEconomicActivityTypeDetails() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
		economicActivityTypeUpdate,
	);
}

export function* onUpdateEconomicActivityTypeResponseReset() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
		resetUpdateEconomicActivityTypeDetailsResponse,
	);
}
/** *********************  END UPDATE ECONOMIC ACTIVITY TYPE DETAILS ******************** */

/** *********************  START GET ECONOMIC ACTIVITY TYPE LIST  ******************** */
export function* economicActivityTypeDetailsListGet({ payload }) {
	try {
		const key = 'GetEconomicActivityTypeListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityTypeListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getEconomicActivityTypeListFailure(responseData.data));
		} else {
			yield put(getEconomicActivityTypeListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getEconomicActivityTypeListFailure(error));
	}
}

export function* resetGetEconomicActivityTypeListResponse() {
	yield put(getEconomicActivityTypeListResponseChanged());
}

export function* onGetEconomicActivityTypeListDetails() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_START,
		economicActivityTypeDetailsListGet,
	);
}

export function* onGetEconomicActivityTypeListResponseReset() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_RESPONSE_RESET,
		resetGetEconomicActivityTypeListResponse,
	);
}
/** *********************  END GET ECONOMIC ACTIVITY TYPE LIST ******************** */

/** *********************  START FETCH ECONOMIC ACTIVITY TYPE DETAILS  ******************** */
export function* fetchEconomicActivityTypeData({ payload }) {
	try {
		const key = 'FetchEconomicActivityTypeDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			EconomicActivityTypeID: payload.economicActivityTypeID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityTypeDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchEconomicActivityTypeDetailsFailure(responseData.data));
		} else {
			yield put(fetchEconomicActivityTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchEconomicActivityTypeDetailsFailure(error));
	}
}

export function* resetFetchEconomicActivityTypeDetailsResponse() {
	yield put(fetchEconomicActivityTypeDetailsResponseChanged());
}

export function* onFetchEconomicActivityTypeDetails() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
		fetchEconomicActivityTypeData,
	);
}

export function* onFetchEconomicActivityTypeResponseReset() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
		resetFetchEconomicActivityTypeDetailsResponse,
	);
}
/** *********************  END FETCH ECONOMIC ACTIVITY TYPE DETAILS ******************** */

/** *********************  START DELETE ECONOMIC ACTIVITY TYPE DETAILS  ******************** */
export function* economicActivityTypeDelete({ payload }) {
	try {
		const key = 'DeleteEconomicActivityTypeDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			EconomicActivityTypeID: payload.economicActivityTypeID,
			EconomicActivityTypeName: payload.economicActivityTypeName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityTypeDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteEconomicActivityTypeDetailsFailure(responseData.data));
		} else {
			yield put(deleteEconomicActivityTypeDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteEconomicActivityTypeDetailsFailure(error));
	}
}

export function* resetDeleteEconomicActivityTypeDetailsResponse() {
	yield put(deleteEconomicActivityTypeDetailsResponseChanged());
}

export function* onDeleteEconomicActivityTypeDetail() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
		economicActivityTypeDelete,
	);
}

export function* onDeleteEconomicActivityTypeResponseReset() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
		resetDeleteEconomicActivityTypeDetailsResponse,
	);
}
/** *********************  END DELETE ECONOMIC ACTIVITY TYPE DETAILS ******************** */

/** *********************  START FETCH ALL ECONOMIC ACTIVITY TYPE LIST  ******************** */
export function* fetchAllEconomicActivityTypesList({ payload }) {
	try {
		const key = 'FetchAllEconomicActivityTypesKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allEconomicActivityTypeFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllEconomicActivityTypeFailure(responseData.data));
		} else {
			yield put(fetchAllEconomicActivityTypeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllEconomicActivityTypeFailure(error));
	}
}

export function* resetFetchAllEconomicActivityTypesResponse() {
	yield put(fetchAllEconomicActivityTypeResponseChanged());
}

export function* onFetchAllEconomicActivityTypesDetails() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_START,
		fetchAllEconomicActivityTypesList,
	);
}

export function* onFetchAllEconomicActivityTypesResponseReset() {
	yield takeLatest(
		EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_RESPONSE_RESET,
		resetFetchAllEconomicActivityTypesResponse,
	);
}
/** *********************  END FETCH ALL ECONOMIC ACTIVITY TYPE LIST ******************** */

export function* economicActivityTypeMasterSaga() {
	yield all([
		call(onCreateEconomicActivityTypeDetails),
		call(onCreateEconomicActivityTypeResponseReset),
		call(onUpdateEconomicActivityTypeDetails),
		call(onUpdateEconomicActivityTypeResponseReset),
		call(onGetEconomicActivityTypeListDetails),
		call(onGetEconomicActivityTypeListResponseReset),
		call(onFetchEconomicActivityTypeDetails),
		call(onFetchEconomicActivityTypeResponseReset),
		call(onDeleteEconomicActivityTypeDetail),
		call(onDeleteEconomicActivityTypeResponseReset),
		call(onFetchAllEconomicActivityTypesDetails),
		call(onFetchAllEconomicActivityTypesResponseReset),
	]);
}
