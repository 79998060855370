/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import LoanApplicationTypes from './loanApplication.types';

/** ***************** ADD LOAN APPLICATION START *********************** */
export const addLoanApplicationStart = (addData) => ({
	type: LoanApplicationTypes.ADD_LOAN_APPLICATION_START,
	payload: addData,
});

export const addLoanApplicationSuccess = (successData) => ({
	type: LoanApplicationTypes.ADD_LOAN_APPLICATION_SUCCESS,
	payload: successData,
});

export const addLoanApplicationFailure = (failureData) => ({
	type: LoanApplicationTypes.ADD_LOAN_APPLICATION_FAILURE,
	payload: failureData,
});

export const addLoanApplicationResponseResetStart = () => ({
	type: LoanApplicationTypes.ADD_LOAN_APPLICATION_RESPONSE_RESET,
});

export const addLoanApplicationResponseChanged = () => ({
	type: LoanApplicationTypes.ADD_LOAN_APPLICATION_RESPONSE_CHANGED,
});

/** ***************** ADD LOAN APPLICATION END *********************** */

/** ***************** UPDATE LOAN APPLICATION START *********************** */
export const updateLoanApplicationStart = (updateData) => ({
	type: LoanApplicationTypes.UPDATE_LOAN_APPLICATION_START,
	payload: updateData,
});

export const updateLoanApplicationSuccess = (successData) => ({
	type: LoanApplicationTypes.UPDATE_LOAN_APPLICATION_SUCCESS,
	payload: successData,
});

export const updateLoanApplicationFailure = (failureData) => ({
	type: LoanApplicationTypes.UPDATE_LOAN_APPLICATION_FAILURE,
	payload: failureData,
});

export const updateLoanApplicationResponseResetStart = () => ({
	type: LoanApplicationTypes.UPDATE_LOAN_APPLICATION_RESPONSE_RESET,
});

export const updateLoanApplicationResponseChanged = () => ({
	type: LoanApplicationTypes.UPDATE_LOAN_APPLICATION_RESPONSE_CHANGED,
});

/** ***************** UPDATE LOAN APPLICATION END *********************** */

/** ***************** GET LOAN LIST START *********************** */
export const getLoanListStart = (getData) => ({
	type: LoanApplicationTypes.GET_LOAN_LIST_START,
	payload: getData,
});

export const getLoanListSuccess = (successData) => ({
	type: LoanApplicationTypes.GET_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const getLoanListFailure = (failureData) => ({
	type: LoanApplicationTypes.GET_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const getLoanListResponseResetStart = () => ({
	type: LoanApplicationTypes.GET_LOAN_LIST_RESPONSE_RESET,
});

export const getLoanListResponseChanged = () => ({
	type: LoanApplicationTypes.GET_LOAN_LIST_RESPONSE_CHANGED,
});

/** ***************** GET LOAN LIST END *********************** */

/** ***************** FETCH LOAN APPLICATION START *********************** */
export const fetchLoanApplicationStart = (fetchData) => ({
	type: LoanApplicationTypes.FETCH_LOAN_APPLICATION_START,
	payload: fetchData,
});

export const fetchLoanApplicationSuccess = (successData) => ({
	type: LoanApplicationTypes.FETCH_LOAN_APPLICATION_SUCCESS,
	payload: successData,
});

export const fetchLoanApplicationFailure = (failureData) => ({
	type: LoanApplicationTypes.FETCH_LOAN_APPLICATION_FAILURE,
	payload: failureData,
});

export const fetchLoanApplicationResponseResetStart = () => ({
	type: LoanApplicationTypes.FETCH_LOAN_APPLICATION_RESPONSE_RESET,
});

export const fetchLoanApplicationResponseChanged = () => ({
	type: LoanApplicationTypes.FETCH_LOAN_APPLICATION_RESPONSE_CHANGED,
});

/** ***************** FETCH LOAN APPLICATION END *********************** */

/** ***************** DELETE LOAN APPLICATION START *********************** */
export const deleteLoanApplicationStart = (deleteData) => ({
	type: LoanApplicationTypes.DELETE_LOAN_APPLICATION_START,
	payload: deleteData,
});

export const deleteLoanApplicationSuccess = (successData) => ({
	type: LoanApplicationTypes.DELETE_LOAN_APPLICATION_SUCCESS,
	payload: successData,
});

export const deleteLoanApplicationFailure = (failureData) => ({
	type: LoanApplicationTypes.DELETE_LOAN_APPLICATION_FAILURE,
	payload: failureData,
});

export const deleteLoanApplicationResponseResetStart = () => ({
	type: LoanApplicationTypes.DELETE_LOAN_APPLICATION_RESPONSE_RESET,
});

export const deleteLoanApplicationResponseChanged = () => ({
	type: LoanApplicationTypes.DELETE_LOAN_APPLICATION_RESPONSE_CHANGED,
});

/** ***************** DELETE LOAN APPLICATION END *********************** */

/** ***************** FETCH ALL LOAN LIST START *********************** */
export const fetchAllLoanListStart = (fetchLoans) => ({
	type: LoanApplicationTypes.FETCH_ALL_LOAN_START,
	payload: fetchLoans,
});

export const fetchAllLoanListSuccess = (successData) => ({
	type: LoanApplicationTypes.FETCH_ALL_LOAN_SUCCESS,
	payload: successData,
});

export const fetchAllLoanListFailure = (failureData) => ({
	type: LoanApplicationTypes.FETCH_ALL_LOAN_FAILURE,
	payload: failureData,
});

export const fetchAllLoanListResponseResetStart = () => ({
	type: LoanApplicationTypes.FETCH_ALL_LOAN_RESPONSE_RESET,
});

export const fetchAllLoanListResponseChanged = () => ({
	type: LoanApplicationTypes.FETCH_ALL_LOAN_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL LOAN LIST END *********************** */

/** ***************** STATUS UPDATE LOAN APPLICATION START ************** */

export const statusUpdateLoanApplicationStart = (statusData) => ({
	type: LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_START,
	payload: statusData,
});

export const statusUpdateLoanApplicationSuccess = (successData) => ({
	type: LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_SUCCESS,
	payload: successData,
});

export const statusUpdateLoanApplicationFailure = (failureData) => ({
	type: LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_FAILURE,
	payload: failureData,
});

export const statusUpdateLoanApplicationResponseReset = () => ({
	type: LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_RESPONSE_RESET_START,
});

export const statusUpdateLoanApplicationResponseChanged = () => ({
	type: LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_RESPONSE_CHANGED,
});

/** ***************** STATUS UPDATE LOAN APPLICATION END ************** */
