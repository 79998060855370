/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import futureDemandReportTypes from './future-demand-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchFutureDemandDetailResponse: null,
	fetchFutureDemandDetailArr: [],
	loanAmount: 0,
	loanOSAmount: 0,
	demandAmount: 0,
	overdueAmount: 0,
	getFutureDemandExcelListResponse: null,
	getFutureDemandExcelListArr: [],
	getFutureDemandExcelListStatus: null

};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_SUCCESS:
			return {
				...state,
				fetchFutureDemandDetailResponse: action.payload,
				fetchFutureDemandDetailArr: docodeResponse(action.payload, 'FutureDemandDetailsKey'),
				loanAmount: action.payload.TotalLoanAmount,
				loanOSAmount: action.payload.TotalLoanOLBAmount,
				demandAmount: action.payload.TotalDemandAmount,
				overdueAmount: action.payload.TotalLoanAmountArrear,
			};

		case futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_FAILURE:
			return { ...state, fetchFutureDemandDetailResponse: action.payload };

		case futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchFutureDemandDetailResponse: null };



		case futureDemandReportTypes.GET_FUTURE_DEMAND_EXCEL_LIST_SUCCESS:
			return {
				...state,
				getFutureDemandExcelListResponse: action.payload,
				getFutureDemandExcelListArr: docodeResponse(action.payload, 'FutureDemandExcelKey'),
				getFutureDemandExcelListStatus: 'Success',
			};

		case futureDemandReportTypes.GET_FUTURE_DEMAND_EXCEL_LIST_FAILURE:
			return { ...state, getFutureDemandExcelListStatus: action.payload };

		case futureDemandReportTypes.GET_FUTURE_DEMAND_EXCEL_LIST_RESPONSE_CHANGED:
			return { ...state, getFutureDemandExcelListStatus: null };
	

		default:
			return state;
	}
};
