/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const goldLoanProposalListGet = (listData) => {
	const data = {
		GetGoldLoanProposalListToken: listData,
	};
	const url = `${API_URL}GetAllGoldLoanIndexDetailsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const goldLoanProposalDelete = (deleteData) => {
	const data = {
		DeleteGoldLoanDetailsToken: deleteData,
	};
	const url = `${API_URL}DeleteParticularGoldLoanIndex`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const updateGoldLoan = (updateData) => {
	const data = {
		UpdateGoldLoanProposalListToken: updateData,
	};
	const url = `${API_URL}updateGoldLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const goldLoanProposalDetailsFetch = (listData) => {
	const data = {
		FetchAllGoldLoanKeyToken: listData,
	};
	const url = `${API_URL}fetchGoldLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createNewGoldLoanProposal = (addData) => {
	const data = {
		GoldLoanProposalToken: addData,
	};
	const url = `${API_URL}addGoldLoanProposal`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const goldLoanProposalGroupOfficerFetch = (fetchData) => {
	const data = {
		GetAllOfficerListToken: fetchData,
	};
	const url = `${API_URL}GetOfficerList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const goldLoanProposalGoldProductFetch = (fetchData) => {
	const data = {
		FetchGoldLoanProductDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchGoldLoanProductDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const approveGoldLoanProposal = (approveData) => {
	const data = {
		ApproveGoldLoanProposalListToken: approveData,
	};
	const url = `${API_URL}ApproveGoldLoanPaidAmount`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchGoldLoanProposalGroup = (fetchData) => {
	const data = {
		GetAllGroupListToken: fetchData,
	};
	const url = `${API_URL}fetchAllGroupList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchGoldLoanProposalGroupMember = (fetchData) => {
	const data = {
		GroupMemberListToken: fetchData,
	};
	const url = `${API_URL}fetchAllGroupMemberList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
