/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const loanapplicationAdd = (addData) => {
	const data = {
		AddLoanApplicationToken: addData,
	};
	const url = `${API_URL}addNewLoanApplication`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanapplicationUpdate = (updateData) => {
	const data = {
		LoanApplicationUpdateToken: updateData,
	};
	const url = `${API_URL}updateLoanApplication`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanListGet = (listData) => {
	const data = {
		GetLoanApplicationListToken: listData,
	};
	const url = `${API_URL}GetAllLoanApplicationDetailsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanapplicationFetch = (fetchData) => {
	const data = {
		FetchLoanApplicationToken: fetchData,
	};
	const url = `${API_URL}fetchLoanApplicationDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const loanapplicationDelete = (deleteData) => {
	const data = {
		DeleteLoanApplicationToken: deleteData,
	};
	const url = `${API_URL}DeleteParticularLoanApplication`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allLoansFetch = (fetchAllLoanData) => {
	const data = {
		FetchAllLoanApplicationToken: fetchAllLoanData,
	};
	const url = `${API_URL}fetchAllLoanList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const statusUpdateLoanApplication = (statusData) => {
	const data = {
		LoanApplicationStatusUpdateToken: statusData,
	};
	const url = `${API_URL}LoanApplicationStatusUpdate`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
