/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import MonthlyProgressActionTypes from './monthlyProgress.types';

/** ***************** GET PROGRESS REPORT DETAILS START *********************** */
export const getMonthlyProgressDetailsStart = (getData) => ({
	type: MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_START,
	payload: getData,
});

export const getMonthlyProgressDetailsSuccess = (successData) => ({
	type: MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_SUCCESS,
	payload: successData,
});

export const getMonthlyProgressDetailsFailure = (failureData) => ({
	type: MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_FAILURE,
	payload: failureData,
});

export const getMonthlyProgressDetailsResponseResetStart = () => ({
	type: MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_RESPONSE_RESET,
});

export const getMonthlyProgressDetailsResponseChanged = () => ({
	type: MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_RESPONSE_CHANGED,
});
/** ***************** GET PROGRESS REPORT DETAILS END *********************** */
