/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import staffReportActionTypes from './staffReport.types';

/** ***************** FETCH TAT DETAILS START *********************** */
export const getStaffDetailsStart = (addData) => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_START,
	payload: addData,
});

export const getStaffDetailsSuccess = (successData) => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const getStaffDetailsFailure = (failureData) => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const getStaffDetailsResponseResetStart = () => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_RESPONSE_CHANGED,
});

export const getStaffDetailsResponseChanged = () => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_RESPONSE_RESET,
});

export const fetchstaffReportExcelStart = (userCredentials) => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_EXCEL_START,
	payload: userCredentials,
});

export const fetchstaffReportExcelSuccess = (user) => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_EXCEL_SUCCESS,
	payload: user,
});

export const fetchstaffReportExcelFailure = (error) => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_EXCEL_FAILURE,
	payload: error,
});

export const fetchstaffReportExcelReset = () => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_EXCEL_RESPONSE_RESET,
});

export const fetchstaffReportExcelChanged = () => ({
	type: staffReportActionTypes.GET_STAFF_DETAILS_EXCEL_RESPONSE_CHANGED,
});

export const getStaffOfficerDetailsStart = (addData) => ({
	type: staffReportActionTypes.GET_STAFF_OFFICER_START,
	payload: addData,
});

export const getStaffOfficerDetailsSuccess = (successData) => ({
	type: staffReportActionTypes.GET_STAFF_OFFICER_SUCCESS,
	payload: successData,
});

export const getStaffOfficerDetailsFailure = (failureData) => ({
	type: staffReportActionTypes.GET_STAFF_OFFICER_FAILURE,
	payload: failureData,
});

export const getStaffOfficerDetailsResponseReset = () => ({
	type: staffReportActionTypes.GET_STAFF_OFFICER_RESPONSE_CHANGED,
});

export const getStaffOfficerDetailsResponseChanged = () => ({
	type: staffReportActionTypes.GET_STAFF_OFFICER_RESPONSE_RESET,
});

/** ***************** FETCH TAT DETAILS END *********************** */
