/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import MemberActionTypes from './member.types';
import { docodeResponse, decodeBase64Response } from '../../common/common.utils';

const INITIAL_STATE = {
	addMemberDetailsResponse: null,
	updateMemberDetailsResponse: null,
	getMemberListResponse: null,
	getMemberListArr: [],
	fetchMemberDetailsResponse: null,
	fetchMemberDetailsArr: [],
	deleteMemberDetailsResponse: null,
	fetchCoApplicantHighMarkDetailsResponse: null,
	fetchCoApplicantHighMarkDetailsArr: [],
	verifyDocumentDetailsResponse: null,
	checkHighMarkDetailsResponse: null,
	checkEquifaxDetailsResponse: null,
	checkCoApplicantHighmarkDetailsResponse: null,
	checkCoApplicantHighmarkDetailsArr: [],
	updateNoLoanResponse: null,
	updateRejectResponse: null,
	updateRecheckResponse: null,
	fetchPaySprintLogDetailsResponse: null,
	fetchPaySprintLogDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MemberActionTypes.ADD_MEMBER_DETAILS_SUCCESS:
			return { ...state, addMemberDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_DETAILS_FAILURE:
			return { ...state, addMemberDetailsResponse: action.payload };

		case MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, addMemberDetailsResponse: null };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_SUCCESS:
			return { ...state, updateMemberDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_FAILURE:
			return { ...state, updateMemberDetailsResponse: action.payload };

		case MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateMemberDetailsResponse: null };

		case MemberActionTypes.GET_MEMBER_LIST_SUCCESS:
			return {
				...state,
				getMemberListResponse: action.payload,
				getMemberListArr: docodeResponse(action.payload, 'GetMemberListKey'),
			};

		case MemberActionTypes.GET_MEMBER_LIST_FAILURE:
			return { ...state, getMemberListResponse: action.payload };

		case MemberActionTypes.GET_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, getMemberListResponse: null };

		case MemberActionTypes.FETCH_MEMBER_DETAILS_SUCCESS:
			return {
				...state,
				fetchMemberDetailsResponse: action.payload,
				fetchMemberDetailsArr: docodeResponse(action.payload, 'FetchMemberDetailsKey'),
			};

		case MemberActionTypes.FETCH_MEMBER_DETAILS_FAILURE:
			return { ...state, fetchMemberDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchMemberDetailsResponse: null, fetchMemberDetailsArr: [] };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_SUCCESS:
			return { ...state, deleteMemberDetailsResponse: action.payload };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_FAILURE:
			return { ...state, deleteMemberDetailsResponse: action.payload };

		case MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteMemberDetailsResponse: null };

		// CO-APPLICANT DETAILS

		case MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS:
			return {
				...state,
				fetchCoApplicantHighMarkDetailsResponse: action.payload,
				fetchCoApplicantHighMarkDetailsArr: docodeResponse(
					action.payload,
					'FetchCoApplicantHighMarkDetailsKey',
				),
			};

		case MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_FAILURE:
			return { ...state, fetchCoApplicantHighMarkDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchCoApplicantHighMarkDetailsResponse: null,
				fetchCoApplicantHighMarkDetailsArr: [],
			};

		// VERIFY DOCUMENT DETAILS

		case MemberActionTypes.VERIFY_DOCUMENT_DETAILS_SUCCESS:
			return {
				...state,
				verifyDocumentDetailsResponse: action.payload,
			};

		case MemberActionTypes.VERIFY_DOCUMENT_DETAILS_FAILURE:
			return { ...state, verifyDocumentDetailsResponse: action.payload };

		case MemberActionTypes.VERIFY_DOCUMENT_DETAILS_RESPONSE_RESET:
			return {
				...state,
				verifyDocumentDetailsResponse: null,
			};

		// CHECK HIGHMARK DETAILS

		case MemberActionTypes.CHECK_HIGHMARK_DETAILS_SUCCESS:
			return {
				...state,
				checkHighMarkDetailsResponse: action.payload,
			};

		case MemberActionTypes.CHECK_HIGHMARK_DETAILS_FAILURE:
			return { ...state, checkHighMarkDetailsResponse: action.payload };

		case MemberActionTypes.CHECK_HIGHMARK_DETAILS_RESPONSE_RESET:
			return {
				...state,
				checkHighMarkDetailsResponse: null,
			};
		// CHECK EQUIFAX DETAILS

		case MemberActionTypes.CHECK_EQUIFAX_DETAILS_SUCCESS:
			return {
				...state,
				checkEquifaxDetailsResponse: action.payload,
			};

		case MemberActionTypes.CHECK_EQUIFAX_DETAILS_FAILURE:
			return { ...state, checkEquifaxDetailsResponse: action.payload };

		case MemberActionTypes.CHECK_EQUIFAX_DETAILS_RESPONSE_RESET:
			return {
				...state,
				checkEquifaxDetailsResponse: null,
			};
		// CHECK CO APPLICANT HIGHMARK DETAILS

		case MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_SUCCESS:
			return {
				...state,
				checkCoApplicantHighmarkDetailsResponse: action.payload,
				checkCoApplicantHighmarkDetailsArr: docodeResponse(
					action.payload,
					'AppVerifyKYCKey',
				),
			};

		case MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_FAILURE:
			return { ...state, checkCoApplicantHighmarkDetailsResponse: action.payload };

		case MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET:
			return {
				...state,
				checkCoApplicantHighmarkDetailsResponse: null,
				checkCoApplicantHighmarkDetailsArr: [],
			};

		// UPDATE NO LOAN MEMBER RESPONSE

		case MemberActionTypes.UPDATE_NO_LOAN_MEMBER_SUCCESS:
			return { ...state, updateNoLoanResponse: action.payload };

		case MemberActionTypes.UPDATE_NO_LOAN_MEMBER_FAILURE:
			return { ...state, updateNoLoanResponse: action.payload };

		case MemberActionTypes.UPDATE_NO_LOAN_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateNoLoanResponse: null };

		// UPDATE REJECT MEMBER RESPONSE

		case MemberActionTypes.REJECT_MEMBER_SUCCESS:
			return { ...state, updateRejectResponse: action.payload };

		case MemberActionTypes.REJECT_MEMBER_FAILURE:
			return { ...state, updateRejectResponse: action.payload };

		case MemberActionTypes.REJECT_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateRejectResponse: null };

		// UPDATE RECHECK MEMBER RESPONSE

		case MemberActionTypes.RECHECK_MEMBER_SUCCESS:
			return { ...state, updateRecheckResponse: action.payload };

		case MemberActionTypes.RECHECK_MEMBER_FAILURE:
			return { ...state, updateRecheckResponse: action.payload };

		case MemberActionTypes.RECHECK_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateRecheckResponse: null };

		// UPDATE RECHECK MEMBER RESPONSE

		case MemberActionTypes.APPROVE_MEMBER_SUCCESS:
			return { ...state, updateApproveResponse: action.payload };

		case MemberActionTypes.APPROVE_MEMBER_FAILURE:
			return { ...state, updateApproveResponse: action.payload };

		case MemberActionTypes.APPROVE_MEMBER_RESPONSE_CHANGED:
			return { ...state, updateApproveResponse: null };

		// FETCH PAYSPRINT LOG

		case MemberActionTypes.FETCH_PAYSPRINT_DETAILS_SUCCESS:
			return {
				...state,
				fetchPaySprintLogDetailsResponse: action.payload,
				fetchPaySprintLogDetailsArr: decodeBase64Response(
					action.payload,
					'FetchPaySprintLogDetailsKey',
				),
			};

		case MemberActionTypes.FETCH_PAYSPRINT_DETAILS_FAILURE:
			return { ...state, fetchPaySprintLogDetailsResponse: action.payload };

		case MemberActionTypes.FETCH_PAYSPRINT_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchPaySprintLogDetailsResponse: null,
				fetchPaySprintLogDetailsArr: [],
			};
		default:
			return state;
	}
};
