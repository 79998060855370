/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import VerificationDetailsActionTypes from './verification-details.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addBusinessVerificationResponse: null,
	fetchBusinessVerifyResponse: null,
	fetchBusinessVerifyList: [],
	updateBusinessVerifyResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_SUCCESS:
			return { ...state, addBusinessVerificationResponse: action.payload };

		case VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_FAILURE:
			return { ...state, addBusinessVerificationResponse: action.payload };

		case VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_RESPONSE_CHANGED:
			return { ...state, addBusinessVerificationResponse: null };

		case VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_SUCCESS:
			return { ...state, updateBusinessVerifyResponse: action.payload };

		case VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_FAILURE:
			return { ...state, updateBusinessVerifyResponse: action.payload };

		case VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_RESPONSE_CHANGED:
			return { ...state, updateBusinessVerifyResponse: null };

		case VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_SUCCESS:
			return {
				...state,
				fetchBusinessVerifyResponse: action.payload,
				fetchBusinessVerifyList: docodeResponse(action.payload, 'GetBusinessVerifyKey'),
			};

		case VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_FAILURE:
			return { ...state, fetchBusinessVerifyResponse: action.payload };

		case VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_RESPONSE_CHANGED:
			return { ...state, fetchBusinessVerifyResponse: null };

		default:
			return state;
	}
};
