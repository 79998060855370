/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import staffActionTypes from './staff.types';

export const createStaffSuccess = (Staff) => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_SUCCESS,
	payload: Staff,
});

export const createStaffFailure = (error) => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createStaffStart = (Staff) => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_START,
	payload: Staff,
});

export const staffCreateChanged = () => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_CHANGED,
});

export const staffCreateReset = () => ({
	type: staffActionTypes.STAFF_CREATE_REPSONSE_RESET,
});

export const deleteStaffSuccess = (Staff) => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_SUCCESS,
	payload: Staff,
});

export const deleteStaffFailure = (error) => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteStaffStart = (StaffDetails) => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_START,
	payload: StaffDetails,
});

export const staffDeleteChanged = () => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_CHANGED,
});

export const staffDeleteReset = () => ({
	type: staffActionTypes.STAFF_DELETE_REPSONSE_RESET,
});

export const getAllStaffListFilter = (STAFF) => ({
	type: staffActionTypes.STAFF_LIST_START,
	payload: STAFF,
});

export const getAllStaffListSuccess = (STAFF) => ({
	type: staffActionTypes.STAFF_LIST_SUCCESS,
	payload: STAFF,
});

export const getAllStaffListFailure = (STAFF) => ({
	type: staffActionTypes.STAFF_LIST_FAILURE,
	payload: STAFF,
});
export const getAllStaffListResetAction = () => ({
	type: staffActionTypes.STAFF_LIST_RESET,
});

export const getAllStaffListChangedAction = () => ({
	type: staffActionTypes.STAFF_LIST_CHANGED,
});

export const getStaffBasedOnTeamStart = (STAFF) => ({
	type: staffActionTypes.STAFF_GET_BASED_ON_TEAM,
	payload: STAFF,
});

export const getStaffBasedOnTeamSuccess = (STAFF) => ({
	type: staffActionTypes.STAFF_GET_BASED_ON_TEAM_SUCCESS,
	payload: STAFF,
});

export const getStaffBasedOnTeamFailure = (STAFF) => ({
	type: staffActionTypes.STAFF_GET_BASED_ON_TEAM_FAILURE,
	payload: STAFF,
});
export const getStaffBasedOnTeamResetAction = () => ({
	type: staffActionTypes.STAFF_GET_BASED_ON_TEAM_RESET,
});

export const getStaffBasedOnTeamChangedAction = () => ({
	type: staffActionTypes.STAFF_GET_BASED_ON_TEAM_CHANGED,
});

export const getManagerListTeamStart = (STAFF) => ({
	type: staffActionTypes.GET_MANAGER_LIST_START,
	payload: STAFF,
});

export const getManagerListSuccess = (STAFF) => ({
	type: staffActionTypes.GET_MANAGER_LIST_SUCCESS,
	payload: STAFF,
});

export const getManagerListFailure = (STAFF) => ({
	type: staffActionTypes.GET_MANAGER_LIST_FAILURE,
	payload: STAFF,
});
export const getManagerListResetAction = () => ({
	type: staffActionTypes.GET_MANAGER_LIST_CHANGED,
});

export const getManagerListChangedAction = () => ({
	type: staffActionTypes.GET_MANAGER_LIST_RESET,
});
