/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import RelatedPartiesActionTypes from './related-parties.types';
import {
	partiesDetailsAdd,
	fetchPartiesDetails,
	updatePartiesDetails,
} from './related-parties.service';
import {
	addBusinessPartiesSuccess,
	addBusinessPartiesFailure,
	addBusinessPartiesResponseChanged,
	updateBusinessPartiesSuccess,
	updateBusinessPartiesFailure,
	updateBusinessPartiesResponseChanged,
	fetchBusinessPartiesSuccess,
	fetchBusinessPartiesFailure,
	fetchBusinessPartiesResponseChanged,
} from './related-parties.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS RELATED PARTIES  ******************** */
export function* businessPartiesAdd({ payload }) {
	try {
		const key = 'BusinessReletedPartiesKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			ReletedPartiesListArr: payload.relatedParties,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(partiesDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessPartiesFailure(responseData.data));
		} else {
			yield put(addBusinessPartiesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessPartiesFailure(error));
	}
}

export function* resetAddBusinessPartiesResponse() {
	yield put(addBusinessPartiesResponseChanged());
}

export function* onAddBusinessPartiesDetails() {
	yield takeLatest(RelatedPartiesActionTypes.ADD_RELATED_PARTIES_START, businessPartiesAdd);
}

export function* onAddBusinessPartiesResponseReset() {
	yield takeLatest(
		RelatedPartiesActionTypes.ADD_RELATED_PARTIES_RESPONSE_RESET,
		resetAddBusinessPartiesResponse,
	);
}
/** *********************  END ADD BUSINESS RELATED PARTIES ******************** */

/** *********************  START UPDATE BUSINESS RELATED PARTIES  ******************** */
export function* businessPartiesUpdate({ payload }) {
	try {
		const key = 'UpdateBusinessReletedPartiesKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			ReletedPartiesListArr: payload.relatedParties,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updatePartiesDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessPartiesFailure(responseData.data));
		} else {
			yield put(updateBusinessPartiesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessPartiesFailure(error));
	}
}

export function* resetUpdateBusinessPartiesResponse() {
	yield put(updateBusinessPartiesResponseChanged());
}

export function* onUpdateBusinessPartiesDetails() {
	yield takeLatest(RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_START, businessPartiesUpdate);
}

export function* onUpdateBusinessPartiesResponseReset() {
	yield takeLatest(
		RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_RESPONSE_RESET,
		resetUpdateBusinessPartiesResponse,
	);
}
/** *********************  END UPDATE BUSINESS RELATED PARTIES ******************** */

/** *********************  START FETCH BUSINESS RELATED PARTIES  ******************** */
export function* businessPartiesFetch({ payload }) {
	try {
		const key = 'GetBusinessReletedPartiesKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchPartiesDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessPartiesFailure(responseData.data));
		} else {
			yield put(fetchBusinessPartiesSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessPartiesFailure(error));
	}
}

export function* resetFetchBusinessPartiesResponse() {
	yield put(fetchBusinessPartiesResponseChanged());
}

export function* onFetchBusinessPartiesDetails() {
	yield takeLatest(RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_START, businessPartiesFetch);
}

export function* onFetchBusinessPartiesResponseReset() {
	yield takeLatest(
		RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_RESPONSE_RESET,
		resetFetchBusinessPartiesResponse,
	);
}
/** *********************  END FETCH BUSINESS RELATED PARTIES ******************** */

export function* partiesDetailsSaga() {
	yield all([
		call(onAddBusinessPartiesDetails),
		call(onAddBusinessPartiesResponseReset),
		call(onUpdateBusinessPartiesDetails),
		call(onUpdateBusinessPartiesResponseReset),
		call(onFetchBusinessPartiesDetails),
		call(onFetchBusinessPartiesResponseReset),
	]);
}
