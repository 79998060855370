/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BusinessDetailsActionTypes from './business-details.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	tabChangeValue: 'Business Details',
	isShowOffCanvas: false,
	isBusinessEdit: false,
	addBusinessDetailsResponse: null,
	addBusinessDetailsResp: [],
	updateBusinessDetailsResponse: null,
	fetchBusinessDetailsResponse: null,
	fetchBusinessDetailsData: [],
	deleteBusinessResponse: null,

	getBusinessListResponse: null,
	getBusinessList: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BusinessDetailsActionTypes.BUSINESS_SUBMIT_TAB_VALUE_CHANGED:
			return { ...state, tabChangeValue: action.payload };

		case BusinessDetailsActionTypes.BUSINESS_IS_OPEN_OFFCANVAS:
			return { ...state, isShowOffCanvas: action.payload };

		case BusinessDetailsActionTypes.IS_EDIT_BUSINESS_DETAILS:
			return { ...state, isBusinessEdit: action.payload };

		case BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_SUCCESS:
			return {
				...state,
				addBusinessDetailsResponse: action.payload,
				addBusinessDetailsResp: docodeResponse(action.payload, 'BusinessDetailsKey'),
			};

		case BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_FAILURE:
			return { ...state, addBusinessDetailsResponse: action.payload };

		case BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_RESPONSE_CHANGED:
			return { ...state, addBusinessDetailsResponse: null };

		case BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_SUCCESS:
			return { ...state, updateBusinessDetailsResponse: action.payload };

		case BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_FAILURE:
			return { ...state, updateBusinessDetailsResponse: action.payload };

		case BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateBusinessDetailsResponse: null };

		case BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_SUCCESS:
			return {
				...state,
				fetchBusinessDetailsResponse: action.payload,
				fetchBusinessDetailsData: docodeResponse(action.payload, 'GetBusinessDetailsKey'),
				addBusinessDetailsResp: docodeResponse(action.payload, 'GetBusinessDetailsKey'),
			};

		case BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_FAILURE:
			return { ...state, fetchBusinessDetailsResponse: action.payload };

		case BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchBusinessDetailsResponse: null,
				fetchBusinessDetailsData: [],
				// addBusinessDetailsResp: [],
			};

		case BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_SUCCESS:
			return { ...state, deleteBusinessResponse: action.payload };

		case BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_FAILURE:
			return { ...state, deleteBusinessResponse: action.payload };

		case BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteBusinessResponse: null };

		case BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_SUCCESS:
			return {
				...state,
				getBusinessListResponse: action.payload,
				getBusinessList: docodeResponse(action.payload, 'GetBusinessListKey'),
			};

		case BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_FAILURE:
			return { ...state, getBusinessListResponse: action.payload };

		case BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_RESPONSE_CHANGED:
			return { ...state, getBusinessListResponse: null };

		default:
			return state;
	}
};
