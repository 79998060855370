/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const businessfundDetailsAdd = (addData) => {
	const data = {
		AddBusinessFundDetailsToken: addData,
	};
	const url = `${API_URL}addBusinessFundDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const businessfundDetailsUpdate = (updateData) => {
	const data = {
		UpdateBusinessFundDetailsToken: updateData,
	};
	const url = `${API_URL}updateBusinessFundDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const businessfundListGet = (listData) => {
	const data = {
		GetBusinessFundListToken: listData,
	};
	const url = `${API_URL}getBusinessFundList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const businessfundDetailsFetch = (fetchData) => {
	const data = {
		FetchBusinessFundDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchBusinessFundDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const businessfundDetailsDelete = (deleteData) => {
	const data = {
		DeleteBusinessFundDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteBusinessFundDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allbusinessfundFetch = (fetchAllBusinessfundData) => {
	const data = {
		AllBusinessfundListFetchToken: fetchAllBusinessfundData,
	};
	const url = `${API_URL}fetchAllBusinessfundList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
