/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import MonthlyProgressActionTypes from './monthlyProgress.types';

import {
    monthlyProgressListGet,
} from './monthlyProgress.service';

import {
    getMonthlyProgressDetailsSuccess,
    getMonthlyProgressDetailsFailure,
    getMonthlyProgressDetailsResponseChanged,

} from './monthlyProgress.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getMonthlyProgressList({ payload }) {
	try {
		const key = 'GetMonthlyReportProgressKey';
		const jsonData = {
			CompanyID: payload.companyID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(monthlyProgressListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMonthlyProgressDetailsFailure(responseData.data));
		} else {
			yield put(getMonthlyProgressDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMonthlyProgressDetailsFailure(error));
	}
}

export function* resetGetMonthlyProgressListResponse() {
	yield put(getMonthlyProgressDetailsResponseChanged());
}

export function* onGetMonthlyProgressListDetails() {
	yield takeLatest(MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_START, getMonthlyProgressList);
}

export function* onGetMonthlyProgressListResponseReset() {
	yield takeLatest(MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_RESPONSE_RESET, resetGetMonthlyProgressListResponse);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */

export function* monthlyProgressSaga() {
	yield all([
		call(onGetMonthlyProgressListDetails),
		call(onGetMonthlyProgressListResponseReset),
	]);
}
