/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import PreCloseReportActionTypes from './preclose-loan.types';

/** ***************** GET PRECLOSE REPORT DETAILS START *********************** */
export const getPrecloseReportDetailsStart = (getData) => ({
	type: PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_START,
	payload: getData,
});

export const getPrecloseReportDetailsSuccess = (successData) => ({
	type: PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getPrecloseReportDetailsFailure = (failureData) => ({
	type: PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getPrecloseReportDetailsResponseResetStart = () => ({
	type: PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_RESPONSE_RESET,
});

export const getPrecloseReportDetailsResponseChanged = () => ({
	type: PreCloseReportActionTypes.GET_PRECLOSE_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET PRECLOSE REPORT DETAILS END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: PreCloseReportActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */


/** ***************** FETCH PRECLOSE DETAILS REPORT EXCEL DETAILS START *********************** */
export const fetchPrecloseReportExcelStart = (addData) => ({
	type: PreCloseReportActionTypes.FETCH_PRECLOSE_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchPrecloseReportExcelSuccess = (successData) => ({
	type: PreCloseReportActionTypes.FETCH_PRECLOSE_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchPrecloseReportExcelFailure = (failureData) => ({
	type: PreCloseReportActionTypes.FETCH_PRECLOSE_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchPrecloseReportResponseExcelResetStart = () => ({
	type: PreCloseReportActionTypes.FETCH_PRECLOSE_REPORT_RESPONSE_EXCEL_RESET_START,
});

export const fetchPrecloseReportResponseExcelResetChanged = () => ({
	type: PreCloseReportActionTypes.FETCH_PRECLOSE_REPORT_RESPONSE_EXCEL_CHANGED,
});

/** ***************** FETCH PRECLOSE DETAILS REPORT EXCEL DETAILS END *********************** */