/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import InsuranceReportTypes from './insurance-report.types';

/** ***************** FETCH INSURANCE DETAILS START *********************** */
export const fetchInsuranceReportStart = (addData) => ({
	type: InsuranceReportTypes.FETCH_INSURANCE_REPORT_START,
	payload: addData,
});

export const fetchInsuranceReportSuccess = (successData) => ({
	type: InsuranceReportTypes.FETCH_INSURANCE_REPORT_SUCCESS,
	payload: successData,
});

export const fetchInsuranceReportFailure = (failureData) => ({
	type: InsuranceReportTypes.FETCH_INSURANCE_REPORT_FAILURE,
	payload: failureData,
});

export const fetchInsuranceReportResponseResetStart = () => ({
	type: InsuranceReportTypes.FETCH_INSURANCE_REPORT_RESPONSE_RESET_START,
});

export const fetchInsuranceReportResponseResetChanged = () => ({
	type: InsuranceReportTypes.FETCH_INSURANCE_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH INSURANCE DETAILS END *********************** */
