/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ProductTypeActionTypes from './product-type.types';

/** ***************** ADD PRODUCT TYPE DETAILS START *********************** */
export const addProductTypeDetailsStart = (addData) => ({
	type: ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_START,
	payload: addData,
});

export const addProductTypeDetailsSuccess = (successData) => ({
	type: ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const addProductTypeDetailsFailure = (failureData) => ({
	type: ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const addProductTypeDetailsResponseResetStart = () => ({
	type: ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
});

export const addProductTypeDetailsResponseChanged = () => ({
	type: ProductTypeActionTypes.ADD_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD PRODUCT TYPE DETAILS END *********************** */

/** ***************** UPDATE PRODUCT TYPE DETAILS START *********************** */
export const updateProductTypeDetailsStart = (updateData) => ({
	type: ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_START,
	payload: updateData,
});

export const updateProductTypeDetailsSuccess = (successData) => ({
	type: ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const updateProductTypeDetailsFailure = (failureData) => ({
	type: ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const updateProductTypeDetailsResponseResetStart = () => ({
	type: ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
});

export const updateProductTypeDetailsResponseChanged = () => ({
	type: ProductTypeActionTypes.UPDATE_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE PRODUCT TYPE DETAILS END *********************** */

/** ***************** GET PRODUCT TYPE LIST START *********************** */
export const getProductTypeListStart = (getData) => ({
	type: ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_START,
	payload: getData,
});

export const getProductTypeListSuccess = (successData) => ({
	type: ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_SUCCESS,
	payload: successData,
});

export const getProductTypeListFailure = (failureData) => ({
	type: ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_FAILURE,
	payload: failureData,
});

export const getProductTypeListResponseResetStart = () => ({
	type: ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_RESPONSE_RESET,
});

export const getProductTypeListResponseChanged = () => ({
	type: ProductTypeActionTypes.GET_PRODUCT_TYPE_LIST_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT TYPE LIST END *********************** */

/** ***************** FETCH PRODUCT TYPE DETAILS START *********************** */
export const fetchProductTypeDetailsStart = (fetchData) => ({
	type: ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_START,
	payload: fetchData,
});

export const fetchProductTypeDetailsSuccess = (successData) => ({
	type: ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchProductTypeDetailsFailure = (failureData) => ({
	type: ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchProductTypeDetailsResponseResetStart = () => ({
	type: ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
});

export const fetchProductTypeDetailsResponseChanged = () => ({
	type: ProductTypeActionTypes.FETCH_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PRODUCT TYPE DETAILS END *********************** */

/** ***************** DELETE PRODUCT TYPE DETAILS START *********************** */
export const deleteProductTypeDetailsStart = (deleteData) => ({
	type: ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_START,
	payload: deleteData,
});

export const deleteProductTypeDetailsSuccess = (successData) => ({
	type: ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteProductTypeDetailsFailure = (failureData) => ({
	type: ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteProductTypeDetailsResponseResetStart = () => ({
	type: ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_RESPONSE_RESET,
});

export const deleteProductTypeDetailsResponseChanged = () => ({
	type: ProductTypeActionTypes.DELETE_PRODUCT_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE PRODUCT TYPE DETAILS END *********************** */
