/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import BranchProfileReportActionTypes from './branchProfileReport.types';

/** ***************** GET BRANCH PROFILE REPORT START *********************** */
export const getBranchProfileReportStart = (getData) => ({
	type: BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_START,
	payload: getData,
});

export const getBranchProfileReportSuccess = (successData) => ({
	type: BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_SUCCESS,
	payload: successData,
});

export const getBranchProfileReportFailure = (failureData) => ({
	type: BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_FAILURE,
	payload: failureData,
});

export const getBranchProfileReportResponseResetStart = () => ({
	type: BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_RESPONSE_RESET,
});

export const getBranchProfileReportResponseChanged = () => ({
	type: BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH PROFILE REPORT END *********************** */
