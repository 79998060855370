/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const auditTrailReportGet = (listData) => {
	const data = {
		auditTrailToken: listData,
	};
	const url = `${API_URL}GetAuditReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

/** ***************** AUDIT TRAIL REPORT REPORT EXCEL  *********************** */

export const AuditTrailReportExcelget = (branchData) => {
	const params = new URLSearchParams();
	params.append('auditTrailToken', branchData);
	const url = `${API_URL}Report/Excel/AuditTrailReportExportwithtoken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

/** ***************** AUDIT TRAIL REPORT REPORT JSON  *********************** */

export const auditTrailReportJSONGet = (listData) => {
	const data = {
		auditTrailToken: listData,
	};
	const url = `${API_URL}GetAuditReportJson`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};