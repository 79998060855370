/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BranchReportActionTypes from './branchReport.types';
import { docodeResponse } from '../../common/common.utils';


const INITIAL_STATE = {
	getBranchReportListResponse: null,
	getBranchReportListArr: [],
	totalCount: 0,
	TotalFromOutStandingAmount: 0,
	OutStandingPrincipal: 0,
	OutStandingInterest: 0,
	TotalToOutStandingAmount: 0,
	ToOutStandingPrincipal: 0,
	ToOutStandingInterest: 0,
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case BranchReportActionTypes.GET_BRANCH_REPORT_LIST_SUCCESS:
			return {
				...state,
				getBranchReportListResponse: action.payload,
				getBranchReportListArr: docodeResponse(action.payload, 'GetBranchDCBReportKey'),

				totalCount: action.payload.totalCount,
				TotalFromOutStandingAmount: action.payload.TotalFromOutStandingAmount,
				OutStandingPrincipal: action.payload.OutStandingPrincipal,
				OutStandingInterest: action.payload.OutStandingInterest,
				TotalToOutStandingAmount: action.payload.TotalToOutStandingAmount,
				ToOutStandingPrincipal: action.payload.ToOutStandingPrincipal,
				ToOutStandingInterest: action.payload.ToOutStandingInterest,
			};

		case BranchReportActionTypes.GET_BRANCH_REPORT_LIST_FAILURE:
			return { ...state, getBranchReportListResponse: action.payload };

		case BranchReportActionTypes.GET_BRANCH_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchReportListResponse: null };
        default:
            return state;
    }
}