// /* eslint-disable eslint-comments/disable-enable-pair */
// /* eslint-disable import/prefer-default-export */
// import axios from 'axios';
// import { API_URL } from '../../const';

// export const portfolioAtRiskReportDetailsGet = (fetchData) => {
// 	const data = {
// 		GetPortfolioAtRiskReportToken: fetchData,
// 	};
// 	const url = `${API_URL}PostPortfolioProfitRiskReportWithToken`;
// 	// console.log('GetPortfolioAtRiskReport',data,url);
// 	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
// };

/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const PortflioAtRiskDetailsGet = (fetchData) => {
	const data = {
		GetPortfolioAtRiskReportToken: fetchData,
	};
	const url = `${API_URL}PostPortfolioProfitRiskReportWithToken`;
	// console.log('GetoverdueAtRiskReport',data,url);
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchOverDueExcel = (branchData) => {
	// const data = {
	// 	RepaymentCollectionExcel: branchData,
	// };
	// const url = `${API_URL}CreateJobHandler`;
	const API_URL_EXCEL = 'https://dev.api.squarenow.in/';
	const url = `${API_URL_EXCEL}Excel/OverDueReportsMemberWiseExport.php?CompanyID=${branchData.companyID}&DatabaseName=${branchData.DatabaseName}&FromDate=${branchData.fromDate}&ToDate=${branchData.toDate}&BranchID=${branchData.BranchID}&CenterID=${branchData.CenterID}&GroupID=${branchData.GroupID}&MemberID=${branchData.MemberID}&DateType=${branchData.DateType}&CollectionMode=${branchData.CollectionMode}&IsBusiness=${branchData.IsBusiness}&RequestID=${branchData.RequestID}&CountLimit=${branchData.CountLimit}&FunderID=${branchData.FunderID}&UserID=${branchData.UserID}`;
	return axios.post(url, branchData, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	});
};

export const UserDetailsGet = (fetchData) => {
	const data = {
		UserDetailstToken: fetchData,
	};
	const url = `${API_URL}GetUserDetailsWithToken`;
	// console.log('GetoverdueAtRiskReport',data,url);
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
export const LoanInfoBasedOnMemberGet = (fetchData) => {
	const data = {
		LoanInforBasedOnMemberBothClosedToken: fetchData,
	};
	const url = `${API_URL}GetLoanInfoBasedOnMemebersBothclosedWithToken`;
	// console.log('GetoverdueAtRiskReport',data,url);
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
