/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import sanctionReportTypes from "./sanction-report.types";

/** ***************** FETCH SANCTION DETAILS START *********************** */
export const getSanctionReportStart = (addData) => ({
	type: sanctionReportTypes.GET_SANCTION_REPORT_START,
	payload: addData,
});

export const getSanctionReportSuccess = (successData) => ({
	type: sanctionReportTypes.GET_SANCTION_REPORT_SUCCESS,
	payload: successData,
});

export const getSanctionReportFailure = (failureData) => ({
	type: sanctionReportTypes.GET_SANCTION_REPORT_FAILURE,
	payload: failureData,
});

export const getSanctionReportResponseResetStart = () => ({
	type: sanctionReportTypes.GET_SANCTION_REPORT_RESPONSE_RESET_START,
});

export const getSanctionReportResponseResetChanged = () => ({
	type: sanctionReportTypes.GET_SANCTION_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH SANCTION DETAILS END *********************** */
