/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ThriftActionTypes from './thrift.types';
import {
	thriftDetailsAdd,
	thriftDetailsUpdate,
	thriftListGet,
	thriftDetailsFetch,
	thriftDetailsDelete,
	allThriftsFetch,
} from './thrift.service';

import {
	addThriftDetailsSuccess,
	addThriftDetailsFailure,
	addThriftDetailsResponseChanged,
	updateThriftDetailsSuccess,
	updateThriftDetailsFailure,
	updateThriftDetailsResponseChanged,
	getThriftListSuccess,
	getThriftListFailure,
	getThriftListResponseChanged,
	fetchThriftDetailsSuccess,
	fetchThriftDetailsFailure,
	fetchThriftDetailsResponseChanged,
	deleteThriftDetailsSuccess,
	deleteThriftDetailsFailure,
	deleteThriftDetailsResponseChanged,
	fetchAllThriftListSuccess,
	fetchAllThriftListFailure,
	fetchAllThriftListResponseChanged,
} from './thrift.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD THRIFT DETAILS  ******************** */
export function* createThrift({ payload }) {
	try {
		const key = 'AddThriftDetailKey';
		const jsonData = {
			ThriftName: payload.thriftName,
			ThriftAmount: payload.thriftAmount,
			ThriftInterest: payload.thriftInterest,
			NoOfInstalment: payload.thriftNoOfInstalemt,
			InterestType: payload.thriftInterestType,
			InstalmentType: payload.thriftInstalmentType,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(thriftDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addThriftDetailsFailure(responseData.data));
		} else {
			yield put(addThriftDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addThriftDetailsFailure(error));
	}
}

export function* resetAddThriftResponse() {
	yield put(addThriftDetailsResponseChanged());
}

export function* onCreateThriftDetails() {
	yield takeLatest(ThriftActionTypes.ADD_THRIFT_DETAILS_START, createThrift);
}

export function* onCreateThriftResponseReset() {
	yield takeLatest(ThriftActionTypes.ADD_THRIFT_DETAILS_RESPONSE_RESET, resetAddThriftResponse);
}
/** *********************  END ADD THRIFT DETAILS ******************** */

/** *********************  START UPDATE THRIFT DETAILS  ******************** */
export function* thriftUpdate({ payload }) {
	try {
		const key = 'UpdateThriftDetailsKey';
		const jsonData = {
			ThriftID: payload.thriftID,
			ThriftName: payload.thriftName,
			ThriftAmount: payload.thriftAmount,
			ThriftInterest: payload.thriftInterest,
			NoOfInstalment: payload.thriftNoOfInstalemt,
			InterestType: payload.thriftInterestType,
			InstalmentType: payload.thriftInstalmentType,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(thriftDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateThriftDetailsFailure(responseData.data));
		} else {
			yield put(updateThriftDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateThriftDetailsFailure(error));
	}
}

export function* resetUpdateThriftDetailsResponse() {
	yield put(updateThriftDetailsResponseChanged());
}

export function* onUpdateThriftDetails() {
	yield takeLatest(ThriftActionTypes.UPDATE_THRIFT_DETAILS_START, thriftUpdate);
}

export function* onUpdateThriftResponseReset() {
	yield takeLatest(
		ThriftActionTypes.UPDATE_THRIFT_DETAILS_RESPONSE_RESET,
		resetUpdateThriftDetailsResponse,
	);
}
/** *********************  END UPDATE THRIFT DETAILS ******************** */

/** *********************  START GET THRIFT LIST  ******************** */
export function* thriftDetailsListGet({ payload }) {
	try {
		const key = 'GetThriftListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
			Sec: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(thriftListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getThriftListFailure(responseData.data));
		} else {
			yield put(getThriftListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getThriftListFailure(error));
	}
}

export function* resetGetThriftListResponse() {
	yield put(getThriftListResponseChanged());
}

export function* onGetThriftListDetails() {
	yield takeLatest(ThriftActionTypes.GET_THRIFT_LIST_START, thriftDetailsListGet);
}

export function* onGetThriftListResponseReset() {
	yield takeLatest(ThriftActionTypes.GET_THRIFT_LIST_RESPONSE_RESET, resetGetThriftListResponse);
}
/** *********************  END GET THRIFT LIST ******************** */

/** *********************  START FETCH THRIFT DETAILS  ******************** */
export function* fetchThriftData({ payload }) {
	try {
		const key = 'FetchThriftDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ThriftID: payload.thriftID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(thriftDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchThriftDetailsFailure(responseData.data));
		} else {
			yield put(fetchThriftDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchThriftDetailsFailure(error));
	}
}

export function* resetFetchThriftDetailsResponse() {
	yield put(fetchThriftDetailsResponseChanged());
}

export function* onFetchThriftDetails() {
	yield takeLatest(ThriftActionTypes.FETCH_THRIFT_DETAILS_START, fetchThriftData);
}

export function* onFetchThriftResponseReset() {
	yield takeLatest(
		ThriftActionTypes.FETCH_THRIFT_DETAILS_RESPONSE_RESET,
		resetFetchThriftDetailsResponse,
	);
}
/** *********************  END FETCH THRIFT DETAILS ******************** */

/** *********************  START DELETE THRIFT DETAILS  ******************** */
export function* thriftDelete({ payload }) {
	try {
		const key = 'DeleteThriftDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			ThriftID: payload.thriftID,
			ThriftName: payload.thriftName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(thriftDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteThriftDetailsFailure(responseData.data));
		} else {
			yield put(deleteThriftDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteThriftDetailsFailure(error));
	}
}

export function* resetDeleteThriftDetailsResponse() {
	yield put(deleteThriftDetailsResponseChanged());
}

export function* onDeleteThriftDetail() {
	yield takeLatest(ThriftActionTypes.DELETE_THRIFT_DETAILS_START, thriftDelete);
}

export function* onDeleteThriftResponseReset() {
	yield takeLatest(
		ThriftActionTypes.DELETE_THRIFT_DETAILS_RESPONSE_RESET,
		resetDeleteThriftDetailsResponse,
	);
}
/** *********************  END DELETE THRIFT DETAILS ******************** */

/** *********************  START FETCH ALL THRIFTS LIST  ******************** */
export function* fetchAllThriftsList({ payload }) {
	try {
		const key = 'FetchAllThriftsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allThriftsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllThriftListFailure(responseData.data));
		} else {
			yield put(fetchAllThriftListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllThriftListFailure(error));
	}
}

export function* resetFetchAllThriftsResponse() {
	yield put(fetchAllThriftListResponseChanged());
}

export function* onFetchAllThriftsDetails() {
	yield takeLatest(ThriftActionTypes.FETCH_ALL_THRIFT_START, fetchAllThriftsList);
}

export function* onFetchAllThriftsResponseReset() {
	yield takeLatest(
		ThriftActionTypes.FETCH_ALL_THRIFT_RESPONSE_RESET,
		resetFetchAllThriftsResponse,
	);
}
/** *********************  END FETCH ALL THRIFTS LIST ******************** */

export function* thriftMasterSaga() {
	yield all([
		call(onCreateThriftDetails),
		call(onCreateThriftResponseReset),
		call(onUpdateThriftDetails),
		call(onUpdateThriftResponseReset),
		call(onGetThriftListDetails),
		call(onGetThriftListResponseReset),
		call(onFetchThriftDetails),
		call(onFetchThriftResponseReset),
		call(onDeleteThriftDetail),
		call(onDeleteThriftResponseReset),
		call(onFetchAllThriftsDetails),
		call(onFetchAllThriftsResponseReset),
	]);
}
