/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import GroupActionTypes from './group.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addGroupDetailsResponse: null,
	selectGetGroupListResponse: null,
	selectGetGroupListArr: [],

	selectFetchAllGroupResponse: null,
	selectFetchAllGroupArr: [],

	selectFetchAllApprovedMemberResponse: null,
	selectFetchAllApprovedMemberArr: [],

	selectAllOfficerResponse: null,
	selectAllOfficerArr: [],

	selectAllGroupDetailsResponse: null,
	selectAllGroupDetailsArr: [],

	selectBranchOfficerResponse: null,
	selectBranchOfficerArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GroupActionTypes.ADD_GROUP_DETAILS_SUCCESS:
			return { ...state, addGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_GROUP_DETAILS_FAILURE:
			return { ...state, addGroupDetailsResponse: action.payload };

		case GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, addGroupDetailsResponse: null };

		case GroupActionTypes.GET_GROUP_LIST_SUCCESS:
			return {
				...state,
				selectGetGroupListResponse: action.payload,
				selectGetGroupListArr: docodeResponse(action.payload, 'GetAllGroupListKey'),
			};

		case GroupActionTypes.GET_GROUP_LIST_FAILURE:
			return { ...state, selectGetGroupListResponse: action.payload };

		case GroupActionTypes.GET_GROUP_LIST_RESPONSE_CHANGED:
			return { ...state, selectGetGroupListResponse: null };

		case GroupActionTypes.FETCH_ALL_GROUP_SUCCESS:
			return {
				...state,
				selectFetchAllGroupResponse: action.payload,
				selectFetchAllGroupArr: docodeResponse(action.payload, 'FetchAllGroupListKey'),
			};

		case GroupActionTypes.FETCH_ALL_GROUP_FAILURE:
			return { ...state, selectFetchAllGroupResponse: action.payload };

		case GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED:
			return { ...state, selectFetchAllGroupResponse: null };

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_SUCCESS:
			return {
				...state,
				selectFetchAllApprovedMemberResponse: action.payload,
				selectFetchAllApprovedMemberArr: docodeResponse(
					action.payload,
					'GetBranchAllOfficerListKey',
				),
			};

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_FAILURE:
			return { ...state, selectFetchAllApprovedMemberResponse: action.payload };

		case GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_CHANGED:
			return { ...state, selectFetchAllApprovedMemberResponse: null };

		case GroupActionTypes.GET_ALL_OFFICER_SUCCESS:
			return {
				...state,
				selectAllOfficerResponse: action.payload,
				selectAllOfficerArr: docodeResponse(action.payload, 'GetAllOfficerKey'),
			};

		case GroupActionTypes.GET_ALL_OFFICER_FAILURE:
			return { ...state, selectAllOfficerResponse: action.payload };

		case GroupActionTypes.GET_ALL_OFFICER_RESPONSE_CHANGED:
			return { ...state, selectAllOfficerResponse: null };

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_SUCCESS:
			return {
				...state,
				selectAllGroupDetailsResponse: action.payload,
				selectAllGroupDetailsArr: docodeResponse(action.payload, 'GetAllGroupDetailsKey'),
			};

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_FAILURE:
			return { ...state, selectAllGroupDetailsResponse: action.payload };

		case GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED:
			return { ...state, selectAllGroupDetailsResponse: null };

		case GroupActionTypes.GET_BRANCH_OFFICER_SUCCESS:
			return {
				...state,
				selectBranchOfficerResponse: action.payload,
				selectBranchOfficerArr: docodeResponse(
					action.payload,
					'CENTEROFFICERLISTBASEDONCOMPANY',
				),
			};

		case GroupActionTypes.GET_BRANCH_OFFICER_FAILURE:
			return { ...state, selectBranchOfficerResponse: action.payload };

		case GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_CHANGED:
			return { ...state, selectBranchOfficerResponse: null };

		default:
			return state;
	}
};
