/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import TatReportTypes from './tat-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchTatDetailResponse: null,
	fetchTatDetailArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TatReportTypes.FETCH_TAT_REPORT_SUCCESS:
			return {
				...state,
				fetchTatDetailResponse: action.payload,
				fetchTatDetailArr: docodeResponse(action.payload, 'FetchTatDetailsKey'),
			};

		case TatReportTypes.FETCH_TAT_REPORT_FAILURE:
			return { ...state, fetchTatDetailResponse: action.payload };

		case TatReportTypes.FETCH_TAT_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchTatDetailResponse: null };

		default:
			return state;
	}
};
