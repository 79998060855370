/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import PartnerDetailsActionTypes from './partner-details.types';
import {
	partnerDetailsAdd,
	fetchPartnerDetails,
	updatePartnerDetails,
} from './partner-details.service';
import {
	addBusinessPartnerSuccess,
	addBusinessPartnerFailure,
	addBusinessPartnerResponseChanged,
	updateBusinessPartnerSuccess,
	updateBusinessPartnerFailure,
	updateBusinessPartnerResponseChanged,
	fetchBusinessPartnerSuccess,
	fetchBusinessPartnerFailure,
	fetchBusinessPartnerResponseChanged,
} from './partner-details.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS PARTNER  ******************** */
export function* businessPartnerAdd({ payload }) {
	try {
		const key = 'BusinessPartnerKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			PartnerListArr: payload.partnerListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(partnerDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessPartnerFailure(responseData.data));
		} else {
			yield put(addBusinessPartnerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessPartnerFailure(error));
	}
}

export function* resetAddBusinessPartnerResponse() {
	yield put(addBusinessPartnerResponseChanged());
}

export function* onAddBusinessPartnerDetails() {
	yield takeLatest(PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_START, businessPartnerAdd);
}

export function* onAddBusinessPartnerResponseReset() {
	yield takeLatest(
		PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_RESPONSE_RESET,
		resetAddBusinessPartnerResponse,
	);
}
/** *********************  END ADD BUSINESS PARTNER ******************** */

/** *********************  START UPDATE BUSINESS PARTNER  ******************** */
export function* businessPartnerUpdate({ payload }) {
	try {
		const key = 'UpdateBusinessPartnerKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			PartnerListArr: payload.partnerListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updatePartnerDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessPartnerFailure(responseData.data));
		} else {
			yield put(updateBusinessPartnerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessPartnerFailure(error));
	}
}

export function* resetUpdateBusinessPartnerResponse() {
	yield put(updateBusinessPartnerResponseChanged());
}

export function* onUpdateBusinessPartnerDetails() {
	yield takeLatest(
		PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_START,
		businessPartnerUpdate,
	);
}

export function* onUpdateBusinessPartnerResponseReset() {
	yield takeLatest(
		PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_RESPONSE_RESET,
		resetUpdateBusinessPartnerResponse,
	);
}
/** *********************  END UPDATE BUSINESS PARTNER ******************** */

/** *********************  START FETCH BUSINESS PARTNER  ******************** */
export function* businessPartnerFetch({ payload }) {
	try {
		const key = 'GetBusinessPartnerKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchPartnerDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessPartnerFailure(responseData.data));
		} else {
			yield put(fetchBusinessPartnerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessPartnerFailure(error));
	}
}

export function* resetFetchBusinessPartnerResponse() {
	yield put(fetchBusinessPartnerResponseChanged());
}

export function* onFetchBusinessPartnerDetails() {
	yield takeLatest(PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_START, businessPartnerFetch);
}

export function* onFetchBusinessPartnerResponseReset() {
	yield takeLatest(
		PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_RESPONSE_RESET,
		resetFetchBusinessPartnerResponse,
	);
}
/** *********************  END FETCH BUSINESS PARTNER ******************** */

export function* partnerDetailsSaga() {
	yield all([
		call(onAddBusinessPartnerDetails),
		call(onAddBusinessPartnerResponseReset),
		call(onUpdateBusinessPartnerDetails),
		call(onUpdateBusinessPartnerResponseReset),
		call(onFetchBusinessPartnerDetails),
		call(onFetchBusinessPartnerResponseReset),
	]);
}
