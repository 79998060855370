const ThriftActionTypes = {
	ADD_THRIFT_DETAILS_START: 'add_thrift_details_start',
	ADD_THRIFT_DETAILS_SUCCESS: 'add_thrift_details_success',
	ADD_THRIFT_DETAILS_FAILURE: 'add_thrift_details_failure',
	ADD_THRIFT_DETAILS_RESPONSE_RESET: 'add_thrift_details_response_reset',
	ADD_THRIFT_DETAILS_RESPONSE_CHANGED: 'add_thrift_details_response_changed',

	UPDATE_THRIFT_DETAILS_START: 'update_thrift_details_start',
	UPDATE_THRIFT_DETAILS_SUCCESS: 'update_thrift_details_success',
	UPDATE_THRIFT_DETAILS_FAILURE: 'update_thrift_details_failure',
	UPDATE_THRIFT_DETAILS_RESPONSE_RESET: 'update_thrift_details_response_reset',
	UPDATE_THRIFT_DETAILS_RESPONSE_CHANGED: 'update_thrift_details_response_changed',

	GET_THRIFT_LIST_START: 'get_thrift_list_start',
	GET_THRIFT_LIST_SUCCESS: 'get_thrift_list_success',
	GET_THRIFT_LIST_FAILURE: 'get_thrift_list_failure',
	GET_THRIFT_LIST_RESPONSE_RESET: 'get_thrift_list_response_reset',
	GET_THRIFT_LIST_RESPONSE_CHANGED: 'get_thrift_list_response_changed',

	FETCH_THRIFT_DETAILS_START: 'fetch_thrift_details_start',
	FETCH_THRIFT_DETAILS_SUCCESS: 'fetch_thrift_details_success',
	FETCH_THRIFT_DETAILS_FAILURE: 'fetch_thrift_details_failure',
	FETCH_THRIFT_DETAILS_RESPONSE_RESET: 'fetch_thrift_details_response_reset',
	FETCH_THRIFT_DETAILS_RESPONSE_CHANGED: 'fetch_thrift_details_response_changed',

	DELETE_THRIFT_DETAILS_START: 'delete_thrift_details_start',
	DELETE_THRIFT_DETAILS_SUCCESS: 'delete_thrift_details_success',
	DELETE_THRIFT_DETAILS_FAILURE: 'delete_thrift_details_failure',
	DELETE_THRIFT_DETAILS_RESPONSE_RESET: 'delete_thrift_details_response_reset',
	DELETE_THRIFT_DETAILS_RESPONSE_CHANGED: 'delete_thrift_details_response_changed',

	FETCH_ALL_THRIFT_START: 'fetch_all_thrift_start',
	FETCH_ALL_THRIFT_SUCCESS: 'fetch_all_thrift_success',
	FETCH_ALL_THRIFT_FAILURE: 'fetch_all_thrift_failure',
	FETCH_ALL_THRIFT_RESPONSE_RESET: 'fetch_all_thrift_response_reset',
	FETCH_ALL_THRIFT_RESPONSE_CHANGED: 'fetch_all_thrift_response_changed',
};
export default ThriftActionTypes;
