/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BranchDCBReportActionTypes from './branchDCBReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getBranchDCBReportListResponse: null,
	getBranchDCBReportListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_SUCCESS:
			return {
				...state,
				getBranchDCBReportListResponse: action.payload,
				getBranchDCBReportListArr: docodeResponse(action.payload, 'GetBranchDCBReportKey'),
			};

		case BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_FAILURE:
			return { ...state, getBranchDCBReportListResponse: action.payload };

		case BranchDCBReportActionTypes.GET_BRANCH_DCB_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchDCBReportListResponse: null };
		default:
			return state;
	}
};
