/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import sanctionReportTypes from './sanction-report.types';
import { getSanctionReportDetails } from './sanction-report.service';
import {
    getSanctionReportSuccess,
    getSanctionReportFailure,
    getSanctionReportResponseResetChanged
} from './sanction-report.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START GET SANCTION DETAILS  ******************** */
export function* getSanctionReport({ payload }) {
	try {
		const key = 'GetSanctionReportDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			CenterID: payload.CenterID,
			FromDate: payload.FromDate,
            ToDate: payload.ToDate,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getSanctionReportDetails, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(getSanctionReportFailure(responseData.data));
		} else {
			yield put(getSanctionReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSanctionReportFailure(error));
	}
}

export function* resetGetSanctionReportResponse() {
	yield put(getSanctionReportResponseResetChanged());
}

export function* onGetSanctionReportDetails() {
	yield takeLatest(sanctionReportTypes.GET_SANCTION_REPORT_START, getSanctionReport);
}

export function* onGetSanctionReportResponseReset() {
	yield takeLatest(sanctionReportTypes.GET_SANCTION_REPORT_RESPONSE_RESET_START, resetGetSanctionReportResponse);
}
/** *********************  END GET SANCTION DETAILS ******************** */

export function* sanctionReportSaga() {
	yield all([call(onGetSanctionReportDetails), 
        call(onGetSanctionReportResponseReset)]);
}
