// /* eslint-disable eslint-comments/disable-enable-pair */
// /* eslint-disable no-debugger */
// /* eslint-disable import/prefer-default-export */
// import { takeLatest, put, all, call } from 'redux-saga/effects';

// import DemandCollectionReportTypes from './demand-collection-report.types';
// import { getDemandCollectionReportDetails } from './demand-collection-report.service';
// import {
// 	fetchDemandCollectionReportSuccess,
// 	fetchDemandCollectionReportFailure,
// 	fetchDemandCollectionReportResponseResetChanged,
// } from './demand-collection-report.action';
// import { encodeJson } from '../../enode-decode';

// /** *********************  START FETCH FUTURE DEMAND DETAILS  ******************** */
// export function* fetchDemandCollectionReportReportDetails({ payload }) {
// 	try {
// 		const key = 'DemandCollectionDetailsKey';
// 		const jsonData = {
// 			CompanyID: payload.companyID,
// 			DatabaseName: payload.databaseName,
// 			BranchID: payload.CenterID,
// 			FromDate: payload.FromDate,
// 			FunderID: payload.FunderID,
// 			IsPersonalLoan: payload.IsPersonalLoan,
// 			LocationID: payload.branchID,
// 			ToDate: payload.ToDate,
// 		};
// 		const encodeData = encodeJson(jsonData, key);
// 		const responseData = yield call(getDemandCollectionReportDetails, encodeData);
// 		if (responseData.data.statusCode === '101') {
// 			yield put(fetchDemandCollectionReportFailure(responseData.data));
// 		} else {
// 			yield put(fetchDemandCollectionReportSuccess(responseData.data));
// 		}
// 	} catch (error) {
// 		yield put(fetchDemandCollectionReportFailure(error));
// 	}
// }

// export function* resetFetchDemandCollectionReportResponse() {
// 	yield put(fetchDemandCollectionReportResponseResetChanged());
// }

// export function* onFetchDemandCollectionReportDetails() {
// 	yield takeLatest(
// 		DemandCollectionReportTypes.FETCH_DEMAND_COLLECTION_REPORT_START,
// 		fetchDemandCollectionReportReportDetails,
// 	);
// }

// export function* onFetchDemandCollectionReportResponseReset() {
// 	yield takeLatest(
// 		DemandCollectionReportTypes.FETCH_DEMAND_COLLECTION_REPORT_RESPONSE_RESET_START,
// 		resetFetchDemandCollectionReportResponse,
// 	);
// }
// /** *********************  END FETCH FUTURE DEMAND DETAILS ******************** */

// export function* DemandCollectionReportSaga() {
// 	yield all([
// 		call(onFetchDemandCollectionReportDetails),
// 		call(onFetchDemandCollectionReportResponseReset),
// 	]);
// }

/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import DemandCollectionActionTypes from './demand-collection-report.types';

import {
	DemandCollectionListGet,
	DemandCollectionExcelListGet,
	DemandCollectionPDFListGet,
} from './demand-collection-report.service';

import {
	getDemandCollectionDetailsSuccess,
	getDemandCollectionDetailsFailure,
	getDemandCollectionDetailsResponseChanged,
	getDemandCollectionDetailsResponseArrayChanged,
	getDemandCollectionExcelDetailsSuccess,
	getDemandCollectionExcelDetailsFailure,
	getDemandCollectionExcelDetailsResponseChanged,
	getDemandCollectionPDFDetailsSuccess,
	getDemandCollectionPDFDetailsFailure,
	getDemandCollectionPDFDetailsResponseChanged,
} from './demand-collection-report.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET LOAN DISBURSEMENT REPORT DETAILS  ******************** */
export function* getDemandCollectionList({ payload }) {
	try {
		const key = 'DemandCollectionKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.userID,
			UserRole: payload.userName,
			LocationID: payload.centerID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			GroupID: payload.groupID,
			FunderID: payload.funderID,
			LoanType: payload.LoanType,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserLevel: payload.userLevel,
			IsThriftExist: payload.isThrift,
			isTenureClosedODLoans: payload.IsTenureClosedArrearsinDemandCollectionReport,
			SearchByViewType: payload.ViewType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(DemandCollectionListGet, encodeData, payload.ViewType);
		if (responseData.data.statusCode === '100') {
			yield put(getDemandCollectionDetailsFailure(responseData.data));
		} else {
			yield put(getDemandCollectionDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDemandCollectionDetailsFailure(error));
	}
}

export function* resetGetDemandCollectionListResponse() {
	yield put(getDemandCollectionDetailsResponseChanged());
}

export function* resetGetDemandCollectionListResponseArray() {
	yield put(getDemandCollectionDetailsResponseArrayChanged());
}

export function* onGetDemandCollectionListDetails() {
	yield takeLatest(
		DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_START,
		getDemandCollectionList,
	);
}

export function* onGetDemandCollectionListResponseReset() {
	yield takeLatest(
		DemandCollectionActionTypes.GET_DEMAND_COLLECTION_LIST_RESPONSE_RESET,
		resetGetDemandCollectionListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT REPORT LIST ******************** */

/** *********************  START GET LOAN DISBURSEMENT EXCEL REPORT DETAILS  ******************** */
export function* getDemandCollectionExcelList({ payload }) {
	try {
		const key = 'DemandCollectionExcelKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.userID,
			UserRole: payload.userName,
			LocationID: payload.centerID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			GroupID: payload.groupID,
			FunderID: payload.funderID,
			LoanType: payload.LoanType,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserLevel: payload.userLevel,
			IsThriftExist: payload.isThrift,
			isTenureClosedODLoans: payload.IsTenureClosedArrearsinDemandCollectionReport,
			SearchByViewType: payload.ViewType,
			RequestID: payload.RequestID
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(DemandCollectionExcelListGet, encodeData, payload.ViewType);
		if (responseData.data.statusCode === '100') {
			yield put(getDemandCollectionExcelDetailsFailure(responseData.data));
		} else {
			yield put(getDemandCollectionExcelDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDemandCollectionExcelDetailsFailure(error));
	}
}

export function* resetGetDemandCollectionExcelListResponse() {
	yield put(getDemandCollectionExcelDetailsResponseChanged());
}

export function* onGetDemandCollectionExcelListDetails() {
	yield takeLatest(
		DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_START,
		getDemandCollectionExcelList,
	);
}

export function* onGetDemandCollectionExcelListResponseReset() {
	yield takeLatest(
		DemandCollectionActionTypes.GET_DEMAND_COLLECTION_EXCEL_LIST_RESPONSE_RESET,
		resetGetDemandCollectionExcelListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT EXCEL REPORT LIST ******************** */



/** *********************  START GET LOAN DISBURSEMENT PDF REPORT DETAILS  ******************** */
export function* getDemandCollectionPDFList({ payload }) {
	try {
		const key = 'DemandCollectionPDFKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userName,
			LocationID: payload.centerID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			GroupID: payload.groupID,
			FunderID: payload.funderID,
			LoanType: payload.LoanType,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			UserLevel: payload.userLevel,
			IsThriftExist: payload.isThrift,
			isTenureClosedODLoans: payload.IsTenureClosedArrearsinDemandCollectionReport,
			isActiveLoanArrearMembers: payload.IsActiveLoanArrearsinDemandCollectionReport,
			SearchByViewType: payload.ViewType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(DemandCollectionPDFListGet, encodeData, payload.userAccessBranchID);
		if (responseData.data.statusCode === '100') {
			yield put(getDemandCollectionPDFDetailsFailure(responseData.data));
		} else {
			yield put(getDemandCollectionPDFDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDemandCollectionPDFDetailsFailure(error));
	}
}

export function* resetGetDemandCollectionPDFListResponse() {
	yield put(getDemandCollectionPDFDetailsResponseChanged());
}

export function* onGetDemandCollectionPDFListDetails() {
	yield takeLatest(
		DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_START,
		getDemandCollectionPDFList,
	);
}

export function* onGetDemandCollectionPDFListResponseReset() {
	yield takeLatest(
		DemandCollectionActionTypes.GET_DEMAND_COLLECTION_PDF_LIST_RESPONSE_RESET,
		resetGetDemandCollectionPDFListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT PDF REPORT LIST ******************** */


export function* reportDemandCollectionSaga() {
	yield all([
		call(onGetDemandCollectionListDetails),
		call(onGetDemandCollectionListResponseReset),
		call(onGetDemandCollectionExcelListDetails),
		call(onGetDemandCollectionExcelListResponseReset),
		call(onGetDemandCollectionPDFListDetails),
		call(onGetDemandCollectionPDFListResponseReset),
	]);
}
