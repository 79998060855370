/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchReportActionTypes from './branchProfileReport.types';
import { branchProfileReportGet } from './branchProfileReport.service';

import {
	getBranchProfileReportSuccess,
	getBranchProfileReportFailure,
	getBranchProfileReportResponseChanged,
} from './branchProfileReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getBranchProfileReport({ payload }) {
	try {
		const key = 'FetchBranchProfileReportKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserRole: payload.UserRole,
			Level: payload.Level,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
			ProfileDate: payload.ProfileDate,
			SortbyMethod: payload.SortbyMethod,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchProfileReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchProfileReportFailure(responseData.data));
		} else {
			yield put(getBranchProfileReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchProfileReportFailure(error));
	}
}

export function* resetGetBranchProfileReportListResponse() {
	yield put(getBranchProfileReportResponseChanged());
}

export function* onGetBranchProfileReportDetails() {
	yield takeLatest(
		BranchReportActionTypes.GET_BRANCH_PROFILE_REPORT_START,
		getBranchProfileReport,
	);
}

export function* onGetBranchProfileReportResponseReset() {
	yield takeLatest(
		BranchReportActionTypes.GET_BRANCH_PROFILE_REPORT_RESPONSE_RESET,
		resetGetBranchProfileReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */

export function* branchProfileReportSaga() {
	yield all([call(onGetBranchProfileReportDetails), call(onGetBranchProfileReportResponseReset)]);
}
