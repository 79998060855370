/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BusinessFundActionTypes from './business-fund.types';
import {
	businessfundDetailsAdd,
	businessfundDetailsUpdate,
	businessfundListGet,
	businessfundDetailsFetch,
	businessfundDetailsDelete,
	allbusinessfundFetch,
} from './business-fund.service';

import {
	addBusinessFundDetailsSuccess,
	addBusinessFundDetailsFailure,
	addBusinessFundDetailsResponseChanged,
	updateBusinessFundDetailsSuccess,
	updateBusinessFundDetailsFailure,
	updateBusinessFundDetailsResponseChanged,
	getBusinessFundListSuccess,
	getBusinessFundListFailure,
	getBusinessFundListResponseChanged,
	fetchBusinessFundDetailsSuccess,
	fetchBusinessFundDetailsFailure,
	fetchBusinessFundDetailsResponseChanged,
	deleteBusinessFundDetailsSuccess,
	deleteBusinessFundDetailsFailure,
	deleteBusinessFundDetailsResponseChanged,
	fetchAllBusinessFundListSuccess,
	fetchAllBusinessFundListFailure,
	fetchAllBusinessFundListResponseChanged,
} from './business-fund.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD BUSINESS FUND DETAILS  ******************** */
export function* createBusinessFund({ payload }) {
	try {
		const key = 'AddBusinessFundDetailKey';
		const jsonData = {
			BusinessType: payload.businessType,
			FundName: payload.fundName,
			Description: payload.description,
			Amount: payload.amount,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessfundDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessFundDetailsFailure(responseData.data));
		} else {
			yield put(addBusinessFundDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessFundDetailsFailure(error));
	}
}

export function* resetAddBusinessFundResponse() {
	yield put(addBusinessFundDetailsResponseChanged());
}

export function* onCreateBusinessFundDetails() {
	yield takeLatest(BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_START, createBusinessFund);
}

export function* onCreateBusinessFundResponseReset() {
	yield takeLatest(BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_RESPONSE_RESET, resetAddBusinessFundResponse);
}
/** *********************  END ADD BUSINESS FUND DETAILS ******************** */

/** *********************  START UPDATE BUSINESS FUND DETAILS  ******************** */
export function* businessfundUpdate({ payload }) {
	try {
		const key = 'UpdateBusinessFundDetailsKey';
		const jsonData = {
			BusinessFundID: payload.businessfundID,
			BusinessType: payload.businessType,
			FundName: payload.fundName,
			Description: payload.description,
			Amount: payload.amount,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessfundDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessFundDetailsFailure(responseData.data));
		} else {
			yield put(updateBusinessFundDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessFundDetailsFailure(error));
	}
}

export function* resetUpdateBusinessFundDetailsResponse() {
	yield put(updateBusinessFundDetailsResponseChanged());
}

export function* onUpdateBusinessFundDetails() {
	yield takeLatest(BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_START, businessfundUpdate);
}

export function* onUpdateBusinessFundResponseReset() {
	yield takeLatest(
		BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_RESPONSE_RESET,
		resetUpdateBusinessFundDetailsResponse,
	);
}
/** *********************  END UPDATE BUSINESS FUND DETAILS ******************** */

/** *********************  START GET BUSINESS FUND LIST  ******************** */
export function* businessfundDetailsListGet({ payload }) {
	try {
		const key = 'GetBusinessFundListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		console.log(encodeData)
		const responseData = yield call(businessfundListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBusinessFundListFailure(responseData.data));
		} else {
			yield put(getBusinessFundListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBusinessFundListFailure(error));
	}
}

export function* resetGetBusinessFundListResponse() {
	yield put(getBusinessFundListResponseChanged());
}

export function* onGetBusinessFundListDetails() {
	yield takeLatest(BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_START, businessfundDetailsListGet);
}

export function* onGetBusinessFundListResponseReset() {
	yield takeLatest(BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_RESPONSE_RESET, resetGetBusinessFundListResponse);
}
/** *********************  END GET BUSINESS FUND LIST ******************** */

/** *********************  START FETCH BUSINESS FUND DETAILS  ******************** */
export function* fetchBusinessFundData({ payload }) {
	try {
		const key = 'FetchBusinessFundDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BusinessFundID: payload.businessfundID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessfundDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessFundDetailsFailure(responseData.data));
		} else {
			yield put(fetchBusinessFundDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessFundDetailsFailure(error));
	}
}

export function* resetFetchBusinessFundDetailsResponse() {
	yield put(fetchBusinessFundDetailsResponseChanged());
}

export function* onFetchBusinessFundDetails() {
	yield takeLatest(BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_START, fetchBusinessFundData);
}

export function* onFetchBusinessFundResponseReset() {
	yield takeLatest(
		BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_RESPONSE_RESET,
		resetFetchBusinessFundDetailsResponse,
	);
}
/** *********************  END FETCH BUSINESS FUND DETAILS ******************** */

/** *********************  START DELETE BUSINESS FUND DETAILS  ******************** */
export function* businessfundDelete({ payload }) {
	try {
		const key = 'DeleteBusinessFundDetailsKey';
		const jsonData = {
			// CompanyID: payload.companyID,
			BusinessFundID: payload,
			IsActive: '0',
			// BusinessFundName: payload.businessfundName,
			// DatabaseName: payload.databaseName,
			// DeletedBy: payload.userID,
			// UserAccessBranchID: payload.userAccessBranchID,
			// UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessfundDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBusinessFundDetailsFailure(responseData.data));
		} else {
			yield put(deleteBusinessFundDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBusinessFundDetailsFailure(error));
	}
}

export function* resetDeleteBusinessFundDetailsResponse() {
	yield put(deleteBusinessFundDetailsResponseChanged());
}

export function* onDeleteBusinessFundDetail() {
	yield takeLatest(BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_START, businessfundDelete);
}

export function* onDeleteBusinessFundResponseReset() {
	yield takeLatest(
		BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_RESPONSE_RESET,
		resetDeleteBusinessFundDetailsResponse,
	);
}
/** *********************  END DELETE BUSINESS FUND DETAILS ******************** */

/** *********************  START FETCH ALL BUSINESS FUNDS LIST  ******************** */
export function* fetchAllBusinessFundsList({ payload }) {
	try {
		const key = 'FetchAllBusinessFundsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allbusinessfundFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllBusinessFundListFailure(responseData.data));
		} else {
			yield put(fetchAllBusinessFundListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllBusinessFundListFailure(error));
	}
}

export function* resetFetchAllBusinessFundsResponse() {
	yield put(fetchAllBusinessFundListResponseChanged());
}

export function* onFetchAllBusinessFundsDetails() {
	yield takeLatest(BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_START, fetchAllBusinessFundsList);
}

export function* onFetchAllBusinessFundsResponseReset() {
	yield takeLatest(BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_RESPONSE_RESET, resetFetchAllBusinessFundsResponse);
}
/** *********************  END FETCH ALL BUSINESS FUNDS LIST ******************** */

export function* businessfundMasterSaga() {
	yield all([
		call(onCreateBusinessFundDetails),
		call(onCreateBusinessFundResponseReset),
		call(onUpdateBusinessFundDetails),
		call(onUpdateBusinessFundResponseReset),
		call(onGetBusinessFundListDetails),
		call(onGetBusinessFundListResponseReset),
		call(onFetchBusinessFundDetails),
		call(onFetchBusinessFundResponseReset),
		call(onDeleteBusinessFundDetail),
		call(onDeleteBusinessFundResponseReset),
		call(onFetchAllBusinessFundsDetails),
		call(onFetchAllBusinessFundsResponseReset),
	]);
}
