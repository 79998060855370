const AuditingToolActionTypes = {

    GET_AUDITING_TOOL_LIST_START: 'get_auditing_tool_start',
	GET_AUDITING_TOOL_LIST_SUCCESS: 'get_auditing_tool_success',
	GET_AUDITING_TOOL_LIST_FAILURE: 'get_auditing_tool_failure',
	GET_AUDITING_TOOL_LIST_RESPONSE_RESET: 'get_auditing_tool_response_reset',
	GET_AUDITING_TOOL_LIST_RESPONSE_CHANGED: 'get_auditing_tool_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',
    
}
export default AuditingToolActionTypes;