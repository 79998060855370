/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import VerificationDetailsActionTypes from './verification-details.types';
import {
	verificationDetailsAdd,
	updateVerificationDetails,
	fetchVerificationDetails,
} from './verification-details.service';

import {
	addBusinessVerificationSuccess,
	addBusinessVerificationFailure,
	addBusinessVerificationResponseChanged,
	updateBusinessVerificationSuccess,
	updateBusinessVerificationFailure,
	updateBusinessVerificationResponseChanged,
	fetchBusinessVerificationSuccess,
	fetchBusinessVerificationFailure,
	fetchBusinessVerificationResponseChanged,
} from './verification-details.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS VERIFICATION  ******************** */
export function* businessVerificationAdd({ payload }) {
	try {
		const key = 'BusinessVerificationKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			VerificationListArr: payload.verificationListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(verificationDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessVerificationFailure(responseData.data));
		} else {
			yield put(addBusinessVerificationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessVerificationFailure(error));
	}
}

export function* resetAddBusinessVerificationResponse() {
	yield put(addBusinessVerificationResponseChanged());
}

export function* onAddBusinessVerificationDetails() {
	yield takeLatest(
		VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_START,
		businessVerificationAdd,
	);
}

export function* onAddBusinessVerificationResponseReset() {
	yield takeLatest(
		VerificationDetailsActionTypes.ADD_BUSINESS_VERIFICATION_RESPONSE_RESET,
		resetAddBusinessVerificationResponse,
	);
}
/** *********************  END ADD BUSINESS VERIFICATION ******************** */

/** *********************  START UPDATE BUSINESS VERIFICATION  ******************** */
export function* businessVerificationUpdate({ payload }) {
	try {
		const key = 'UpdateBusinessVerificationKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			VerificationListArr: payload.verificationListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateVerificationDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessVerificationFailure(responseData.data));
		} else {
			yield put(updateBusinessVerificationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessVerificationFailure(error));
	}
}

export function* resetUpdateBusinessVerificationResponse() {
	yield put(updateBusinessVerificationResponseChanged());
}

export function* onUpdateBusinessVerificationDetails() {
	yield takeLatest(
		VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_START,
		businessVerificationUpdate,
	);
}

export function* onUpdateBusinessVerificationResponseReset() {
	yield takeLatest(
		VerificationDetailsActionTypes.UPDATE_BUSINESS_VERIFICATION_RESPONSE_RESET,
		resetUpdateBusinessVerificationResponse,
	);
}
/** *********************  END UPDATE BUSINESS VERIFICATION ******************** */

/** *********************  START FETCH BUSINESS VERIFICATION  ******************** */
export function* businessVerificationFetch({ payload }) {
	try {
		const key = 'GetBusinessVerifyKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchVerificationDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessVerificationFailure(responseData.data));
		} else {
			yield put(fetchBusinessVerificationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessVerificationFailure(error));
	}
}

export function* resetFetchBusinessVerificationResponse() {
	yield put(fetchBusinessVerificationResponseChanged());
}

export function* onFetchBusinessVerificationDetails() {
	yield takeLatest(
		VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_START,
		businessVerificationFetch,
	);
}

export function* onFetchBusinessVerificationResponseReset() {
	yield takeLatest(
		VerificationDetailsActionTypes.FETCH_BUSINESS_VERIFICATION_RESPONSE_RESET,
		resetFetchBusinessVerificationResponse,
	);
}
/** *********************  END FETCH BUSINESS VERIFICATION ******************** */

export function* verificationDetailsSaga() {
	yield all([
		call(onAddBusinessVerificationDetails),
		call(onAddBusinessVerificationResponseReset),
		call(onUpdateBusinessVerificationDetails),
		call(onUpdateBusinessVerificationResponseReset),
		call(onFetchBusinessVerificationDetails),
		call(onFetchBusinessVerificationResponseReset),
	]);
}
