const advanceCollectionActionTypes = {
	GET_ADVANCE_MEMBER_LOAN_LIST_START: 'GET_ADVANCE_MEMBER_LOAN_LIST_START',
	GET_ADVANCE_MEMBER_LOAN_LIST_SUCCESS: 'GET_ADVANCE_MEMBER_LOAN_LIST_SUCCESS',
	GET_ADVANCE_MEMBER_LOAN_LIST_FAILURE: 'GET_ADVANCE_MEMBER_LOAN_LIST_FAILURE',
	GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_RESET: 'GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_RESET',
	GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_CHANGED: 'GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_CHANGED',

	UPDATE_ADVANCE_COLLECTION_START: 'UPDATE_ADVANCE_COLLECTION_START',
	UPDATE_ADVANCE_COLLECTION_SUCCESS: 'UPDATE_ADVANCE_COLLECTION_SUCCESS',
	UPDATE_ADVANCE_COLLECTION_FAILURE: 'UPDATE_ADVANCE_COLLECTION_FAILURE',
	UPDATE_ADVANCE_COLLECTION_RESPONSE_RESET: 'UPDATE_ADVANCE_COLLECTION_RESPONSE_RESET',
	UPDATE_ADVANCE_COLLECTION_RESPONSE_CHANGED: 'UPDATE_ADVANCE_COLLECTION_RESPONSE_CHANGED',
};
export default advanceCollectionActionTypes;
