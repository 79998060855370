/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const createTeam = (TeamData) => {
	const data = {
		CreateNewTeamToken: TeamData,
	};
	const url = `${API_URL}AddTeam`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateTeam = (TeamData) => {
	const data = {
		UpdateExistTeamDetailsToken: TeamData,
	};
	const url = `${API_URL}UpdateTeamDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteTeam = (TeamData) => {
	const data = {
		DeleteExistTeamToken: TeamData,
	};
	const url = `${API_URL}DeleteTeam`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// export const getAllTeams = () => {
// 	const url = `${API_URL}GetAllTeamList`;
// 	return axios.get(url, { headers: { 'Content-type': 'application/json' } });
// };

export const getAllTeams = (TeamData) => {
	const data = {
		FetchTeamToken: TeamData,
	};
	const url = `${API_URL}GetAllTeamList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getTeams = () => {
	const url = `${API_URL}GetTeamDetails/TeamID`;
	return axios.get(url, { headers: { 'Content-type': 'application/json' } });
};

// export const getTeamLeads = (TeamData) => {
// 	const data = {
// 		FetchTeamLeadToken: TeamData,
// 	};
// 	const url = `${API_URL}GetAllTeamLead`;
// 	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
// };

/*
export const getTeamLeads = ({Region}) => {


	let param = '';
	if(Region !== '') {
		param = `/${Region}`;
	}

	const url = `${API_URL}GetAllTeamLead${param}`;
	return axios.get(url, { headers: { 'Content-type': 'application/json' } });
};
*/


export const getTeamLeads = (TeamData) => {
	console.log("getTeamLeads service called...");
	const data = {
		FetchTeamLeadToken: TeamData,
	};
	const url = `${API_URL}GetAllTeamLead`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
