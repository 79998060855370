/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import overdueReportActionTypes from './overDueReport.types';

/** ***************** GET PROGRESS REPORT DETAILS START *********************** */
export const getoverdueReportDetailsStart = (getData) => ({
	type: overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_START,
	payload: getData,
});

export const getoverdueReportDetailsSuccess = (successData) => ({
	type: overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getoverdueReportDetailsFailure = (failureData) => ({
	type: overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getoverdueReportDetailsResponseResetStart = () => ({
	type: overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_RESPONSE_RESET,
});

export const getoverdueReportDetailsResponseChanged = () => ({
	type: overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_RESPONSE_CHANGED,
});

/** ***************** Over Due report Excel *********************** */
export const fetchOverDueExcelSuccess = (user) => ({
	type: overdueReportActionTypes.FETCH_OVERDUE_SUCCESS,
	payload: user,
});

export const fetchOverDueExcelFailure = (error) => ({
	type: overdueReportActionTypes.FETCH_OVERDUE_EXCEL_FAILURE,
	payload: error,
});

export const fetchOverDueExcelStart = (userCredentials) => ({
	type: overdueReportActionTypes.FETCH_OVERDUE_EXCEL_START,
	payload: userCredentials,
});

export const fetchOverDueExcelReset = () => ({
	type: overdueReportActionTypes.FETCH_OVERDUE_EXCEL_RESPONSE_RESET,
});

export const fetchOverDueExcelResponseChanged = () => ({
	type: overdueReportActionTypes.FETCH_OVERDUE_EXCEL_RESPONSE_CHANGED,
});
/** ***************** GET PROGRESS REPORT DETAILS END *********************** */
