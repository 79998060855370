/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import { encodeJson } from '../../enode-decode';
import generalTypesAction from '../../genera.types';

import staffAllListActionTypes from './staffAllList.types';

import { getStaffAllList } from './staffAllList.services';
import {
	getStaffAllListSuccess,
	getStaffAllListFailure,
	getStaffAllListChangedAction,
} from './staffAllList.actions';

export function* getAllStaffListPage({ payload: { searchValueSet } }) {
	try {
		const jsonData = {
			InputFilter: searchValueSet,
		};
		const staffData = encodeJson(jsonData, generalTypesAction.FETCH_STAFF_ALLLIST);
		const responseData = yield call(getStaffAllList, staffData);
		// const responseData = yield call(getAllStaff, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(getStaffAllListFailure(responseData.data));
		} else {
			yield put(getStaffAllListSuccess(responseData.data));
			// const DecodeLoginData = JWT.decode(
			// 	responseData.data,
			// 	generalTypesAction.FETCH_STAFF_ALLLIST,
			// );
			// yield put(getStaffAllListSuccess(DecodeLoginData));
		}
	} catch (error) {
		yield put(getStaffAllListFailure(error));
	}
}

export function* getStaffAllListReset() {
	yield put(getStaffAllListChangedAction());
}

export function* getStaffAllListCall() {
	yield takeLatest(staffAllListActionTypes.STAFFLIST_START, getAllStaffListPage);
}

export function* getStaffAllListResetCall() {
	yield takeLatest(staffAllListActionTypes.STAFFLIST_RESET, getStaffAllListReset);
}
export function* staffListSaga() {
	yield all([call(getStaffAllListCall), call(getStaffAllListResetCall)]);
}
