/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import LoanApplicationTypes from './loanApplication.types';
import {
	loanapplicationAdd,
	loanapplicationUpdate,
	loanListGet,
	loanapplicationFetch,
	loanapplicationDelete,
	allLoansFetch,
	statusUpdateLoanApplication,
} from './loanApplication.service';

import {
	addLoanApplicationSuccess,
	addLoanApplicationFailure,
	addLoanApplicationResponseChanged,
	updateLoanApplicationSuccess,
	updateLoanApplicationFailure,
	updateLoanApplicationResponseChanged,
	getLoanListSuccess,
	getLoanListFailure,
	getLoanListResponseChanged,
	fetchLoanApplicationSuccess,
	fetchLoanApplicationFailure,
	fetchLoanApplicationResponseChanged,
	deleteLoanApplicationSuccess,
	deleteLoanApplicationFailure,
	deleteLoanApplicationResponseChanged,
	fetchAllLoanListSuccess,
	fetchAllLoanListFailure,
	fetchAllLoanListResponseChanged,
	statusUpdateLoanApplicationFailure,
	statusUpdateLoanApplicationSuccess,
	statusUpdateLoanApplicationResponseChanged,
} from './loanApplication.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD LOAN DETAILS  ******************** */
export function* createLoan({ payload }) {
	try {
		const key = 'AddLoanApplicationKey';
		const jsonData = {
			termsAndCondData: payload.termsCondition,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			language: payload.selectedLang,
			applicationModel: payload.BigImage,
			CreatedBy: payload.userID,
		};
		console.log('payload',payload);
		const encodeData = encodeJson(jsonData, key);
		
		const responseData = yield call(loanapplicationAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addLoanApplicationFailure(responseData.data));
		} else {
			yield put(addLoanApplicationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addLoanApplicationFailure(error));
	}
}

export function* resetAddLoanResponse() {
	yield put(addLoanApplicationResponseChanged());
}

export function* onCreateLoanApplication() {
	yield takeLatest(LoanApplicationTypes.ADD_LOAN_APPLICATION_START, createLoan);
}

export function* onCreateLoanResponseReset() {
	yield takeLatest(
		LoanApplicationTypes.ADD_LOAN_APPLICATION_RESPONSE_RESET,
		resetAddLoanResponse,
	);
}
/** *********************  END ADD LOAN DETAILS ******************** */

/** *********************  START UPDATE LOAN DETAILS  ******************** */
export function* loanUpdate({ payload }) {
	try {
		const key = 'UpdateLoanApplicationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			termsAndCondData: payload.termsCondition,
			DatabaseName: payload.databaseName,
			applicationModel: payload.BigImage,
			loanApplicationID: payload.loanApplicationID,
			language: payload.selectedLang,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		debugger;
		const responseData = yield call(loanapplicationUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanApplicationFailure(responseData.data));
		} else {
			yield put(updateLoanApplicationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanApplicationFailure(error));
	}
}

export function* resetUpdateLoanApplicationResponse() {
	yield put(updateLoanApplicationResponseChanged());
}

export function* onUpdateLoanApplication() {
	yield takeLatest(LoanApplicationTypes.UPDATE_LOAN_APPLICATION_START, loanUpdate);
}

export function* onUpdateLoanResponseReset() {
	yield takeLatest(
		LoanApplicationTypes.UPDATE_LOAN_APPLICATION_RESPONSE_RESET,
		resetUpdateLoanApplicationResponse,
	);
}
/** *********************  END UPDATE LOAN DETAILS ******************** */

/** *********************  START GET LOAN LIST  ******************** */
export function* loanApplicationListGet({ payload }) {
	try {
		const key = 'GetLoanApplicationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loanListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanListFailure(responseData.data));
		} else {
			yield put(getLoanListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanListFailure(error));
	}
}

export function* resetGetLoanListResponse() {
	yield put(getLoanListResponseChanged());
}

export function* onGetLoanListDetails() {
	yield takeLatest(LoanApplicationTypes.GET_LOAN_LIST_START, loanApplicationListGet);
}

export function* onGetLoanListResponseReset() {
	yield takeLatest(LoanApplicationTypes.GET_LOAN_LIST_RESPONSE_RESET, resetGetLoanListResponse);
}
/** *********************  END GET LOAN LIST ******************** */

/** *********************  START FETCH LOAN DETAILS  ******************** */
export function* fetchLoanData({ payload }) {
	try {
		const key = 'FetchLoanApplicationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			LoanApplicationID: payload.loanApplicationID,
			// language: payload.selectedLang,
			// applicationModel: payload.BigImage,
			// termsAndCondData: payload.termsCondition,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(loanapplicationFetch, encodeData);
		console.log('responseData', responseData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchLoanApplicationFailure(responseData.data));
		} else {
			yield put(fetchLoanApplicationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchLoanApplicationFailure(error));
	}
}

export function* resetFetchLoanApplicationResponse() {
	yield put(fetchLoanApplicationResponseChanged());
}

export function* onFetchLoanApplication() {
	yield takeLatest(LoanApplicationTypes.FETCH_LOAN_APPLICATION_START, fetchLoanData);
}

export function* onFetchLoanResponseReset() {
	yield takeLatest(
		LoanApplicationTypes.FETCH_LOAN_APPLICATION_RESPONSE_RESET,
		resetFetchLoanApplicationResponse,
	);
}
/** *********************  END FETCH LOAN DETAILS ******************** */

/** *********************  START DELETE LOAN DETAILS  ******************** */
export function* loanDelete({ payload }) {
	try {
		const key = 'DeleteLoanApplicationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			// UserName: payload.userName,
			applicationModel: payload.BigImage,
			termsAndCondData: payload.termsCondition,
			language: payload.selectedLang,
			LoanApplicationID: payload.loanApplicationID,



		};
		const encodeData = encodeJson(jsonData, key);
		
		const responseData = yield call(loanapplicationDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteLoanApplicationFailure(responseData.data));
		} else {
			yield put(deleteLoanApplicationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteLoanApplicationFailure(error));
	}
}

export function* resetDeleteLoanApplicationResponse() {
	yield put(deleteLoanApplicationResponseChanged());
}

export function* onDeleteLoanDetail() {
	yield takeLatest(LoanApplicationTypes.DELETE_LOAN_APPLICATION_START, loanDelete);
}

export function* onDeleteLoanResponseReset() {
	yield takeLatest(
		LoanApplicationTypes.DELETE_LOAN_APPLICATION_RESPONSE_RESET,
		resetDeleteLoanApplicationResponse,
	);
}
/** *********************  END DELETE LOAN DETAILS ******************** */

/** *********************  START FETCH ALL LOANS LIST  ******************** */
export function* fetchAllLoansList({ payload }) {
	try {
		const key = 'FetchLoanApplicationKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			
		};
		const encodeData = encodeJson(jsonData, key);
		
		const responseData = yield call(allLoansFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllLoanListFailure(responseData.data));
		} else {
			yield put(fetchAllLoanListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllLoanListFailure(error));
	}
}

export function* resetFetchAllLoansResponse() {
	yield put(fetchAllLoanListResponseChanged());
}

export function* onFetchAllLoansDetails() {
	yield takeLatest(LoanApplicationTypes.FETCH_ALL_LOAN_START, fetchAllLoansList);
}

export function* onFetchAllLoansResponseReset() {
	yield takeLatest(
		LoanApplicationTypes.FETCH_ALL_LOAN_RESPONSE_RESET,
		resetFetchAllLoansResponse,
	);
}
/** *********************  END FETCH ALL LOANS LIST ******************** */

/** *********************  START STATUS UPDATE LOAN APPLICATION  ******************** */
export function* statusUpdateLoanApplicationData({ payload }) {
	try {
		const key = 'StatusUpdateLoanApplicationKey';
		const jsonData = {
			UserID: payload.userID,
			LoanApplicationID: payload.loanApplicationID,
			IsApprove: payload.isApprove,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(statusUpdateLoanApplication, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(statusUpdateLoanApplicationFailure(responseData.data));
		} else {
			yield put(statusUpdateLoanApplicationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(statusUpdateLoanApplicationFailure(error));
	}
}

export function* resetStatusUpdateLoanApplicationResponse() {
	yield put(statusUpdateLoanApplicationResponseChanged());
}

export function* onStatusUpdateLoanApplicationDetails() {
	yield takeLatest(
		LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_START,
		statusUpdateLoanApplicationData,
	);
}

export function* onStatusUpdateLoanApplicationResponseReset() {
	yield takeLatest(
		LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_RESPONSE_RESET_START,
		resetStatusUpdateLoanApplicationResponse,
	);
}
/** *********************  END STATUS UPDATE LOAN APPLICATION  ******************** */

export function* loanMasterSaga() {
	yield all([
		call(onCreateLoanApplication),
		call(onCreateLoanResponseReset),
		call(onUpdateLoanApplication),
		call(onUpdateLoanResponseReset),
		call(onGetLoanListDetails),
		call(onGetLoanListResponseReset),
		call(onFetchLoanApplication),
		call(onFetchLoanResponseReset),
		call(onDeleteLoanDetail),
		call(onDeleteLoanResponseReset),
		call(onFetchAllLoansDetails),
		call(onFetchAllLoansResponseReset),
		call(onStatusUpdateLoanApplicationDetails),
		call(onStatusUpdateLoanApplicationResponseReset),
	]);
}
