/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CollectionActionTypes from './collection.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getOfficerListCentersResponse: null,
	getOfficerListCentersArr: [],
	getOfficerListArrearCentersResponse: null,
	getOfficerListArrearCentersArr: [],
	getBranchCollectionUsersListResponse: null,
	getBranchCollectionUsersListArr: [],
	getCenterListOfficerCollectionsResponse: null,
	getCenterListOfficerCollectionsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CollectionActionTypes.GET_OFFICER_LIST_CENTERS_SUCCESS:
			return {
				...state,
				getOfficerListCentersResponse: action.payload,
				getOfficerListCentersArr: docodeResponse(action.payload, 'centerListForOfficerKey'),
			};

		case CollectionActionTypes.GET_OFFICER_LIST_CENTERS_FAILURE:
			return { ...state, getOfficerListCentersResponse: action.payload };

		case CollectionActionTypes.GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED:
			return { ...state, getOfficerListCentersResponse: null };

		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS:
			return {
				...state,
				getOfficerListArrearCentersResponse: action.payload,
				getOfficerListArrearCentersArr: docodeResponse(
					action.payload,
					'arrearCenterListForOfficerKey',
				),
			};

		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE:
			return { ...state, getOfficerListArrearCentersResponse: action.payload };

		case CollectionActionTypes.GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED:
			return { ...state, getOfficerListArrearCentersResponse: null };

		case CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS:
			return {
				...state,
				getBranchCollectionUsersListResponse: action.payload,
				getBranchCollectionUsersListArr: docodeResponse(
					action.payload,
					'branchCollectionUsersListKey',
				),
			};

		case CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_FAILURE:
			return { ...state, getBranchCollectionUsersListResponse: action.payload };

		case CollectionActionTypes.GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchCollectionUsersListResponse: null };

		case CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS:
			return {
				...state,
				getCenterListOfficerCollectionsResponse: action.payload,
				getCenterListOfficerCollectionsArr: docodeResponse(
					action.payload,
					'centerListOfficerCollectionsKey',
				),
			};

		case CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE:
			return { ...state, getCenterListOfficerCollectionsResponse: action.payload };

		case CollectionActionTypes.GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED:
			return { ...state, getCenterListOfficerCollectionsResponse: null };

		default:
			return state;
	}
};
