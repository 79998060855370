/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import executiveActionTypes from './executive.types';

const INITIAL_STATE = {
	executiveStatus: null,
	executiveResponse: null,
	executiveCreateStatus: null,
	executiveCreateResponse: null,
	executiveDeleteStatus: null,
	executiveDeleteResponse: null,
	memberAllocationStatus: null,
	memberAllocationResponse: null,
};

const executiveReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				executiveCreateResponse: action.payload,
				executiveCreateStatus: 'Success',
			};
		case executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				executiveCreateResponse: action.payload,
				executiveCreateStatus: 'Failure',
			};
		case executiveActionTypes.EXECUTIVE_CREATE_REPSONSE_CHANGED:
			return { ...state, executiveCreateResponse: null, executiveCreateStatus: null };

		case executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_SUCCESS:
			return {
				...state,
				executiveDeleteResponse: action.payload,
				executiveDeleteStatus: 'Success',
			};
		case executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_FAILURE:
			return {
				...state,
				executiveDeleteResponse: action.payload,
				executiveDeleteStatus: 'Failure',
			};
		case executiveActionTypes.EXECUTIVE_DELETE_REPSONSE_CHANGED:
			return { ...state, executiveDeleteResponse: null, executiveDeleteStatus: null };
		case executiveActionTypes.EXECUTIVE_LIST_SUCCESS:
			return {
				...state,
				executiveStatus: 'Success',
				executiveResponse: action.payload,
			};
		case executiveActionTypes.EXECUTIVE_LIST_FAILURE:
			return {
				...state,
				executiveStatus: 'Failure',
				executiveResponse: action.payload,
			};
		case executiveActionTypes.EXECUTIVE_LIST_CHANGED:
			return {
				...state,
				executiveStatus: null,
				executiveResponse: null,
			};
		case executiveActionTypes.ALLOCATION_LIST_SUCCESS:
			return {
				...state,
				memberAllocationStatus: 'Success',
				memberAllocationResponse: action.payload,
			};
		case executiveActionTypes.ALLOCATION_LIST_FAILURE:
			return {
				...state,
				memberAllocationStatus: 'Failure',
				memberAllocationResponse: action.payload,
			};
		case executiveActionTypes.ALLOCATION_LIST_CHANGED:
			return {
				...state,
				memberAllocationStatus: null,
				memberAllocationResponse: null,
			};
		case executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_SUCCESS:
			return {
				...state,
				allocateMemberToStaffStatus: 'Success',
				allocateMemberToStaffResponse: action.payload,
			};
		case executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_FAILURE:
			return {
				...state,
				allocateMemberToStaffStatus: 'Failure',
				allocateMemberToStaffResponse: action.payload,
			};
		case executiveActionTypes.ALLOCATE_MEMBER_TO_STAFF_CHANGED:
			return {
				...state,
				allocateMemberToStaffStatus: null,
				allocateMemberToStaffResponse: null,
			};

		case executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_SUCCESS:
			return {
				...state,
				transferMemberToStaffStatus: 'Success',
				transferMemberToStaffResponse: action.payload,
			};
		case executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_FAILURE:
			return {
				...state,
				transferMemberToStaffStatus: 'Failure',
				transferMemberToStaffResponse: action.payload,
			};
		case executiveActionTypes.TRANSFER_MEMBER_TO_STAFF_CHANGED:
			return {
				...state,
				transferMemberToStaffStatus: null,
				transferMemberToStaffResponse: null,
			};
		default:
			return state;
	}
};

export default executiveReducer;
