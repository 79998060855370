const BranchDetailsActionTypes = {
	ADD_BUSINESS_BRANCH_START: 'add_business_branch_start',
	ADD_BUSINESS_BRANCH_SUCCESS: 'add_business_branch_success',
	ADD_BUSINESS_BRANCH_FAILURE: 'add_business_branch_failure',
	ADD_BUSINESS_BRANCH_RESPONSE_RESET: 'add_business_branch_response_reset',
	ADD_BUSINESS_BRANCH_RESPONSE_CHANGED: 'add_business_branch_response_changed',

	FETCH_BUSINESS_BRANCH_START: 'fetch_business_branch_start',
	FETCH_BUSINESS_BRANCH_SUCCESS: 'fetch_business_branch_success',
	FETCH_BUSINESS_BRANCH_FAILURE: 'fetch_business_branch_failure',
	FETCH_BUSINESS_BRANCH_RESPONSE_RESET: 'fetch_business_branch_response_reset',
	FETCH_BUSINESS_BRANCH_RESPONSE_CHANGED: 'fetch_business_branch_response_changed',

	UPDATE_BUSINESS_BRANCH_START: 'update_business_branch_start',
	UPDATE_BUSINESS_BRANCH_SUCCESS: 'update_business_branch_success',
	UPDATE_BUSINESS_BRANCH_FAILURE: 'update_business_branch_failure',
	UPDATE_BUSINESS_BRANCH_RESPONSE_RESET: 'update_business_branch_response_reset',
	UPDATE_BUSINESS_BRANCH_RESPONSE_CHANGED: 'update_business_branch_response_changed',
};
export default BranchDetailsActionTypes;
