/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const selectUser = (state) => state.login;

export const selectCurrentUser = createSelector([selectUser], (login) => login.loginResponse);

export const selectCurrentDecodedUser = createSelector(
	[selectUser],
	(login) => login.loginDeocodedResponse,
);

// export const getLoginDetails = createSelector([selectUser], (login) => {
// 	const { loginDeocodedResponse } = login;

// 	let userID = '';
// 	let companyID = '';
// 	let databaseName = '';
// 	if (loginDeocodedResponse !== null && loginDeocodedResponse !== undefined) {
// 		const loginData = loginDeocodedResponse.data[0];
// 		userID = loginData.UserID;
// 		companyID = loginData.CompanyID;
// 		databaseName = loginData.DatabaseName;
// 	}
// 	return [userID, companyID, databaseName];
// });

export const getLoginObjDetails = createSelector([selectUser], (login) => {
	const { loginDeocodedResponse, currencySymbols } = login;
	let loginObj = '';
	if (loginDeocodedResponse !== null && loginDeocodedResponse !== undefined) {
		const loginData = loginDeocodedResponse.data[0];
		loginObj = {
			userID: loginData.UserID,
			userName: loginData.UserName,
			databaseName: loginData.DatabaseName,
			userAccessBranchID: loginData.UserAccessBranchID,
			userRole: loginData.UserRoleName,
			userLevel: loginData.UserLevel,
			generalCompanyID: loginData.GeneralCompanyID,
			limit: 0,
			branchIDs:
				loginData.BranchAccessIDs !== undefined && loginData.BranchAccessIDs.length > 0
					? loginData.BranchAccessIDs
					: 'All',
			isAuthenticationNeeded: loginData.Company[0].isAuthenticationNeeded,
			isNbfcLogin: loginData.Company[0].ProvisionType === 'NBFC' ? 1 : 0,
			companyID: loginData.Company[0].CompanyID,
			companyName: loginData.Company[0].CompanyName,
			infogCompanyID: loginData.Company[0].InfogID,
			isWorkFlowManagementNeeded: loginData.Company[0].isWorkFlowManagementNeeded,
			ISHighMarkCheck: loginData.Company[0].ISHighMarkCheck,
			isThrift: loginData.Company[0].isThrift,
			IsTenureClosedArrearsinDemandCollectionReport:
				loginData.Company[0].IsTenureClosedArrearsinDemandCollectionReport,
			IsActiveLoanArrearsinDemandCollectionReport:
				loginData.Company[0].IsActiveLoanArrearsinDemandCollectionReport,
			currency: currencySymbols,
			ProvisionType: loginData.Company[0].ProvisionType,
		};
	}
	return loginObj;
});
