/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const getAllStaff = (staffData) => {
	const data = {
		FetchStaffBasedOnInputToken: staffData,
	};
	const url = `${API_URL}GetAllStaffBasedOnInput`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createTeamStaff = (StaffData) => {
	const data = {
		AddNewStaffDetailsForTeamToken: StaffData,
	};
	const url = `${API_URL}AddTeamStaff`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteTeamStaff = (StaffData) => {
	const data = {
		DeleteExistTeamStaffToken: StaffData,
	};
	const url = `${API_URL}DeleteTeamStaff`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getTeamBasedOnStaff = (StaffData) => {
	const data = {
		FetchStaffWithTeamIDToken: StaffData,
	};
	const url = `${API_URL}GetStaffBasedOnTeam`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getManagerList = (StaffData) => {
	const data = {
		FetchManagerToken: StaffData,
	};
	const url = `${API_URL}GetAllManagerList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// export const getAllTeamLeads = (StaffData) => {
// 	const data = {
// 		FetchTeamLeadToken: StaffData,
// 	};
// 	const url = `${API_URL}GetAllTeamLead`;
// 	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
// };
