/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import memberCollectionActionTypes from './memberCollection.types';
import { fetchSearchMemberLoanList, fetchMemberLoanInfo } from './memberCollection.service';

import {
	getSearchMemberLoanListSuccess,
	getSearchMemberLoanListFailure,
	getSearchMemberLoanListResponseChanged,
	getMemberLoanInfoSuccess,
	getMemberLoanInfoFailure,
	getMemberLoanInfoResponseChanged,
} from './memberCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START SEARCH MEMBER LOAN LIST DETAILS  ******************** */
export function* getSearchMemberLoanList({ payload }) {
	try {
		const key = 'searchMemberLoanListKey';
		const jsonData = {
			searchMemberValue: payload.searchMemberValue,
			searchMemberType: payload.searchMemberType,
			MemberCompanyID: payload.MemberCompanyID,
			UserID: payload.UserID,
			UserRole: payload.UserRole,
			Level: payload.Level,
			DatabaseName: payload.DatabaseName,
			ProvisionType: payload.ProvisionType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchSearchMemberLoanList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getSearchMemberLoanListFailure(responseData.data));
		} else {
			yield put(getSearchMemberLoanListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getSearchMemberLoanListFailure(error));
	}
}

export function* resetGetSearchMemberLoanListResponse() {
	yield put(getSearchMemberLoanListResponseChanged());
}

export function* onGetSearchMemberLoanListDetails() {
	yield takeLatest(
		memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_START,
		getSearchMemberLoanList,
	);
}

export function* onGetSearchMemberLoanListResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET,
		resetGetSearchMemberLoanListResponse,
	);
}
/** *********************  END GET SEARCH MEMBER LOAN LIST  ******************** */

/** *********************  START MEMBER LOAN INFO DETAILS  ******************** */
export function* getMemberLoanInfo({ payload }) {
	try {
		const key = 'LoanInfoMemberLoanListKey';
		const jsonData = {
			LoanIndexGroupMemberID: payload.LoanIndexGroupMemberID,
			memberID: payload.memberID,
			companyID: payload.companyID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchMemberLoanInfo, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberLoanInfoFailure(responseData.data));
		} else {
			yield put(getMemberLoanInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberLoanInfoFailure(error));
	}
}

export function* resetGetMemberLoanInfoResponse() {
	yield put(getMemberLoanInfoResponseChanged());
}

export function* onGetMemberLoanInfoDetails() {
	yield takeLatest(memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_START, getMemberLoanInfo);
}

export function* onGetMemberLoanInfoResponseReset() {
	yield takeLatest(
		memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_RESET,
		resetGetMemberLoanInfoResponse,
	);
}
/** *********************  END GET MEMBER LOAN INFO  ******************** */

export function* memberCollectionSaga() {
	yield all([
		call(onGetSearchMemberLoanListDetails),
		call(onGetSearchMemberLoanListResponseReset),
		call(onGetMemberLoanInfoDetails),
		call(onGetMemberLoanInfoResponseReset),
	]);
}
