const RequestReportActionTypes = {

	FETCH_REQUEST_REPORT_DETAILS_START: 'fetch_request_report_details_start',
	FETCH_REQUEST_REPORT_DETAILS_SUCCESS: 'fetch_request_report_details_success',
	FETCH_REQUEST_REPORT_DETAILS_FAILURE: 'fetch_request_report_details_failure',
	FETCH_REQUEST_REPORT_DETAILS_RESPONSE_RESET: 'fetch_request_report_details_response_reset',
	FETCH_REQUEST_REPORT_DETAILS_RESPONSE_CHANGED: 'fetch_request_report_details_response_changed',

	ADD_REQUEST_REPORT_DETAILS_START: 'add_request_report_details_start',
	ADD_REQUEST_REPORT_DETAILS_SUCCESS: 'add_request_report_details_success',
	ADD_REQUEST_REPORT_DETAILS_FAILURE: 'add_request_report_details_failure',
	ADD_REQUEST_REPORT_DETAILS_RESPONSE_RESET: 'add_request_report_details_response_reset',
	ADD_REQUEST_REPORT_DETAILS_RESPONSE_CHANGED: 'add_request_report_details_response_changed',
}
export default RequestReportActionTypes;