/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const createBusinessDetails = (businessData) => {
	const data = {
		AddBusinessTokenDetails: businessData,
	};
	const url = `${API_URL}addBusinessDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateBusinessDetails = (updateData) => {
	const data = {
		UpdateBusinessDetailsToken: updateData,
	};
	const url = `${API_URL}updateBusinessDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBusinessDetails = (fetchData) => {
	const data = {
		GetBusinessDetailsToken: fetchData,
	};
	const url = `${API_URL}getBusinessDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteBusinessDetails = (deleteData) => {
	const data = {
		DeleteBusinessDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteBusinessDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const businessListGet = (listData) => {
	const data = {
		GetBusinessListToken: listData,
	};
	const url = `${API_URL}getBusinessDetailsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
