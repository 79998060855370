const BusinessLoanDisbursementActionTypes = {
	FETCH_BUSINESS_LOAN_INDEX_START: 'fetch_business_loan_index_start',
	FETCH_BUSINESS_LOAN_INDEX_SUCCESS: 'fetch_business_loan_index_success',
	FETCH_BUSINESS_LOAN_INDEX_FAILURE: 'fetch_business_loan_index_failure',
	FETCH_BUSINESS_LOAN_INDEX_RESPONSE_RESET: 'fetch_business_loan_index_response_reset',
	FETCH_BUSINESS_LOAN_INDEX_RESPONSE_CHANGED: 'fetch_business_loan_index_response_changed',

	FETCH_INSTRUMENT_LIST_START: 'fetch_instrument_list_start',
	FETCH_INSTRUMENT_LIST_SUCCESS: 'fetch_instrument_list_success',
	FETCH_INSTRUMENT_LIST_FAILURE: 'fetch_instrument_list_failure',
	FETCH_INSTRUMENT_LIST_RESPONSE_RESET: 'fetch_instrument_list_response_reset',
	FETCH_INSTRUMENT_LIST_RESPONSE_CHANGED: 'fetch_instrument_list_response_changed',

	FETCH_FINANCE_BASED_ACCOUNT_TYPE_START: 'fetch_finance_based_account_type_start',
	FETCH_FINANCE_BASED_ACCOUNT_TYPE_SUCCESS: 'fetch_finance_based_account_type_success',
	FETCH_FINANCE_BASED_ACCOUNT_TYPE_FAILURE: 'fetch_finance_based_account_type_failure',
	FETCH_FINANCE_BASED_ACCOUNT_TYPE_RESPONSE_RESET:
		'fetch_finance_based_account_type_response_reset',
	FETCH_FINANCE_BASED_ACCOUNT_TYPE_RESPONSE_CHANGED:
		'fetch_finance_based_account_type_response_changed',

	BUSINESS_LOAN_DISBURSEMENT_START: 'business_loan_disbursement_start',
	BUSINESS_LOAN_DISBURSEMENT_SUCCESS: 'business_loan_disbursement_success',
	BUSINESS_LOAN_DISBURSEMENT_FAILURE: 'business_loan_disbursement_failure',
	BUSINESS_LOAN_DISBURSEMENT_RESPONSE_RESET: 'business_loan_disbursement_response_reset',
	BUSINESS_LOAN_DISBURSEMENT_RESPONSE_CHANGED: 'business_loan_disbursement_response_changed',

	FETCH_VOUCHAR_COUNT_START: 'fetch_vouchar_count_start',
	FETCH_VOUCHAR_COUNT_SUCCESS: 'fetch_vouchar_count_success',
	FETCH_VOUCHAR_COUNT_FAILURE: 'fetch_vouchar_count_failure',
	FETCH_VOUCHAR_COUNT_RESPONSE_RESET: 'fetch_vouchar_count_response_reset',
	FETCH_VOUCHAR_COUNT_RESPONSE_CHANGED: 'fetch_vouchar_count_response_changed',

	FETCH_BUSINESS_LOAN_INFO_DETAILS_START: 'fetch_business_loan_info_details_start',
	FETCH_BUSINESS_LOAN_INFO_DETAILS_SUCCESS: 'fetch_business_loan_info_details_success',
	FETCH_BUSINESS_LOAN_INFO_DETAILS_FAILURE: 'fetch_business_loan_info_details_failure',
	FETCH_BUSINESS_LOAN_INFO_DETAILS_RESPONSE_RESET:
		'fetch_business_loan_info_details_response_reset',
	FETCH_BUSINESS_LOAN_INFO_DETAILS_RESPONSE_CHANGED:
		'fetch_business_loan_info_details_response_changed',
};
export default BusinessLoanDisbursementActionTypes;
