/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BusinessFundActionTypes from './business-fund.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addBusinessFundDetailsResponse: null,
	updateBusinessFundDetailsResponse: null,
	getBusinessFundListResponse: null,
	getBusinessFundListArr: [],
	fetchBusinessFundDetailsResponse: null,
	fetchBusinessFundDetailsArr: [],
	deleteBusinessFundDetailsResponse: null,
	fetchAllBusinessFundListResponse: null,
	fetchAllBusinessFundListArr: [],
};

const businessfundReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_SUCCESS:
			return { ...state, addBusinessFundDetailsResponse: action.payload };

		case BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_FAILURE:
			return { ...state, addBusinessFundDetailsResponse: action.payload };

		case BusinessFundActionTypes.ADD_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED:
			return { ...state, addBusinessFundDetailsResponse: null };

		case BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_SUCCESS:
			return { ...state, updateBusinessFundDetailsResponse: action.payload };

		case BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_FAILURE:
			return { ...state, updateBusinessFundDetailsResponse: action.payload };

		case BusinessFundActionTypes.UPDATE_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateBusinessFundDetailsResponse: null };

		case BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_SUCCESS:
			return {
				...state,
				getBusinessFundListResponse: action.payload,
				getBusinessFundListArr: docodeResponse(action.payload, 'GetBusinessFundListKey'),
			};

		case BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_FAILURE:
			return { ...state, getBusinessFundListResponse: action.payload };

		case BusinessFundActionTypes.GET_BUSINESS_FUND_LIST_RESPONSE_CHANGED:
			return { ...state, getBusinessFundListResponse: null };

		case BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_SUCCESS:
			return {
				...state,
				fetchBusinessFundDetailsResponse: action.payload,
				fetchBusinessFundDetailsArr: docodeResponse(action.payload, 'FetchBusinessFundDetailsKey'),
			};

		case BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_FAILURE:
			return { ...state, fetchBusinessFundDetailsResponse: action.payload };

		case BusinessFundActionTypes.FETCH_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchBusinessFundDetailsResponse: null, fetchBusinessFundDetailsArr: [] };

		case BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_SUCCESS:
			return { ...state, deleteBusinessFundDetailsResponse: action.payload };

		case BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_FAILURE:
			return { ...state, deleteBusinessFundDetailsResponse: action.payload };

		case BusinessFundActionTypes.DELETE_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteBusinessFundDetailsResponse: null };

		case BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_SUCCESS:
			return {
				...state,
				fetchAllBusinessFundListResponse: action.payload,
				fetchAllBusinessFundListArr: docodeResponse(action.payload, 'FetchAllBusinessFundsKey'),
			};

		case BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_FAILURE:
			return { ...state, fetchAllBusinessFundListResponse: action.payload };

		case BusinessFundActionTypes.FETCH_ALL_BUSINESS_FUND_RESPONSE_CHANGED:
			return { ...state, fetchAllBusinessFundListResponse: null };

		default:
			return state;
	}
};
 export default businessfundReducer;