/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import futureDemandReportTypes from './future-demand-report.types';

/** ***************** FETCH FUTURE DEMAND COLLECTION REPORT DETAILS START *********************** */
export const fetchFutureDemandReportStart = (addData) => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_START,
	payload: addData,
});

export const fetchFutureDemandReportSuccess = (successData) => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_SUCCESS,
	payload: successData,
});

export const fetchFutureDemandReportFailure = (failureData) => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_FAILURE,
	payload: failureData,
});

export const fetchFutureDemandReportResponseResetStart = () => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_RESPONSE_RESET_START,
});

export const fetchFutureDemandReportResponseResetChanged = () => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH FUTURE DEMAND COLLECTION REPORT DETAILS END *********************** */


/** ***************** FETCH FUTURE DEMAND COLLECTION REPORT EXCEL DETAILS START *********************** */
export const fetchFutureDemandReportExcelStart = (addData) => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchFutureDemandReportExcelSuccess = (successData) => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchFutureDemandReportExcelFailure = (failureData) => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchFutureDemandReportResponseExcelResetStart = () => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_RESPONSE_EXCEL_RESET_START,
});

export const fetchFutureDemandReportResponseExcelResetChanged = () => ({
	type: futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_RESPONSE_EXCEL_CHANGED,
});

/** ***************** FETCH FUTURE DEMAND COLLECTION REPORT EXCEL DETAILS END *********************** */
