/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import TodayCollectionActionTypes from './todayCollection.types';

/** ***************** GET BRANCH OFFICER CENTERS LIST START *********************** */
export const fetchTodayCollectionListStart = (getData) => ({
	type: TodayCollectionActionTypes.GET_TODAY_COLLECTION_LIST_START,
	payload: getData,
});

export const getTodayCollectionListSuccess = (successData) => ({
	type: TodayCollectionActionTypes.GET_TODAY_COLLECTION_LIST_SUCCESS,
	payload: successData,
});

export const getTodayCollectionListFailure = (failureData) => ({
	type: TodayCollectionActionTypes.GET_TODAY_COLLECTION_LIST_FAILURE,
	payload: failureData,
});

export const fetchTodayCollectionListResponseResetStart = () => ({
	type: TodayCollectionActionTypes.GET_TODAY_COLLECTION_LIST_RESPONSE_RESET,
});

export const getTodayCollectionListResponseChanged = () => ({
	type: TodayCollectionActionTypes.GET_TODAY_COLLECTION_LIST_RESPONSE_CHANGED,
});
/** ***************** GET TODAY COLLECTION LIST END *********************** */
