/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import NPAReportTypes from './npa-report.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchNPADetailResponse: null,
	fetchNPADetailArr: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NPAReportTypes.FETCH_NPA_REPORT_SUCCESS:
			return {
				...state,
				fetchNPADetailResponse: action.payload,
				fetchNPADetailArr: docodeResponse(action.payload, 'GetNPAReportDetailsKey'),
			};

		case NPAReportTypes.FETCH_NPA_REPORT_FAILURE:
			return { ...state, fetchNPADetailResponse: action.payload };

		case NPAReportTypes.FETCH_NPA_REPORT_RESPONSE_CHANGED:
			return { ...state, fetchNPADetailResponse: null };

		default:
			return state;
	}
};
