/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BranchCollectionReportActionTypes from './branchCollectionReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getBranchCollectionReportListResponse: null,
	getBranchCollectionReportListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_SUCCESS:
			return {
				...state,
				getBranchCollectionReportListResponse: action.payload,
				getBranchCollectionReportListArr: docodeResponse(
					action.payload,
					'GetBranchCollectionReportKey',
				),
			};

		case BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_FAILURE:
			return { ...state, getBranchCollectionReportListResponse: action.payload };

		case BranchCollectionReportActionTypes.GET_BRANCH_COLLECTION_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getBranchCollectionReportListResponse: null };
		default:
			return state;
	}
};
