/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import LoanActionTypes from './goldLoanProposal.types';
import {
	goldLoanProposalListGet,
	goldLoanProposalDelete,
	updateGoldLoan,
	goldLoanProposalDetailsFetch,
	createNewGoldLoanProposal,
	goldLoanProposalGroupOfficerFetch,
	approveGoldLoanProposal,
	fetchGoldLoanProposalGroup,
	fetchGoldLoanProposalGroupMember
} from './goldLoanProposal.service';

import {
	addGoldLoanProposalSuccess,
	addGoldLoanProposalFailure,
	addGoldLoanProposalResponseChanged,
	getGoldLoanProposalFailure,
	getGoldLoanProposalSuccess,
	getGoldLoanProposalListResponseChanged,
	deleteLoanProposalFailure,
	deleteLoanProposalSuccess,
	deleteLoanProposalResponseChanged,
	updateLoanProposalSuccess,
	updateLoanProposalFailure,
	updateLoanProposalResponseChanged,
	fetchGoldLoanProposalSuccess,
	fetchGoldLoanProposalFailure,
	fetchGoldLoanProposalResponseChanged,
	fetchGoldLoanProposalGroupOfficerSuccess,
	fetchGoldLoanProposalGroupOfficerFailure,
	fetchGoldLoanProposalGroupOfficerResponseChanged,
	approveLoanProposalSuccess,
	approveLoanProposalFailure,
	approveLoanProposalResponseChanged,
	fetchGoldLoanProposalGroupSuccess,
	fetchGoldLoanProposalGroupFailure,
	fetchGoldLoanProposalGroupResponseChanged,
	fetchGoldLoanProposalGroupMemberSuccess,
	fetchGoldLoanProposalGroupMemberFailure,
	fetchGoldLoanProposalGroupMemberResponseChanged,
} from './goldLoanProposal.action';
import { encodeJson } from '../../../enode-decode';

/**   ******************* GET GOLD LOAN PROPOSAL *************** */
export function* goldLoanProposalGet({ payload }) {
	try {
		const key = 'GetGoldLoanProposalKey';
		const jsonData = {
			BranchID: payload.branchID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			LoanIndexType: payload.loanIndexType,
			UserID: payload.userID,
			Level: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(goldLoanProposalListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getGoldLoanProposalFailure(responseData.data));
		} else {
			yield put(getGoldLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getGoldLoanProposalFailure(error));
	}
}

export function* resetGetGoldLoanProposalResponse() {
	yield put(getGoldLoanProposalListResponseChanged());
}

export function* onGetGoldLoanProposal() {
	yield takeLatest(LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_START, goldLoanProposalGet);
}

export function* onGetGoldLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_RESPONSE_RESET,
		resetGetGoldLoanProposalResponse,
	);
}

/** *********************  START DELETE GOLD LOAN PROPOSAL   ******************** */
export function* deleteLoanProposalGoldLoan({ payload }) {
	try {
		const key = 'DeleteGoldLoanProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			goldLoanID: payload.GoldLoanID,
		};

		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(goldLoanProposalDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteLoanProposalFailure(responseData.data));
		} else {
			yield put(deleteLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteLoanProposalFailure(error));
	}
}

export function* resetDeleteGoldLoanProposalResponse() {
	yield put(deleteLoanProposalResponseChanged());
}

export function* onDeleteGoldLoanProposalDetail() {
	yield takeLatest(LoanActionTypes.DELETE_LOAN_PROPOSAL_START, deleteLoanProposalGoldLoan);
}

export function* onDeleteBusinessLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetDeleteGoldLoanProposalResponse,
	);
}
/** *********************  END DELETE GOLD LOAN PROPOSAL  ******************** */

/** *********************  START FETCH GOLD LOAN PROPOSAL  ******************** */
export function* fetchGoldLoanProposalData({ payload }) {
	try {
		const key = 'FetchGoldLoanProposalKey';
		const jsonData = {
			CompanyID: payload.companyID,
			GoldLoanID: payload.GoldLoanID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(goldLoanProposalDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGoldLoanProposalFailure(responseData.data));
		} else {
			yield put(fetchGoldLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGoldLoanProposalFailure(error));
	}
}

export function* resetFetchGoldLoanLoanProposalResponse() {
	yield put(fetchGoldLoanProposalResponseChanged());
}

export function* onFetchGoldLoanProposalDetails() {
	yield takeLatest(LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_START, fetchGoldLoanProposalData);
}

export function* onFetchGoldLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_RESPONSE_RESET,
		resetFetchGoldLoanLoanProposalResponse,
	);
}

/** ****************** START UPDATE GOLD LOAN PROPOSAL ******************** */

export function* GoldLoanProposalUpdate({ payload }) {
	try {
		const key = 'UpdateGoldLoanProposalKey';
		const jsonData = {
			GoldLoanID : payload.GoldLoanID,
			GoldLoanUID: payload.GoldLoanUID,
			AreaID: payload.LoanareaID,
			BranchID: payload.LoanbranchID,
			LoangroupID: payload.LoangroupID,
			MemberID: payload.LoanmemberID,
			UniqueAreaID: payload.UniqueAreaID,
			UniqueBranchID: payload.UniqueBranchID,
			UniqueGroupID: payload.UniqueGroupID,
			TotalLoanAmount: payload.TotalLoanAmount, 
			LoanDisbursementDate: payload.LoanDisbursementDate,
			LoanProposalDate: payload.LoanProposalDate,
			LoanStatus: payload.LoanStatus,
			LoancompanyID: payload.LoancompanyID,
			IsLoanApproved: payload.IsLoanApproved,
			LoanStartDate: payload.LoanStartDate,
			LoanEndDate: payload.LoanEndDate,
			LoanIndexCreatedDate: payload.LoanIndexCreatedDate,
			LoanType: payload.LoanType,
			LoanPeriod: payload.LoanPeriod,
			GoldOverallImage: payload.GoldOverallImage,
			noticedate1: payload.noticedate1,
			noticeAmount1: payload.noticeAmount1,
			noticedate2: payload.noticedate2,
			noticeAmount2: payload.noticeAmount2,
			noticedate3: payload.noticedate3,
			noticeAmount3: payload.noticeAmount3,
			ProductID: payload.ProductID,
			ProductName: payload.ProductName,
			MeetupLocation: payload.MeetupLocation,
			MeetupLat: payload.MeetupLat,
			MeetupLong: payload.MeetupLong,
			MeetupTime: payload.MeetupTime,
			GroupOfficerID: payload.GroupOfficerID,
			GroupOfficerName: payload.GroupOfficerName,
			Funder: payload.Funder,
			FunderName: payload.FunderName,
			TranscationType: payload.TranscationType,
			IsClosed: payload.IsClosed,
			LoanAmount: payload.LoanAmount,
			OutstandingPrincipal: payload.OutstandingPrincipal,
			InterestPercentage: payload.InterestPercentage,
			outstandingInterest: payload.OutstandingInterest,
			NextDueNo: payload.NextDueNo,
			NoticeAmount: payload.NoticeAmount,
			NoticeDays: payload.NoticeDays,
			NoticeType: payload.NoticeType,
			NumberOfDueLeft: payload.NumberOfDueLeft,
			PurposeOfLoanID: payload.purposeOfLoanID,
			NoticeDayDiff: payload.NoticeDayDiff,
			isNotice: payload.isNotice,
			MemberUID: payload.MemberUID,
			MemberPhone: payload.MemberPhone,
			MemberAadarNum: payload.MemberAadarNum,
			MemberName: payload.MemberName,
			uniqueMemberID: payload.uniqueMemberID,
			MemberPhoneNum: payload.MemberPhoneNum,
			MemberAadharNum: payload.MemberAadharNum,
			MemberCenter: payload.MemberCenter,
			MemberCenterUID: payload.MemberCenterUID,
			IsAllTrainingAttended: payload.IsAllTrainingAttended,
			IsCBCheckDone: payload.IsCBCheckDone,
			CenterName: payload.CenterName,
			UniqueCenterID: payload.UniqueCenterID,
			BranchName: payload.BranchName,
			GoldProductInterest: payload.GoldProductInterest,
			CurrentInterestAmount: payload.CurrentInterestAmount,
			GoldLoanProductArr: JSON.stringify(payload.GoldProductList).replace(/"/g, "'"),
			AddedOn: payload.AddedOn,
			AddedBy: payload.AddedBy,
			isView: payload.isView,
			CompanyID: payload.companyID,
			CreatedUser: payload.userID,
			ModifiedUser: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateGoldLoan, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateLoanProposalFailure(responseData.data));
		} else {
			yield put(updateLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateLoanProposalFailure(error));
	}
}

export function* resetUpdateGoldLoanProposalResponse() {
	yield put(updateLoanProposalResponseChanged());
}

export function* onUpdateGoldLoanProposal() {
	yield takeLatest(LoanActionTypes.UPDATE_LOAN_PROPOSAL_START, GoldLoanProposalUpdate);
}

export function* onUpdateGoldLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetUpdateGoldLoanProposalResponse,
	);
}
/** *********************  END UPDATE GOLD LOAN PROPOSAL  ******************** */

/** *********************  START ADD GOLD LOAN PROPOSAL   ******************** */

export function* createNewGoldLoan({ payload }) {
	try {
		const key = 'AddGoldLoanProposalKey';
		const jsonData = {
			goldLoanID: payload.GoldLoanID,
			GoldLoanUID: payload.GoldLoanUID,
			LoanareaID: payload.LoanareaID,
			LoanbranchID: payload.LoanbranchID,
			LoangroupID: payload.LoangroupID,
			LoanmemberID: payload.LoanmemberID,
			UniqueAreaID: payload.UniqueAreaID,
			UniqueBranchID: payload.UniqueBranchID,
			UniqueGroupID: payload.UniqueGroupID,
			TotalLoanAmount: payload.TotalLoanAmount, //
			LoanDisbursementDate: payload.LoanDisbursementDate,
			LoanProposalDate: payload.LoanProposalDate,
			LoanStatus: payload.LoanStatus,
			LoancompanyID: payload.companyID,
			IsLoanApproved: payload.IsLoanApproved,
			LoanStartDate: payload.LoanStartDate,
			LoanEndDate: payload.LoanEndDate,
			LoanIndexCreatedDate: payload.LoanIndexCreatedDate,
			LoanType: payload.LoanType,
			LoanPeriod: payload.LoanPeriod,
			GoldOverallImage: payload.GoldOverallImage,
			noticedate1: payload.noticedate1,
			noticeAmount1: payload.noticeAmount1,
			noticedate2: payload.noticedate2,
			noticeAmount2: payload.noticeAmount2,
			noticedate3: payload.noticedate3,
			noticeAmount3: payload.noticeAmount3,
			ProductID: payload.productID,
			ProductName: payload.ProductName,
			MeetupLocation: payload.MeetupLocation,
			Principal: payload.Principal,
			MemberMeetupLat: payload.MeetupLat,
			MemberMeetupLong: payload.MeetupLong,
			MeetupTime: payload.MeetupTime,
			GroupOfficerID: payload.GroupOfficerID,
			GroupOfficerName: payload.UserName,
			Funder: payload.Funder,
			FunderName: payload.FunderName,
			TranscationType: payload.TranscationType,
			IsClosed: payload.IsClosed,
			LoanAmount: payload.LoanAmount, 
			OutstandingPrincipal: payload.OutstandingPrincipal, 
			InterestPercentage: payload.InterestPercentage, 
			OutstandingInterest: payload.OutstandingInterest,
			NumberOfDueLeft: payload.NumberOfDueLeft,
			NextDueNo: payload.NextDueNo,
			NoticeAmount: payload.NoticeAmount,
			NoticeDays: payload.NoticeDays,
			NoticeType: payload.NoticeType,
			NoticeDayDiff: payload.NoticeDayDiff,
			isNotice: payload.isNotice,
			MemberUID: payload.MemberUID,
			MemberPhone: payload.MemberPhone,
			MemberAadarNum: payload.MemberAadarNum,
			MemberName: payload.MemberName,
			uniqueMemberID: payload.uniqueMemberID,
			MemberCenter: payload.MemberCenter,
			MemberCenterUID: payload.MemberCenterUID,
			IsAllTrainingAttended: payload.IsAllTrainingAttended,
			IsCBCheckDone: payload.IsCBCheckDone,
			CenterName: payload.CenterName,
			UniqueCenterID: payload.UniqueCenterID,
			BranchName: payload.BranchName,
			GoldProductInterest: payload.GoldProductInterest,
			CurrentInterestAmount: payload.CurrentInterestAmount,
			PurposeOfLoanID: payload.purposeOfLoanID,
			Index: payload.Index,
			goldProductID: payload.goldProductID,
			GoldLoanProductArr: JSON.stringify(payload.GoldProductList).replace(/"/g, "'"),
			AddedOn: payload.AddedOn,
			AddedBy: payload.userID,
			isView: payload.isView,
			CompanyID: payload.companyID,
			CreatedUser: payload.userID,
			ModifiedUser: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		debugger;
		const responseData = yield call(createNewGoldLoanProposal, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addGoldLoanProposalFailure(responseData.data));
		} else {
			yield put(addGoldLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addGoldLoanProposalFailure(error));
	}
}

export function* resetAddGoldLoanProposalResponse() {
	yield put(addGoldLoanProposalResponseChanged());
}

export function* onCreateGoldLoanProposal() {
	yield takeLatest(LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_START, createNewGoldLoan);
}

export function* onCreateGoldLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_RESPONSE_RESET,
		resetAddGoldLoanProposalResponse,
	);
}
/** *********************  END ADD GOLD LOAN PROPOSAL  ******************** */

/** *********************  START FETCH GOLD LOAN GROUP OFFICER PROPOSAL  ******************** */
export function* fetchGoldLoanProposalGroupOfficerData({ payload }) {
	try {
		const key = 'GetAllOfficerListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			UserRole: payload.userRole,
			Level: payload.userRole,
			locationID: payload.CenterID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(goldLoanProposalGroupOfficerFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGoldLoanProposalGroupOfficerFailure(responseData.data));
		} else {
			yield put(fetchGoldLoanProposalGroupOfficerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGoldLoanProposalGroupOfficerFailure(error));
	}
}

export function* resetFetchGoldLoanLoanProposalGroupOfficerResponse() {
	yield put(fetchGoldLoanProposalGroupOfficerResponseChanged());
}

export function* onFetchGoldLoanProposalGroupOfficerDetails() {
	yield takeLatest(
		LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_START,
		fetchGoldLoanProposalGroupOfficerData,
	);
}

export function* onFetchGoldLoanProposalGroupOfficerResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_RESPONSE_RESET,
		resetFetchGoldLoanLoanProposalResponse,
	);
}

/** *********************  START APPROVE GOLD LOAN PROPOSAL   ******************** */
export function* approveLoanProposalGoldLoan({ payload }) {
	try {
		const key = 'ApproveGoldLoanProposalKey';
		const jsonData = {
			UserID: payload.userID,
			LoanIndexID: payload.loanIndexID,
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			IsApprove: payload.isApprove,
		};

		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(approveGoldLoanProposal, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(approveLoanProposalFailure(responseData.data));
		} else {
			yield put(approveLoanProposalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(approveLoanProposalFailure(error));
	}
}

export function* resetApproveGoldLoanProposalResponse() {
	yield put(approveLoanProposalResponseChanged());
}

export function* onApproveGoldLoanProposalDetail() {
	yield takeLatest(LoanActionTypes.APPROVE_LOAN_PROPOSAL_START, approveLoanProposalGoldLoan);
}

export function* onApproveGoldLoanProposalResponseReset() {
	yield takeLatest(
		LoanActionTypes.APPROVE_LOAN_PROPOSAL_RESPONSE_RESET,
		resetApproveGoldLoanProposalResponse,
	);
}
/** *********************  END APPROVE GOLD LOAN PROPOSAL  ******************** */

/** *********************  START FETCH GOLD LOAN PROPOSAL GROUP  ******************** */
export function* fetchGoldLoanProposalGroupData({ payload }) {
	try {
		const key = 'GetAllGroupsListkey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			LocationID: payload.CenterID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGoldLoanProposalGroup, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGoldLoanProposalGroupFailure(responseData.data));
		} else {
			yield put(fetchGoldLoanProposalGroupSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGoldLoanProposalGroupFailure(error));
	}
}

export function* resetFetchGoldLoanLoanProposalGroupResponse() {
	yield put(fetchGoldLoanProposalGroupResponseChanged());
}

export function* onFetchGoldLoanProposalGroupDetails() {
	yield takeLatest(
		LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_START,
		fetchGoldLoanProposalGroupData,
	);
}

export function* onFetchGoldLoanProposalGroupResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_RESPONSE_RESET,
		resetFetchGoldLoanLoanProposalResponse,
	);
}

/** *********************  START FETCH GOLD LOAN PROPOSAL GROUP MEMBER ******************** */
export function* fetchGoldLoanProposalGroupMemberData({ payload }) {
	try {
		const key = 'GroupMemberListKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			GroupID: payload.GroupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchGoldLoanProposalGroupMember, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchGoldLoanProposalGroupMemberFailure(responseData.data));
		} else {
			yield put(fetchGoldLoanProposalGroupMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGoldLoanProposalGroupMemberFailure(error));
	}
}

export function* resetFetchGoldLoanLoanProposalGroupMemberResponse() {
	yield put(fetchGoldLoanProposalGroupMemberResponseChanged());
}

export function* onFetchGoldLoanProposalGroupMemberDetails() {
	yield takeLatest(
		LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_START,
		fetchGoldLoanProposalGroupMemberData,
	);
}

export function* onFetchGoldLoanProposalGroupMemberResponseReset() {
	yield takeLatest(
		LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_RESPONSE_RESET,
		resetFetchGoldLoanLoanProposalGroupMemberResponse,
	);
}


export function* goldLoanProposalSaga() {
	yield all([
		call(onGetGoldLoanProposal),
		call(onGetGoldLoanProposalResponseReset),
		call(onDeleteGoldLoanProposalDetail),
		call(onDeleteBusinessLoanProposalResponseReset),
		call(onUpdateGoldLoanProposal),
		call(onUpdateGoldLoanProposalResponseReset),
		call(onFetchGoldLoanProposalDetails),
		call(onFetchGoldLoanProposalResponseReset),
		call(onCreateGoldLoanProposal),
		call(onCreateGoldLoanProposalResponseReset),
		call(onFetchGoldLoanProposalGroupOfficerDetails),
		call(onFetchGoldLoanProposalGroupOfficerResponseReset),
		call(onApproveGoldLoanProposalDetail),
		call(onApproveGoldLoanProposalResponseReset),
		call(onFetchGoldLoanProposalGroupResponseReset),
		call(onFetchGoldLoanProposalGroupDetails),
		call(onFetchGoldLoanProposalGroupMemberDetails),
		call(onFetchGoldLoanProposalGroupMemberResponseReset),
	]);
}
