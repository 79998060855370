/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import regularDemandReportTypes from './regular-demand-report.types';
import { getRegularDemandReportDetailsData } from './regular-demand-report.service';
import {
	getRegularDemandReportSuccess,
	getRegularDemandReportFailure,
	getRegularDemandReportResponseResetChanged,
} from './regular-demand-report.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START GET REGULAR DEMAND DETAILS  ******************** */
export function* getRegularDemandReport({ payload }) {
	try {
		const key = 'GetRegularDemandReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.CenterID,
			FromDate: payload.FromDate,
            OrderListBy: payload.OrderListBy,
			Location: payload.branchID,
            GroupID: payload.GroupID
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getRegularDemandReportDetailsData, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(getRegularDemandReportFailure(responseData.data));
		} else {
			yield put(getRegularDemandReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getRegularDemandReportFailure(error));
	}
}

export function* resetGetRegularDemandReportResponse() {
	yield put(getRegularDemandReportResponseResetChanged());
}

export function* onGetRegularDemandReportDetails() {
	yield takeLatest(regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_START, getRegularDemandReport);
}

export function* onGetRegularDemandReportResponseReset() {
	yield takeLatest(regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_RESPONSE_RESET_START, resetGetRegularDemandReportResponse);
}
/** *********************  END GET REGULAR DEMAND DETAILS ******************** */

export function* regularDemandReportSaga() {
	yield all([call(onGetRegularDemandReportDetails), 
        call(onGetRegularDemandReportResponseReset)]);
}
