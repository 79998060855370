/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const bankDetailsAdd = (bankData) => {
	const data = {
		AddBusinessTokenBank: bankData,
	};
	const url = `${API_URL}addBusinessBank`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBankDetails = (fetchData) => {
	const data = {
		GetBusinessBankToken: fetchData,
	};
	const url = `${API_URL}getBusinessBank`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateBankDetails = (updateData) => {
	const data = {
		UpdateBusinessDetailsToken: updateData,
	};
	const url = `${API_URL}updateBusinessBank`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
