/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import BranchActionTypes from './branch.types';

/** ***************** ADD BRANCH DETAILS START *********************** */
export const addBranchDetailsStart = (addData) => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_START,
	payload: addData,
});

export const addBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const addBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const addBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_RESET,
});

export const addBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD BRANCH DETAILS END *********************** */

/** ***************** UPDATE BRANCH DETAILS START *********************** */
export const updateBranchDetailsStart = (updateData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_START,
	payload: updateData,
});

export const updateBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const updateBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const updateBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_RESET,
});

export const updateBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE BRANCH DETAILS END *********************** */

/** ***************** GET BRANCH DETAILS START *********************** */
export const getBranchDetailsStart = (getData) => ({
	type: BranchActionTypes.GET_BRANCH_LIST_START,
	payload: getData,
});

export const getBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.GET_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.GET_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.GET_BRANCH_LIST_RESPONSE_RESET,
});

export const getBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.GET_BRANCH_LIST_RESPONSE_CHANGED,
});

/** ***************** GET BRANCH DETAILS END *********************** */

/** ***************** FETCH BRANCH DETAILS START *********************** */
export const fetchBranchDetailsStart = (fetchData) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_START,
	payload: fetchData,
});

export const fetchBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_RESET,
});

export const fetchBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH BRANCH DETAILS END *********************** */

/** ***************** DELETE BRANCH DETAILS START *********************** */
export const deleteBranchDetailsStart = (deleteData) => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_START,
	payload: deleteData,
});

export const deleteBranchDetailsSuccess = (successData) => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteBranchDetailsFailure = (failureData) => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteBranchDetailsResponseResetStart = () => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_RESET,
});

export const deleteBranchDetailsResponseChanged = () => ({
	type: BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE BRANCH DETAILS END *********************** */
