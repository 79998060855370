/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import AuditTrailReportActionTypes from './auditTrailReport.types';
import { docodeResponse, decodeBase64Response } from '../../common/common.utils';

const INITIAL_STATE = {
	getAuditTrailReportListResponse: null,
	getAuditTrailReportListArr: [],
	fetchPostAuditTrailExcelResponse: null,
	fetchPostAuditTrailExcelArr: [],
	fetchPostAuditTrailJSONResponse: null,
	fetchPostAuditTrailJSONArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_SUCCESS:
			return {
				...state,
				getAuditTrailReportListResponse: action.payload,
				getAuditTrailReportListArr: docodeResponse(action.payload, 'AuditTrailReportKey'),
			};

		case AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_FAILURE:
			return {
				...state,
				getAuditTrailReportListResponse: action.payload,
				getAuditTrailReportListArr: [],
			};

		case AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_CHANGED:
			return {
				...state,
				getAuditTrailReportListResponse: null,
			};

		/** ***************** AUDIT TRAIL REPORT REPORT EXCEL  *********************** */

		case AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_SUCCESS:
			return {
				...state,
				fetchPostAuditTrailExcelResponse: action.payload,
				fetchPostAuditTrailExcelArr: docodeResponse(
					action.payload,
					'AuditTrailReportExcelKey',
				),
			};

		case AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_FAILURE:
			return {
				...state,
				fetchPostAuditTrailExcelResponse: null,
				fetchPostAuditTrailExcelArr: action.payload,
			};

		case AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_RESET:
			return { ...state, INITIAL_STATE };

		/** ***************** AUDIT TRAIL REPORT REPORT JSON  *********************** */

		case AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_SUCCESS:
			return {
				...state,
				fetchPostAuditTrailJSONResponse: action.payload,
				fetchPostAuditTrailJSONArr: decodeBase64Response(
					action.payload,
					'AuditTrailReportKey',
				),
			};

		case AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_FAILURE:
			return {
				...state,
				fetchPostAuditTrailJSONResponse: null,
				fetchPostAuditTrailJSONArr: action.payload,
			};

		case AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_RESET:
			return {
				...state,
				fetchPostAuditTrailJSONResponse: null,
				fetchPostAuditTrailJSONArr: [],
			};
		default:
			return state;
	}
};
