const InActiveMemberActionTypes = {

    GET_INACTIVE_MEMBER_LIST_START: 'get_inactive_member_start',
	GET_INACTIVE_MEMBER_LIST_SUCCESS: 'get_inactive_member_success',
	GET_INACTIVE_MEMBER_LIST_FAILURE: 'get_inactive_member_failure',
	GET_INACTIVE_MEMBER_LIST_RESPONSE_RESET: 'get_inactive_member_response_reset',
	GET_INACTIVE_MEMBER_LIST_RESPONSE_CHANGED: 'get_inactive_member_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',
    
}
export default InActiveMemberActionTypes;