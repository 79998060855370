/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PurposeOfLoanActionTypes from './purposeofloan.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addPurposeOfLoanDetailsResponse: null,
	updatePurposeOfLoanDetailsResponse: null,
	getPurposeOfLoanListResponse: null,
	getPurposeOfLoanListArr: [],
	fetchPurposeOfLoanDetailsResponse: null,
	fetchPurposeOfLoanDetailsArr: [],
	deletePurposeOfLoanDetailsResponse: null,

	// PURPOSE OF LOAN CATEGORY STATES
	purposeOfLoanCategoryResp: null,
	purposeOfLoanCategoryRespArr: [],
	purposeOfLoanCategoryAddStatus: null,
	purposeOfLoanCategoryAddResp: null,
	purposeOfLoanCategoryUpdateStatus: null,
	purposeOfLoanCategoryUpdateResp: null,
	purposeOfLoanCategoryDeleteStatus: null,
	purposeOfLoanCategoryDeleteResp: null,
};

const purposeofLoanReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_SUCCESS:
			return { ...state, addPurposeOfLoanDetailsResponse: action.payload };

		case PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_FAILURE:
			return { ...state, addPurposeOfLoanDetailsResponse: action.payload };

		case PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED:
			return { ...state, addPurposeOfLoanDetailsResponse: null };

		case PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_SUCCESS:
			return { ...state, updatePurposeOfLoanDetailsResponse: action.payload };

		case PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_FAILURE:
			return { ...state, updatePurposeOfLoanDetailsResponse: action.payload };

		case PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED:
			return { ...state, updatePurposeOfLoanDetailsResponse: null };

		case PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_SUCCESS:
			return {
				...state,
				getPurposeOfLoanListResponse: action.payload,
				getPurposeOfLoanListArr: docodeResponse(action.payload, 'GetPurposeOfLoanListKey'),
			};

		case PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_FAILURE:
			return { ...state, getPurposeOfLoanListResponse: action.payload };

		case PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getPurposeOfLoanListResponse: null };

		case PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_SUCCESS:
			return {
				...state,
				fetchPurposeOfLoanDetailsResponse: action.payload,
				fetchPurposeOfLoanDetailsArr: docodeResponse(
					action.payload,
					'FetchPurposeOfLoanDetailsKey',
				),
			};

		case PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_FAILURE:
			return { ...state, fetchPurposeOfLoanDetailsResponse: action.payload };

		case PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchPurposeOfLoanDetailsResponse: null,
				fetchPurposeOfLoanDetailsArr: [],
			};

		case PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_SUCCESS:
			return { ...state, deletePurposeOfLoanDetailsResponse: action.payload };

		case PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_FAILURE:
			return { ...state, deletePurposeOfLoanDetailsResponse: action.payload };

		case PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED:
			return { ...state, deletePurposeOfLoanDetailsResponse: null };

		// PURPOSE OF LOAN CATEGORY CASES
		// GET PURPOSE OF LOAN CATEGORY
		case PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_SUCCESS:
			return {
				...state,
				purposeOfLoanCategoryResp: 'Success',
				purposeOfLoanCategoryRespArr: docodeResponse(
					action.payload,
					'PURPOSEOFLOANCATEGORYLISTKEY',
				),
			};

		case PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_FAILURE:
			return { ...state, purposeOfLoanCategoryResp: action.payload };

		case PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_CHANGED:
			return { ...state, purposeOfLoanCategoryResp: null };

		// ADD PURPOSE OF LOAN CATEGORY
		case PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_SUCCESS:
			return {
				...state,
				purposeOfLoanCategoryAddStatus: 'Success',
				purposeOfLoanCategoryAddResp: docodeResponse(
					action.payload,
					'PURPOSEOFLOANCATEGORYCREATEKEY',
				),
			};

		case PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_FAILURE:
			return { ...state, purposeOfLoanCategoryAddStatus: null };

		case PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_CHANGED:
			return { ...state, purposeOfLoanCategoryAddStatus: null };

		default:
			return state;
	}
};

export default purposeofLoanReducer;