/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
const InsuranceCompanyActionTypes = {
	ADD_INSURANCE_COMPANY_DETAILS_START: 'add_insurance_company_details_start',
	ADD_INSURANCE_COMPANY_DETAILS_SUCCESS: 'add_insurance_company_details_success',
	ADD_INSURANCE_COMPANY_DETAILS_FAILURE: 'add_insurance_company_details_failure',
	ADD_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET: 'add_insurance_company_details_response_reset',
	ADD_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED: 'add_insurance_company_details_response_changed',

	UPDATE_INSURANCE_COMPANY_DETAILS_START: 'update_insurance_company_details_start',
	UPDATE_INSURANCE_COMPANY_DETAILS_SUCCESS: 'update_insurance_company_details_success',
	UPDATE_INSURANCE_COMPANY_DETAILS_FAILURE: 'update_insurance_company_details_failure',
	UPDATE_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET: 'update_insurance_company_details_response_reset',
	UPDATE_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED: 'update_insurance_company_details_response_changed',

	GET_INSURANCE_COMPANY_LIST_START: 'get_insurance_company_list_start',
	GET_INSURANCE_COMPANY_LIST_SUCCESS: 'get_insurance_company_list_success',
	GET_INSURANCE_COMPANY_LIST_FAILURE: 'get_insurance_company_list_failure',
	GET_INSURANCE_COMPANY_LIST_RESPONSE_RESET: 'get_insurance_company_list_response_reset',
	GET_INSURANCE_COMPANY_LIST_RESPONSE_CHANGED: 'get_insurance_company_list_response_changed',

	FETCH_INSURANCE_COMPANY_DETAILS_START: 'fetch_insurance_company_details_start',
	FETCH_INSURANCE_COMPANY_DETAILS_SUCCESS: 'fetch_insurance_company_details_success',
	FETCH_INSURANCE_COMPANY_DETAILS_FAILURE: 'fetch_insurance_company_details_failure',
	FETCH_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET: 'fetch_insurance_company_details_response_reset',
	FETCH_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED: 'fetch_insurance_company_details_response_changed',

	DELETE_INSURANCE_COMPANY_DETAILS_START: 'delete_insurance_company_details_start',
	DELETE_INSURANCE_COMPANY_DETAILS_SUCCESS: 'delete_insurance_company_details_success',
	DELETE_INSURANCE_COMPANY_DETAILS_FAILURE: 'delete_insurance_company_details_failure',
	DELETE_INSURANCE_COMPANY_DETAILS_RESPONSE_RESET: 'delete_insurance_company_details_response_reset',
	DELETE_INSURANCE_COMPANY_DETAILS_RESPONSE_CHANGED: 'delete_insurance_company_details_response_changed',

	FETCH_ALL_INSURANCE_COMPANY_START: 'fetch_all_insurance_company_start',
	FETCH_ALL_INSURANCE_COMPANY_SUCCESS: 'fetch_all_insurance_company_success',
	FETCH_ALL_INSURANCE_COMPANY_FAILURE: 'fetch_all_insurance_company_failure',
	FETCH_ALL_INSURANCE_COMPANY_RESPONSE_RESET: 'fetch_all_insurance_company_response_reset',
	FETCH_ALL_INSURANCE_COMPANY_RESPONSE_CHANGED: 'fetch_all_insurance_company_response_changed',
};
export default InsuranceCompanyActionTypes;
