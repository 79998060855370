/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const LoanDisbursementListGet = (listData) => {
	const data = {
		LoanDisbursementReportToken: listData,
	};
	const url = `${API_URL}PostLoanDisbursementReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const officerBranchListGet = (listData) => {
	const data = {
		GetOfficerListBranchToken: listData,
	};
	const url = `${API_URL}GetAllOfficerUserBasedonBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const LoanDisbursementExcelListGet = (listData) => {
	const params = new URLSearchParams();
	params.append('FetchLoanDisbursementListToken', listData);
	const url = `${API_URL}Report/Excel/LoanDisbursementReportExcelWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};

export const LoanDisbursementBankDetailsExcelListGet = (listData) => {
	const params = new URLSearchParams();
	params.append('FetchLoanMemberBankDetailsListToken', listData);
	const url = `${API_URL}Report/Excel/LoanMemberBankDetailsReportExcelWithToken.php`;
	return axios.post(url, params, {
		headers: { 'Content-type': 'application/x-www-form-urlencoded' },
	});
};
