/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import ProofDetailsActionTypes from './proof-details.types';
import { proofDetailsAdd, fetchProofDetails, updateProofDetails } from './proof-details.service';

import {
	addBusinessProofSuccess,
	addBusinessProofFailure,
	addBusinessProofResponseChanged,
	updateBusinessProofSuccess,
	updateBusinessProofFailure,
	updateBusinessProofResponseChanged,
	fetchBusinessProofSuccess,
	fetchBusinessProofFailure,
	fetchBusinessProofResponseChanged,
} from './proof-details.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS PROOF  ******************** */
export function* businessProofAdd({ payload }) {
	try {
		const key = 'BusinessProofKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessProofList: payload.proofListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(proofDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessProofFailure(responseData.data));
		} else {
			yield put(addBusinessProofSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessProofFailure(error));
	}
}

export function* resetAddBusinessProofResponse() {
	yield put(addBusinessProofResponseChanged());
}

export function* onAddBusinessProofDetails() {
	yield takeLatest(ProofDetailsActionTypes.ADD_BUSINESS_PROOF_START, businessProofAdd);
}

export function* onAddBusinessProofResponseReset() {
	yield takeLatest(
		ProofDetailsActionTypes.ADD_BUSINESS_PROOF_RESPONSE_RESET,
		resetAddBusinessProofResponse,
	);
}
/** *********************  END ADD BUSINESS PROOF ******************** */

/** *********************  START UPDATE BUSINESS PROOF  ******************** */
export function* businessProofUpdate({ payload }) {
	try {
		const key = 'UpdateBusinessProofKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessProofList: payload.proofListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateProofDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessProofFailure(responseData.data));
		} else {
			yield put(updateBusinessProofSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessProofFailure(error));
	}
}

export function* resetUpdateBusinessProofResponse() {
	yield put(updateBusinessProofResponseChanged());
}

export function* onUpdateBusinessProofDetails() {
	yield takeLatest(ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_START, businessProofUpdate);
}

export function* onUpdateBusinessProofResponseReset() {
	yield takeLatest(
		ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_RESPONSE_RESET,
		resetUpdateBusinessProofResponse,
	);
}
/** *********************  END ADD BUSINESS PROOF ******************** */

/** *********************  START FETCH BUSINESS PROOF  ******************** */
export function* businessProofFetch({ payload }) {
	try {
		const key = 'GetBusinessProofKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchProofDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessProofFailure(responseData.data));
		} else {
			yield put(fetchBusinessProofSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessProofFailure(error));
	}
}

export function* resetFetchBusinessProofResponse() {
	yield put(fetchBusinessProofResponseChanged());
}

export function* onFetchBusinessProofDetails() {
	yield takeLatest(ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_START, businessProofFetch);
}

export function* onFetchBusinessProofResponseReset() {
	yield takeLatest(
		ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_RESPONSE_RESET,
		resetFetchBusinessProofResponse,
	);
}
/** *********************  END ADD BUSINESS PROOF ******************** */

export function* proofDetailsSaga() {
	yield all([
		call(onAddBusinessProofDetails),
		call(onAddBusinessProofResponseReset),
		call(onUpdateBusinessProofDetails),
		call(onUpdateBusinessProofResponseReset),
		call(onFetchBusinessProofDetails),
		call(onFetchBusinessProofResponseReset),
	]);
}
