/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import EconomicActivityActionTypes from './economic-activity.types';
import {
	economicActivityDetailsAdd,
	economicActivityDetailsUpdate,
	economicActivityListGet,
	economicActivityDetailsFetch,
	economicActivityDetailsDelete,
	allEconomicActivityFetch,
} from './economic-activity.service';

import {
	addEconomicActivityDetailsSuccess,
	addEconomicActivityDetailsFailure,
	addEconomicActivityDetailsResponseChanged,
	updateEconomicActivityDetailsSuccess,
	updateEconomicActivityDetailsFailure,
	updateEconomicActivityDetailsResponseChanged,
	getEconomicActivityListSuccess,
	getEconomicActivityListFailure,
	getEconomicActivityListResponseChanged,
	fetchEconomicActivityDetailsSuccess,
	fetchEconomicActivityDetailsFailure,
	fetchEconomicActivityDetailsResponseChanged,
	deleteEconomicActivityDetailsSuccess,
	deleteEconomicActivityDetailsFailure,
	deleteEconomicActivityDetailsResponseChanged,
	fetchAllEconomicActivitySuccess,
	fetchAllEconomicActivityFailure,
	fetchAllEconomicActivityResponseChanged,
} from './economic-activity.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD PRODUCT TYPE DETAILS  ******************** */
export function* createEconomicActivity({ payload }) {
	try {
		const key = 'AddEconomicActivityDetailKey';
		const jsonData = {
			EconomicActivityArrData: payload.activityListArr,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addEconomicActivityDetailsFailure(responseData.data));
		} else {
			yield put(addEconomicActivityDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addEconomicActivityDetailsFailure(error));
	}
}

export function* resetAddEconomicActivityResponse() {
	yield put(addEconomicActivityDetailsResponseChanged());
}

export function* onCreateEconomicActivityDetails() {
	yield takeLatest(
		EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_START,
		createEconomicActivity,
	);
}

export function* onCreateEconomicActivityResponseReset() {
	yield takeLatest(
		EconomicActivityActionTypes.ADD_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
		resetAddEconomicActivityResponse,
	);
}
/** *********************  END ADD PRODUCT TYPE DETAILS ******************** */

/** *********************  START UPDATE PRODUCT TYPE DETAILS  ******************** */
export function* economicActivityUpdate({ payload }) {
	try {
		const key = 'UpdateEconomicActivityDetailsKey';
		const jsonData = {
			EconomicActivityArrData: payload.activityListArr,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateEconomicActivityDetailsFailure(responseData.data));
		} else {
			yield put(updateEconomicActivityDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateEconomicActivityDetailsFailure(error));
	}
}

export function* resetUpdateEconomicActivityDetailsResponse() {
	yield put(updateEconomicActivityDetailsResponseChanged());
}

export function* onUpdateEconomicActivityDetails() {
	yield takeLatest(
		EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_START,
		economicActivityUpdate,
	);
}

export function* onUpdateEconomicActivityResponseReset() {
	yield takeLatest(
		EconomicActivityActionTypes.UPDATE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
		resetUpdateEconomicActivityDetailsResponse,
	);
}
/** *********************  END UPDATE PRODUCT TYPE DETAILS ******************** */

/** *********************  START GET PRODUCT TYPE LIST  ******************** */
export function* economicActivityDetailsListGet({ payload }) {
	try {
		const key = 'GetEconomicActivityListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			SearchData: payload.inputSearch,
			Limit: payload.limit,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getEconomicActivityListFailure(responseData.data));
		} else {
			yield put(getEconomicActivityListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getEconomicActivityListFailure(error));
	}
}

export function* resetGetEconomicActivityListResponse() {
	yield put(getEconomicActivityListResponseChanged());
}

export function* onGetEconomicActivityListDetails() {
	yield takeLatest(
		EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_START,
		economicActivityDetailsListGet,
	);
}

export function* onGetEconomicActivityListResponseReset() {
	yield takeLatest(
		EconomicActivityActionTypes.GET_ECONOMIC_ACTIVITY_LIST_RESPONSE_RESET,
		resetGetEconomicActivityListResponse,
	);
}
/** *********************  END GET PRODUCT TYPE LIST ******************** */

/** *********************  START FETCH PRODUCT TYPE DETAILS  ******************** */
export function* fetchEconomicActivityData({ payload }) {
	try {
		const key = 'FetchEconomicActivityDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			EconomicActivityID: payload.economicActivityID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchEconomicActivityDetailsFailure(responseData.data));
		} else {
			yield put(fetchEconomicActivityDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchEconomicActivityDetailsFailure(error));
	}
}

export function* resetFetchEconomicActivityDetailsResponse() {
	yield put(fetchEconomicActivityDetailsResponseChanged());
}

export function* onFetchEconomicActivityDetails() {
	yield takeLatest(
		EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_START,
		fetchEconomicActivityData,
	);
}

export function* onFetchEconomicActivityResponseReset() {
	yield takeLatest(
		EconomicActivityActionTypes.FETCH_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
		resetFetchEconomicActivityDetailsResponse,
	);
}
/** *********************  END FETCH PRODUCT TYPE DETAILS ******************** */

/** *********************  START DELETE PRODUCT TYPE DETAILS  ******************** */
export function* economicActivityDelete({ payload }) {
	try {
		const key = 'DeleteEconomicActivityDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			EconomicActivityID: payload.economicActivityID,
			EconomicActivityName: payload.economicActivityName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(economicActivityDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteEconomicActivityDetailsFailure(responseData.data));
		} else {
			yield put(deleteEconomicActivityDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteEconomicActivityDetailsFailure(error));
	}
}

export function* resetDeleteEconomicActivityDetailsResponse() {
	yield put(deleteEconomicActivityDetailsResponseChanged());
}

export function* onDeleteEconomicActivityDetail() {
	yield takeLatest(
		EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_START,
		economicActivityDelete,
	);
}

export function* onDeleteEconomicActivityResponseReset() {
	yield takeLatest(
		EconomicActivityActionTypes.DELETE_ECONOMIC_ACTIVITY_DETAILS_RESPONSE_RESET,
		resetDeleteEconomicActivityDetailsResponse,
	);
}
/** *********************  END DELETE PRODUCT TYPE DETAILS ******************** */

/** *********************  START FETCH ALL PRODUCT TYPE LIST  ******************** */
export function* fetchAllEconomicActivitysList({ payload }) {
	try {
		const key = 'FetchAllEconomicActivitysKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allEconomicActivityFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllEconomicActivityFailure(responseData.data));
		} else {
			yield put(fetchAllEconomicActivitySuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllEconomicActivityFailure(error));
	}
}

export function* resetFetchAllEconomicActivitysResponse() {
	yield put(fetchAllEconomicActivityResponseChanged());
}

export function* onFetchAllEconomicActivitysDetails() {
	yield takeLatest(
		EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_START,
		fetchAllEconomicActivitysList,
	);
}

export function* onFetchAllEconomicActivitysResponseReset() {
	yield takeLatest(
		EconomicActivityActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_RESPONSE_RESET,
		resetFetchAllEconomicActivitysResponse,
	);
}
/** *********************  END FETCH ALL PRODUCT TYPE LIST ******************** */

export function* economicActivityMasterSaga() {
	yield all([
		call(onCreateEconomicActivityDetails),
		call(onCreateEconomicActivityResponseReset),
		call(onUpdateEconomicActivityDetails),
		call(onUpdateEconomicActivityResponseReset),
		call(onGetEconomicActivityListDetails),
		call(onGetEconomicActivityListResponseReset),
		call(onFetchEconomicActivityDetails),
		call(onFetchEconomicActivityResponseReset),
		call(onDeleteEconomicActivityDetail),
		call(onDeleteEconomicActivityResponseReset),
		call(onFetchAllEconomicActivitysDetails),
		call(onFetchAllEconomicActivitysResponseReset),
	]);
}
