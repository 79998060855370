const DataVerificationActionTypes = {

    GET_DATA_VERIFICATION_LIST_START: 'get_data_verification_start',
	GET_DATA_VERIFICATION_LIST_SUCCESS: 'get_data_verification_success',
	GET_DATA_VERIFICATION_LIST_FAILURE: 'get_data_verification_failure',
	GET_DATA_VERIFICATION_LIST_RESPONSE_RESET: 'get_data_verification_response_reset',
	GET_DATA_VERIFICATION_LIST_RESPONSE_CHANGED: 'get_data_verification_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',
    
}
export default DataVerificationActionTypes;