/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const economicActivityDetailsAdd = (addData) => {
	const data = {
		AddEconomicActivityDetailsToken: addData,
	};
	const url = `${API_URL}addEconomicActivityDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityDetailsUpdate = (updateData) => {
	const data = {
		UpdateEconomicActivityDetailsToken: updateData,
	};
	const url = `${API_URL}updateEconomicActivityDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityListGet = (listData) => {
	const data = {
		GetEconomicActivityListToken: listData,
	};
	const url = `${API_URL}getEconomicActivityList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityDetailsFetch = (fetchData) => {
	const data = {
		FetchEconomicActivityDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchEconomicActivityDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityDetailsDelete = (deleteData) => {
	const data = {
		DeleteEconomicActivityDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteEconomicActivityDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allEconomicActivityFetch = (fetchBranchesData) => {
	const data = {
		FetchAllEconomicActivityListData: fetchBranchesData,
	};
	const url = `${API_URL}fetchAllEconomicActivityList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
