/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */

// export const API_URL = 'https://api.squarenow.co.in/SquareNowWeb/';
export const API_URL = 'https://dev.api.squarenow.in/SquareNowWeb/';
// export const API_URL = 'http://localhost:8888/mfiapi/SquareNowWeb/';

// export const EXPORT_API_URL = 'https://api.squarenow.in/';
export const EXPORT_API_URL = 'https://dev.api.squarenow.in/';
// export const EXPORT_API_URL = 'http://localhost:8888/mfiapi/';
