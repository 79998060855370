/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';
import PortFlioAtRiskTypes from './portflioAtRiskReport.types';
import {
	PortflioAtRiskDetailsGet,
	fetchOverDueExcel,
	UserDetailsGet,
	LoanInfoBasedOnMemberGet,
} from './portflioAtRiskReport.service';
import {
	getportflioAtRiskSuccess,
	getportflioAtRiskFailure,
	getportflioAtRiskResponseChanged,
	fetchOverDueExcelSuccess,
	fetchOverDueExcelFailure,
	fetchOverDueExcelResponseChanged,
	getUserDetailsSuccess,
	getUserDetailsFailure,
	getUserDetailsResponseChanged,
	getLoanInfoBasedOnMemberFailure,
	getLoanInfoBasedOnMemberSuccess,
} from './portflioAtRiskReport.action';
import { encodeJson } from '../../enode-decode';
/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getPortflioAtRisk({ payload }) {
	// debugger;
	try {
		const key = 'GetPortfolioAtRiskReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			TillDate: payload.TillDate,
			fromDays: payload.fromDays,
			toDays: payload.toDays,
			UserRole: payload.userRole,
			FieldOfficer: payload.officerID,
			SearchValue: payload.SearchValue,
			FunderID: payload.funderID,
			PurposeOfLoanID: payload.PurposeOfLoanID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PortflioAtRiskDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getportflioAtRiskFailure(responseData.data));
		} else {
			yield put(getportflioAtRiskSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getportflioAtRiskFailure(error));
	}
}

export function* resetGetPortfolioReportListResponse() {
	yield put(getportflioAtRiskResponseChanged());
}

export function* onGetPortflioAtRiskListDetails() {
	yield takeLatest(PortFlioAtRiskTypes.PORTFLIO_AT_RISK_START, getPortflioAtRisk);
}

export function* onGetPortflioAtRiskListResponseReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.PORTFLIO_AT_RISK_RESPONSE_RESET,
		resetGetPortfolioReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */
export function* fetchOverDueExcelCall({ payload }) {
	try {
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			UserRole: payload.userRole,
			DateFilter: payload.datefilter,
			OverDueListBy: payload.OverDueListBy,
			IsThriftExist: payload.IsThriftExist,
		};
		// const branchData = encodeJson(jsonData, generalTypesAction.REPAYMENTCOLLECTIONEXCEL);
		const responseData = yield call(fetchOverDueExcel, jsonData);
		if (responseData.data.statusCode === '500') {
			yield put(fetchOverDueExcelFailure(responseData.data));
		} else {
			yield put(fetchOverDueExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchOverDueExcelFailure(error));
	}
}

export function* onSelectOverDueExcel() {
	yield takeLatest(PortFlioAtRiskTypes.FETCH_OVERDUE_EXCEL_START, fetchOverDueExcelCall);
}

export function* fetchOverDueExcelReset() {
	yield put(fetchOverDueExcelResponseChanged());
}

export function* onSelectOverDueExcelReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.FETCH_OVERDUE_EXCEL_RESPONSE_RESET,
		fetchOverDueExcelReset,
	);
}

export function* userDetailspar({ payload }) {
	// debugger;
	try {
		const key = 'userDetailsparKey';
		const jsonData = {
			staffID: payload.userID,
			userLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(UserDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getUserDetailsFailure(responseData.data));
		} else {
			yield put(getUserDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getUserDetailsFailure(error));
	}
}

export function* resetGetUserDetailsResponse() {
	yield put(getUserDetailsResponseChanged());
}

export function* onGetUserDetailsListDetails() {
	yield takeLatest(PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_START, userDetailspar);
}

export function* onGetUserDetailsListResponseReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.GET_USER_DETAILS_PAR_RESPONSE_RESET,
		resetGetUserDetailsResponse,
	);
}

export function* LoanInfoBasedOnMemberDetails({ payload }) {
	// debugger;
	try {
		const key = 'LOANINFORBASEDONMEMBERBOTHCLOSEDTOKEN';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			TillDate: payload.TillDate,
			fromDays: payload.fromDays,
			toDays: payload.toDays,
			UserRole: payload.userRole,
			FieldOfficer: payload.officerID,
			SearchValue: payload.SearchValue,
			FunderID: payload.funderID,
			PurposeOfLoanID: payload.PurposeOfLoanID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LoanInfoBasedOnMemberGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanInfoBasedOnMemberFailure(responseData.data));
		} else {
			yield put(getLoanInfoBasedOnMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanInfoBasedOnMemberFailure(error));
	}
}

export function* resetGetLoanInfoBasedOnMemberResponse() {
	yield put(getUserDetailsResponseChanged());
}

export function* onGetLoanInfoBasedOnMemberDetails() {
	yield takeLatest(
		PortFlioAtRiskTypes.GET_LOAN_INFO_BASEDON_MEMBER_START,
		LoanInfoBasedOnMemberDetails,
	);
}

export function* onGetLoanInfoBasedOnMemberResponseReset() {
	yield takeLatest(
		PortFlioAtRiskTypes.GET_LOAN_INFO_BASEDON_MEMBER_RESPONSE_RESET,
		resetGetLoanInfoBasedOnMemberResponse,
	);
}
export function* portfolioAtRiskReportSaga() {
	yield all([
		call(onGetPortflioAtRiskListDetails),
		call(onGetPortflioAtRiskListResponseReset),
		call(onSelectOverDueExcel),
		call(onSelectOverDueExcelReset),
		call(onGetUserDetailsListDetails),
		call(onGetUserDetailsListResponseReset),
		call(onGetLoanInfoBasedOnMemberDetails),
		call(onGetLoanInfoBasedOnMemberResponseReset),
	]);
}
