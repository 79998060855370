const BusinessDetailsActionTypes = {
	IS_EDIT_BUSINESS_DETAILS: 'is_edit_business_details',
	BUSINESS_SUBMIT_TAB_VALUE_CHANGED: 'business_submit_tab_value_changed',
	BUSINESS_IS_OPEN_OFFCANVAS: 'business_is_open_offcanvas',

	ADD_BUSINESS_DETAILS_START: 'add_business_details_start',
	ADD_BUSINESS_DETAILS_SUCCESS: 'add_business_details_success',
	ADD_BUSINESS_DETAILS_FAILURE: 'add_business_details_failure',
	ADD_BUSINESS_DETAILS_RESPONSE_RESET: 'add_business_details_response_reset',
	ADD_BUSINESS_DETAILS_RESPONSE_CHANGED: 'add_business_details_response_changed',

	UPDATE_BUSINESS_DETAILS_START: 'update_business_details_start',
	UPDATE_BUSINESS_DETAILS_SUCCESS: 'update_business_details_success',
	UPDATE_BUSINESS_DETAILS_FAILURE: 'update_business_details_failure',
	UPDATE_BUSINESS_DETAILS_RESPONSE_RESET: 'update_business_details_response_reset',
	UPDATE_BUSINESS_DETAILS_RESPONSE_CHANGED: 'update_business_details_response_changed',

	FETCH_BUSINESS_DETAILS_START: 'fetch_business_details_start',
	FETCH_BUSINESS_DETAILS_SUCCESS: 'fetch_business_details_success',
	FETCH_BUSINESS_DETAILS_FAILURE: 'fetch_business_details_failure',
	FETCH_BUSINESS_DETAILS_RESPONSE_RESET: 'fetch_business_details_response_reset',
	FETCH_BUSINESS_DETAILS_RESPONSE_CHANGED: 'fetch_business_details_response_changed',

	DELETE_BUSINESS_DETAILS_START: 'delete_business_details_start',
	DELETE_BUSINESS_DETAILS_SUCCESS: 'delete_business_details_success',
	DELETE_BUSINESS_DETAILS_FAILURE: 'delete_business_details_failure',
	DELETE_BUSINESS_DETAILS_RESPONSE_RESET: 'delete_business_details_response_reset',
	DELETE_BUSINESS_DETAILS_RESPONSE_CHANGED: 'delete_business_details_response_changed',

	GET_ALL_BUSINESS_LIST_START: 'get_all_business_list_start',
	GET_ALL_BUSINESS_LIST_SUCCESS: 'get_all_business_list_success',
	GET_ALL_BUSINESS_LIST_FAILURE: 'get_all_business_list_failure',
	GET_ALL_BUSINESS_LIST_RESPONSE_RESET: 'get_all_business_list_response_reset',
	GET_ALL_BUSINESS_LIST_RESPONSE_CHANGED: 'get_all_business_list_response_changed',
};
export default BusinessDetailsActionTypes;
