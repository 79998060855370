/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import advanceCollectionActionTypes from './advanceCollection.types';
import {
	fetchAdvancehMemberLoanList,
	advanceCollectionUpdateAPI,
} from './advanceCollection.service';

import {
	fetchAdvanceMemberLoanInfoSuccess,
	fetchAdvanceMemberLoanInfoFailure,
	fetchAdvanceMemberLoanInfoResponseChanged,
	updateAdvanceCollectionResponseChanged,
	updateAdvanceCollectionSuccess,
	updateAdvanceCollectionFailure,
} from './advanceCollection.action';

import { encodeJson } from '../../../enode-decode';

/** *********************  START MEMBER LOAN LIST DETAILS  ******************** */
export function* getAdvanceMemberLoanList({ payload }) {
	try {
		const key = 'FetchAdvanceMemberListDataKey';
		const jsonData = {
			GroupOfficerID: payload.officerID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.FromDate,
			CompanyID: payload.CompanyID,
			BranchID: payload.BranchID,
			LocationID: payload.CenterID,
			GroupID: payload.GroupID,
			UserLevel: payload.UserLevel,
			RepayDate: payload.FromDate,
			IsArrearAdvanceCollection: false,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchAdvancehMemberLoanList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAdvanceMemberLoanInfoFailure(responseData.data));
		} else {
			yield put(fetchAdvanceMemberLoanInfoSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAdvanceMemberLoanInfoFailure(error));
	}
}

export function* resetGetAdvanceMemberLoanListResponse() {
	yield put(fetchAdvanceMemberLoanInfoResponseChanged());
}

export function* onGetAdvanceMemberLoanListDetails() {
	yield takeLatest(
		advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_START,
		getAdvanceMemberLoanList,
	);
}

export function* onGetAdvanceMemberLoanListResponseReset() {
	yield takeLatest(
		advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_RESET,
		resetGetAdvanceMemberLoanListResponse,
	);
}
/** *********************  END GET MEMBER LOAN LIST  ******************** */

/** *********************  START ADVANCE COLLECTION UPDATE  ******************** */
export function* updateAdvanceCollectionList({ payload }) {
	try {
		const key = 'UpdateAdvanceCollectionDataKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			CompanyID: payload.CompanyID,
			isAdvance: payload.isAdvance,
			LoanPaidList: payload.LoanPaidList,
			advanceCollectionDate: payload.advanceCollectionDate,
			PaymentFrom: payload.PaymentFrom,
			UserID: payload.UserID,
			fiveHundred: payload.fiveHundred,
			twoHundred: payload.twoHundred,
			hundred: payload.hundred,
			fifty: payload.fifty,
			twenty: payload.twenty,
			ten: payload.ten,
			five: payload.five,
			two: payload.two,
			one: payload.one,
			totalDenominationAmount: payload.totalDenominationAmount,
			AreaID: payload.AreaID,
			BranchID: payload.BranchID,
			GroupID: payload.GroupID,
			DenominationDate: payload.DenominationDate,
			isAllCenter: payload.isAllCenter,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(advanceCollectionUpdateAPI, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateAdvanceCollectionFailure(responseData.data));
		} else {
			yield put(updateAdvanceCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateAdvanceCollectionFailure(error));
	}
}

export function* resetAdvanceCollectionUpdateResponse() {
	yield put(updateAdvanceCollectionResponseChanged());
}

export function* onAdvanceCollectionUpdate() {
	yield takeLatest(
		advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_START,
		updateAdvanceCollectionList,
	);
}

export function* onAdvanceCollectionUpdateResponseReset() {
	yield takeLatest(
		advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_RESPONSE_RESET,
		resetAdvanceCollectionUpdateResponse,
	);
}
/** *********************  END ADVANCE COLLECTION UPDATE  ******************** */

export function* advanceCollectionSaga() {
	yield all([
		call(onGetAdvanceMemberLoanListDetails),
		call(onGetAdvanceMemberLoanListResponseReset),
		call(onAdvanceCollectionUpdate),
		call(onAdvanceCollectionUpdateResponseReset),
	]);
}
