/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import DataVerificationActionTypes from './data-verification.types';

/** ***************** GET DATA VERIFICATION DETAILS START *********************** */
export const getDataVerificationDetailsStart = (getData) => ({
	type: DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_START,
	payload: getData,
});

export const getDataVerificationDetailsSuccess = (successData) => ({
	type: DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_SUCCESS,
	payload: successData,
});

export const getDataVerificationDetailsFailure = (failureData) => ({
	type: DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_FAILURE,
	payload: failureData,
});

export const getDataVerificationDetailsResponseResetStart = () => ({
	type: DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_RESPONSE_RESET,
});

export const getDataVerificationDetailsResponseChanged = () => ({
	type: DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_RESPONSE_CHANGED,
});
/** ***************** GET DATA VERIFICATION DETAILS END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

