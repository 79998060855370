/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import TatReportTypes from './tat-report.types';

/** ***************** FETCH TAT DETAILS START *********************** */
export const fetchTatReportDetailStart = (addData) => ({
	type: TatReportTypes.FETCH_TAT_REPORT_START,
	payload: addData,
});

export const fetchTatReportDetailSuccess = (successData) => ({
	type: TatReportTypes.FETCH_TAT_REPORT_SUCCESS,
	payload: successData,
});

export const fetchTatReportDetailFailure = (failureData) => ({
	type: TatReportTypes.FETCH_TAT_REPORT_FAILURE,
	payload: failureData,
});

export const fetchTatReportDetailResponseResetStart = () => ({
	type: TatReportTypes.FETCH_TAT_REPORT_RESPONSE_RESET_START,
});

export const fetchTatReportDetailResponseChanged = () => ({
	type: TatReportTypes.FETCH_TAT_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH TAT DETAILS END *********************** */
