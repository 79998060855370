/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import progressReportTypes from './ProgressReport.types';

export const fetchAllGroupOfficerUserBasedOnBranchSuccess = (user) => ({
	type: progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_SUCCESS,
	payload: user,
});

export const fetchAllGroupOfficerUserBasedOnBranchFailure = (error) => ({
	type: progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_FAILURE,
	payload: error,
});

export const fetchAllGroupOfficerUserBasedOnBranchStart = (userCredentials) => ({
	type: progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_START,
	payload: userCredentials,
});

export const fetchAllGroupOfficerUserBasedOnBranchReset = () => ({
	type: progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESET,
});

export const fetchAllGroupOfficerUserBasedOnBranchChanged = () => ({
	type: progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_CHANGED,
});

export const fetchBranchProgressReportSuccess = (user) => ({
	type: progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_SUCCESS,
	payload: user,
});

export const fetchBranchProgressReportFailure = (error) => ({
	type: progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_FAILURE,
	payload: error,
});

export const fetchBranchProgressReportStart = (userCredentials) => ({
	type: progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_START,
	payload: userCredentials,
});

export const fetchBranchProgressReportReset = () => ({
	type: progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_RESET,
});

export const fetchBranchProgressReportChanged = () => ({
	type: progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_CHANGED,
});
