/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import AccountDayBookReportActionTypes from './accountDayBookReport.types';
import { accountDayBookReportGet } from './accountDayBookReport.service';

import {
	getAccountDayBookReportSuccess,
	getAccountDayBookReportFailure,
	getAccountDayBookReportResponseChanged,
} from './accountDayBookReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getAccountDayBookReport({ payload }) {
	try {
		const key = 'FetchAccountDayBookKey';
		const jsonData = {
			EntryDate: payload.EntryDate,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			AccountHeadID: payload.AccountHeadID,
			Infog_CompanyID: payload.Infog_CompanyID,
			AccountType: payload.AccountType,
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			SearchbyDateType: payload.SearchbyDateType,
			BranchID: payload.BranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(accountDayBookReportGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAccountDayBookReportFailure(responseData.data));
		} else {
			yield put(getAccountDayBookReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAccountDayBookReportFailure(error));
	}
}

export function* resetGetAccountDayBookReportListResponse() {
	yield put(getAccountDayBookReportResponseChanged());
}

export function* onGetAccountDayBookReportDetails() {
	yield takeLatest(
		AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_START,
		getAccountDayBookReport,
	);
}

export function* onGetAccountDayBookReportResponseReset() {
	yield takeLatest(
		AccountDayBookReportActionTypes.GET_ACCOUNT_DAY_BOOK_REPORT_RESPONSE_RESET,
		resetGetAccountDayBookReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */

export function* accountDayBookReportSaga() {
	yield all([
		call(onGetAccountDayBookReportDetails),
		call(onGetAccountDayBookReportResponseReset),
	]);
}
