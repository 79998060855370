
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import DemandFollowupActionTypes from './demand-followup-report.types';

import {
	DemandFollowupListGet,
} from './demand-followup-report.service';

import {
	getDemandFollowupDetailsSuccess,
	getDemandFollowupDetailsFailure,
	getDemandFollowupDetailsResponseChanged,
	getDemandFollowupDetailsResponseArrayChanged,
} from './demand-followup-report.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET DEMAND FOLLOWUP REPORT DETAILS  ******************** */
export function* getDemandFollowupList({ payload }) {
	try {
		const key = 'DemandFollowupKey';
		const jsonData = {
			companyID: payload.CompanyID,
			Branch: payload.branchID,
			DatabaseName: payload.databaseName,
			Month: payload.Month,
			Year: payload.Year
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(DemandFollowupListGet, encodeData, payload.ViewType);
		if (responseData.data.statusCode === '100') {
			yield put(getDemandFollowupDetailsFailure(responseData.data));
		} else {
			yield put(getDemandFollowupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDemandFollowupDetailsFailure(error));
	}
}

export function* resetGetDemandFollowupListResponse() {
	yield put(getDemandFollowupDetailsResponseChanged());
}

export function* resetGetDemandFollowupListResponseArray() {
	yield put(getDemandFollowupDetailsResponseArrayChanged());
}

export function* onGetDemandFollowupListDetails() {
	yield takeLatest(
		DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_START,
		getDemandFollowupList,
	);
}

export function* onGetDemandFollowupListResponseReset() {
	yield takeLatest(
		DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_RESPONSE_RESET,
		resetGetDemandFollowupListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT REPORT LIST ******************** */


export function* reportDemandFollowupSaga() {
	yield all([
		call(onGetDemandFollowupListDetails),
		call(onGetDemandFollowupListResponseReset),
	]);
}
