/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import DataVerificationActionTypes from './data-verification.types';

import {
    dataverificationListGet,
    officerBranchListGet,

} from './data-verification.service';

import {
    getDataVerificationDetailsSuccess,
    getDataVerificationDetailsFailure,
    getDataVerificationDetailsResponseChanged,
    getOfficerBranchDetailsSuccess,
    getOfficerBranchDetailsFailure,
    getOfficerBranchDetailsResponseChanged,

} from './data-verification.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PRECLOSE REPORT DETAILS  ******************** */
export function* getDataVerificationList({ payload }) {
	try {
		const key = 'GetDataVerificationReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
            IsThriftActive: payload.IsThriftActive,
			UserLevel: payload.userLevel,

		};
		const encodeData = encodeJson(jsonData, key);
		debugger;
		const responseData = yield call(dataverificationListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getDataVerificationDetailsFailure(responseData.data));
		} else {
			yield put(getDataVerificationDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getDataVerificationDetailsFailure(error));
	}
}

export function* resetGetDataVerificationListResponse() {
	yield put(getDataVerificationDetailsResponseChanged());
}

export function* onGetDataVerificationListDetails() {
	yield takeLatest(DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_START, getDataVerificationList);
}

export function* onGetDataVerificationListResponseReset() {
	yield takeLatest(DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_RESPONSE_RESET, resetGetDataVerificationListResponse);
}
/** *********************  END GET PRECLOSE REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_START, getOfficerBranchList);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET, resetGetDataVerificationListResponse);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */


export function* reportDataVerificationSaga() {
	yield all([
		call(onGetDataVerificationListDetails),
		call(onGetDataVerificationListResponseReset),
        call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
	]);
}
