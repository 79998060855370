/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchBusinessLoanIndex = (fetchData) => {
	const data = {
		BusinessLoanIndexDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchBusinessBasedLoanIndex`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchInstructionDetails = (instructionData) => {
	const data = {
		GetAllInstrumentListToken: instructionData,
	};
	const url = `${API_URL}fetchInstrumentList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchFinanceBasaedAccountTypeDetails = (fetchFinanceBasaedAccountType) => {
	const data = {
		FinanceBasedTypeDetailsToken: fetchFinanceBasaedAccountType,
	};
	const url = `${API_URL}fetchFinanceAccountBaseType`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createLoanDisbursement = (loanDisbursement) => {
	const data = {
		CreateBusinessLoanIndexToken: loanDisbursement,
	};
	const url = `${API_URL}createBusinessLoanIndex`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getVoucharCount = (countFetch) => {
	const data = {
		FetchVoucharCountToken: countFetch,
	};
	const url = `${API_URL}fetchVoucharCountBasedOnCompany`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBasedOnBusinessLoanInfo = (fetchLoan) => {
	const data = {
		BusinessLoanInfoDetailsToken: fetchLoan,
	};
	const url = `${API_URL}getLoanInfoBasedBusinessLoanStatus`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
