/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import AuditingToolActionTypes from './auditing-tool.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getAuditingToolListResponse: null,
	getAuditingToolListArr: [],

    getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_SUCCESS:
			return {
				...state,
				getAuditingToolListResponse: action.payload,
				getAuditingToolListArr: docodeResponse(action.payload, 'GetAuditingToolKey'),
			};

		case AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_FAILURE:
			return { ...state, getAuditingToolListResponse: action.payload };

		case AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_CHANGED:
			return { ...state, getAuditingToolListResponse: null };

        case AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
            return {
                ...state,
                getOfficerBranchListResponse: action.payload,
                getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
            };

        case AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
            return { ...state, getOfficerBranchListResponse: action.payload };

        case AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
            return { ...state, getOfficerBranchListResponse: null };
        default:
            return state;
    }
}