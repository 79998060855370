/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import PurposeOfLoanActionTypes from './purposeofloan.types';

/** ***************** ADD PURPOSE OF LOAN DETAILS START *********************** */
export const addPurposeOfLoanDetailsStart = (addData) => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_START,
	payload: addData,
});

export const addPurposeOfLoanDetailsSuccess = (successData) => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_SUCCESS,
	payload: successData,
});

export const addPurposeOfLoanDetailsFailure = (failureData) => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_FAILURE,
	payload: failureData,
});

export const addPurposeOfLoanDetailsResponseResetStart = () => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
});

export const addPurposeOfLoanDetailsResponseChanged = () => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD PURPOSE OF LOAN DETAILS END *********************** */

/** ***************** UPDATE PURPOSE OF LOAN DETAILS START *********************** */
export const updatePurposeOfLoanDetailsStart = (updateData) => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_START,
	payload: updateData,
});

export const updatePurposeOfLoanDetailsSuccess = (successData) => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_SUCCESS,
	payload: successData,
});

export const updatePurposeOfLoanDetailsFailure = (failureData) => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_FAILURE,
	payload: failureData,
});

export const updatePurposeOfLoanDetailsResponseResetStart = () => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
});

export const updatePurposeOfLoanDetailsResponseChanged = () => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE PURPOSE OF LOAN DETAILS END *********************** */

/** ***************** GET PURPOSE OF LOAN LIST START *********************** */
export const getPurposeOfLoanListStart = (getData) => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_START,
	payload: getData,
});

export const getPurposeOfLoanListSuccess = (successData) => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const getPurposeOfLoanListFailure = (failureData) => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const getPurposeOfLoanListResponseResetStart = () => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_RESPONSE_RESET,
});

export const getPurposeOfLoanListResponseChanged = () => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_LIST_RESPONSE_CHANGED,
});

/** ***************** GET PURPOSE OF LOAN LIST END *********************** */

/** ***************** FETCH PURPOSE OF LOAN DETAILS START *********************** */
export const fetchPurposeOfLoanDetailsStart = (fetchData) => ({
	type: PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_START,
	payload: fetchData,
});

export const fetchPurposeOfLoanDetailsSuccess = (successData) => ({
	type: PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchPurposeOfLoanDetailsFailure = (failureData) => ({
	type: PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchPurposeOfLoanDetailsResponseResetStart = () => ({
	type: PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
});

export const fetchPurposeOfLoanDetailsResponseChanged = () => ({
	type: PurposeOfLoanActionTypes.FETCH_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PURPOSE OF LOAN DETAILS END *********************** */

/** ***************** DELETE PURPOSE OF LOAN DETAILS START *********************** */
export const deletePurposeOfLoanDetailsStart = (deleteData) => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_START,
	payload: deleteData,
});

export const deletePurposeOfLoanDetailsSuccess = (successData) => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_SUCCESS,
	payload: successData,
});

export const deletePurposeOfLoanDetailsFailure = (failureData) => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_FAILURE,
	payload: failureData,
});

export const deletePurposeOfLoanDetailsResponseResetStart = () => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_RESET,
});

export const deletePurposeOfLoanDetailsResponseChanged = () => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE PURPOSE OF LOAN DETAILS END *********************** */
/** *************** GET PURPOSE OF LOAN CATEGORY LIST  ***************** */

export const getPurposeOfLoanCategoryStart = (purposeOfLoan) => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_START,
	payload: purposeOfLoan,
});

export const getPurposeOfLoanCategorySuccess = (Success) => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_SUCCESS,
	payload: Success,
});

export const getPurposeOfLoanCategoryFailure = (error) => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_FAILURE,
	payload: error,
});

export const getPurposeOfLoanCategoryReset = () => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_RESET,
});

export const getPurposeOfLoanCategoryChanged = () => ({
	type: PurposeOfLoanActionTypes.GET_PURPOSE_OF_LOAN_CATEGORY_CHANGED,
});

/** *************** ADD PURPOSE OF LOAN CATEGORY LIST  ***************** */

export const addPurposeOfLoanCategoryStart = (purposeOfLoan) => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_START,
	payload: purposeOfLoan,
});

export const addPurposeOfLoanCategorySuccess = (Success) => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_SUCCESS,
	payload: Success,
});

export const addPurposeOfLoanCategoryFailure = (error) => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_FAILURE,
	payload: error,
});

export const addPurposeOfLoanCategoryReset = () => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_RESET,
});

export const addPurposeOfLoanCategoryChanged = () => ({
	type: PurposeOfLoanActionTypes.ADD_PURPOSE_OF_LOAN_CATEGORY_CHANGED,
});

/** *************** UDPATE PURPOSE OF LOAN CATEGORY LIST  ***************** */

export const updatePurposeOfLoanCategoryStart = (purposeOfLoan) => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_CATEGORY_START,
	payload: purposeOfLoan,
});

export const updatePurposeOfLoanCategorySuccess = (Success) => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_CATEGORY_SUCCESS,
	payload: Success,
});

export const updatePurposeOfLoanCategoryFailure = (error) => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_CATEGORY_FAILURE,
	payload: error,
});

export const updatePurposeOfLoanCategoryReset = () => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_CATEGORY_RESET,
});

export const updatePurposeOfLoanCategoryChanged = () => ({
	type: PurposeOfLoanActionTypes.UPDATE_PURPOSE_OF_LOAN_CATEGORY_CHANGED,
});

/** *************** DELETE PURPOSE OF LOAN CATEGORY LIST  ***************** */

export const deletePurposeOfLoanCategoryStart = (purposeOfLoan) => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_CATEGORY_START,
	payload: purposeOfLoan,
});

export const deletePurposeOfLoanCategorySuccess = (Success) => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_CATEGORY_SUCCESS,
	payload: Success,
});

export const deletePurposeOfLoanCategoryFailure = (error) => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_CATEGORY_FAILURE,
	payload: error,
});

export const deletePurposeOfLoanCategoryReset = () => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_CATEGORY_RESET,
});

export const deletePurposeOfLoanCategoryChanged = () => ({
	type: PurposeOfLoanActionTypes.DELETE_PURPOSE_OF_LOAN_CATEGORY_CHANGED,
});
