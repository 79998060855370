/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import MemberActionTypes from './member.types';
import {
	memberDetailsAdd,
	memberDetailsUpdate,
	memberListGet,
	memberDetailsFetch,
	memberDetailsDelete,
	coApplicantHighMarkDetailsFetch,
	verifyDocumentDetailsFetch,
	checkHighmarkDetailsFetch,
	checkEquifaxDetailsFetch,
	checkCoApplicantHighmarkDetailsFetch,
	updateNoLoanMember,
	updateMemberIsApproveService,
	PaySprintLogDetailsFetch,
} from './member.service';

import {
	addMemberDetailsSuccess,
	addMemberDetailsFailure,
	addMemberDetailsResponseChanged,
	updateMemberDetailsSuccess,
	updateMemberDetailsFailure,
	updateMemberDetailsResponseChanged,
	getMemberDetailsSuccess,
	getMemberDetailsFailure,
	getMemberDetailsResponseChanged,
	fetchMemberDetailsSuccess,
	fetchMemberDetailsFailure,
	fetchMemberDetailsResponseChanged,
	deleteMemberDetailsSuccess,
	deleteMemberDetailsFailure,
	deleteMemberDetailsResponseChanged,
	fetchCoApplicantHighMarkDetailsSuccess,
	fetchCoApplicantHighMarkDetailsFailure,
	fetchCoApplicantHighMarkDetailsResponseChanged,
	verifyDocumentDetailsSuccess,
	verifyDocumentDetailsFailure,
	verifyDocumentDetailsResponseChanged,
	checkHighmarkDetailsSuccess,
	checkHighmarkDetailsFailure,
	checkHighmarkDetailsResponseChanged,
	checkEquifaxDetailsSuccess,
	checkEquifaxDetailsFailure,
	checkEquifaxDetailsResponseChanged,
	checkCoApplicantHighmarkDetailsSuccess,
	checkCoApplicantHighmarkDetailsFailure,
	checkCoApplicantHighmarkDetailsResponseChanged,
	updateNoLoanMemberFailure,
	updateNoLoanMemberSuccess,
	updateNoLoanMemberResponseChanged,
	updateMemberRejectFailure,
	updateMemberRejectSuccess,
	updateMemberRejectResponseChanged,
	updateMemberRecheckFailure,
	updateMemberRecheckSuccess,
	updateMemberRecheckResponseChanged,
	updateMemberApproveFailure,
	updateMemberApproveSuccess,
	updateMemberApproveResponseChanged,
	fetchPaySprintLogDetailsSuccess,
	fetchPaySprintLogDetailsFailure,
	fetchPaySprintLogDetailsResponseChanged,
} from './member.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD MEMBER DETAILS  ******************** */
export function* addMemberDetails({ payload }) {
	try {
		const key = 'AddMemberDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			MemberName: payload.memberName,
			MemberCity: payload.memberCity,
			MemberState: payload.memberState,
			MemberCountry: payload.memberCountry,
			MemberDistrict: payload.memberDistrict,
			NextUniqueAreaID: payload.nextUniqueAreaID,
			MemberAddress: payload.memberAddress,
			StartDate: payload.memberStartDate,
			MemberLocation: payload.memberLocation,
			MemberLatitude: payload.memberMapLatitude,
			MemberLongitude: payload.memberMapLongitude,
			MemberPinCode: payload.memberPincode,
			Village: payload.village,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessMemberID: payload.userAccessMemberID,
			InfogCompanyID: payload.infogCompanyID,
			DatabaseName: payload.databaseName,
			IsHeadOffice: payload.isHeadOffice ? 1 : 0,
			IsNbfcLogin: payload.isNbfcLogin,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addMemberDetailsFailure(responseData.data));
		} else {
			yield put(addMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addMemberDetailsFailure(error));
	}
}

export function* resetAddMemberResponse() {
	yield put(addMemberDetailsResponseChanged());
}

export function* onAddMemberDetails() {
	yield takeLatest(MemberActionTypes.ADD_MEMBER_DETAILS_START, addMemberDetails);
}

export function* onAddMemberDetailsResponseReset() {
	yield takeLatest(MemberActionTypes.ADD_MEMBER_DETAILS_RESPONSE_RESET, resetAddMemberResponse);
}
/** *********************  END ADD MEMBER DETAILS ******************** */

/** *********************  START UPDATE MEMBER DETAILS  ******************** */
export function* updateMemberDetails({ payload }) {
	try {
		const key = 'UpdateMemberDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			MemberID: payload.memberID,
			MemberName: payload.memberName,
			MemberCity: payload.memberCity,
			MemberState: payload.memberState,
			MemberCountry: payload.memberCountry,
			MemberDistrict: payload.memberDistrict,
			NextUniqueAreaID: payload.nextUniqueAreaID,
			MemberAddress: payload.memberAddress,
			StartDate: payload.memberStartDate,
			MemberLocation: payload.memberLocation,
			MemberLatitude: payload.memberMapLatitude,
			MemberLongitude: payload.memberMapLongitude,
			MemberPinCode: payload.memberPincode,
			Village: payload.village,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessMemberID: payload.userAccessMemberID,
			InfogCompanyID: payload.infogCompanyID,
			DatabaseName: payload.databaseName,
			IsHeadOffice: payload.isHeadOffice ? 1 : 0,
			IsNbfcLogin: payload.isNbfcLogin,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberDetailsFailure(responseData.data));
		} else {
			yield put(updateMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberDetailsFailure(error));
	}
}

export function* resetUpdateMemberDetailsResponse() {
	yield put(updateMemberDetailsResponseChanged());
}

export function* onUpdateMemberDetails() {
	yield takeLatest(MemberActionTypes.UPDATE_MEMBER_DETAILS_START, updateMemberDetails);
}

export function* onUpdateMemberResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_MEMBER_DETAILS_RESPONSE_RESET,
		resetUpdateMemberDetailsResponse,
	);
}
/** *********************  END UPDATE MEMBER DETAILS ******************** */

/** *********************  START GET MEMBER LIST  ******************** */
export function* getMemberList({ payload }) {
	try {
		const key = 'GetMemberListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			MemberID: payload.userAccessMemberID,
			searchValueSet: payload.searchValueSet,
			phoneNumber: payload.phoneNumber,
			aadharNumber: payload.aadharNumber,
			uniqueMemberID: payload.uniqueMemberID,
			memberApprovestatus: payload.memberApprovestatus,
			Limit: payload.limit,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getMemberDetailsFailure(responseData.data));
		} else {
			yield put(getMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getMemberDetailsFailure(error));
	}
}

export function* resetGetMemberListResponse() {
	yield put(getMemberDetailsResponseChanged());
}

export function* onGetMemberListDetails() {
	yield takeLatest(MemberActionTypes.GET_MEMBER_LIST_START, getMemberList);
}

export function* onGetMemberListResponseReset() {
	yield takeLatest(MemberActionTypes.GET_MEMBER_LIST_RESPONSE_RESET, resetGetMemberListResponse);
}
/** *********************  END GET MEMBER LIST ******************** */

/** *********************  START FETCH MEMBER DETAILS  ******************** */
export function* fetchMemberDetails({ payload }) {
	try {
		const key = 'FetchMemberDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			memberID: payload.memberID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchMemberDetailsFailure(responseData.data));
		} else {
			yield put(fetchMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchMemberDetailsFailure(error));
	}
}

export function* resetFetchMemberDetailsResponse() {
	yield put(fetchMemberDetailsResponseChanged());
}

export function* onFetchMemberDetails() {
	yield takeLatest(MemberActionTypes.FETCH_MEMBER_DETAILS_START, fetchMemberDetails);
}

export function* onFetchMemberDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_MEMBER_DETAILS_RESPONSE_RESET,
		resetFetchMemberDetailsResponse,
	);
}
/** *********************  END FETCH MEMBER DETAILS ******************** */

/** *********************  START DELETE MEMBER DETAILS  ******************** */
export function* deleteMember({ payload }) {
	try {
		const key = 'DeleteMemberDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.memberID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessMemberID: payload.userAccessMemberID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(memberDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteMemberDetailsFailure(responseData.data));
		} else {
			yield put(deleteMemberDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteMemberDetailsFailure(error));
	}
}

export function* resetDeleteMemberDetailsResponse() {
	yield put(deleteMemberDetailsResponseChanged());
}

export function* onDeleteMemberDetails() {
	yield takeLatest(MemberActionTypes.DELETE_MEMBER_DETAILS_START, deleteMember);
}

export function* onDeleteMemberDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.DELETE_MEMBER_DETAILS_RESPONSE_RESET,
		resetDeleteMemberDetailsResponse,
	);
}
/** *********************  END DELETE MEMBER DETAILS ******************** */

/** *********************  START FETCH CO APPLICANT DETAILS  ******************** */
export function* fetchCoApplicantHighMarkDetails({ payload }) {
	try {
		const key = 'FetchCoApplicantHighMarkDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			coApplicantID: payload.coApplicantID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(coApplicantHighMarkDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCoApplicantHighMarkDetailsFailure(responseData.data));
		} else {
			yield put(fetchCoApplicantHighMarkDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCoApplicantHighMarkDetailsFailure(error));
	}
}

export function* resetFetchCoApplicantHighMarkDetailsResponse() {
	yield put(fetchCoApplicantHighMarkDetailsResponseChanged());
}

export function* onFetchCoApplicantHighMarkDetails() {
	yield takeLatest(
		MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_START,
		fetchCoApplicantHighMarkDetails,
	);
}

export function* onFetchCoApplicantHighMarkDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET,
		resetFetchCoApplicantHighMarkDetailsResponse,
	);
}
/** *********************  END FETCH CO APPLICANT DETAILS ******************** */

/** *********************  START VERIFY DOCUMENT DETAILS  ******************** */
export function* verifyDocumentDetails({ payload }) {
	try {
		const key = 'WebVerifyKYCKey';
		const jsonData = {
			CompanyID: payload.companyID,
			coApplicantID: payload.coApplicantID,
			DatabaseName: payload.databaseName,
			documentType: payload.documentType,
			memberID: payload.memberID,
			documentNumber: payload.documentNumber,
			Type: payload.Type,
			GeneralCompanyID: payload.generalCompanyID,
			UserID: payload.userID,
			DOB: payload.DOB,
			randNum: payload.randNumForOTPVerification,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(
			verifyDocumentDetailsFetch,
			encodeData,
			jsonData.documentType,
		);
		if (responseData.data.statusCode === '100') {
			yield put(verifyDocumentDetailsFailure(responseData.data));
		} else {
			yield put(verifyDocumentDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(verifyDocumentDetailsFailure(error));
	}
}

export function* resetVerifyDocumentDetailsResponse() {
	yield put(verifyDocumentDetailsResponseChanged());
}

export function* onVerifyDocumentDetails() {
	yield takeLatest(MemberActionTypes.VERIFY_DOCUMENT_DETAILS_START, verifyDocumentDetails);
}

export function* onVerifyDocumentDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.VERIFY_DOCUMENT_DETAILS_RESPONSE_RESET,
		resetVerifyDocumentDetailsResponse,
	);
}
/** *********************  END VERIFY DOCUMENT DETAILS ******************** */
/** *********************  START CHECK HIGHMARK DETAILS  ******************** */
export function* checkHighmarkDetails({ payload }) {
	try {
		const key = 'CallHighMarkDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AppliedAmount: payload.appliedAmountForHighmark,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			IsConsumer: payload.isConsumer,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(
			checkHighmarkDetailsFetch,
			encodeData,
			jsonData.documentType,
		);
		if (responseData.data.statusCode === '100') {
			yield put(checkHighmarkDetailsFailure(responseData.data));
		} else {
			yield put(checkHighmarkDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkHighmarkDetailsFailure(error));
	}
}

export function* resetCheckHighmarkDetailsResponse() {
	yield put(checkHighmarkDetailsResponseChanged());
}

export function* onCheckHighmarkDetails() {
	yield takeLatest(MemberActionTypes.CHECK_HIGHMARK_DETAILS_START, checkHighmarkDetails);
}

export function* onCheckHighmarkDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.CHECK_HIGHMARK_DETAILS_RESPONSE_RESET,
		resetCheckHighmarkDetailsResponse,
	);
}
/** *********************  END VERIFY DOCUMENT DETAILS ******************** */

/** *********************  START CHECK EQUIFAX DETAILS  ******************** */
export function* checkEquifaxDetails({ payload }) {
	try {
		const key = 'CallEquifaxDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AppliedAmount: payload.appliedAmountForEquifax,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			ProductCode: payload.Product,
			IsConsumer: payload.isConsumer,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(
			checkEquifaxDetailsFetch,
			encodeData,
			jsonData.documentType,
		);
		if (responseData.data.statusCode === '100') {
			yield put(checkEquifaxDetailsFailure(responseData.data));
		} else {
			yield put(checkEquifaxDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkEquifaxDetailsFailure(error));
	}
}

export function* resetCheckEquifaxDetailsResponse() {
	yield put(checkEquifaxDetailsResponseChanged());
}

export function* onCheckEquifaxDetails() {
	yield takeLatest(MemberActionTypes.CHECK_EQUIFAX_DETAILS_START, checkEquifaxDetails);
}

export function* onCheckEquifaxDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.CHECK_EQUIFAX_DETAILS_RESPONSE_RESET,
		resetCheckEquifaxDetailsResponse,
	);
}
/** *********************  END CHECK EQUIFAX DETAILS ******************** */

/** *********************  START CO APPLICANT CHECK HIGHMARK DETAILS  ******************** */
export function* checkCoApplicantHighmarkDetails({ payload }) {
	try {
		const key = 'CoBorrowerCrifCheckKey';
		const jsonData = {
			CompanyID: payload.companyID,
			coApplicantID: payload.coApplicantID,
			DatabaseName: payload.databaseName,
			memberID: payload.memberID,
			IsConsumer: payload.isConsumer,
			AppliedAmount: payload.appliedAmount,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(checkCoApplicantHighmarkDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(checkCoApplicantHighmarkDetailsFailure(responseData.data));
		} else {
			yield put(checkCoApplicantHighmarkDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(checkCoApplicantHighmarkDetailsFailure(error));
	}
}

export function* resetCheckCoApplicantHighmarkDetailsResponse() {
	yield put(checkCoApplicantHighmarkDetailsResponseChanged());
}

export function* onCheckCoApplicantHighmarkDetails() {
	yield takeLatest(
		MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_START,
		checkCoApplicantHighmarkDetails,
	);
}

export function* onCheckCoApplicantHighmarkDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.CHECK_COAPPLICANT_HIGHMARK_DETAILS_RESPONSE_RESET,
		resetCheckCoApplicantHighmarkDetailsResponse,
	);
}
/** *********************  END VERIFY DOCUMENT DETAILS ******************** */

/** *********************  START UPDATE NO LOAN MEMBER DETAILS  ******************** */
export function* updateNoLoanMemberDetails({ payload }) {
	try {
		const key = 'updateNoLoanMemberKey';
		const jsonData = {
			CompanyID: payload.companyID,
			isNoLoanMember: payload.isNoLoanMember,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateNoLoanMember, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateNoLoanMemberFailure(responseData.data));
		} else {
			yield put(updateNoLoanMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateNoLoanMemberFailure(error));
	}
}

export function* resetUpdateNoLoanMemberResponse() {
	yield put(updateNoLoanMemberResponseChanged());
}

export function* onUpdateNoLoanMember() {
	yield takeLatest(MemberActionTypes.UPDATE_NO_LOAN_MEMBER_START, updateNoLoanMemberDetails);
}

export function* onupdateNoLoanMemberDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.UPDATE_NO_LOAN_MEMBER_RESPONSE_RESET,
		resetUpdateNoLoanMemberResponse,
	);
}
/** *********************  END NO LOAN MEMBER DETAILS ******************** */

/** *********************  START UPDATE REJECT MEMBER DETAILS  ******************** */
export function* updateMemberDetailsReject({ payload }) {
	try {
		const key = 'updateMemberIsApproveKey';
		const jsonData = {
			CompanyID: payload.companyID,
			userName: payload.userName,
			userID: payload.userID,
			Notes: payload.isMemberApproveNotes,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			IsMemberApproved: payload.IsMemberApproved,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateMemberIsApproveService, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberRejectFailure(responseData.data));
		} else {
			yield put(updateMemberRejectSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberRejectFailure(error));
	}
}

export function* resetUpdateMemberRejectResponse() {
	yield put(updateMemberRejectResponseChanged());
}

export function* onUpdateMemberReject() {
	yield takeLatest(MemberActionTypes.REJECT_MEMBER_START, updateMemberDetailsReject);
}

export function* onupdateMemberRejectDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.REJECT_MEMBER_RESPONSE_RESET,
		resetUpdateMemberRejectResponse,
	);
}
/** *********************  END REJECT MEMBER DETAILS ******************** */

/** *********************  START UPDATE RECHECK MEMBER DETAILS  ******************** */
export function* updateMemberDetailsRecheck({ payload }) {
	try {
		const key = 'updateMemberIsApproveKey';
		const jsonData = {
			CompanyID: payload.companyID,
			userName: payload.userName,
			userID: payload.userID,
			Notes: payload.isMemberApproveNotes,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			IsMemberApproved: payload.IsMemberApproved,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateMemberIsApproveService, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberRecheckFailure(responseData.data));
		} else {
			yield put(updateMemberRecheckSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberRecheckFailure(error));
	}
}

export function* resetUpdateMemberRecheckResponse() {
	yield put(updateMemberRecheckResponseChanged());
}

export function* onUpdateMemberRecheck() {
	yield takeLatest(MemberActionTypes.RECHECK_MEMBER_START, updateMemberDetailsRecheck);
}

export function* onupdateMemberRecheckDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.RECHECK_MEMBER_RESPONSE_RESET,
		resetUpdateMemberRecheckResponse,
	);
}
/** *********************  END RECHECK MEMBER DETAILS ******************** */


/** *********************  START UPDATE APPROVE MEMBER DETAILS  ******************** */
export function* updateMemberDetailsApprove({ payload }) {
	try {
		const key = 'updateMemberIsApproveKey';
		const jsonData = {
			CompanyID: payload.companyID,
			userName: payload.userName,
			memberName: payload.memberName,
			userID: payload.userID,
			Notes: payload.isMemberApproveNotes,
			DatabaseName: payload.databaseName,
			MemberID: payload.memberID,
			IsMemberApproved: payload.IsMemberApproved,
			IsAppraisalApproveNeeded: payload.IsAppraisalApproveNeeded,
			AppraisalApproveID: payload.AppraisalApproveID,
			AppraisalApproveSetupID: payload.AppraisalApproveSetupID,
			AppraisalLevel: payload.AppraisalLevel,
			isAllAppraisalApproved: payload.isAllApproved,
			NoOfAppraisalApproval: payload.NoOfAppraisalApproval,
			isNoLoanMember: payload.isNoLoanMember,
			Level: payload.userLevel,
			IsWorkflowManagement: payload.isWorkFlowManagementNeeded,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateMemberIsApproveService, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateMemberApproveFailure(responseData.data));
		} else {
			yield put(updateMemberApproveSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateMemberApproveFailure(error));
	}
}

export function* resetUpdateMemberApproveResponse() {
	yield put(updateMemberApproveResponseChanged());
}

export function* onUpdateMemberApprove() {
	yield takeLatest(MemberActionTypes.APPROVE_MEMBER_START, updateMemberDetailsApprove);
}

export function* onupdateMemberApproveDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.APPROVE_MEMBER_RESPONSE_RESET,
		resetUpdateMemberApproveResponse,
	);
}
/** *********************  END APPROVE MEMBER DETAILS ******************** */

/** *********************  START FETCH PAYSPRINT LOG DETAILS  ******************** */
export function* fetchPaySprintLogDetails({ payload }) {
	try {
		const key = 'FetchPaySprintLogDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			MemberID: payload.memberID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(PaySprintLogDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPaySprintLogDetailsFailure(responseData.data));
		} else {
			yield put(fetchPaySprintLogDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPaySprintLogDetailsFailure(error));
	}
}

export function* resetFetchPaySprintLogDetailsResponse() {
	yield put(fetchPaySprintLogDetailsResponseChanged());
}

export function* onFetchPaySprintLogDetails() {
	yield takeLatest(MemberActionTypes.FETCH_PAYSPRINT_DETAILS_START, fetchPaySprintLogDetails);
}

export function* onFetchPaySprintLogDetailsResponseReset() {
	yield takeLatest(
		MemberActionTypes.FETCH_PAYSPRINT_DETAILS_RESPONSE_RESET,
		resetFetchPaySprintLogDetailsResponse,
	);
}
/** *********************  END FETCH PAYSPRINT LOG DETAILS ******************** */
export function* memberMasterSaga() {
	yield all([
		call(onAddMemberDetails),
		call(onAddMemberDetailsResponseReset),
		call(onUpdateMemberDetails),
		call(onUpdateMemberResponseReset),
		call(onGetMemberListDetails),
		call(onGetMemberListResponseReset),
		call(onFetchMemberDetails),
		call(onFetchMemberDetailsResponseReset),
		call(onDeleteMemberDetails),
		call(onDeleteMemberDetailsResponseReset),
		call(onFetchCoApplicantHighMarkDetails),
		call(onFetchCoApplicantHighMarkDetailsResponseReset),
		call(onVerifyDocumentDetails),
		call(onVerifyDocumentDetailsResponseReset),
		call(onCheckHighmarkDetails),
		call(onCheckHighmarkDetailsResponseReset),
		call(onCheckEquifaxDetails),
		call(onCheckEquifaxDetailsResponseReset),
		call(onCheckCoApplicantHighmarkDetails),
		call(onCheckCoApplicantHighmarkDetailsResponseReset),
		call(onUpdateNoLoanMember),
		call(onupdateNoLoanMemberDetailsResponseReset),
		call(onUpdateMemberReject),
		call(onupdateMemberRejectDetailsResponseReset),
		call(onUpdateMemberRecheck),
		call(onupdateMemberRecheckDetailsResponseReset),
		call(onUpdateMemberApprove),
		call(onupdateMemberApproveDetailsResponseReset),
		call(onFetchPaySprintLogDetails),
		call(onFetchPaySprintLogDetailsResponseReset),
	]);
}
