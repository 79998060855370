/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const memberDetailsAdd = (addData) => {
	const data = {
		AddMemberDetailsToken: addData,
	};
	const url = `${API_URL}addMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsUpdate = (updateData) => {
	const data = {
		UpdateMemberDetailsToken: updateData,
	};
	const url = `${API_URL}updateMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberListGet = (listData) => {
	const data = {
		GetMemberListToken: listData,
	};
	const url = `${API_URL}getMemberList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsFetch = (fetchData) => {
	const data = {
		FetchMemberToken: fetchData,
	};
	const url = `${API_URL}fetchMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const memberDetailsDelete = (deleteData) => {
	const data = {
		DeleteMemberDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteMemberDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CO-APPLICANT HIGHMARK

export const coApplicantHighMarkDetailsFetch = (fetchData) => {
	const data = {
		FetchCoApplicantHighMarkToken: fetchData,
	};
	const url = `${API_URL}fetchCoApplicantHighMarkDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CHECK HIGHMARK

export const checkHighmarkDetailsFetch = (fetchData) => {
	const data = {
		checkHighMarkToken: fetchData,
	};
	const url = `${API_URL}callHighMarkWeb`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// CHECK EQUIFAX

export const checkEquifaxDetailsFetch = (fetchData) => {
	const data = {
		checkEquifaxToken: fetchData,
	};
	const url = `${API_URL}UploadEquifaxDetailsWeb`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
// CHECK CO APPLICANT HIGHMARK

export const checkCoApplicantHighmarkDetailsFetch = (fetchData) => {
	const data = {
		CheckCoAppliantHighMarkToken: fetchData,
	};
	const url = `${API_URL}callCoApplicantHighMarkWeb`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// UPDATE NO LOAN MEMBER
export const updateNoLoanMember = (fetchData) => {
	const data = {
		updateNoLoanMemberToken: fetchData,
	};
	const url = `${API_URL}updateNoLoanMemberWeb`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// UPDATE REJECT MEMBER
export const updateMemberIsApproveService = (fetchData) => {
	const data = {
		updateIsApproveMemberToken: fetchData,
	};
	const url = `${API_URL}UpdateMemberIsApprove`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// VERIFY DOCUMENTS

export const verifyDocumentDetailsFetch = (fetchData, documentType) => {
	const data = {
		DocumentVerificationToken: fetchData,
	};
	let API_NAME = '';
	if (documentType === 'Aadhar') {
		API_NAME = 'VerifyAadharKYCWeb';
	}
	if (documentType === 'AadharOTP') {
		API_NAME = 'AadharOtpVerifyWeb';
	}
	if (documentType === 'PAN' || documentType === 'PanCard') {
		API_NAME = 'VerifyPanDetailedKYCWeb';
	}
	if (documentType === 'PassPort') {
		API_NAME = 'VerifyPanDetailedKYCWeb';
	}
	if (documentType === 'DrivingLicense') {
		API_NAME = 'VerifyDrivingKYCWeb';
	}
	if (documentType === 'VoterID') {
		API_NAME = 'VerifyVoterIDKYCWeb';
	}
	if (documentType === 'AadharOTPValidation') {
		API_NAME = 'AadharOtpValidateVerifyWeb';
	}
	const url = `${API_URL}${API_NAME}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

// PAYSSPRINT LOG FETCH

export const PaySprintLogDetailsFetch = (fetchData) => {
	const data = {
		FetchPaySprintLogToken: fetchData,
	};
	const url = `${API_URL}fetchPaySprintLogDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
