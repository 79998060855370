/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import ClosedLoanDetailsActionTypes from './closed-loan-details.types';

/** ***************** GET PROGRESS REPORT DETAILS START *********************** */
export const getClosedLoanDetailsDetailsStart = (getData) => ({
	type: ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_START,
	payload: getData,
});

export const getClosedLoanDetailsDetailsSuccess = (successData) => ({
	type: ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_SUCCESS,
	payload: successData,
});

export const getClosedLoanDetailsDetailsFailure = (failureData) => ({
	type: ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_FAILURE,
	payload: failureData,
});

export const getClosedLoanDetailsDetailsResponseResetStart = () => ({
	type: ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_RESPONSE_RESET,
});

export const getClosedLoanDetailsDetailsResponseChanged = () => ({
	type: ClosedLoanDetailsActionTypes.GET_CLOSED_LOAN_DETAILS_LIST_RESPONSE_CHANGED,
});
/** ***************** GET PROGRESS REPORT DETAILS END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: ClosedLoanDetailsActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

