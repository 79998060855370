/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';
import staffReportActionTypes from './staffReport.types';
// eslint-disable-next-line import/named
import {
	staffReportDetailsGet,
	staffReportExcel,
	staffOfficerDetails,
} from './staffReport.service';
import {
	getStaffDetailsSuccess,
	getStaffDetailsFailure,
	getStaffDetailsResponseChanged,
	fetchstaffReportExcelFailure,
	fetchstaffReportExcelSuccess,
	fetchstaffReportExcelChanged,
	getStaffOfficerDetailsSuccess,
	getStaffOfficerDetailsFailure,
	getStaffOfficerDetailsResponseChanged,
} from './staffReport.action';
import { encodeJson } from '../../enode-decode';
/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getStaffReportList({ payload }) {
	// debugger;
	try {
		const key = 'STAFFDETAILSREPORTKEY';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			OfficerID: payload.officerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffReportDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getStaffDetailsFailure(responseData.data));
		} else {
			yield put(getStaffDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStaffDetailsFailure(error));
	}
}

export function* resetGetStaffReportListResponse() {
	yield put(getStaffDetailsResponseChanged());
}

export function* onGetStaffdueReportListDetails() {
	yield takeLatest(staffReportActionTypes.GET_STAFF_DETAILS_START, getStaffReportList);
}

export function* onGetStaffReportListResponseReset() {
	yield takeLatest(
		staffReportActionTypes.GET_STAFF_DETAILS_RESPONSE_RESET,
		resetGetStaffReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */

/** *********************  START STAFF DETAILS  ******************** */

export function* getStaffReportExcel({ payload }) {
	// debugger;
	try {
		// const key = 'STAFFDETAILSREPORTKEY';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			OfficerID: payload.officerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
		};
		// const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffReportExcel, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchstaffReportExcelFailure(responseData.data));
		} else {
			yield put(fetchstaffReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchstaffReportExcelFailure(error));
	}
}

export function* resetGetStaffReportExcelResponse() {
	yield put(fetchstaffReportExcelChanged());
}

export function* onGetStaffDetailsReportExcelListDetails() {
	yield takeLatest(staffReportActionTypes.GET_STAFF_DETAILS_EXCEL_START, getStaffReportExcel);
}

export function* onGetStaffReportExcelListResponseReset() {
	yield takeLatest(
		staffReportActionTypes.GET_STAFF_DETAILS_EXCEL_RESPONSE_RESET,
		resetGetStaffReportExcelResponse,
	);
}
/** ********************* STAFF OFFICER DETAILS   ******************** */
export function* GetStaffOfficer({ payload }) {
	try {
		const key = 'STAFFOFFICERKEY';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			OfficerID: payload.officerID,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffOfficerDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getStaffOfficerDetailsFailure(responseData.data));
		} else {
			yield put(getStaffOfficerDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStaffOfficerDetailsFailure(error));
	}
}

export function* resetGetStaffOfficerListResponse() {
	yield put(getStaffOfficerDetailsResponseChanged());
}

export function* onGetStaffOfficerListDetails() {
	yield takeLatest(staffReportActionTypes.GET_STAFF_OFFICER_START, GetStaffOfficer);
}

export function* onGetStaffofficerResponseReset() {
	yield takeLatest(
		staffReportActionTypes.GET_STAFF_OFFICER_RESPONSE_RESET,
		resetGetStaffOfficerListResponse,
	);
}
export function* StaffReportSaga() {
	yield all([
		call(onGetStaffdueReportListDetails),
		call(onGetStaffReportListResponseReset),
		call(onGetStaffDetailsReportExcelListDetails),
		call(onGetStaffReportExcelListResponseReset),
		call(onGetStaffOfficerListDetails),
		call(onGetStaffofficerResponseReset),
	]);
}
