/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchDetailsActionTypes from './branch-details.types';
import {
	branchDetailsAdd,
	fetchBranchDetails,
	updateBranchDetails,
} from './branch-details.service';

import {
	addBusinessBranchSuccess,
	addBusinessBranchFailure,
	addBusinessBranchResponseChanged,
	fetchBusinessBranchSuccess,
	fetchBusinessBranchFailure,
	fetchBusinessBranchResponseChanged,
	updateBusinessBranchSuccess,
	updateBusinessBranchFailure,
	updateBusinessBranchResponseChanged,
} from './branch-details.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS BRANCH  ******************** */
export function* businessBranchAdd({ payload }) {
	try {
		const key = 'BusinessBranchKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBranchList: payload.branchListArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessBranchFailure(responseData.data));
		} else {
			yield put(addBusinessBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessBranchFailure(error));
	}
}

export function* resetAddBusinessBranchResponse() {
	yield put(addBusinessBranchResponseChanged());
}

export function* onAddBusinessBranchDetails() {
	yield takeLatest(BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_START, businessBranchAdd);
}

export function* onAddBusinessBranchResponseReset() {
	yield takeLatest(
		BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_RESPONSE_RESET,
		resetAddBusinessBranchResponse,
	);
}
/** *********************  END ADD BUSINESS BRANCH ******************** */

/** *********************  START FETCH BUSINESS BRANCH  ******************** */
export function* businessBranchFetch({ payload }) {
	try {
		const key = 'GetBusinessBranchKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const updateData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBranchDetails, updateData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessBranchFailure(responseData.data));
		} else {
			yield put(fetchBusinessBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessBranchFailure(error));
	}
}

export function* resetFetchBusinessBranchResponse() {
	yield put(fetchBusinessBranchResponseChanged());
}

export function* onFetchBusinessBranchDetails() {
	yield takeLatest(BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_START, businessBranchFetch);
}

export function* onFetchBusinessBranchResponseReset() {
	yield takeLatest(
		BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_RESPONSE_RESET,
		resetFetchBusinessBranchResponse,
	);
}
/** *********************  END FETCH BUSINESS BRANCH ******************** */

/** *********************  START UPDATE BUSINESS BRANCH  ******************** */
export function* businessBranchUpadate({ payload }) {
	try {
		const key = 'UpdateBusinessBranchKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			BusinessBranchList: payload.branchListArr,
		};
		const updateData = encodeJson(jsonData, key);
		const responseData = yield call(updateBranchDetails, updateData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessBranchFailure(responseData.data));
		} else {
			yield put(updateBusinessBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessBranchFailure(error));
	}
}

export function* resetUpdateBusinessBranchResponse() {
	yield put(updateBusinessBranchResponseChanged());
}

export function* onUpdateBusinessBranchDetails() {
	yield takeLatest(BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_START, businessBranchUpadate);
}

export function* onUpdateBusinessBranchResponseReset() {
	yield takeLatest(
		BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_RESPONSE_RESET,
		resetUpdateBusinessBranchResponse,
	);
}
/** *********************  END UPDATE BUSINESS BRANCH ******************** */

export function* branchDetailsSaga() {
	yield all([
		call(onAddBusinessBranchDetails),
		call(onAddBusinessBranchResponseReset),
		call(onFetchBusinessBranchDetails),
		call(onFetchBusinessBranchResponseReset),
		call(onUpdateBusinessBranchDetails),
		call(onUpdateBusinessBranchResponseReset),
	]);
}
