/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import LTVReportTypes from './ltv-report.types';

import { getLTVReportDetails } from './ltv-report.service';

import {
    getLTVReportSuccess,
    getLTVReportFailure,
    getLTVReportResponseResetChanged 
} from './ltv-report.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET LTV DETAILS  ******************** */
export function* getLTVReport({ payload }) {
	try {
		const key = 'GetLTVReportDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			AsOnDate: payload.AsOnDate,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getLTVReportDetails, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(getLTVReportFailure(responseData.data));
		} else {
			yield put(getLTVReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLTVReportFailure(error));
	}
}

export function* resetGetLTVReportResponse() {
	yield put(getLTVReportResponseResetChanged());
}

export function* onGetLTVReportDetails() {
	yield takeLatest(LTVReportTypes.GET_LTV_REPORT_START, getLTVReport);
}

export function* onGetLTVReportResponseReset() {
	yield takeLatest(LTVReportTypes.GET_LTV_REPORT_RESPONSE_RESET_START, resetGetLTVReportResponse);
}
/** *********************  END GET LTV DETAILS ******************** */

export function* LTVReportSaga() {
	yield all([call(onGetLTVReportDetails), 
        call(onGetLTVReportResponseReset)]);
}
