const DemandCollectionActionTypes = {

    GET_DEMAND_COLLECTION_LIST_START: 'get_demand_collection_start',
	GET_DEMAND_COLLECTION_LIST_SUCCESS: 'get_demand_collection_success',
	GET_DEMAND_COLLECTION_LIST_FAILURE: 'get_demand_collection_failure',
	GET_DEMAND_COLLECTION_LIST_RESPONSE_RESET: 'get_demand_collection_response_reset',
	GET_DEMAND_COLLECTION_LIST_RESPONSE_CHANGED: 'get_demand_collection_response_changed',
	GET_DEMAND_COLLECTION_LIST_RESPONSE_ARRAY_CHANGED: 'get_demand_collection_response_array_changed',
    
	GET_DEMAND_COLLECTION_EXCEL_LIST_START: 'get_demand_collection_excel_list_start',
	GET_DEMAND_COLLECTION_EXCEL_LIST_SUCCESS: 'get_demand_collection_excel_success',
	GET_DEMAND_COLLECTION_EXCEL_LIST_FAILURE: 'get_demand_collection_excel_failure',
	GET_DEMAND_COLLECTION_EXCEL_LIST_RESPONSE_RESET: 'get_demand_collection_excel_response_reset',
	GET_DEMAND_COLLECTION_EXCEL_LIST_RESPONSE_CHANGED: 'get_demand_collection_excel_response_changed',
    
	GET_DEMAND_COLLECTION_PDF_LIST_START: 'get_demand_collection_pdf_start',
	GET_DEMAND_COLLECTION_PDF_LIST_SUCCESS: 'get_demand_collection_pdf_success',
	GET_DEMAND_COLLECTION_PDF_LIST_FAILURE: 'get_demand_collection_pdf_failure',
	GET_DEMAND_COLLECTION_PDF_LIST_RESPONSE_RESET: 'get_demand_collection_pdf_response_reset',
	GET_DEMAND_COLLECTION_PDF_LIST_RESPONSE_CHANGED: 'get_demand_collection_pdf_response_changed',

}
export default DemandCollectionActionTypes;