/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ProductActionTypes from './product.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addProductDetailsResponse: null,
	updateProductDetailsResponse: null,
	getProductListResponse: null,
	getProductListArr: [],
	fetchProductDetailsResponse: null,
	fetchProductDetailsArr: [],
	deleteProductDetailsResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ProductActionTypes.ADD_PRODUCT_DETAILS_SUCCESS:
			return { ...state, addProductDetailsResponse: action.payload };

		case ProductActionTypes.ADD_PRODUCT_DETAILS_FAILURE:
			return { ...state, addProductDetailsResponse: action.payload };

		case ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, addProductDetailsResponse: null };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_SUCCESS:
			return { ...state, updateProductDetailsResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_FAILURE:
			return { ...state, updateProductDetailsResponse: action.payload };

		case ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateProductDetailsResponse: null };

		case ProductActionTypes.GET_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				getProductListResponse: action.payload,
				getProductListArr: docodeResponse(action.payload, 'GetProductListKey'),
			};

		case ProductActionTypes.GET_PRODUCT_LIST_FAILURE:
			return { ...state, getProductListResponse: action.payload };

		case ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_CHANGED:
			return { ...state, getProductListResponse: null };

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
			return {
				...state,
				fetchProductDetailsResponse: action.payload,
				fetchProductDetailsArr: docodeResponse(action.payload, 'FetchProductDetailsKey'),
			};

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE:
			return { ...state, fetchProductDetailsResponse: action.payload };

		case ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchProductDetailsResponse: null, fetchProductDetailsArr: [] };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_SUCCESS:
			return { ...state, deleteProductDetailsResponse: action.payload };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_FAILURE:
			return { ...state, deleteProductDetailsResponse: action.payload };

		case ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteProductDetailsResponse: null };

		default:
			return state;
	}
};
