/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import RoleActionTypes from './role.types';

/** ***************** ADD ROLE DETAILS START *********************** */
export const addRoleDetailsStart = (addData) => ({
	type: RoleActionTypes.ADD_ROLE_DETAILS_START,
	payload: addData,
});

export const addRoleDetailsSuccess = (successData) => ({
	type: RoleActionTypes.ADD_ROLE_DETAILS_SUCCESS,
	payload: successData,
});

export const addRoleDetailsFailure = (failureData) => ({
	type: RoleActionTypes.ADD_ROLE_DETAILS_FAILURE,
	payload: failureData,
});

export const addRoleDetailsResponseResetStart = () => ({
	type: RoleActionTypes.ADD_ROLE_DETAILS_RESPONSE_RESET,
});

export const addRoleDetailsResponseChanged = () => ({
	type: RoleActionTypes.ADD_ROLE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD ROLE DETAILS END *********************** */

/** ***************** UPDATE ROLE DETAILS START *********************** */
export const updateRoleDetailsStart = (updateData) => ({
	type: RoleActionTypes.UPDATE_ROLE_DETAILS_START,
	payload: updateData,
});

export const updateRoleDetailsSuccess = (successData) => ({
	type: RoleActionTypes.UPDATE_ROLE_DETAILS_SUCCESS,
	payload: successData,
});

export const updateRoleDetailsFailure = (failureData) => ({
	type: RoleActionTypes.UPDATE_ROLE_DETAILS_FAILURE,
	payload: failureData,
});

export const updateRoleDetailsResponseResetStart = () => ({
	type: RoleActionTypes.UPDATE_ROLE_DETAILS_RESPONSE_RESET,
});

export const updateRoleDetailsResponseChanged = () => ({
	type: RoleActionTypes.UPDATE_ROLE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE ROLE DETAILS END *********************** */

/** ***************** GET ROLE LIST START *********************** */
export const getRoleListStart = (getData) => ({
	type: RoleActionTypes.GET_ROLE_LIST_START,
	payload: getData,
});

export const getRoleListSuccess = (successData) => ({
	type: RoleActionTypes.GET_ROLE_LIST_SUCCESS,
	payload: successData,
});

export const getRoleListFailure = (failureData) => ({
	type: RoleActionTypes.GET_ROLE_LIST_FAILURE,
	payload: failureData,
});

export const getRoleListResponseResetStart = () => ({
	type: RoleActionTypes.GET_ROLE_LIST_RESPONSE_RESET,
});

export const getRoleListResponseChanged = () => ({
	type: RoleActionTypes.GET_ROLE_LIST_RESPONSE_CHANGED,
});

/** ***************** GET ROLE LIST END *********************** */

/** ***************** FETCH ROLE DETAILS START *********************** */
export const fetchRoleDetailsStart = (fetchData) => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_START,
	payload: fetchData,
});

export const fetchRoleDetailsSuccess = (successData) => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchRoleDetailsFailure = (failureData) => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchRoleDetailsResponseResetStart = () => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_RESET,
});

export const fetchRoleDetailsResponseChanged = () => ({
	type: RoleActionTypes.FETCH_ROLE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH ROLE DETAILS END *********************** */

/** ***************** DELETE ROLE DETAILS START *********************** */
export const deleteRoleDetailsStart = (deleteData) => ({
	type: RoleActionTypes.DELETE_ROLE_DETAILS_START,
	payload: deleteData,
});

export const deleteRoleDetailsSuccess = (successData) => ({
	type: RoleActionTypes.DELETE_ROLE_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteRoleDetailsFailure = (failureData) => ({
	type: RoleActionTypes.DELETE_ROLE_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteRoleDetailsResponseResetStart = () => ({
	type: RoleActionTypes.DELETE_ROLE_DETAILS_RESPONSE_RESET,
});

export const deleteRoleDetailsResponseChanged = () => ({
	type: RoleActionTypes.DELETE_ROLE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE ROLE DETAILS END *********************** */

/** ***************** FETCH ALL ROLE LIST START *********************** */
export const fetchAllRoleListStart = (fetchRoles) => ({
	type: RoleActionTypes.FETCH_ALL_ROLE_START,
	payload: fetchRoles,
});

export const fetchAllRoleListSuccess = (successData) => ({
	type: RoleActionTypes.FETCH_ALL_ROLE_SUCCESS,
	payload: successData,
});

export const fetchAllRoleListFailure = (failureData) => ({
	type: RoleActionTypes.FETCH_ALL_ROLE_FAILURE,
	payload: failureData,
});

export const fetchAllRoleListResponseResetStart = () => ({
	type: RoleActionTypes.FETCH_ALL_ROLE_RESPONSE_RESET,
});

export const fetchAllRoleListResponseChanged = () => ({
	type: RoleActionTypes.FETCH_ALL_ROLE_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL ROLE LIST END *********************** */
