/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import AuditTrailReportActionTypes from './auditTrailReport.types';

/** ***************** GET BRANCH COLLECTION REPORT START *********************** */
export const getAuditTrailReportStart = (getData) => ({
	type: AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_START,
	payload: getData,
});

export const getAuditTrailReportSuccess = (successData) => ({
	type: AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getAuditTrailReportFailure = (failureData) => ({
	type: AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getAuditTrailReportResponseResetStart = () => ({
	type: AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_RESET,
});

export const getAuditTrailReportResponseChanged = () => ({
	type: AuditTrailReportActionTypes.GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** AUDIT TRAIL REPORT REPORT  *********************** */
/** ***************** AUDIT TRAIL REPORT REPORT EXCEL  *********************** */

export const getAuditTrailReportExcelStart = (user) => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_START,
	payload: user,
});

export const getAuditTrailReportExcelSuccess = (user) => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_SUCCESS,
	payload: user,
});

export const getAuditTrailReportExcelFailure = (error) => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_FAILURE,
	payload: error,
});

export const getAuditTrailReportExcelResponseReset = () => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_RESET,
});

export const getAuditTrailReportExcelResponseChanged = () => ({
	type: AuditTrailReportActionTypes.FETCH_POST_TRAIL_REPORT_LIST_RESPONSE_CHANGED,
});

/** ***************** AUDIT TRAIL REPORT REPORT JSON  *********************** */

export const getAuditTrailReportJsonStart = (user) => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_START,
	payload: user,
});

export const getAuditTrailReportJsonSuccess = (user) => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_SUCCESS,
	payload: user,
});

export const getAuditTrailReportJsonFailure = (error) => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_FAILURE,
	payload: error,
});

export const getAuditTrailReportJsonResponseReset = () => ({
	type: AuditTrailReportActionTypes.FETCH_POST_AUDIT_TRAIL_REPORT_JSON_RESET,
});

export const getAuditTrailReportJsonResponseChanged = () => ({
	type: AuditTrailReportActionTypes.FETCH_POST_TRAIL_REPORT_LIST_JSON_RESPONSE_CHANGED,
});
