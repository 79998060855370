const BusinessLoanProposalActionTypes = {
	ADD_BUSINESS_LOAN_PROPOSAL_START: 'add_business_loan_proposal_start',
	ADD_BUSINESS_LOAN_PROPOSAL_SUCCESS: 'add_business_loan_proposal_success',
	ADD_BUSINESS_LOAN_PROPOSAL_FAILURE: 'add_business_loan_proposal_failure',
	ADD_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET: 'add_business_loan_proposal_response_reset',
	ADD_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED: 'add_business_loan_proposal_response_changed',

	UPDATE_BUSINESS_LOAN_PROPOSAL_START: 'update_business_loan_proposal_start',
	UPDATE_BUSINESS_LOAN_PROPOSAL_SUCCESS: 'update_business_loan_proposal_success',
	UPDATE_BUSINESS_LOAN_PROPOSAL_FAILURE: 'update_business_loan_proposal_failure',
	UPDATE_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET: 'update_business_loan_proposal_response_reset',
	UPDATE_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED:
		'update_business_loan_proposal_response_changed',

	GET_BUSINESS_LOAN_PROPOSAL_LIST_START: 'get_business_loan_proposal_list_start',
	GET_BUSINESS_LOAN_PROPOSAL_LIST_SUCCESS: 'get_business_loan_proposal_list_success',
	GET_BUSINESS_LOAN_PROPOSAL_LIST_FAILURE: 'get_business_loan_proposal_list_failure',
	GET_BUSINESS_LOAN_PROPOSAL_LIST_RESPONSE_RESET:
		'get_business_loan_proposal_list_response_reset',
	GET_BUSINESS_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED:
		'get_business_loan_proposal_list_response_changed',

	FETCH_BUSINESS_LOAN_PROPOSAL_START: 'fetch_business_loan_proposal_start',
	FETCH_BUSINESS_LOAN_PROPOSAL_SUCCESS: 'fetch_business_loan_proposal_success',
	FETCH_BUSINESS_LOAN_PROPOSAL_FAILURE: 'fetch_business_loan_proposal_failure',
	FETCH_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET: 'fetch_business_loan_proposal_response_reset',
	FETCH_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED: 'fetch_business_loan_proposal_response_changed',

	APPROVE_BUSINESS_PROPOSAL_START: 'approve_business_proposal_start',
	APPROVE_BUSINESS_PROPOSAL_SUCCESS: 'approve_business_proposal_success',
	APPROVE_BUSINESS_PROPOSAL_FAILURE: 'approve_business_proposal_failure',
	APPROVE_BUSINESS_PROPOSAL_RESPONSE_RESET_START: 'approve_business_proposal_response_reset',
	APPROVE_BUSINESS_PROPOSAL_RESPONSE_CHANGED: 'approve_business_proposal_response_changed',

	DELETE_BUSINESS_LOAN_PROPOSAL_START: 'delete_business_loan_proposal_start',
	DELETE_BUSINESS_LOAN_PROPOSAL_SUCCESS: 'delete_business_loan_proposal_success',
	DELETE_BUSINESS_LOAN_PROPOSAL_FAILURE: 'delete_business_loan_proposal_failure',
	DELETE_BUSINESS_LOAN_PROPOSAL_RESPONSE_RESET: 'delete_business_loan_proposal_response_reset',
	DELETE_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED: 'delete_business_loan_proposal_response_changed',

};
export default BusinessLoanProposalActionTypes;
