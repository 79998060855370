const BusinessFundActionTypes = {
	ADD_BUSINESS_FUND_DETAILS_START: 'add_business_fund_details_start',
	ADD_BUSINESS_FUND_DETAILS_SUCCESS: 'add_business_fund_details_success',
	ADD_BUSINESS_FUND_DETAILS_FAILURE: 'add_business_fund_details_failure',
	ADD_BUSINESS_FUND_DETAILS_RESPONSE_RESET: 'add_business_fund_details_response_reset',
	ADD_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED: 'add_business_fund_details_response_changed',

	UPDATE_BUSINESS_FUND_DETAILS_START: 'update_business_fund_details_start',
	UPDATE_BUSINESS_FUND_DETAILS_SUCCESS: 'update_business_fund_details_success',
	UPDATE_BUSINESS_FUND_DETAILS_FAILURE: 'update_business_fund_details_failure',
	UPDATE_BUSINESS_FUND_DETAILS_RESPONSE_RESET: 'update_business_fund_details_response_reset',
	UPDATE_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED: 'update_business_fund_details_response_changed',

	GET_BUSINESS_FUND_LIST_START: 'get_business_fund_list_start',
	GET_BUSINESS_FUND_LIST_SUCCESS: 'get_business_fund_list_success',
	GET_BUSINESS_FUND_LIST_FAILURE: 'get_business_fund_list_failure',
	GET_BUSINESS_FUND_LIST_RESPONSE_RESET: 'get_business_fund_list_response_reset',
	GET_BUSINESS_FUND_LIST_RESPONSE_CHANGED: 'get_business_fund_list_response_changed',

	FETCH_BUSINESS_FUND_DETAILS_START: 'fetch_business_fund_details_start',
	FETCH_BUSINESS_FUND_DETAILS_SUCCESS: 'fetch_business_fund_details_success',
	FETCH_BUSINESS_FUND_DETAILS_FAILURE: 'fetch_business_fund_details_failure',
	FETCH_BUSINESS_FUND_DETAILS_RESPONSE_RESET: 'fetch_business_fund_details_response_reset',
	FETCH_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED: 'fetch_business_fund_details_response_changed',

	DELETE_BUSINESS_FUND_DETAILS_START: 'delete_business_fund_details_start',
	DELETE_BUSINESS_FUND_DETAILS_SUCCESS: 'delete_business_fund_details_success',
	DELETE_BUSINESS_FUND_DETAILS_FAILURE: 'delete_business_fund_details_failure',
	DELETE_BUSINESS_FUND_DETAILS_RESPONSE_RESET: 'delete_business_fund_details_response_reset',
	DELETE_BUSINESS_FUND_DETAILS_RESPONSE_CHANGED: 'delete_business_fund_details_response_changed',

	FETCH_ALL_BUSINESS_FUND_START: 'fetch_all_business_fund_start',
	FETCH_ALL_BUSINESS_FUND_SUCCESS: 'fetch_all_business_fund_success',
	FETCH_ALL_BUSINESS_FUND_FAILURE: 'fetch_all_business_fund_failure',
	FETCH_ALL_BUSINESS_FUND_RESPONSE_RESET: 'fetch_all_business_fund_response_reset',
	FETCH_ALL_BUSINESS_FUND_RESPONSE_CHANGED: 'fetch_all_business_fund_response_changed',
};
export default BusinessFundActionTypes;
