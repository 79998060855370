import ProofDetailsActionTypes from './proof-details.types';

/** ***************** ADD BUSINESS PROOF START *********************** */
export const addBusinessProofStart = (proofData) => ({
	type: ProofDetailsActionTypes.ADD_BUSINESS_PROOF_START,
	payload: proofData,
});

export const addBusinessProofSuccess = (successValue) => ({
	type: ProofDetailsActionTypes.ADD_BUSINESS_PROOF_SUCCESS,
	payload: successValue,
});

export const addBusinessProofFailure = (failureData) => ({
	type: ProofDetailsActionTypes.ADD_BUSINESS_PROOF_FAILURE,
	payload: failureData,
});

export const addBusinessProofResponseResetStart = () => ({
	type: ProofDetailsActionTypes.ADD_BUSINESS_PROOF_RESPONSE_RESET,
});

export const addBusinessProofResponseChanged = () => ({
	type: ProofDetailsActionTypes.ADD_BUSINESS_PROOF_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS PROOF END *********************** */

/** ***************** ADD BUSINESS UPDATE START *********************** */
export const updateBusinessProofStart = (updateData) => ({
	type: ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_START,
	payload: updateData,
});

export const updateBusinessProofSuccess = (successDate) => ({
	type: ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_SUCCESS,
	payload: successDate,
});

export const updateBusinessProofFailure = (failureData) => ({
	type: ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_FAILURE,
	payload: failureData,
});

export const updateBusinessProofResponseResetStart = () => ({
	type: ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_RESPONSE_RESET,
});

export const updateBusinessProofResponseChanged = () => ({
	type: ProofDetailsActionTypes.UPDATE_BUSINESS_PROOF_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS UPDATE END *********************** */

/** ***************** ADD BUSINESS FETCH START *********************** */
export const fetchBusinessProofStart = (fetchData) => ({
	type: ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_START,
	payload: fetchData,
});

export const fetchBusinessProofSuccess = (successData) => ({
	type: ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_SUCCESS,
	payload: successData,
});

export const fetchBusinessProofFailure = (failureData) => ({
	type: ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_FAILURE,
	payload: failureData,
});

export const fetchBusinessProofResponseResetStart = () => ({
	type: ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_RESPONSE_RESET,
});

export const fetchBusinessProofResponseChanged = () => ({
	type: ProofDetailsActionTypes.FETCH_BUSINESS_PROOF_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS FETCH END *********************** */
