const MonthWiseInterestActionTypes = {

    GET_MONTH_WISE_INTEREST_LIST_START: 'get_month_wise_interest_start',
	GET_MONTH_WISE_INTEREST_LIST_SUCCESS: 'get_month_wise_interest_success',
	GET_MONTH_WISE_INTEREST_LIST_FAILURE: 'get_month_wise_interest_failure',
	GET_MONTH_WISE_INTEREST_LIST_RESPONSE_RESET: 'get_month_wise_interest_response_reset',
	GET_MONTH_WISE_INTEREST_LIST_RESPONSE_CHANGED: 'get_month_wise_interest_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',
    
}
export default MonthWiseInterestActionTypes;