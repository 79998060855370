const ProductActionTypes = {
	ADD_PRODUCT_DETAILS_START: 'add_product_details_start',
	ADD_PRODUCT_DETAILS_SUCCESS: 'add_product_details_success',
	ADD_PRODUCT_DETAILS_FAILURE: 'add_product_details_failure',
	ADD_PRODUCT_DETAILS_RESPONSE_RESET: 'add_product_details_response_reset',
	ADD_PRODUCT_DETAILS_RESPONSE_CHANGED: 'add_product_details_response_changed',

	UPDATE_PRODUCT_DETAILS_START: 'update_product_details_start',
	UPDATE_PRODUCT_DETAILS_SUCCESS: 'update_product_details_success',
	UPDATE_PRODUCT_DETAILS_FAILURE: 'update_product_details_failure',
	UPDATE_PRODUCT_DETAILS_RESPONSE_RESET: 'update_product_details_response_reset',
	UPDATE_PRODUCT_DETAILS_RESPONSE_CHANGED: 'update_product_details_response_changed',

	GET_PRODUCT_LIST_START: 'get_product_list_start',
	GET_PRODUCT_LIST_SUCCESS: 'get_product_list_success',
	GET_PRODUCT_LIST_FAILURE: 'get_product_list_failure',
	GET_PRODUCT_LIST_RESPONSE_RESET: 'get_product_list_response_reset',
	GET_PRODUCT_LIST_RESPONSE_CHANGED: 'get_product_list_response_changed',

	FETCH_PRODUCT_DETAILS_START: 'fetch_product_details_start',
	FETCH_PRODUCT_DETAILS_SUCCESS: 'fetch_product_details_success',
	FETCH_PRODUCT_DETAILS_FAILURE: 'fetch_product_details_failure',
	FETCH_PRODUCT_DETAILS_RESPONSE_RESET: 'fetch_product_details_response_reset',
	FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED: 'fetch_product_details_response_changed',

	DELETE_PRODUCT_DETAILS_START: 'delete_product_details_start',
	DELETE_PRODUCT_DETAILS_SUCCESS: 'delete_product_details_success',
	DELETE_PRODUCT_DETAILS_FAILURE: 'delete_product_details_failure',
	DELETE_PRODUCT_DETAILS_RESPONSE_RESET: 'delete_product_details_response_reset',
	DELETE_PRODUCT_DETAILS_RESPONSE_CHANGED: 'delete_product_details_response_changed',
};
export default ProductActionTypes;
