/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import AttendanceReportActionTypes from './attendanceReport.types';

/** ***************** GET ATTENDANCE REPORT DETAILS START *********************** */
export const getAttendanceReportDetailsStart = (getData) => ({
	type: AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_START,
	payload: getData,
});

export const getAttendanceReportDetailsSuccess = (successData) => ({
	type: AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getAttendanceReportDetailsFailure = (failureData) => ({
	type: AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getAttendanceReportDetailsResponseResetStart = () => ({
	type: AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_RESPONSE_RESET,
});

export const getAttendanceReportDetailsResponseChanged = () => ({
	type: AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET ATTENDANCE REPORT DETAILS END *********************** */

