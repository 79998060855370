/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import LoanApplicationTypes from './loanApplication.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addLoanApplicationResponse: null,
	updateLoanApplicationResponse: null,
	getLoanListResponse: null,
	getLoanListArr: [],
	fetchLoanApplicationResponse: null,
	fetchLoanApplicationArr: [],
	deleteLoanApplicationResponse: null,
	fetchAllLoanListResponse: null,
	fetchAllLoanListArr: [],
	statusUpdateLoanApplicationResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LoanApplicationTypes.ADD_LOAN_APPLICATION_SUCCESS:
			return { ...state, addLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.ADD_LOAN_APPLICATION_FAILURE:
			return { ...state, addLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.ADD_LOAN_APPLICATION_RESPONSE_CHANGED:
			return { ...state, addLoanApplicationResponse: null };

		case LoanApplicationTypes.UPDATE_LOAN_APPLICATION_SUCCESS:
			return { ...state, updateLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.UPDATE_LOAN_APPLICATION_FAILURE:
			return { ...state, updateLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.UPDATE_LOAN_APPLICATION_RESPONSE_CHANGED:
			return { ...state, updateLoanApplicationResponse: null };

		case LoanApplicationTypes.GET_LOAN_LIST_SUCCESS:
			return {
				...state,
				getLoanListResponse: action.payload,
				getLoanListArr: docodeResponse(action.payload, 'GetLoanApplicationKey'),
			};

		case LoanApplicationTypes.GET_LOAN_LIST_FAILURE:
			return { ...state, getLoanListResponse: action.payload };

		case LoanApplicationTypes.GET_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getLoanListResponse: null };

		case LoanApplicationTypes.FETCH_LOAN_APPLICATION_SUCCESS:
			return {
				...state,
				fetchLoanApplicationResponse: action.payload,
				fetchLoanApplicationArr: docodeResponse(action.payload, 'FetchLoanApplicationKey'),
			};

		case LoanApplicationTypes.FETCH_LOAN_APPLICATION_FAILURE:
			return { ...state, fetchLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.FETCH_LOAN_APPLICATION_RESPONSE_CHANGED:
			return { ...state, fetchLoanApplicationResponse: null};

		case LoanApplicationTypes.DELETE_LOAN_APPLICATION_SUCCESS:
			return { ...state, deleteLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.DELETE_LOAN_APPLICATION_FAILURE:
			return { ...state, deleteLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.DELETE_LOAN_APPLICATION_RESPONSE_CHANGED:
			return { ...state, deleteLoanApplicationResponse: null };

		case LoanApplicationTypes.FETCH_ALL_LOAN_SUCCESS:
			return {
				...state,
				fetchAllLoanListResponse: action.payload,
				fetchAllLoanListArr: docodeResponse(action.payload, 'FetchAllLoansKey'),
			};

		case LoanApplicationTypes.FETCH_ALL_LOAN_FAILURE:
			return { ...state, fetchAllLoanListResponse: action.payload };

		case LoanApplicationTypes.FETCH_ALL_LOAN_RESPONSE_CHANGED:
			return { ...state, fetchAllLoanListResponse: null };

		case LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_SUCCESS:
			return {
				...state,
				statusUpdateLoanApplicationResponse: action.payload,
			};

		case LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_FAILURE:
			return { ...state, statusUpdateLoanApplicationResponse: action.payload };

		case LoanApplicationTypes.STATUS_UPDATE_LOAN_APPLICATION_RESPONSE_CHANGED:
			return { ...state, statusUpdateLoanApplicationResponse: null };

		default:
			return state;
	}
};
