const GeneralActionTypes = {
	RESET_ALL_REDUCER_STATE_START: 'reset_all_reducer_state_start',

	IS_EDIT_DETAILS_START: 'is_edit_details_start',
	IS_OPEN_OFFCANVAS_START: 'is_open_offcanvas_start',

	GET_COUNTRY_LIST_START: 'get_country_list_start',
	GET_COUNTRY_LIST_SUCCESS: 'get_country_list_success',
	GET_COUNTRY_LIST_FAILURE: 'get_country_list_failure',
	GET_COUNTRY_LIST_RESPONSE_RESET: 'get_country_list_response_reset',
	GET_COUNTRY_LIST_RESPONSE_CHANGED: 'get_country_list_response_changed',

	GET_STATE_LIST_START: 'get_state_list_start',
	GET_STATE_LIST_SUCCESS: 'get_state_list_success',
	GET_STATE_LIST_FAILURE: 'get_state_list_failure',
	GET_STATE_LIST_RESPONSE_RESET: 'get_state_list_response_reset',
	GET_STATE_LIST_RESPONSE_CHANGED: 'get_state_list_response_changed',

	GET_CITY_LIST_START: 'get_city_list_start',
	GET_CITY_LIST_SUCCESS: 'get_city_list_success',
	GET_CITY_LIST_FAILURE: 'get_city_list_failure',
	GET_CITY_LIST_RESPONSE_RESET: 'get_city_list_response_reset',
	GET_CITY_LIST_RESPONSE_CHANGED: 'get_city_list_response_changed',

	GET_PINCODE_LIST_START: 'get_pincode_list_start',
	GET_PINCODE_LIST_SUCCESS: 'get_pincode_list_success',
	GET_PINCODE_LIST_FAILURE: 'get_pincode_list_failure',
	GET_PINCODE_LIST_RESPONSE_RESET: 'get_pincode_list_response_reset',
	GET_PINCODE_LIST_RESPONSE_CHANGED: 'get_pincode_list_response_changed',

	GET_DISTRICT_LIST_START: 'get_district_list_start',
	GET_DISTRICT_LIST_SUCCESS: 'get_district_list_success',
	GET_DISTRICT_LIST_FAILURE: 'get_district_list_failure',
	GET_DISTRICT_LIST_RESPONSE_RESET: 'get_district_list_response_reset',
	GET_DISTRICT_LIST_RESPONSE_CHANGED: 'get_district_list_response_changed',

	GET_INDUSTRY_LIST_START: 'get_industry_list_start',
	GET_INDUSTRY_LIST_SUCCESS: 'get_industry_list_success',
	GET_INDUSTRY_LIST_FAILURE: 'get_industry_list_failure',
	GET_INDUSTRY_LIST_RESPONSE_RESET_START: 'get_industry_list_response_reset_start',
	GET_INDUSTRY_LIST_RESPONSE_CHANGED: 'get_industry_list_response_changed',

	GET_SUB_INDUSTRY_LIST_START: 'get_sub_industry_list_start',
	GET_SUB_INDUSTRY_LIST_SUCCESS: 'get_sub_industry_list_success',
	GET_SUB_INDUSTRY_LIST_FAILURE: 'get_sub_industry_list_failure',
	GET_SUB_INDUSTRY_LIST_RESPONSE_RESET_START: 'get_sub_industry_list_response_reset_start',
	GET_SUB_INDUSTRY_LIST_RESPONSE_CHANGED: 'get_sub_industry_list_response_changed',

	// All Branch List
	FETCH_ALL_BRANCHES_START: 'fetch_all_branches_start',
	FETCH_ALL_BRANCHES_SUCCESS: 'fetch_all_branches_success',
	FETCH_ALL_BRANCHES_FAILURE: 'fetch_all_branches_failure',
	FETCH_ALL_BRANCHES_RESPONSE_RESET_START: 'fetch_all_branches_response_reset',
	FETCH_ALL_BRANCHES_RESPONSE_CHANGED: 'fetch_all_branches_response_changed',

	// All Purpose Of Loan List
	FETCH_ALL_PURPOSE_OF_LOAN_LIST_START: 'fetch_all_purpose_of_loan_list_start',
	FETCH_ALL_PURPOSE_OF_LOAN_LIST_SUCCESS: 'fetch_all_purpose_of_loan_list_success',
	FETCH_ALL_PURPOSE_OF_LOAN_LIST_FAILURE: 'fetch_all_purpose_of_loan_list_failure',
	FETCH_ALL_PURPOSE_OF_LOAN_LIST_RESPONSE_RESET_START:
		'fetch_all_purpose_of_loan_list_response_reset',
	FETCH_ALL_PURPOSE_OF_LOAN_LIST_RESPONSE_CHANGED:
		'fetch_all_purpose_of_loan_list_response_changed',

	// All Purpose Of Loan Type List
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_START: 'fetch_all_purpose_of_loan_type_start',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_SUCCESS: 'fetch_all_purpose_of_loan_type_success',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_FAILURE: 'fetch_all_purpose_of_loan_type_failure',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_RESET_START:
		'fetch_all_purpose_of_loan_type_response_reset',
	FETCH_ALL_PURPOSE_OF_LOAN_TYPE_RESPONSE_CHANGED:
		'fetch_all_purpose_of_loan_type_response_changed',

	// All Business List
	FETCH_ALL_BUSINESS_LIST_START: 'fetch_all_business_list_start',
	FETCH_ALL_BUSINESS_LIST_SUCCESS: 'fetch_all_business_list_success',
	FETCH_ALL_BUSINESS_LIST_FAILURE: 'fetch_all_business_list_failure',
	FETCH_ALL_BUSINESS_LIST_RESPONSE_RESET_START: 'fetch_all_business_list_response_reset',
	FETCH_ALL_BUSINESS_LIST_RESPONSE_CHANGED: 'fetch_all_business_list_response_changed',

	// All Product Type List
	FETCH_ALL_PRODUCT_TYPE_START: 'fetch_all_product_type_start',
	FETCH_ALL_PRODUCT_TYPE_SUCCESS: 'fetch_all_product_type_success',
	FETCH_ALL_PRODUCT_TYPE_FAILURE: 'fetch_all_product_type_failure',
	FETCH_ALL_PRODUCT_TYPE_RESPONSE_RESET: 'fetch_all_product_type_response_reset',
	FETCH_ALL_PRODUCT_TYPE_RESPONSE_CHANGED: 'fetch_all_product_type_response_changed',

	// All Product List
	FETCH_ALL_PRODUCT_START: 'fetch_all_product_start',
	FETCH_ALL_PRODUCT_SUCCESS: 'fetch_all_product_success',
	FETCH_ALL_PRODUCT_FAILURE: 'fetch_all_product_failure',
	FETCH_ALL_PRODUCT_RESPONSE_RESET: 'fetch_all_product_response_reset',
	FETCH_ALL_PRODUCT_RESPONSE_CHANGED: 'fetch_all_product_response_changed',

	// Fetch All Branch Officer
	FETCH_ALL_BRANCH_OFFICER_START: 'fetch_all_branch_officer_start',
	FETCH_ALL_BRANCH_OFFICER_SUCCESS: 'fetch_all_branch_officer_success',
	FETCH_ALL_BRANCH_OFFICER_FAILURE: 'fetch_all_branch_officer_failure',
	FETCH_ALL_BRANCH_OFFICER_RESPONSE_RESET_START: 'fetch_all_branch_officer_response_reset_start',
	FETCH_ALL_BRANCH_OFFICER_RESPONSE_CHANGED: 'fetch_all_branch_officer_response_changed',

	// Fetch All Center
	FETCH_ALL_CENTERS_START: 'fetch_all_centers_start',
	FETCH_ALL_CENTERS_SUCCESS: 'fetch_all_centers_success',
	FETCH_ALL_CENTERS_FAILURE: 'fetch_all_centers_failure',
	FETCH_ALL_CENTERS_RESPONSE_RESET_START: 'fetch_all_centers_response_reset',
	FETCH_ALL_CENTERS_RESPONSE_CHANGED: 'fetch_all_centers_response_changed',

	// Fetch Center Groups
	FETCH_CENTER_GROUPS_START: 'fetch_center_groups_start',
	FETCH_CENTER_GROUPS_SUCCESS: 'fetch_center_groups_success',
	FETCH_CENTER_GROUPS_FAILURE: 'fetch_center_groups_failure',
	FETCH_CENTER_GROUPS_RESPONSE_RESET_START: 'fetch_center_groups_response_reset',
	FETCH_CENTER_GROUPS_RESPONSE_CHANGED: 'fetch_center_groups_response_changed',

	// Fetch Group Members
	FETCH_GROUP_MEMBERS_START: 'fetch_group_members_start',
	FETCH_GROUP_MEMBERS_SUCCESS: 'fetch_group_members_success',
	FETCH_GROUP_MEMBERS_FAILURE: 'fetch_group_members_failure',
	FETCH_GROUP_MEMBERS_RESPONSE_RESET_START: 'fetch_group_members_response_reset',
	FETCH_GROUP_MEMBERS_RESPONSE_CHANGED: 'fetch_group_members_response_changed',

	// Fetch All Investor
	FETCH_ALL_INVESTOR_LIST_START: 'fetch_all_investor_list_start',
	FETCH_ALL_INVESTOR_LIST_SUCCESS: 'fetch_all_investor_list_success',
	FETCH_ALL_INVESTOR_LIST_FAILURE: 'fetch_all_center_failure',
	FETCH_ALL_INVESTOR_LIST_RESPONSE_RESET_START: 'fetch_all_investor_list_response_reset',
	FETCH_ALL_INVESTOR_LIST_RESPONSE_CHANGED: 'fetch_all_investor_list_response_changed',

	FETCH_ALL_GROUP_START: 'fetch_All_Group_Start',
	FETCH_ALL_GROUP_SUCCESS: 'fetch_All_Group_Success',
	FETCH_ALL_GROUP_FAILURE: 'fetch_All_Group_Failure',
	FETCH_ALL_GROUP_RESPONSE_RESET: 'fetch_All_Group_Response_Reset',
	FETCH_ALL_GROUP_RESPONSE_CHANGED: 'fetch_All_Group_Response_Changed',

	// Branch Account List
	FETCH_BRANCH_ACCOUNT_LIST_START: 'FETCH_BRANCH_ACCOUNT_LIST_START',
	FETCH_BRANCH_ACCOUNT_LIST_SUCCESS: 'FETCH_BRANCH_ACCOUNT_LIST_SUCCESS',
	FETCH_BRANCH_ACCOUNT_LIST_FAILURE: 'FETCH_BRANCH_ACCOUNT_LIST_FAILURE',
	FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_RESET_START:
		'FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_RESET_START',
	FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_CHANGED: 'FETCH_BRANCH_ACCOUNT_LIST_RESPONSE_CHANGED',
};
export default GeneralActionTypes;
