const questionnaireTypes = {

    ADD_QUESTIONNAIRE_START: 'addQuestionnaireStart',
	ADD_QUESTIONNAIRE_SUCCESS: 'addQuestionnaireSuccess',
	ADD_QUESTIONNAIRE_FAILURE: 'addQuestionnaireFailure',
	ADD_QUESTIONNAIRE_RESPONSE_RESET: 'addQuestionnaireResponseReset',
	ADD_QUESTIONNAIRE_RESPONSE_CHANGED: 'addQuestionnaireResponseChanged',

    DELETE_QUESTIONNAIRE_START: 'deleteQuestionnaireStart',
	DELETE_QUESTIONNAIRE_SUCCESS: 'deleteQuestionnaireSuccess',
	DELETE_QUESTIONNAIRE_FAILURE: 'deleteQuestionnaireFailure',
	DELETE_QUESTIONNAIRE_RESPONSE_RESET: 'deleteQuestionnaireResponseReset',
	DELETE_QUESTIONNAIRE_RESPONSE_CHANGED: 'deleteQuestionnaireResponseChanged',
    
	DELETE_QUESTION_START: 'deleteQuestionStart',
	DELETE_QUESTION_SUCCESS: 'deleteQuestionSuccess',
	DELETE_QUESTION_FAILURE: 'deleteQuestionFailure',
	DELETE_QUESTION_RESPONSE_RESET: 'deleteQuestionResponseReset',
	DELETE_QUESTION_RESPONSE_CHANGED: 'deleteQuestionResponseChanged',

	DELETE_QUESTION_CHOICE_START: 'deleteQuestionChoiceStart',
	DELETE_QUESTION_CHOICE_SUCCESS: 'deleteQuestionChoiceSuccess',
	DELETE_QUESTION_CHOICE_FAILURE: 'deleteQuestionChoiceFailure',
	DELETE_QUESTION_CHOICE_RESPONSE_RESET: 'deleteQuestionChoiceResponseReset',
	DELETE_QUESTION_CHOICE_RESPONSE_CHANGED: 'deleteQuestionChoiceResponseChanged',

    GET_QUESTIONNAIRE_START: 'getQuestionnaireStart',
	GET_QUESTIONNAIRE_SUCCESS: 'getQuestionnaireSuccess',
	GET_QUESTIONNAIRE_FAILURE: 'getQuestionnaireFailure',
	GET_QUESTIONNAIRE_RESPONSE_RESET: 'getQuestionnaireResponseReset',
	GET_QUESTIONNAIRE_RESPONSE_CHANGED: 'getQuestionnaireResponseChanged',

    FETCH_QUESTIONNAIRE_START: 'fetchQuestionnaireStart',
	FETCH_QUESTIONNAIRE_SUCCESS: 'fetchQuestionnaireSuccess',
	FETCH_QUESTIONNAIRE_FAILURE: 'fetchQuestionnaireFailure',
	FETCH_QUESTIONNAIRE_RESPONSE_RESET: 'fetchQuestionnaireResponseReset',
	FETCH_QUESTIONNAIRE_RESPONSE_CHANGED: 'fetchQuestionnaireResponseChanged',

	UPDATE_QUESTIONNAIRE_START: 'updateQuestionnaireStart',
	UPDATE_QUESTIONNAIRE_SUCCESS: 'updateQuestionnaireSuccess',
	UPDATE_QUESTIONNAIRE_FAILURE: 'updateQuestionnaireFailure',
	UPDATE_QUESTIONNAIRE_RESPONSE_RESET: 'updateQuestionnaireResponseReset',
	UPDATE_QUESTIONNAIRE_RESPONSE_CHANGED: 'updateQuestionnaireResponseChanged',

}
export default questionnaireTypes;