/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import ActiveMemberActionTypes from './active-member.types';

/** ***************** GET ACTIVE MEMBER START *********************** */
export const getActiveMemberDetailsStart = (getData) => ({
	type: ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_START,
	payload: getData,
});

export const getActiveMemberDetailsSuccess = (successData) => ({
	type: ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const getActiveMemberDetailsFailure = (failureData) => ({
	type: ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const getActiveMemberDetailsResponseResetStart = () => ({
	type: ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_RESPONSE_RESET,
});

export const getActiveMemberDetailsResponseChanged = () => ({
	type: ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_RESPONSE_CHANGED,
});
/** ***************** GET ACTIVE MEMBER END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

/** ***************** FETCH ACTIVE MEMBER DETAILS REPORT EXCEL DETAILS START *********************** */
export const fetchActiveMemberReportExcelStart = (addData) => ({
	type: ActiveMemberActionTypes.FETCH_ACTIVE_MEMBER_REPORT_EXCEL_START,
	payload: addData,
});

export const fetchActiveMemberReportExcelSuccess = (successData) => ({
	type: ActiveMemberActionTypes.FETCH_ACTIVE_MEMBER_REPORT_EXCEL_SUCCESS,
	payload: successData,
});

export const fetchActiveMemberReportExcelFailure = (failureData) => ({
	type: ActiveMemberActionTypes.FETCH_ACTIVE_MEMBER_REPORT_EXCEL_FAILURE,
	payload: failureData,
});

export const fetchActiveMemberReportResponseExcelResetStart = () => ({
	type: ActiveMemberActionTypes.FETCH_ACTIVE_MEMBER_REPORT_RESPONSE_EXCEL_RESET_START,
});

export const fetchActiveMemberReportResponseExcelResetChanged = () => ({
	type: ActiveMemberActionTypes.FETCH_ACTIVE_MEMBER_REPORT_RESPONSE_EXCEL_CHANGED,
});

/** ***************** FETCH ACTIVE MEMBER DETAILS REPORT EXCEL DETAILS END *********************** */

