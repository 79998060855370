/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import MonthWiseInterestActionTypes from './MonthWiseInterestReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getMonthWiseInterestListResponse: null,
	getMonthWiseInterestListArr: [],

    getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_SUCCESS:
			return {
				...state,
				getMonthWiseInterestListResponse: action.payload,
				getMonthWiseInterestListArr: docodeResponse(action.payload, 'GetMonthWiseInterestKey'),
			};

		case MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_FAILURE:
			return { ...state, getMonthWiseInterestListResponse: action.payload };

		case MonthWiseInterestActionTypes.GET_MONTH_WISE_INTEREST_LIST_RESPONSE_CHANGED:
			return { ...state, getMonthWiseInterestListResponse: null };

        case MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
            return {
                ...state,
                getOfficerBranchListResponse: action.payload,
                getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
            };

        case MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
            return { ...state, getOfficerBranchListResponse: action.payload };

        case MonthWiseInterestActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
            return { ...state, getOfficerBranchListResponse: null };
        default:
            return state;
    }
}