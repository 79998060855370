/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BranchProfileReportActionTypes from './branchProfileReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getBranchProfileReportResponse: null,
	getBranchProfileReportArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_SUCCESS:
			return {
				...state,
				getBranchProfileReportResponse: action.payload,
				getBranchProfileReportArr: docodeResponse(
					action.payload,
					'FetchBranchProfileReportKey',
				),
			};

		case BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_FAILURE:
			return { ...state, getBranchProfileReportResponse: action.payload };

		case BranchProfileReportActionTypes.GET_BRANCH_PROFILE_REPORT_RESPONSE_CHANGED:
			return { ...state, getBranchProfileReportResponse: null };
		default:
			return state;
	}
};
