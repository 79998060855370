/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import MonthlyProgressActionTypes from './monthlyProgress.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getMonthlyProgressListResponse: null,
	getMonthlyProgressListArr: [],

};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_SUCCESS:
			return {
				...state,
				getMonthlyProgressListResponse: action.payload,
				getMonthlyProgressListArr: docodeResponse(action.payload, 'GetMonthlyReportProgressKey'),
			};

		case MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_FAILURE:
			return { ...state, getMonthlyProgressListResponse: action.payload };

		case MonthlyProgressActionTypes.GET_MONTHLY_PROGRESS_LIST_RESPONSE_CHANGED:
			return { ...state, getMonthlyProgressListResponse: null };
        default:
            return state;
    }
}