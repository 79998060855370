/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const productTypeDetailsAdd = (addData) => {
	const data = {
		AddProductTypeDetailsToken: addData,
	};
	const url = `${API_URL}addProductTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productTypeDetailsUpdate = (updateData) => {
	const data = {
		UpdateProductTypeDetailsToken: updateData,
	};
	const url = `${API_URL}updateProductTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productTypeListGet = (listData) => {
	const data = {
		GetProductTypeListToken: listData,
	};
	const url = `${API_URL}getProductTypeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productTypeDetailsFetch = (fetchData) => {
	const data = {
		FetchProductTypeDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchProductTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productTypeDetailsDelete = (deleteData) => {
	const data = {
		DeleteProductTypeDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteProductTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
