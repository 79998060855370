/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ThriftActionTypes from './thrift.types';

/** ***************** ADD THRIFT DETAILS START *********************** */
export const addThriftDetailsStart = (addData) => ({
	type: ThriftActionTypes.ADD_THRIFT_DETAILS_START,
	payload: addData,
});

export const addThriftDetailsSuccess = (successData) => ({
	type: ThriftActionTypes.ADD_THRIFT_DETAILS_SUCCESS,
	payload: successData,
});

export const addThriftDetailsFailure = (failureData) => ({
	type: ThriftActionTypes.ADD_THRIFT_DETAILS_FAILURE,
	payload: failureData,
});

export const addThriftDetailsResponseResetStart = () => ({
	type: ThriftActionTypes.ADD_THRIFT_DETAILS_RESPONSE_RESET,
});

export const addThriftDetailsResponseChanged = () => ({
	type: ThriftActionTypes.ADD_THRIFT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD THRIFT DETAILS END *********************** */

/** ***************** UPDATE THRIFT DETAILS START *********************** */
export const updateThriftDetailsStart = (updateData) => ({
	type: ThriftActionTypes.UPDATE_THRIFT_DETAILS_START,
	payload: updateData,
});

export const updateThriftDetailsSuccess = (successData) => ({
	type: ThriftActionTypes.UPDATE_THRIFT_DETAILS_SUCCESS,
	payload: successData,
});

export const updateThriftDetailsFailure = (failureData) => ({
	type: ThriftActionTypes.UPDATE_THRIFT_DETAILS_FAILURE,
	payload: failureData,
});

export const updateThriftDetailsResponseResetStart = () => ({
	type: ThriftActionTypes.UPDATE_THRIFT_DETAILS_RESPONSE_RESET,
});

export const updateThriftDetailsResponseChanged = () => ({
	type: ThriftActionTypes.UPDATE_THRIFT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE THRIFT DETAILS END *********************** */

/** ***************** GET THRIFT LIST START *********************** */
export const getThriftListStart = (getData) => ({
	type: ThriftActionTypes.GET_THRIFT_LIST_START,
	payload: getData,
});

export const getThriftListSuccess = (successData) => ({
	type: ThriftActionTypes.GET_THRIFT_LIST_SUCCESS,
	payload: successData,
});

export const getThriftListFailure = (failureData) => ({
	type: ThriftActionTypes.GET_THRIFT_LIST_FAILURE,
	payload: failureData,
});

export const getThriftListResponseResetStart = () => ({
	type: ThriftActionTypes.GET_THRIFT_LIST_RESPONSE_RESET,
});

export const getThriftListResponseChanged = () => ({
	type: ThriftActionTypes.GET_THRIFT_LIST_RESPONSE_CHANGED,
});

/** ***************** GET THRIFT LIST END *********************** */

/** ***************** FETCH THRIFT DETAILS START *********************** */
export const fetchThriftDetailsStart = (fetchData) => ({
	type: ThriftActionTypes.FETCH_THRIFT_DETAILS_START,
	payload: fetchData,
});

export const fetchThriftDetailsSuccess = (successData) => ({
	type: ThriftActionTypes.FETCH_THRIFT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchThriftDetailsFailure = (failureData) => ({
	type: ThriftActionTypes.FETCH_THRIFT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchThriftDetailsResponseResetStart = () => ({
	type: ThriftActionTypes.FETCH_THRIFT_DETAILS_RESPONSE_RESET,
});

export const fetchThriftDetailsResponseChanged = () => ({
	type: ThriftActionTypes.FETCH_THRIFT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH THRIFT DETAILS END *********************** */

/** ***************** DELETE THRIFT DETAILS START *********************** */
export const deleteThriftDetailsStart = (deleteData) => ({
	type: ThriftActionTypes.DELETE_THRIFT_DETAILS_START,
	payload: deleteData,
});

export const deleteThriftDetailsSuccess = (successData) => ({
	type: ThriftActionTypes.DELETE_THRIFT_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteThriftDetailsFailure = (failureData) => ({
	type: ThriftActionTypes.DELETE_THRIFT_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteThriftDetailsResponseResetStart = () => ({
	type: ThriftActionTypes.DELETE_THRIFT_DETAILS_RESPONSE_RESET,
});

export const deleteThriftDetailsResponseChanged = () => ({
	type: ThriftActionTypes.DELETE_THRIFT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE THRIFT DETAILS END *********************** */

/** ***************** FETCH ALL THRIFT LIST START *********************** */
export const fetchAllThriftListStart = (fetchThrifts) => ({
	type: ThriftActionTypes.FETCH_ALL_THRIFT_START,
	payload: fetchThrifts,
});

export const fetchAllThriftListSuccess = (successData) => ({
	type: ThriftActionTypes.FETCH_ALL_THRIFT_SUCCESS,
	payload: successData,
});

export const fetchAllThriftListFailure = (failureData) => ({
	type: ThriftActionTypes.FETCH_ALL_THRIFT_FAILURE,
	payload: failureData,
});

export const fetchAllThriftListResponseResetStart = () => ({
	type: ThriftActionTypes.FETCH_ALL_THRIFT_RESPONSE_RESET,
});

export const fetchAllThriftListResponseChanged = () => ({
	type: ThriftActionTypes.FETCH_ALL_THRIFT_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL THRIFT LIST END *********************** */
