/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import CenterActionTypes from './center.types';
import {
	centerDetailsAdd,
	centerDetailsUpdate,
	centerListGet,
	centerDetailsFetch,
	centerDetailsDelete,
	fetchCenterUniqueDetails,
} from './center.service';

import {
	addCenterDetailsSuccess,
	addCenterDetailsFailure,
	addCenterDetailsResponseChanged,
	updateCenterDetailsSuccess,
	updateCenterDetailsFailure,
	updateCenterDetailsResponseChanged,
	getCenterDetailsSuccess,
	getCenterDetailsFailure,
	getCenterDetailsResponseChanged,
	fetchCenterDetailsSuccess,
	fetchCenterDetailsFailure,
	fetchCenterDetailsResponseChanged,
	deleteCenterDetailsSuccess,
	deleteCenterDetailsFailure,
	deleteCenterDetailsResponseChanged,
	fetchCenterUniqueIDSuccess,
	fetchCenterUniqueIDFailure,
	fetchCenterUniqueIDResponseChanged,
} from './center.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD CENTER DETAILS  ******************** */
export function* createCenter({ payload }) {
	try {
		const key = 'AddCenterDetailsKey';
		const jsonData = {
			BranchID: payload.branchID,
			UniqueBranchID: payload.nextUniqueCenterID,
			CenterName: payload.centerName,
			LocationAddress: payload.centerAddress,
			LocationPinCode: payload.centerPincode,
			PhoneNumber: payload.centerPhoneNumber,
			Landmark: payload.centerLankMark,
			MeetUpLocation: payload.centerMeetUpLocation,
			Latitude: payload.centerMapLatitude,
			Longitude: payload.centerMapLongitude,
			DistanceFromBranch: payload.centerBranchDistance,
			Meetingday: payload.centerMeetingDay,
			Meetingfrequency: payload.centerMeetingFrequency,
			MeetUpTime: payload.centerMeetUpTime,
			Nextmeetingdate: payload.centerNextMeetingDate,
			Centerstartdate: payload.centerStartDate,
			Village: payload.village,
			ForPersonalLoanCenter: payload.isIndividualCenter ? 1 : 0,
			IsWeekDayBasedCenter: payload.isWeeklyCenter ? 1 : 0,
			IsDefaultCenter: payload.isDefaultNoLoan ? 1 : 0,
			IsGoldLoanCenter: payload.isGoldLoanCenter ? 1 : 0,
			WhichWeek: payload.whichWeek,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addCenterDetailsFailure(responseData.data));
		} else {
			yield put(addCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addCenterDetailsFailure(error));
	}
}

export function* resetAddCenterResponse() {
	yield put(addCenterDetailsResponseChanged());
}

export function* onCreateCenterDetails() {
	yield takeLatest(CenterActionTypes.ADD_CENTER_DETAILS_START, createCenter);
}

export function* onCreateCenterResponseReset() {
	yield takeLatest(CenterActionTypes.ADD_CENTER_DETAILS_RESPONSE_RESET, resetAddCenterResponse);
}
/** *********************  END ADD CENTER DETAILS ******************** */

/** *********************  START UPDATE CENTER DETAILS  ******************** */
export function* centerUpdate({ payload }) {
	try {
		const key = 'UpdateCenterDetailsKey';
		const jsonData = {
			CenterID: payload.centerID,
			BranchID: payload.branchID,
			UniqueBranchID: payload.nextUniqueCenterID,
			CenterName: payload.centerName,
			LocationAddress: payload.centerAddress,
			LocationPinCode: payload.centerPincode,
			PhoneNumber: payload.centerPhoneNumber,
			Landmark: payload.centerLankMark,
			MeetUpLocation: payload.centerMeetUpLocation,
			Latitude: payload.centerMapLatitude,
			Longitude: payload.centerMapLongitude,
			DistanceFromBranch: payload.centerBranchDistance,
			Meetingday: payload.centerMeetingDay,
			Meetingfrequency: payload.centerMeetingFrequency,
			MeetUpTime: payload.centerMeetUpTime,
			Nextmeetingdate: payload.centerNextMeetingDate,
			Centerstartdate: payload.centerStartDate,
			Village: payload.village,
			ForPersonalLoanCenter: payload.isIndividualCenter ? 1 : 0,
			IsWeekDayBasedCenter: payload.isWeeklyCenter ? 1 : 0,
			IsDefaultCenter: payload.isDefaultNoLoan ? 1 : 0,
			IsGoldLoanCenter: payload.isGoldLoanCenter ? 1 : 0,
			WhichWeek: payload.whichWeek,
			CreatedBy: payload.userID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateCenterDetailsFailure(responseData.data));
		} else {
			yield put(updateCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateCenterDetailsFailure(error));
	}
}

export function* resetUpdateCenterDetailsResponse() {
	yield put(updateCenterDetailsResponseChanged());
}

export function* onUpdateCenterDetails() {
	yield takeLatest(CenterActionTypes.UPDATE_CENTER_DETAILS_START, centerUpdate);
}

export function* onUpdateCenterResponseReset() {
	yield takeLatest(
		CenterActionTypes.UPDATE_CENTER_DETAILS_RESPONSE_RESET,
		resetUpdateCenterDetailsResponse,
	);
}
/** *********************  END UPDATE CENTER DETAILS ******************** */

/** *********************  START GET CENTER LIST  ******************** */
export function* centerDetailsListGet({ payload }) {
	try {
		const key = 'GetCenterListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getCenterDetailsFailure(responseData.data));
		} else {
			yield put(getCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getCenterDetailsFailure(error));
	}
}

export function* resetGetCenterListResponse() {
	yield put(getCenterDetailsResponseChanged());
}

export function* onGetCenterListDetails() {
	yield takeLatest(CenterActionTypes.GET_CENTER_LIST_START, centerDetailsListGet);
}

export function* onGetCenterListResponseReset() {
	yield takeLatest(CenterActionTypes.GET_CENTER_LIST_RESPONSE_RESET, resetGetCenterListResponse);
}
/** *********************  END GET CENTER LIST ******************** */

/** *********************  START FETCH CENTER DETAILS  ******************** */
export function* fetchCenterData({ payload }) {
	try {
		const key = 'FetchCenterDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterDetailsFailure(responseData.data));
		} else {
			yield put(fetchCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterDetailsFailure(error));
	}
}

export function* resetFetchCenterDetailsResponse() {
	yield put(fetchCenterDetailsResponseChanged());
}

export function* onFetchCenterDetails() {
	yield takeLatest(CenterActionTypes.FETCH_CENTER_DETAILS_START, fetchCenterData);
}

export function* onFetchCenterResponseReset() {
	yield takeLatest(
		CenterActionTypes.FETCH_CENTER_DETAILS_RESPONSE_RESET,
		resetFetchCenterDetailsResponse,
	);
}
/** *********************  END FETCH CENTER DETAILS ******************** */

/** *********************  START DELETE CENTER DETAILS  ******************** */
export function* centerDelete({ payload }) {
	try {
		const key = 'DeleteCenterDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			CenterID: payload.centerID,
			LocationName: payload.locationName,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(centerDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteCenterDetailsFailure(responseData.data));
		} else {
			yield put(deleteCenterDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteCenterDetailsFailure(error));
	}
}

export function* resetDeleteCenterDetailsResponse() {
	yield put(deleteCenterDetailsResponseChanged());
}

export function* onDeleteCenterDetail() {
	yield takeLatest(CenterActionTypes.DELETE_CENTER_DETAILS_START, centerDelete);
}

export function* onDeleteCenterResponseReset() {
	yield takeLatest(
		CenterActionTypes.DELETE_CENTER_DETAILS_RESPONSE_RESET,
		resetDeleteCenterDetailsResponse,
	);
}
/** *********************  END DELETE CENTER DETAILS ******************** */

/** *********************  START FETCH CENTER UNIQUE ID  ******************** */
export function* getCenterUniqueDetails({ payload }) {
	try {
		const key = 'FetchCenterUniqueIdKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchCenterUniqueDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchCenterUniqueIDFailure(responseData.data));
		} else {
			yield put(fetchCenterUniqueIDSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchCenterUniqueIDFailure(error));
	}
}

export function* resetGetCenterUniqueIDResponse() {
	yield put(fetchCenterUniqueIDResponseChanged());
}

export function* onGetCenterCenterUinqueDetails() {
	yield takeLatest(CenterActionTypes.FETCH_CENTER_UNIQUE_ID_START, getCenterUniqueDetails);
}

export function* onGetCenterCenterUinqueIDResponseReset() {
	yield takeLatest(
		CenterActionTypes.FETCH_CENTER_UNIQUE_ID_RESPONSE_RESET,
		resetGetCenterUniqueIDResponse,
	);
}
/** *********************  END FETCH CENTER UNIQUE ID ******************** */

export function* centerMasterSaga() {
	yield all([
		call(onCreateCenterDetails),
		call(onCreateCenterResponseReset),
		call(onUpdateCenterDetails),
		call(onUpdateCenterResponseReset),
		call(onGetCenterListDetails),
		call(onGetCenterListResponseReset),
		call(onFetchCenterDetails),
		call(onFetchCenterResponseReset),
		call(onDeleteCenterDetail),
		call(onDeleteCenterResponseReset),
		call(onGetCenterCenterUinqueDetails),
		call(onGetCenterCenterUinqueIDResponseReset),
	]);
}
