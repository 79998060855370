const staffReportActionTypes = {
	GET_STAFF_DETAILS_START: 'GET_STAFF_DETAILS_START',
	GET_STAFF_DETAILS_SUCCESS: 'GET_STAFF_DETAILS_SUCCESS',
	GET_STAFF_DETAILS_FAILURE: 'GET_STAFF_DETAILS_FAILURE',
	GET_STAFF_DETAILS_RESPONSE_CHANGED: 'GET_STAFF_DETAILS_RESPONSE_CHANGED',
	GET_STAFF_DETAILS_RESPONSE_RESET: 'GET_STAFF_DETAILS_RESPONSE_RESET',

	GET_STAFF_DETAILS_EXCEL_START: 'GET_STAFF_DETAILS_EXCEL_START',
	GET_STAFF_DETAILS_EXCEL_SUCCESS: 'GET_STAFF_DETAILS_EXCEL_SUCCESS',
	GET_STAFF_DETAILS_EXCEL_FAILURE: 'GET_STAFF_DETAILS_EXCEL_FAILURE',
	GET_STAFF_DETAILS_EXCEL_RESPONSE_CHANGED: 'GET_STAFF_DETAILS_EXCEL_RESPONSE_CHANGED',
	GET_STAFF_DETAILS_EXCEL_RESPONSE_RESET: 'GET_STAFF_DETAILS_EXCEL_RESPONSE_RESET',

	GET_STAFF_OFFICER_START: 'GET_STAFF_OFFICER_START',
	GET_STAFF_OFFICER_SUCCESS: 'GET_STAFF_OFFICER_SUCCESS',
	GET_STAFF_OFFICER_FAILURE: 'GET_STAFF_OFFICER_FAILURE',
	GET_STAFF_OFFICER_RESPONSE_CHANGED: 'GET_STAFF_OFFICER_RESPONSE_CHANGED',
	GET_STAFF_OFFICER_RESPONSE_RESET: 'GET_STAFF_OFFICER_RESPONSE_RESET',
};
export default staffReportActionTypes;
