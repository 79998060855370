/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import ChangeMeetingActionTypes from './change-meeting.types';

import {
    changeMeetingListGet,
    officerBranchListGet,

} from './change-meeting.service';

import {
    getChangeMeetingDetailsSuccess,
    getChangeMeetingDetailsFailure,
    getChangeMeetingDetailsResponseChanged,
    getOfficerBranchDetailsSuccess,
    getOfficerBranchDetailsFailure,
    getOfficerBranchDetailsResponseChanged,

} from './change-meeting.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET PROGRESS REPORT DETAILS  ******************** */
export function* getChangeMeetingList({ payload }) {
	try {
		const key = 'GetChangeMeetingKey';
		const jsonData = {
			CompanyID: payload.companyID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
            FromDate: payload.fromDate,
			ToDate: payload.toDate,
            IsThriftActive: payload.IsThriftActive,
			UserLevel: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		debugger;
		const responseData = yield call(changeMeetingListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getChangeMeetingDetailsFailure(responseData.data));
		} else {
			yield put(getChangeMeetingDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getChangeMeetingDetailsFailure(error));
	}
}

export function* resetGetChangeMeetingListResponse() {
	yield put(getChangeMeetingDetailsResponseChanged());
}

export function* onGetChangeMeetingListDetails() {
	yield takeLatest(ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_START, getChangeMeetingList);
}

export function* onGetChangeMeetingListResponseReset() {
	yield takeLatest(ChangeMeetingActionTypes.GET_CHANGE_MEETING_LIST_RESPONSE_RESET, resetGetChangeMeetingListResponse);
}
/** *********************  END GET PROGRESS REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(ChangeMeetingActionTypes.GET_OFFICER_BRANCH_LIST_START, getOfficerBranchList);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(ChangeMeetingActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET, resetGetChangeMeetingListResponse);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */

export function* reportChangeMeetingSaga() {
	yield all([
		call(onGetChangeMeetingListDetails),
		call(onGetChangeMeetingListResponseReset),
        call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
	]);
}
