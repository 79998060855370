/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BusinessDetailsActionTypes from './business-details.types';
import {
	createBusinessDetails,
	updateBusinessDetails,
	fetchBusinessDetails,
	deleteBusinessDetails,
	businessListGet,
} from './business-details.service';
import {
	addBusinessDetailsSuccess,
	addBusinessDetailsFailure,
	addBusinessDetailsResponseChanged,
	updateBusinessDetailsSuccess,
	updateBusinessDetailsFailure,
	updateBusinessDetailsResponseChanged,
	fetchBusinessDetailsSuccess,
	fetchBusinessDetailsFailure,
	fetchBusinessDetailsResponseChanged,
	deleteBusinessSuccess,
	deleteBusinessFailure,
	deleteBusinessResponseChanged,
	getAllBusinessListSuccess,
	getAllBusinessListFailure,
	getAllBusinessListResponseChanged,
} from './business-details.action';
import { encodeJson } from '../../../enode-decode';

/** *********************  START ADD BUSINESS DETAILS ******************** */
export function* businessDetailsAdd({ payload }) {
	try {
		const key = 'BusinessDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BusinessName: payload.businessName,
			BusinessFormat: payload.businessFormat,
			Constitution: payload.constitution,
			Industry: payload.industry,
			SubIndustry: payload.subIndustry,
			YearOfIncorporation: payload.yearOfIncorporation,
			DateOfIncorporation: payload.dateOfIncorporation,
			NoOfDirectors: payload.noOfDirectors,
			NoOfEmployee: payload.noOfEmployee,
			NoOfBranch: payload.noOfBranch,
			CinNumber: payload.cinNumber,
			CinRegistrationUrl: payload.cinRegistrationUrl,
			PanNumber: payload.panNumber,
			PanUrl: payload.panUrl,
			GstNumber: payload.gstNumber,
			LeiNumber: payload.leiNumber,
			GstImage: payload.gstImage,
			PropertyType: payload.propertyType,
			PropertyStatus: payload.propertyStatus,
			NoOfFlors: payload.noOfFlors,
			RentalCost: payload.rentalCost,
			PurposeOfLoan: payload.purposeOfLoan,
			RequestedAmount: payload.requestedAmount,
			BusinessExperience: payload.businessExperience,
			AverageMonthlyTurnover: payload.averageMonthlyTurnover,
			ExpectedFestivalSale: payload.expectedFestivalSale,
			StockValue: payload.stockValue,
			SurveyNumber: payload.surveyNumber,
			SquareFeet: payload.squareFeet,
			HeadOfficeAddressLine1: payload.headOfficeAddressLine1,
			HeadOfficeAddressLine2: payload.headOfficeAddressLine2,
			HeadOfficeState: payload.headOfficeState,
			HeadOfficeCity: payload.headOfficeCity,
			HeadOfficeZip: payload.headOfficeZip,
			HeadOfficeCountry: payload.headOfficeCountry,
			RegisterOfficeAddressLine1: payload.registerOfficeAddressLine1,
			RegisterOfficeAddressLine2: payload.registerOfficeAddressLine2,
			RegisterOfficeState: payload.registerOfficeState,
			RegisterOfficeCity: payload.registerOfficeCity,
			RegisterOfficeZip: payload.registerOfficeZip,
			RegisterOfficeCountry: payload.registerOfficeCountry,
			ContactPerson1Name: payload.contactPerson1Name,
			ContactPerson1Title: payload.contactPerson1Title,
			ContactPerson1Phone: payload.contactPerson1Phone,
			ContactPerson1Email: payload.contactPerson1Email,
			ContactPerson2Name: payload.contactPerson2Name,
			ContactPerson2Title: payload.contactPerson2Title,
			ContactPerson2Phone: payload.contactPerson2Phone,
			ContactPerson2Email: payload.contactPerson2Email,
			ContactPerson3Name: payload.contactPerson3Name,
			ContactPerson3Title: payload.contactPerson3Title,
			ContactPerson3Phone: payload.contactPerson3Phone,
			ContactPerson3Email: payload.contactPerson3Email,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createBusinessDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBusinessDetailsFailure(responseData.data));
		} else {
			yield put(addBusinessDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBusinessDetailsFailure(error));
	}
}

export function* resetAddBusinessResponse() {
	yield put(addBusinessDetailsResponseChanged());
}

export function* onAddBusinessDetails() {
	yield takeLatest(BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_START, businessDetailsAdd);
}

export function* onAddBusinessResponseReset() {
	yield takeLatest(
		BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_RESPONSE_RESET,
		resetAddBusinessResponse,
	);
}
/** *********************  END ADD BUSINESS DETAILS  ******************** */

/** *********************  START UPDATE BUSINESS DETAILS   ******************** */
export function* businessDetailsUpdate({ payload }) {
	try {
		const key = 'UpdateBusinessDetailsKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			BusinessName: payload.businessName,
			BusinessFormat: payload.businessFormat,
			Constitution: payload.constitution,
			Industry: payload.industry,
			SubIndustry: payload.subIndustry,
			YearOfIncorporation: payload.yearOfIncorporation,
			DateOfIncorporation: payload.dateOfIncorporation,
			NoOfDirectors: payload.noOfDirectors,
			NoOfEmployee: payload.noOfEmployee,
			NoOfBranch: payload.noOfBranch,
			CinNumber: payload.cinNumber,
			CinRegistrationUrl: payload.cinRegistrationUrl,
			PanNumber: payload.panNumber,
			PanUrl: payload.panUrl,
			GstNumber: payload.gstNumber,
			LeiNumber: payload.leiNumber,
			GstImage: payload.gstImage,
			PropertyType: payload.propertyType,
			PropertyStatus: payload.propertyStatus,
			NoOfFlors: payload.noOfFlors,
			RentalCost: payload.rentalCost,
			PurposeOfLoan: payload.purposeOfLoan,
			RequestedAmount: payload.requestedAmount,
			BusinessExperience: payload.businessExperience,
			AverageMonthlyTurnover: payload.averageMonthlyTurnover,
			ExpectedFestivalSale: payload.expectedFestivalSale,
			StockValue: payload.stockValue,
			SurveyNumber: payload.surveyNumber,
			SquareFeet: payload.squareFeet,
			HeadOfficeAddressLine1: payload.headOfficeAddressLine1,
			HeadOfficeAddressLine2: payload.headOfficeAddressLine2,
			HeadOfficeState: payload.headOfficeState,
			HeadOfficeCity: payload.headOfficeCity,
			HeadOfficeZip: payload.headOfficeZip,
			HeadOfficeCountry: payload.headOfficeCountry,
			RegisterOfficeAddressLine1: payload.registerOfficeAddressLine1,
			RegisterOfficeAddressLine2: payload.registerOfficeAddressLine2,
			RegisterOfficeState: payload.registerOfficeState,
			RegisterOfficeCity: payload.registerOfficeCity,
			RegisterOfficeZip: payload.registerOfficeZip,
			RegisterOfficeCountry: payload.registerOfficeCountry,
			ContactPerson1Name: payload.contactPerson1Name,
			ContactPerson1Title: payload.contactPerson1Title,
			ContactPerson1Phone: payload.contactPerson1Phone,
			ContactPerson1Email: payload.contactPerson1Email,
			ContactPerson2Name: payload.contactPerson2Name,
			ContactPerson2Title: payload.contactPerson2Title,
			ContactPerson2Phone: payload.contactPerson2Phone,
			ContactPerson2Email: payload.contactPerson2Email,
			ContactPerson3Name: payload.contactPerson3Name,
			ContactPerson3Title: payload.contactPerson3Title,
			ContactPerson3Phone: payload.contactPerson3Phone,
			ContactPerson3Email: payload.contactPerson3Email,
			CreatedBy: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateBusinessDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBusinessDetailsFailure(responseData.data));
		} else {
			yield put(updateBusinessDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBusinessDetailsFailure(error));
	}
}

export function* resetUpdateBusinessResponse() {
	yield put(updateBusinessDetailsResponseChanged());
}

export function* onUpdateBusinessDetails() {
	yield takeLatest(
		BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_START,
		businessDetailsUpdate,
	);
}

export function* onUpdateBusinessResponseReset() {
	yield takeLatest(
		BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_RESPONSE_RESET,
		resetUpdateBusinessResponse,
	);
}
/** *********************  END UPDATE BUSINESS DETAILS ******************** */

/** *********************  START FETCH BUSINESS DETAILS  ******************** */
export function* businessDetailsFetch({ payload }) {
	try {
		const key = 'GetBusinessDetailsKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchBusinessDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBusinessDetailsFailure(responseData.data));
		} else {
			yield put(fetchBusinessDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBusinessDetailsFailure(error));
	}
}

export function* resetFetchBusinessDetailsResponse() {
	yield put(fetchBusinessDetailsResponseChanged());
}

export function* onFetchBusinessDetails() {
	yield takeLatest(BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_START, businessDetailsFetch);
}

export function* onFetchBusinessResponseReset() {
	yield takeLatest(
		BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_RESPONSE_RESET,
		resetFetchBusinessDetailsResponse,
	);
}
/** *********************  END FETCH BUSINESS DETAILS ******************** */

/** *********************  START FETCH BUSINESS DETAILS  ******************** */
export function* businessDelete({ payload }) {
	try {
		const key = 'DeleteBusinessDetailsKey';
		const jsonData = {
			BusinessID: payload.businessID,
			CompanyID: payload.companyID,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteBusinessDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBusinessFailure(responseData.data));
		} else {
			yield put(deleteBusinessSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBusinessFailure(error));
	}
}

export function* resetDeleteBusinessResponse() {
	yield put(deleteBusinessResponseChanged());
}

export function* onDeleteBusiness() {
	yield takeLatest(BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_START, businessDelete);
}

export function* onDeleteBusinessResponseReset() {
	yield takeLatest(
		BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_RESPONSE_RESET,
		resetDeleteBusinessResponse,
	);
}
/** *********************  END FETCH BUSINESS DETAILS ******************** */

/** *********************  START GET ALL BUSINESS   ******************** */
export function* allBusinessListGet({ payload }) {
	try {
		const key = 'GetBusinessListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			Limit: payload.limit,
			UserID: payload.userID,
			DatabaseName: payload.databaseName,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(businessListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllBusinessListFailure(responseData.data));
		} else {
			yield put(getAllBusinessListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllBusinessListFailure(error));
	}
}

export function* resetAllBusinessListReset() {
	yield put(getAllBusinessListResponseChanged());
}

export function* onGetBusinessListDetails() {
	yield takeLatest(BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_START, allBusinessListGet);
}

export function* onGetBusinessListResponseReset() {
	yield takeLatest(
		BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_RESPONSE_RESET,
		resetAllBusinessListReset,
	);
}
/** *********************  END GET ALL BUSINESS ******************** */

export function* businessDetailsSaga() {
	yield all([
		call(onAddBusinessDetails),
		call(onAddBusinessResponseReset),
		call(onUpdateBusinessDetails),
		call(onUpdateBusinessResponseReset),
		call(onFetchBusinessDetails),
		call(onFetchBusinessResponseReset),
		call(onDeleteBusiness),
		call(onDeleteBusinessResponseReset),
		call(onGetBusinessListDetails),
		call(onGetBusinessListResponseReset),
	]);
}
