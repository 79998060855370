/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import TatReportTypes from './tat-report.types';
import { getTatDetails } from './tat-report.service';
import {
	fetchTatReportDetailSuccess,
	fetchTatReportDetailFailure,
	fetchTatReportDetailResponseChanged,
} from './tat-report.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH TAT DETAILS  ******************** */
export function* fetchTatReportDetails({ payload }) {
	try {
		const key = 'FetchTatDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			FilterValue: payload.filterData,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getTatDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchTatReportDetailFailure(responseData.data));
		} else {
			yield put(fetchTatReportDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchTatReportDetailFailure(error));
	}
}

export function* resetFetchTatResponse() {
	yield put(fetchTatReportDetailResponseChanged());
}

export function* onFetchTatDetails() {
	yield takeLatest(TatReportTypes.FETCH_TAT_REPORT_START, fetchTatReportDetails);
}

export function* onFetchTatResponseReset() {
	yield takeLatest(TatReportTypes.FETCH_TAT_REPORT_RESPONSE_RESET_START, resetFetchTatResponse);
}
/** *********************  END FETCH TAT DETAILS ******************** */

export function* tatReportSaga() {
	yield all([call(onFetchTatDetails), call(onFetchTatResponseReset)]);
}
