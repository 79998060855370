/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import advanceCollectionActionTypes from './advanceCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getAdvanceMemberLoanInfoResponse: null,
	getAdvanceMemberLoanInfoArr: [],
	updatedAdvanceCollectionResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_SUCCESS:
			return {
				...state,
				getAdvanceMemberLoanInfoResponse: action.payload,
				getAdvanceMemberLoanInfoArr: docodeResponse(
					action.payload,
					'FetchAdvanceMemberListDataKey',
				),
			};

		case advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_FAILURE:
			return { ...state, getAdvanceMemberLoanInfoResponse: action.payload };

		case advanceCollectionActionTypes.GET_ADVANCE_MEMBER_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getAdvanceMemberLoanInfoResponse: null };

		case advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_SUCCESS:
			return {
				...state,
				updatedAdvanceCollectionResponse: action.payload,
			};

		case advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_FAILURE:
			return { ...state, updatedAdvanceCollectionResponse: action.payload };

		case advanceCollectionActionTypes.UPDATE_ADVANCE_COLLECTION_RESPONSE_CHANGED:
			return { ...state, updatedAdvanceCollectionResponse: null };

		default:
			return state;
	}
};
