/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const proofDetailsAdd = (proofData) => {
	const data = {
		AddBusinessTokenProof: proofData,
	};
	const url = `${API_URL}addBusinessProof`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchProofDetails = (fetchData) => {
	const data = {
		GetBusinessTokenProof: fetchData,
	};
	const url = `${API_URL}getBusinessProof`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateProofDetails = (updateData) => {
	const data = {
		UpdateProofDetailsToken: updateData,
	};
	const url = `${API_URL}updateBusinessProof`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
