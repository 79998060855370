const futureDemandReportTypes = {
	FETCH_FUTURE_DEMAND_REPORT_START: 'fetchFutureDemandReportStart',
	FETCH_FUTURE_DEMAND_REPORT_SUCCESS: 'fetchFutureDemandReportSuccess',
	FETCH_FUTURE_DEMAND_REPORT_FAILURE: 'fetchFutureDemandReportFailure',
	FETCH_FUTURE_DEMAND_REPORT_RESPONSE_RESET_START: 'fetchFutureDemandReportResponseResetStart',
	FETCH_FUTURE_DEMAND_REPORT_RESPONSE_CHANGED: 'fetchFutureDemandReportResponseResetChanged',

	FETCH_FUTURE_DEMAND_REPORT_EXCEL_START: 'fetchFutureDemandReportExcelStart',
	FETCH_FUTURE_DEMAND_REPORT_EXCEL_SUCCESS: 'fetchFutureDemandReportExcelSuccess',
	FETCH_FUTURE_DEMAND_REPORT_EXCEL_FAILURE: 'fetchFutureDemandReportExcelFailure',
	FETCH_FUTURE_DEMAND_REPORT_RESPONSE_EXCEL_RESET_START: 'fetchFutureDemandReportResponsExceleResetStart',
	FETCH_FUTURE_DEMAND_REPORT_RESPONSE_EXCEL_CHANGED: 'fetchFutureDemandReportResponseExcelResetChanged',
};
export default futureDemandReportTypes;
