/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PartnerDetailsActionTypes from './partner-details.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addBusinessPartnerResponse: null,
	fetchBusinessPartnerResponse: null,
	fetchBusinessPartnerList: [],
	updateBusinessPartnerResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_SUCCESS:
			return { ...state, addBusinessPartnerResponse: action.payload };

		case PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_FAILURE:
			return { ...state, addBusinessPartnerResponse: action.payload };

		case PartnerDetailsActionTypes.ADD_BUSINESS_PARTNER_RESPONSE_CHANGED:
			return { ...state, addBusinessPartnerResponse: null };

		case PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_SUCCESS:
			return { ...state, updateBusinessPartnerResponse: action.payload };

		case PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_FAILURE:
			return { ...state, updateBusinessPartnerResponse: action.payload };

		case PartnerDetailsActionTypes.UPDATE_BUSINESS_PARTNER_RESPONSE_CHANGED:
			return { ...state, updateBusinessPartnerResponse: null };

		case PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_SUCCESS:
			return {
				...state,
				fetchBusinessPartnerResponse: action.payload,
				fetchBusinessPartnerList: docodeResponse(action.payload, 'GetBusinessPartnerKey'),
			};

		case PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_FAILURE:
			return { ...state, fetchBusinessPartnerResponse: action.payload };

		case PartnerDetailsActionTypes.FETCH_BUSINESS_PARTNER_RESPONSE_CHANGED:
			return { ...state, fetchBusinessPartnerResponse: null };

		default:
			return state;
	}
};
