/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import repaymentCollectionReportTypes from './RepaymentCollectionReport.types';
import {
	fetchGroupMemberList,
	fetchPostRepaymentCollection,
	fetchPostRepaymentCollectionExcel,
} from './RepaymentCollectionReport.services';
import {
	fetchGroupMemberListSuccess,
	fetchGroupMemberListFailure,
	fetchGroupMemberListChanged,
	fetchPostRepaymentCollectionSuccess,
	fetchPostRepaymentCollectionFailure,
	fetchPostRepaymentCollectionChanged,
	fetchPostRepaymentCollectionExcelSuccess,
	fetchPostRepaymentCollectionExcelFailure,
} from './RepaymentCollectionReport.actions';
import { encodeJson } from '../../enode-decode';
import generalTypesAction from '../../genera.types';

export function* fetchGroupMemberListCall({ payload: { values } }) {
	try {
		const jsonData = {
			GroupID: values.GroupID,
			DatabaseName: values.DatabaseName,
		};
		const branchData = encodeJson(jsonData, generalTypesAction.GROUPMEMBERLIST);
		const responseData = yield call(fetchGroupMemberList, branchData);
		if (responseData.data.statusCode === '500') {
			yield put(fetchGroupMemberListFailure(responseData.data));
		} else {
			yield put(fetchGroupMemberListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchGroupMemberListFailure(error));
	}
}

export function* onSelectGroupMemberList() {
	yield takeLatest(
		repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_START,
		fetchGroupMemberListCall,
	);
}

export function* fetchGroupMemberListReset() {
	yield put(fetchGroupMemberListChanged());
}

export function* onSelectGroupMemberListReset() {
	yield takeLatest(
		repaymentCollectionReportTypes.FETCH_GROUP_MEMBER_LIST_RESET,
		fetchGroupMemberListReset,
	);
}

export function* fetchPostRepaymentCollectionCall({ payload }) {
	try {
		const key = 'RepaymentCollectionListKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			MemberID: payload.MemberID,
			FunderID: payload.FunderID,
			Limit: payload.Limit,
			IsBusiness: payload.IsBusiness,
			DateType: payload.DateType,
			CollectionMode: payload.CollectionMode,
			InputSearch: '0',
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchPostRepaymentCollection, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPostRepaymentCollectionFailure(responseData.data));
		} else {
			yield put(fetchPostRepaymentCollectionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPostRepaymentCollectionFailure(error));
	}
}

export function* fetchPostRepaymentCollectionExcelCall({ payload }) {
	try {
		const key = 'RepaymentCollectionExcelKey';
		const jsonData = {
			DatabaseName: payload.DatabaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			GroupID: payload.GroupID,
			MemberID: payload.MemberID,
			DateType: payload.DateType,
			FunderID: payload.FunderID,
			CompanyID: payload.CompanyID,
			CollectionMode: payload.CollectionMode,
			CountLimit: payload.Limit,
			UserID: payload.UserID,
			RequestID: payload.RequestID,
			IsBusiness: payload.IsBusiness,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(fetchPostRepaymentCollectionExcel, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchPostRepaymentCollectionExcelFailure(responseData.data));
		} else {
			yield put(fetchPostRepaymentCollectionExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchPostRepaymentCollectionExcelFailure(error));
	}
}

export function* onSelectPostRepaymentCollection() {
	yield takeLatest(
		repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_START,
		fetchPostRepaymentCollectionCall,
	);
}

export function* fetchPostRepaymentCollectionReset() {
	yield put(fetchPostRepaymentCollectionChanged());
}

export function* onSelectPostRepaymentCollectionReset() {
	yield takeLatest(
		repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_RESET,
		fetchPostRepaymentCollectionReset,
	);
}

export function* onSelectPostRepaymentCollectionExcel() {
	yield takeLatest(
		repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_START,
		fetchPostRepaymentCollectionExcelCall,
	);
}

export function* onSelectPostRepaymentCollectionExcelReset() {
	yield takeLatest(
		repaymentCollectionReportTypes.FETCH_POST_REPAYMENT_COLLECTION_EXCEL_RESET,
		fetchPostRepaymentCollectionReset,
	);
}

export function* repaymentCollectionReportSaga() {
	yield all([
		call(onSelectGroupMemberList),
		call(onSelectGroupMemberListReset),
		call(onSelectPostRepaymentCollection),
		call(onSelectPostRepaymentCollectionReset),
		call(onSelectPostRepaymentCollectionExcel),
		call(onSelectPostRepaymentCollectionExcelReset),
	]);
}
