/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ProductActionTypes from './product.types';

/** ***************** ADD PRODUCT DETAILS START *********************** */
export const addProductDetailsStart = (addData) => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_START,
	payload: addData,
});

export const addProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const addProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const addProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const addProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.ADD_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD PRODUCT DETAILS END *********************** */

/** ***************** UPDATE PRODUCT DETAILS START *********************** */
export const updateProductDetailsStart = (updateData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_START,
	payload: updateData,
});

export const updateProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const updateProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const updateProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const updateProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.UPDATE_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE PRODUCT DETAILS END *********************** */

/** ***************** GET PRODUCT LIST START *********************** */
export const getProductListStart = (getData) => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_START,
	payload: getData,
});

export const getProductListSuccess = (successData) => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_SUCCESS,
	payload: successData,
});

export const getProductListFailure = (failureData) => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_FAILURE,
	payload: failureData,
});

export const getProductListResponseResetStart = () => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_RESET,
});

export const getProductListResponseChanged = () => ({
	type: ProductActionTypes.GET_PRODUCT_LIST_RESPONSE_CHANGED,
});

/** ***************** GET PRODUCT LIST END *********************** */

/** ***************** FETCH PRODUCT DETAILS START *********************** */
export const fetchProductDetailsStart = (fetchData) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_START,
	payload: fetchData,
});

export const fetchProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const fetchProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.FETCH_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PRODUCT DETAILS END *********************** */

/** ***************** DELETE PRODUCT DETAILS START *********************** */
export const deleteProductDetailsStart = (deleteData) => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_START,
	payload: deleteData,
});

export const deleteProductDetailsSuccess = (successData) => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteProductDetailsFailure = (failureData) => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteProductDetailsResponseResetStart = () => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_RESET,
});

export const deleteProductDetailsResponseChanged = () => ({
	type: ProductActionTypes.DELETE_PRODUCT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE PRODUCT DETAILS END *********************** */
