const executiveActionTypes = {
	GET_ALL_ALLOCATION_LIST: 'get_all_allocation_list',
	ALLOCATION_LIST_START: 'allocation_list_start',
	ALLOCATION_LIST_SUCCESS: 'allocation_list_success',
	ALLOCATION_LIST_FAILURE: 'allocation_list_failure',
	ALLOCATION_LIST_RESET: 'allocation_list_reset',
	ALLOCATION_LIST_CHANGED: 'allocation_list_changed',

	ALLOCATE_MEMBER_TO_STAFF_LIST: 'allocate_member_to_staff_list',
	ALLOCATE_MEMBER_TO_STAFF_START: 'allocate_member_to_staff_start',
	ALLOCATE_MEMBER_TO_STAFF_SUCCESS: 'allocate_member_to_staff_success',
	ALLOCATE_MEMBER_TO_STAFF_FAILURE: 'allocate_member_to_staff_failure',
	ALLOCATE_MEMBER_TO_STAFF_RESET: 'allocate_member_to_staff_reset',
	ALLOCATE_MEMBER_TO_STAFF_CHANGED: 'allocate_member_to_staff_changed',

	TRANSFER_MEMBER_TO_STAFF_LIST: 'transfer_member_to_staff_list',
	TRANSFER_MEMBER_TO_STAFF_START: 'transfer_member_to_staff_start',
	TRANSFER_MEMBER_TO_STAFF_SUCCESS: 'transfer_member_to_staff_success',
	TRANSFER_MEMBER_TO_STAFF_FAILURE: 'transfer_member_to_staff_failure',
	TRANSFER_MEMBER_TO_STAFF_RESET: 'transfer_member_to_staff_reset',
	TRANSFER_MEMBER_TO_STAFF_CHANGED: 'transfer_member_to_staff_changed',

	EXECUTIVE_DELETE_REPSONSE_START: 'executive_delete_response_start',
	EXECUTIVE_DELETE_REPSONSE_SUCCESS: 'executive_delete_response_success',
	EXECUTIVE_DELETE_REPSONSE_FAILURE: 'executive_delete_response_failure',
	EXECUTIVE_DELETE_REPSONSE_CHANGED: 'executive_delete_response_changed',
	EXECUTIVE_DELETE_REPSONSE_RESET: 'executive_delete_response_reset',

	EXECUTIVE_SELECTED_ID: 'executive_selected_id',	
};
export default executiveActionTypes;
