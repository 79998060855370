/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import memberCollectionActionTypes from './memberCollection.types';

/** ***************** GET SEARCH MEMBER LOAN LIST START *********************** */
export const fetchSearchMemberLoanListStart = (getData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_START,
	payload: getData,
});

export const getSearchMemberLoanListSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS,
	payload: successData,
});

export const getSearchMemberLoanListFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_FAILURE,
	payload: failureData,
});

export const fetchSearchMemberLoanListResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET,
});

export const getSearchMemberLoanListResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED,
});
/** ***************** GET SEARCH MEMBER LOAN LIST END *********************** */

/** ***************** GET MEMBER LOAN INFO START *********************** */
export const fetchMemberLoanInfoStart = (getData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_START,
	payload: getData,
});

export const getMemberLoanInfoSuccess = (successData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_SUCCESS,
	payload: successData,
});

export const getMemberLoanInfoFailure = (failureData) => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_FAILURE,
	payload: failureData,
});

export const fetchMemberLoanInfoResponseResetStart = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_RESET,
});

export const getMemberLoanInfoResponseChanged = () => ({
	type: memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED,
});
/** ***************** GET MEMBER LOAN INFO END *********************** */
