/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import EconomicActivityTypeActionTypes from './economic-activity-type.types';

/** ***************** ADD ECONOMIC ACTIVITY TYPE DETAILS START *********************** */
export const addEconomicActivityTypeDetailsStart = (addData) => ({
	type: EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
	payload: addData,
});

export const addEconomicActivityTypeDetailsSuccess = (successData) => ({
	type: EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const addEconomicActivityTypeDetailsFailure = (failureData) => ({
	type: EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const addEconomicActivityTypeDetailsResponseResetStart = () => ({
	type: EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
});

export const addEconomicActivityTypeDetailsResponseChanged = () => ({
	type: EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD ECONOMIC ACTIVITY TYPE DETAILS END *********************** */

/** ***************** UPDATE ECONOMIC ACTIVITY TYPE DETAILS START *********************** */
export const updateEconomicActivityTypeDetailsStart = (updateData) => ({
	type: EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
	payload: updateData,
});

export const updateEconomicActivityTypeDetailsSuccess = (successData) => ({
	type: EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const updateEconomicActivityTypeDetailsFailure = (failureData) => ({
	type: EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const updateEconomicActivityTypeDetailsResponseResetStart = () => ({
	type: EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
});

export const updateEconomicActivityTypeDetailsResponseChanged = () => ({
	type: EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE ECONOMIC ACTIVITY TYPE DETAILS END *********************** */

/** ***************** GET ECONOMIC ACTIVITY TYPE LIST START *********************** */
export const getEconomicActivityTypeListStart = (getData) => ({
	type: EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_START,
	payload: getData,
});

export const getEconomicActivityTypeListSuccess = (successData) => ({
	type: EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_SUCCESS,
	payload: successData,
});

export const getEconomicActivityTypeListFailure = (failureData) => ({
	type: EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_FAILURE,
	payload: failureData,
});

export const getEconomicActivityTypeListResponseResetStart = () => ({
	type: EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_RESPONSE_RESET,
});

export const getEconomicActivityTypeListResponseChanged = () => ({
	type: EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_RESPONSE_CHANGED,
});

/** ***************** GET ECONOMIC ACTIVITY TYPE LIST END *********************** */

/** ***************** FETCH ECONOMIC ACTIVITY TYPE DETAILS START *********************** */
export const fetchEconomicActivityTypeDetailsStart = (fetchData) => ({
	type: EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
	payload: fetchData,
});

export const fetchEconomicActivityTypeDetailsSuccess = (successData) => ({
	type: EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchEconomicActivityTypeDetailsFailure = (failureData) => ({
	type: EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchEconomicActivityTypeDetailsResponseResetStart = () => ({
	type: EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
});

export const fetchEconomicActivityTypeDetailsResponseChanged = () => ({
	type: EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH ECONOMIC ACTIVITY TYPE DETAILS END *********************** */

/** ***************** DELETE ECONOMIC ACTIVITY TYPE DETAILS START *********************** */
export const deleteEconomicActivityTypeDetailsStart = (deleteData) => ({
	type: EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_START,
	payload: deleteData,
});

export const deleteEconomicActivityTypeDetailsSuccess = (successData) => ({
	type: EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteEconomicActivityTypeDetailsFailure = (failureData) => ({
	type: EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteEconomicActivityTypeDetailsResponseResetStart = () => ({
	type: EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_RESET,
});

export const deleteEconomicActivityTypeDetailsResponseChanged = () => ({
	type: EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE ECONOMIC ACTIVITY TYPE DETAILS END *********************** */

/** ***************** FETCH ALL ECONOMIC ACTIVITY TYPE START *********************** */
export const fetchAllEconomicActivityTypeStart = (fetchEconomicActivityTypes) => ({
	type: EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_START,
	payload: fetchEconomicActivityTypes,
});

export const fetchAllEconomicActivityTypeSuccess = (successData) => ({
	type: EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_SUCCESS,
	payload: successData,
});

export const fetchAllEconomicActivityTypeFailure = (failureData) => ({
	type: EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_FAILURE,
	payload: failureData,
});

export const fetchAllEconomicActivityTypeResponseResetStart = () => ({
	type: EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_RESPONSE_RESET,
});

export const fetchAllEconomicActivityTypeResponseChanged = () => ({
	type: EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL ECONOMIC ACTIVITY TYPE END *********************** */
