const ActiveMemberActionTypes = {

    GET_ACTIVE_MEMBER_LIST_START: 'get_active_member_start',
	GET_ACTIVE_MEMBER_LIST_SUCCESS: 'get_active_member_success',
	GET_ACTIVE_MEMBER_LIST_FAILURE: 'get_active_member_failure',
	GET_ACTIVE_MEMBER_LIST_RESPONSE_RESET: 'get_active_member_response_reset',
	GET_ACTIVE_MEMBER_LIST_RESPONSE_CHANGED: 'get_active_member_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',

	FETCH_ACTIVE_MEMBER_REPORT_EXCEL_START: 'fetch_active_member_report_excel_start',
	FETCH_ACTIVE_MEMBER_REPORT_EXCEL_SUCCESS: 'fetch_active_member_report_excel_success',
	FETCH_ACTIVE_MEMBER_REPORT_EXCEL_FAILURE: 'fetch_active_member_report_excel_failure',
	FETCH_ACTIVE_MEMBER_REPORT_RESPONSE_EXCEL_RESET_START: 'fetch_active_member_report_response_excel_reset_start',
	FETCH_ACTIVE_MEMBER_REPORT_RESPONSE_EXCEL_CHANGED: 'fetch_active_member_report_response_excel_changed',
    
}
export default ActiveMemberActionTypes;