const staffActionTypes = {
	STAFF_GET_ALL_LIST: 'staff_get_all_list',
	STAFF_LIST_START: 'staff_list_start',
	STAFF_LIST_SUCCESS: 'staff_list_success',
	STAFF_LIST_FAILURE: 'staff_list_failure',
	STAFF_LIST_RESET: 'staff_list_reset',
	STAFF_LIST_CHANGED: 'staff_list_changed',

	STAFF_CREATE_REPSONSE_START: 'staff_create_response_start',
	STAFF_CREATE_REPSONSE_SUCCESS: 'staff_create_response_success',
	STAFF_CREATE_REPSONSE_FAILURE: 'staff_create_response_failure',
	STAFF_CREATE_REPSONSE_CHANGED: 'staff_create_response_changed',
	STAFF_CREATE_REPSONSE_RESET: 'staff_create_response_reset',

	STAFF_UPDATE_REPSONSE_START: 'staff_update_response_start',
	STAFF_UPDATE_REPSONSE_SUCCESS: 'staff_update_response_success',
	STAFF_UPDATE_REPSONSE_FAILURE: 'staff_update_response_failure',
	STAFF_UPDATE_REPSONSE_CHANGED: 'staff_update_response_changed',
	STAFF_UPDATE_REPSONSE_RESET: 'staff_update_response_reset',

	STAFF_DELETE_REPSONSE_START: 'staff_delete_response_start',
	STAFF_DELETE_REPSONSE_SUCCESS: 'staff_delete_response_success',
	STAFF_DELETE_REPSONSE_FAILURE: 'staff_delete_response_failure',
	STAFF_DELETE_REPSONSE_CHANGED: 'staff_delete_response_changed',
	STAFF_DELETE_REPSONSE_RESET: 'staff_delete_response_reset',

	STAFF_GET_BASED_ON_TEAM: 'staff_get_based_on_team',
	STAFF_GET_BASED_ON_TEAM_SUCCESS: 'staff_get_based_on_team_success',
	STAFF_GET_BASED_ON_TEAM_FAILURE: 'staff_get_based_on_team_failure',
	STAFF_GET_BASED_ON_TEAM_CHANGED: 'staff_get_based_on_team_changed',
	STAFF_GET_BASED_ON_TEAM_RESET: 'staff_get_based_on_team_reset',

	GET_MANAGER_LIST_START: 'get_manager_list_start',
	GET_MANAGER_LIST_SUCCESS: 'get_manager_list_success',
	GET_MANAGER_LIST_FAILURE: 'get_manager_list_failure',
	GET_MANAGER_LIST_CHANGED: 'get_manager_list_changed',
	GET_MANAGER_LIST_RESET: 'get_manager_list_reset',
	STAFF_SELECTED_ID: 'staff_selected_id',
};
export default staffActionTypes;
