/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const fetchOfficerListCenters = (listData) => {
	const data = {
		CenterListBasedForOfficerToken: listData,
	};
	const url = `${API_URL}GetCenterListForOfficerWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchOfficerListArrearCenters = (listData) => {
	const data = {
		ArrearCenterListBasedForOfficerToken: listData,
	};
	const url = `${API_URL}GetArrearCenterListForOfficerWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchBranchCollectionUsersList = (listData) => {
	const data = {
		BranchCollectionUsersToken: listData,
	};
	const url = `${API_URL}GetBranchCollectionUsersWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCenterListOfficerCollections = (listData) => {
	const data = {
		centerListOfficerCollectionsToken: listData,
	};
	const url = `${API_URL}GetCenterListOfficerCollectionWithToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
