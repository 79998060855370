/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
// import JWT from 'expo-jwt';
import { encodeJson } from '../../enode-decode';
import generalTypesAction from '../../genera.types';
import staffActionTypes from './staff.types';

import {
	getAllStaff,
	createTeamStaff,
	deleteTeamStaff,
	getTeamBasedOnStaff,
	getManagerList,
} from './staff.services';
import {
	getAllStaffListSuccess,
	getAllStaffListFailure,
	getAllStaffListChangedAction,
	createStaffSuccess,
	createStaffFailure,
	staffCreateChanged,
	deleteStaffSuccess,
	deleteStaffFailure,
	staffDeleteChanged,
	getStaffBasedOnTeamSuccess,
	getStaffBasedOnTeamFailure,
	getStaffBasedOnTeamChangedAction,
	getManagerListSuccess,
	getManagerListFailure,
	getManagerListChangedAction,
} from './staff.actions';

export function* createNewStaff({ payload: { TeamID, StaffList, UpdatedBy } }) {
	try {
		const jsonData = {
			// TeamID: values.TeamID,
			// StaffList: values.StaffList,
			// UpdatedBy: values.UpdatedBy,
			teamID: TeamID,
			staffList: StaffList,
			updatedBy: UpdatedBy,
		};
		const StaffData = encodeJson(jsonData, generalTypesAction.ADD_NEW_TEAMSTAFF);
		const responseData = yield call(createTeamStaff, StaffData);
		if (responseData.data.statusCode === '100') {
			yield put(createStaffFailure(responseData.data));
		} else {
			// const DecodeStaffData = JWT.decode(
			// 	responseData.data.response,
			// 	generalTypesAction.Staff,
			// );
			yield put(createStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createStaffFailure(error));
	}
}

export function* deleteStaffDetails({ payload: { staffID, teamID, updatedBy } }) {
	try {
		const jsonData = {
			StaffID: staffID,
			TeamID: teamID,
			UpdatedBy: updatedBy,
		};
		const StaffData = encodeJson(jsonData, generalTypesAction.DELETE_EXIST_TEAMSTAFF);
		const responseData = yield call(deleteTeamStaff, StaffData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteStaffFailure(responseData.data));
		} else {
			// const DecodeStaffData = JWT.decode(
			// 	responseData.data.response,
			// 	generalTypesAction.Staff,
			// );
			yield put(deleteStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteStaffFailure(error));
	}
}

export function* getListOfStaff({ payload: { staffList } }) {
	try {
		const jsonData = {
			Level: staffList.Level,
			TeamID: staffList.TeamID,
			Region: staffList.Region,
			inputFilter: staffList.InputFilter,
		};
		const staffData = encodeJson(jsonData, generalTypesAction.FETCH_STAFF_DETAILS_FILTER);
		const responseData = yield call(getAllStaff, staffData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllStaffListFailure(responseData.data));
		} else {
			yield put(getAllStaffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllStaffListFailure(error));
	}
}

export function* getTeamBasedStaff({ payload: { teamStaffListData } }) {
	try {
		const jsonData = {
			TeamID: teamStaffListData.TeamID,
			inputFilter: teamStaffListData.InputFilter,
			region: teamStaffListData.Region,
			managerRegion: teamStaffListData.ManagerRegion,
			manager: teamStaffListData.Manager,
			teamLeader: teamStaffListData.TeamLeader
		};

		const staffData = encodeJson(jsonData, generalTypesAction.FETCH_STAFF_BASED_ON_TEAM_ID);
		const responseData = yield call(getTeamBasedOnStaff, staffData);
		if (responseData.data.statusCode === '100') {
			yield put(getStaffBasedOnTeamFailure(responseData.data));
		} else {
			yield put(getStaffBasedOnTeamSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getStaffBasedOnTeamFailure(error));
	}
}

export function* getManagerListSelect({ payload: { teamStaffListData } }) {
	try {
		const jsonData = {
			inputFilter: teamStaffListData.InputFilter,
			region: teamStaffListData.Region
		};
		const staffData = encodeJson(jsonData, generalTypesAction.FETCH_MANAGER);
		const responseData = yield call(getManagerList, staffData);
		if (responseData.data.statusCode === '100') {
			yield put(getManagerListFailure(responseData.data));
		} else {
			yield put(getManagerListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getManagerListFailure(error));
	}
}

export function* createStaffResponseReset() {
	yield put(staffCreateChanged());
}

export function* deleteStaffResponseReset() {
	yield put(staffDeleteChanged());
}

export function* staffCreateStart() {
	yield takeLatest(staffActionTypes.STAFF_CREATE_REPSONSE_START, createNewStaff);
}

export function* staffCreateResponseReset() {
	yield takeLatest(staffActionTypes.STAFF_CREATE_REPSONSE_RESET, createStaffResponseReset);
}

export function* staffDeleteStart() {
	yield takeLatest(staffActionTypes.STAFF_DELETE_REPSONSE_START, deleteStaffDetails);
}

export function* staffDeleteResponseReset() {
	yield takeLatest(staffActionTypes.STAFF_DELETE_REPSONSE_RESET, deleteStaffResponseReset);
}

export function* getListStaffListReset() {
	yield put(getAllStaffListChangedAction());
}

export function* getTeamBasedStaffReset() {
	yield put(getStaffBasedOnTeamChangedAction());
}

export function* getManagerListSelectReset() {
	yield put(getManagerListChangedAction());
}

export function* getAllStaffList() {
	yield takeLatest(staffActionTypes.STAFF_LIST_START, getListOfStaff);
}

export function* getAllStaffListReset() {
	yield takeLatest(staffActionTypes.STAFF_LIST_RESET, getListStaffListReset);
}

export function* staffOnTeamBasedStart() {
	yield takeLatest(staffActionTypes.STAFF_GET_BASED_ON_TEAM, getTeamBasedStaff);
}

export function* staffOnTeamBasedResponseReset() {
	yield takeLatest(staffActionTypes.STAFF_GET_BASED_ON_TEAM_RESET, getTeamBasedStaffReset);
}

export function* getManagerListSaga() {
	yield takeLatest(staffActionTypes.GET_MANAGER_LIST_START, getManagerListSelect);
}

export function* getManagerListSagaReset() {
	yield takeLatest(staffActionTypes.GET_MANAGER_LIST_RESET, getManagerListSelectReset);
}
export function* staffSaga() {
	yield all([
		call(getAllStaffList),
		call(getAllStaffListReset),
		call(staffCreateStart),
		call(staffCreateResponseReset),
		call(staffDeleteStart),
		call(staffDeleteResponseReset),
		call(staffOnTeamBasedStart),
		call(staffOnTeamBasedResponseReset),
		call(getManagerListSaga),
		call(getManagerListSagaReset),
	]);
}
