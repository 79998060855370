const repaymentCollectionReportTypes = {
	FETCH_GROUP_MEMBER_LIST_START: 'FETCH_GROUP_MEMBER_LIST_START',
	FETCH_GROUP_MEMBER_LIST_SUCCESS: 'FETCH_GROUP_MEMBER_LIST_SUCCESS',
	FETCH_GROUP_MEMBER_LIST_FAILURE: 'FETCH_GROUP_MEMBER_LIST_FAILURE',
	FETCH_GROUP_MEMBER_LIST_CHANGED: 'FETCH_GROUP_MEMBER_LIST_CHANGED',
	FETCH_GROUP_MEMBER_LIST_RESPONSE_CHANGED: 'FETCH_GROUP_MEMBER_LIST_RESPONSE_CHANGED',
	FETCH_GROUP_MEMBER_LIST_RESET: 'FETCH_GROUP_MEMBER_LIST_RESET',

	FETCH_POST_REPAYMENT_COLLECTION_START: 'FETCH_POST_REPAYMENT_COLLECTION_START',
	FETCH_POST_REPAYMENT_COLLECTION_SUCCESS: 'FETCH_POST_REPAYMENT_COLLECTION_SUCCESS',
	FETCH_POST_REPAYMENT_COLLECTION_FAILURE: 'FETCH_POST_REPAYMENT_COLLECTION_FAILURE',
	FETCH_POST_REPAYMENT_COLLECTION_CHANGED: 'FETCH_POST_REPAYMENT_COLLECTION_CHANGED',
	FETCH_POST_REPAYMENT_COLLECTION_RESPONSE_CHANGED:
		'FETCH_POST_REPAYMENT_COLLECTION_RESPONSE_CHANGED',
	FETCH_POST_REPAYMENT_COLLECTION_RESET: 'FETCH_POST_REPAYMENT_COLLECTION_RESET',

	FETCH_POST_REPAYMENT_COLLECTION_EXCEL_START: 'FETCH_POST_REPAYMENT_COLLECTION_EXCEL_START',
	FETCH_POST_REPAYMENT_COLLECTION_EXCEL_SUCCESS: 'FETCH_POST_REPAYMENT_COLLECTION_EXCEL_SUCCESS',
	FETCH_POST_REPAYMENT_COLLECTION_EXCEL_FAILURE: 'FETCH_POST_REPAYMENT_COLLECTION_EXCEL_FAILURE',
	FETCH_POST_REPAYMENT_COLLECTION_EXCEL_RESET: 'FETCH_POST_REPAYMENT_COLLECTION_EXCEL_RESET',
};
export default repaymentCollectionReportTypes;
