/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import LoanDisbursementActionTypes from './loan-disbursement.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	TotalLoanDisbursementAmount: 0,
	getLoanDisbursementListResponse: null,
	getLoanDisbursementListArr: [],
	getLoanDisbursementListStatus: null,

	getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],

	getLoanDisbursementExcelListResponse: null,
	// getLoanDisbursementExcelListArr: [],
	getLoanDisbursementExcelListStatus: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_SUCCESS:
			return {
				...state,
				getLoanDisbursementListResponse: action.payload,
				getLoanDisbursementListArr: docodeResponse(action.payload, 'LoanDisbursementKey'),
				TotalLoanDisbursementAmount: action.payload.TotalLoanDisbursementAmount,
				getLoanDisbursementListStatus: 'Success',
			};

		case LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_FAILURE:
			return { ...state, getLoanDisbursementListStatus: action.payload };

		case LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_RESPONSE_CHANGED:
			return { ...state, getLoanDisbursementListStatus: null };

		case LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
			return {
				...state,
				getOfficerBranchListResponse: action.payload,
				getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
			};

		case LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
			return { ...state, getOfficerBranchListResponse: action.payload };

		case LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
			return { ...state, getOfficerBranchListResponse: null };

		case LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_SUCCESS:
			return {
				...state,
				getLoanDisbursementExcelListResponse: action.payload,
				// getLoanDisbursementExcelListArr: docodeResponse(action.payload, 'LoanDisbursementKey'),
				getLoanDisbursementExcelListStatus: 'Success',
			};

		case LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_FAILURE:
			return { ...state, getLoanDisbursementExcelListStatus: action.payload };

		case LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_RESPONSE_CHANGED:
			return { ...state, getLoanDisbursementExcelListStatus: null };
		default:
			return state;
	}
};
