/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import progressReportTypes from './ProgressReport.types';
import {
	fetchAllOfficerUserBasedOnBranchReport,
	fetchBranchProgressReport,
} from './ProgressReport.services';
import {
	fetchAllGroupOfficerUserBasedOnBranchSuccess,
	fetchAllGroupOfficerUserBasedOnBranchFailure,
	fetchAllGroupOfficerUserBasedOnBranchChanged,
	fetchBranchProgressReportSuccess,
	fetchBranchProgressReportFailure,
	fetchBranchProgressReportChanged,
} from './ProgressReport.actions';
import { encodeJson } from '../../enode-decode';
import generalTypesAction from '../../genera.types';

export function* fetchAllOfficerUserBasedOnBranchCall({ payload: { values } }) {
	try {
		const jsonData = {
			CompanyID: values.CompanyID,
			DatabaseName: values.DatabaseName,
			BranchID: values.BranchID,
			// isNewOfficerAlso: values.isNewOfficerAlso,
			UserLevel: values.UserLevel,
			UserID: values.UserID,
			UserRole: values.UserRole,
		};
		const branchData = encodeJson(jsonData, generalTypesAction.ALLOFFICERUSERBASEDONBRANCH);
		const responseData = yield call(fetchAllOfficerUserBasedOnBranchReport, branchData);
		debugger;
		if (responseData.data.statusCode === '500') {
			yield put(fetchAllGroupOfficerUserBasedOnBranchFailure(responseData.data));
		} else {
			yield put(fetchAllGroupOfficerUserBasedOnBranchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllGroupOfficerUserBasedOnBranchFailure(error));
	}
}

export function* onSelectAllOfficerUserBasedOnBranch() {
	yield takeLatest(
		progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_START,
		fetchAllOfficerUserBasedOnBranchCall,
	);
}

export function* fetchAllOfficerUserBasedOnBranchReset() {
	yield put(fetchAllGroupOfficerUserBasedOnBranchChanged());
}

export function* onSelectAllOfficerUserBasedOnBranchReset() {
	yield takeLatest(
		progressReportTypes.FETCH_All_OFFICER_USER_BASED_ON_BRANCH_RESET,
		fetchAllOfficerUserBasedOnBranchReset,
	);
}

export function* fetchBranchProgressReportCall({ payload: { values } }) {
	try {
		const jsonData = {
			CompanyID: values.CompanyID,
			DatabaseName: values.DatabaseName,
			BranchID: values.BranchID,
			isNewOfficerAlso: values.isNewOfficerAlso,
		};
		const branchData = encodeJson(jsonData, generalTypesAction.FETCHBRANCHPROGRESSREPORT);
		const responseData = yield call(fetchBranchProgressReport, branchData);
		if (responseData.data.statusCode === '500') {
			yield put(fetchBranchProgressReportFailure(responseData.data));
		} else {
			yield put(fetchBranchProgressReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchProgressReportFailure(error));
	}
}

export function* onSelectBranchProgressReport() {
	yield takeLatest(
		progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_START,
		fetchBranchProgressReportCall,
	);
}

export function* fetchBranchProgressReportReset() {
	yield put(fetchBranchProgressReportChanged());
}

export function* onSelectBranchProgressReportReset() {
	yield takeLatest(
		progressReportTypes.FETCH_BRANCH_PROGRESS_REPORT_RESET,
		fetchBranchProgressReportReset,
	);
}

export function* progressReportSaga() {
	yield all([
		call(onSelectAllOfficerUserBasedOnBranch),
		call(onSelectAllOfficerUserBasedOnBranchReset),
		call(onSelectBranchProgressReport),
		call(onSelectBranchProgressReportReset),
	]);
}
