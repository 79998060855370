/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import EconomicActivityTypeActionTypes from './economic-activity-type.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	addEconomicActivityTypeDetailsResponse: null,
	updateEconomicActivityTypeDetailsResponse: null,
	getEconomicActivityTypeListResponse: null,
	getEconomicActivityTypeListArr: [],
	fetchEconomicActivityTypeDetailsResponse: null,
	fetchEconomicActivityTypeDetailsArr: [],
	deleteEconomicActivityTypeDetailsResponse: null,
	fetchAllEconomicActivityTypesResponse: null,
	fetchAllEconomicActivityTypesArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS:
			return { ...state, addEconomicActivityTypeDetailsResponse: action.payload };

		case EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE:
			return { ...state, addEconomicActivityTypeDetailsResponse: action.payload };

		case EconomicActivityTypeActionTypes.ADD_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED:
			return { ...state, addEconomicActivityTypeDetailsResponse: null };

		case EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS:
			return { ...state, updateEconomicActivityTypeDetailsResponse: action.payload };

		case EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE:
			return { ...state, updateEconomicActivityTypeDetailsResponse: action.payload };

		case EconomicActivityTypeActionTypes.UPDATE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED:
			return { ...state, updateEconomicActivityTypeDetailsResponse: null };

		case EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_SUCCESS:
			return {
				...state,
				getEconomicActivityTypeListResponse: action.payload,
				getEconomicActivityTypeListArr: docodeResponse(
					action.payload,
					'GetEconomicActivityTypeListKey',
				),
			};

		case EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_FAILURE:
			return { ...state, getEconomicActivityTypeListResponse: action.payload };

		case EconomicActivityTypeActionTypes.GET_ECONOMIC_ACTIVITY_TYPE_LIST_RESPONSE_CHANGED:
			return { ...state, getEconomicActivityTypeListResponse: null };

		case EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS:
			return {
				...state,
				fetchEconomicActivityTypeDetailsResponse: action.payload,
				fetchEconomicActivityTypeDetailsArr: docodeResponse(
					action.payload,
					'FetchEconomicActivityTypeDetailsKey',
				),
			};

		case EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE:
			return { ...state, fetchEconomicActivityTypeDetailsResponse: action.payload };

		case EconomicActivityTypeActionTypes.FETCH_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED:
			return {
				...state,
				fetchEconomicActivityTypeDetailsResponse: null,
				fetchEconomicActivityTypeDetailsArr: [],
			};

		case EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_SUCCESS:
			return { ...state, deleteEconomicActivityTypeDetailsResponse: action.payload };

		case EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_FAILURE:
			return { ...state, deleteEconomicActivityTypeDetailsResponse: action.payload };

		case EconomicActivityTypeActionTypes.DELETE_ECONOMIC_ACTIVITY_TYPE_DETAILS_RESPONSE_CHANGED:
			return { ...state, deleteEconomicActivityTypeDetailsResponse: null };

		case EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_SUCCESS:
			return {
				...state,
				fetchAllEconomicActivityTypesResponse: action.payload,
				fetchAllEconomicActivityTypesArr: docodeResponse(
					action.payload,
					'FetchAllEconomicActivityTypesKey',
				),
			};

		case EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_FAILURE:
			return { ...state, fetchAllEconomicActivityTypesResponse: action.payload };

		case EconomicActivityTypeActionTypes.FETCH_ALL_ECONOMIC_ACTIVITY_TYPE_RESPONSE_CHANGED:
			return { ...state, fetchAllEconomicActivityTypesResponse: null };

		default:
			return state;
	}
};
