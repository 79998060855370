/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */
import GroupActionTypes from './group.types';

/** ***************** ADD GROUP DETAILS START *********************** */
export const addGroupDetailsStart = (addData) => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_START,
	payload: addData,
});

export const addGroupDetailsSuccess = (successData) => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const addGroupDetailsFailure = (failureData) => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const addGroupDetailsResponseResetStart = () => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_RESET,
});

export const addGroupDetailsResponseChanged = () => ({
	type: GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD GROUP DETAILS END *********************** */

/** ***************** GET GROUP LIST START *********************** */
export const getGroupListStart = (getData) => ({
	type: GroupActionTypes.GET_GROUP_LIST_START,
	payload: getData,
});

export const getGroupListSuccess = (successData) => ({
	type: GroupActionTypes.GET_GROUP_LIST_SUCCESS,
	payload: successData,
});

export const getGroupListFailure = (failureData) => ({
	type: GroupActionTypes.GET_GROUP_LIST_FAILURE,
	payload: failureData,
});

export const getGroupListResponseResetStart = () => ({
	type: GroupActionTypes.GET_GROUP_LIST_RESPONSE_RESET,
});

export const getGroupListResponseChanged = () => ({
	type: GroupActionTypes.GET_GROUP_LIST_RESPONSE_CHANGED,
});
/** ***************** GET GROUP LIST END *********************** */

/** ***************** FETCH ALL GROUP START *********************** */
export const fetchAllGroupStart = (fetchGroup) => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_START,
	payload: fetchGroup,
});

export const fetchAllGroupSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_SUCCESS,
	payload: successData,
});

export const fetchAllGroupFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_FAILURE,
	payload: failureData,
});

export const fetchAllGroupResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_RESET_START,
});

export const fetchAllGroupResponseChanged = () => ({
	type: GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL GROUP END *********************** */

/** ***************** FETCH ALL APPROVED MEMBER START *********************** */
export const fetchAllApprovedMemberStart = (fetchGroup) => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_START,
	payload: fetchGroup,
});

export const fetchAllApprovedMemberSuccess = (successData) => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_SUCCESS,
	payload: successData,
});

export const fetchAllApprovedMemberFailure = (failureData) => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_FAILURE,
	payload: failureData,
});

export const fetchAllApprovedMemberResponseResetStart = () => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_RESET_START,
});

export const fetchAllApprovedMemberResponseChanged = () => ({
	type: GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL APPROVED MEMBER END *********************** */

/** ***************** GET ALL OFFICER LIST START *********************** */
export const getAllOfficerStart = (getData) => ({
	type: GroupActionTypes.GET_ALL_OFFICER_START,
	payload: getData,
});

export const getAllOfficerSuccess = (successData) => ({
	type: GroupActionTypes.GET_ALL_OFFICER_SUCCESS,
	payload: successData,
});

export const getAllOfficerFailure = (failureData) => ({
	type: GroupActionTypes.GET_ALL_OFFICER_FAILURE,
	payload: failureData,
});

export const getAllOfficerResponseResetStart = () => ({
	type: GroupActionTypes.GET_ALL_OFFICER_RESPONSE_RESET,
});

export const getAllOfficerResponseChanged = () => ({
	type: GroupActionTypes.GET_ALL_OFFICER_RESPONSE_CHANGED,
});
/** ***************** GET ALL OFFICER LIST END *********************** */

/** ***************** GET ALL GROUP DETAILS START *********************** */
export const getAllGroupDetailsStart = (getData) => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_START,
	payload: getData,
});

export const getAllGroupDetailsSuccess = (successData) => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_SUCCESS,
	payload: successData,
});

export const getAllGroupDetailsFailure = (failureData) => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_FAILURE,
	payload: failureData,
});

export const getAllGroupDetailsResponseResetStart = () => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_RESET,
});

export const getAllGroupDetailsResponseChanged = () => ({
	type: GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ALL GROUP DETAILS END *********************** */

/** ***************** GET BRANCH OFFICER LIST START *********************** */
export const fetchBranchOfficerStart = (getData) => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_START,
	payload: getData,
});

export const getBranchOfficerSuccess = (successData) => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_SUCCESS,
	payload: successData,
});

export const getBranchOfficerFailure = (failureData) => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_FAILURE,
	payload: failureData,
});

export const fetchBranchOfficerResponseResetStart = () => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_RESET,
});

export const getBranchOfficerResponseChanged = () => ({
	type: GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_CHANGED,
});
/** ***************** GET BRANCH OFFICER LIST END *********************** */
