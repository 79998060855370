/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchReportActionTypes from './branchReport.types';

import {
    branchReportListGet,
} from './branchReport.service';

import {
    getBranchReportDetailsSuccess,
    getBranchReportDetailsFailure,
    getBranchReportDetailsResponseChanged,

} from './branchReport.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getBranchReportList({ payload }) {
	try {
		const key = 'GetBranchDCBReportKey';
		const jsonData = {
			companyID: payload.companyID,
			branchID: payload.branchID,
            groupID: payload.groupID,
			DatabaseName: payload.databaseName,
            LoanFunder: payload.funderID,
            fromDate: payload.fromDate,
			toDate: payload.toDate,
            centerID: payload.centerID,
			userID: payload.userID,
			userlevel: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchReportListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchReportDetailsFailure(responseData.data));
		} else {
			yield put(getBranchReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchReportDetailsFailure(error));
	}
}

export function* resetGetBranchReportListResponse() {
	yield put(getBranchReportDetailsResponseChanged());
}

export function* onGetBranchReportListDetails() {
	yield takeLatest(BranchReportActionTypes.GET_BRANCH_REPORT_LIST_START, getBranchReportList);
}

export function* onGetBranchReportListResponseReset() {
	yield takeLatest(BranchReportActionTypes.GET_BRANCH_REPORT_LIST_RESPONSE_RESET, resetGetBranchReportListResponse);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */

export function* branchReportSaga() {
	yield all([
		call(onGetBranchReportListDetails),
		call(onGetBranchReportListResponseReset),
	]);
}
