const ClosedLoanDetailsActionTypes = {

    GET_CLOSED_LOAN_DETAILS_LIST_START: 'get_closed_loan_details_start',
	GET_CLOSED_LOAN_DETAILS_LIST_SUCCESS: 'get_closed_loan_details_success',
	GET_CLOSED_LOAN_DETAILS_LIST_FAILURE: 'get_closed_loan_details_failure',
	GET_CLOSED_LOAN_DETAILS_LIST_RESPONSE_RESET: 'get_closed_loan_details_response_reset',
	GET_CLOSED_LOAN_DETAILS_LIST_RESPONSE_CHANGED: 'get_closed_loan_details_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',
    
}
export default ClosedLoanDetailsActionTypes;