/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import StaffActionTypes from './staff.types';

/** ***************** ADD STAFF DETAILS START *********************** */
export const addStaffDetailsStart = (addData) => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_START,
	payload: addData,
});

export const addStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const addStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const addStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_RESET,
});

export const addStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.ADD_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD STAFF DETAILS END *********************** */

/** ***************** UPDATE STAFF DETAILS START *********************** */
export const updateStaffDetailsStart = (updateData) => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_START,
	payload: updateData,
});

export const updateStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const updateStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const updateStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_RESET,
});

export const updateStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE STAFF DETAILS END *********************** */

/** ***************** GET STAFF LIST START *********************** */
export const getStaffListStart = (getData) => ({
	type: StaffActionTypes.GET_STAFF_LIST_START,
	payload: getData,
});

export const getStaffListSuccess = (successData) => ({
	type: StaffActionTypes.GET_STAFF_LIST_SUCCESS,
	payload: successData,
});

export const getStaffListFailure = (failureData) => ({
	type: StaffActionTypes.GET_STAFF_LIST_FAILURE,
	payload: failureData,
});

export const getStaffListResponseResetStart = () => ({
	type: StaffActionTypes.GET_STAFF_LIST_RESPONSE_RESET,
});

export const getStaffListResponseChanged = () => ({
	type: StaffActionTypes.GET_STAFF_LIST_RESPONSE_CHANGED,
});

/** ***************** GET STAFF LIST END *********************** */

/** ***************** FETCH STAFF DETAILS START *********************** */
export const fetchStaffDetailsStart = (fetchData) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_START,
	payload: fetchData,
});

export const fetchStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_RESET,
});

export const fetchStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.FETCH_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH STAFF DETAILS END *********************** */

/** ***************** DELETE STAFF DETAILS START *********************** */
export const deleteStaffDetailsStart = (deleteData) => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_START,
	payload: deleteData,
});

export const deleteStaffDetailsSuccess = (successData) => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteStaffDetailsFailure = (failureData) => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteStaffDetailsResponseResetStart = () => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_RESET,
});

export const deleteStaffDetailsResponseChanged = () => ({
	type: StaffActionTypes.DELETE_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE STAFF DETAILS END *********************** */

/** ***************** DELETE STAFF DETAILS START *********************** */
export const updateIsPublishStatusStart = (publishData) => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_START,
	payload: publishData,
});

export const updateIsPublishStatusSuccess = (successData) => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_SUCCESS,
	payload: successData,
});

export const updateIsPublishStatusFailure = (failureData) => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_FAILURE,
	payload: failureData,
});

export const updateIsPublishStatusResponseResetStart = () => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_RESET,
});

export const updateIsPublishStatusResponseChanged = () => ({
	type: StaffActionTypes.UPDATE_ISPUBLISH_STATUS_RESPONSE_CHANGED,
});

/** ***************** DELETE STAFF DETAILS END *********************** */

/** ***************** FETCH ALL STAFF START *********************** */
export const fetchAllStaffStart = (fetchStaffs) => ({
	type: StaffActionTypes.FETCH_ALL_STAFFS_START,
	payload: fetchStaffs,
});

export const fetchAllStaffSuccess = (successData) => ({
	type: StaffActionTypes.FETCH_ALL_STAFFS_SUCCESS,
	payload: successData,
});

export const fetchAllStaffFailure = (failureData) => ({
	type: StaffActionTypes.FETCH_ALL_STAFFS_FAILURE,
	payload: failureData,
});

export const fetchAllStaffResponseResetStart = () => ({
	type: StaffActionTypes.FETCH_ALL_STAFFS_RESPONSE_RESET,
});

export const fetchAllStaffResponseChanged = () => ({
	type: StaffActionTypes.FETCH_ALL_STAFFS_RESPONSE_CHANGED,
});
/** ***************** FETCH ALL STAFF END *********************** */
