import BranchDetailsActionTypes from './branch-details.types';

/** ***************** ADD BUSINESS BRANCH DETAILS START *********************** */
export const addBusinessBranchStart = (bankData) => ({
	type: BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_START,
	payload: bankData,
});

export const addBusinessBranchSuccess = (successValue) => ({
	type: BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_SUCCESS,
	payload: successValue,
});

export const addBusinessBranchFailure = (failureData) => ({
	type: BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_FAILURE,
	payload: failureData,
});

export const addBusinessBranchResponseResetStart = () => ({
	type: BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_RESPONSE_RESET,
});

export const addBusinessBranchResponseChanged = () => ({
	type: BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS BRANCH DETAILS END *********************** */

/** ***************** FETCH BUSINESS BRANCH DETAILS START *********************** */
export const fetchBusinessBranchStart = (fetchData) => ({
	type: BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_START,
	payload: fetchData,
});

export const fetchBusinessBranchSuccess = (successData) => ({
	type: BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_SUCCESS,
	payload: successData,
});

export const fetchBusinessBranchFailure = (failureData) => ({
	type: BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_FAILURE,
	payload: failureData,
});

export const fetchBusinessBranchResponseResetStart = () => ({
	type: BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_RESPONSE_RESET,
});

export const fetchBusinessBranchResponseChanged = () => ({
	type: BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_RESPONSE_CHANGED,
});

/** ***************** FETCH BUSINESS BRANCH DETAILS END *********************** */

/** ***************** UPDATE BUSINESS BRANCH DETAILS START *********************** */
export const updateBusinessBranchStart = (bankData) => ({
	type: BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_START,
	payload: bankData,
});

export const updateBusinessBranchSuccess = (successValue) => ({
	type: BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_SUCCESS,
	payload: successValue,
});

export const updateBusinessBranchFailure = (failureData) => ({
	type: BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_FAILURE,
	payload: failureData,
});

export const updateBusinessBranchResponseResetStart = () => ({
	type: BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_RESPONSE_RESET,
});

export const updateBusinessBranchResponseChanged = () => ({
	type: BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_RESPONSE_CHANGED,
});

/** ***************** UPDATE BUSINESS BRANCH DETAILS END *********************** */
