/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import NPAReportTypes from './npa-report.types';
import { getNPAReportDetails } from './npa-report.service';
import {
    fetchNPAReportSuccess,
    fetchNPAReportFailure,
    fetchNPAReportResponseResetChanged
} from './npa-report.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH NPA DETAILS  ******************** */
export function* fetchNPAReportDetails({ payload }) {
	try {
		const key = 'GetNPAReportDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			ReportDate:payload.ReportDate,
			BranchID:payload.branchID,
            GroupID: payload.GroupID,
            CenterID: payload.CenterID,
            FunderID: payload.FunderID,
			MemberID: payload.memberID,
		};
		debugger;
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getNPAReportDetails, encodeData);
		if (responseData.data.statusCode === '101') {
			yield put(fetchNPAReportFailure(responseData.data));
		} else {
			yield put(fetchNPAReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchNPAReportFailure(error));
	}
}

export function* resetNPAReportResponse() {
	yield put(fetchNPAReportResponseResetChanged());
}

export function* onFetchNPAReportDetails() {
	yield takeLatest(NPAReportTypes.FETCH_NPA_REPORT_START, fetchNPAReportDetails);
}

export function* onFetchNPAReportResponseReset() {
	yield takeLatest(NPAReportTypes.FETCH_NPA_REPORT_RESPONSE_RESET_START, resetNPAReportResponse);
}
/** *********************  END FETCH NPA DETAILS ******************** */

export function* NPAReportSaga() {
	yield all([call(onFetchNPAReportDetails), 
        call(onFetchNPAReportResponseReset)]);
}
