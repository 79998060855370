const ChangeMeetingActionTypes = {

    GET_CHANGE_MEETING_LIST_START: 'get_change_meeting_start',
	GET_CHANGE_MEETING_LIST_SUCCESS: 'get_change_meeting_success',
	GET_CHANGE_MEETING_LIST_FAILURE: 'get_change_meeting_failure',
	GET_CHANGE_MEETING_LIST_RESPONSE_RESET: 'get_change_meeting_response_reset',
	GET_CHANGE_MEETING_LIST_RESPONSE_CHANGED: 'get_change_meeting_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',
    
}
export default ChangeMeetingActionTypes;