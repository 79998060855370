/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import futureDemandReportTypes from './future-demand-report.types';
import { getFutureDemandReportDetails, FutureDemandExcelListGet } from './future-demand-report.service';
import {
	fetchFutureDemandReportSuccess,
	fetchFutureDemandReportFailure,
	fetchFutureDemandReportResponseResetChanged,
	fetchFutureDemandReportExcelSuccess,
	fetchFutureDemandReportExcelFailure,
	fetchFutureDemandReportResponseExcelResetChanged,
} from './future-demand-report.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START FETCH FUTURE DEMAND DETAILS  ******************** */
export function* fetchFutureDemandReportReportDetails({ payload }) {
	try {
		const key = 'FutureDemandDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
			FromDate:payload.FromDate,
			FunderID:payload.FunderID,
			IsPersonalLoan:payload.IsPersonalLoan,
			LocationID:payload.CenterID,
			ToDate:payload.ToDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getFutureDemandReportDetails, encodeData);
		debugger;
		if (responseData.data.statusCode === '101') {
			yield put(fetchFutureDemandReportFailure(responseData.data));
		} else {
			yield put(fetchFutureDemandReportSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchFutureDemandReportFailure(error));
	}
}

export function* resetFetchFutureDemandReportResponse() {
	yield put(fetchFutureDemandReportResponseResetChanged());
}

export function* onFetchFutureDemandReportDetails() {
	yield takeLatest(futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_START, fetchFutureDemandReportReportDetails);
}

export function* onFetchFutureDemandReportResponseReset() {
	yield takeLatest(futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_RESPONSE_RESET_START, resetFetchFutureDemandReportResponse);
}
/** *********************  END FETCH FUTURE DEMAND DETAILS ******************** */


/** *********************  START GET LOAN FUTURE DEMAND EXCEL REPORT DETAILS  ******************** */
export function* getFutureDemandExcelList({ payload }) {
	try {
		const key = 'FutureDemandExcelKey';
		const jsonData = {
			CompanyID: payload.CompanyID,
			DatabaseName: payload.databaseName,
			BranchID: payload.branchID,
			FromDate:payload.fromDate,
			FunderID:payload.funderID,
			IsPersonalLoan:payload.IsPersonalLoan,
			LocationID:payload.centerID,
			ToDate:payload.toDate,
			ProvisionType:payload.ProvisionType,
			RequestID: payload.RequestID
		};
		debugger;
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(FutureDemandExcelListGet, encodeData, payload.ViewType);
		if (responseData.data.statusCode === '100') {
			yield put(fetchFutureDemandReportExcelFailure(responseData.data));
		} else {
			yield put(fetchFutureDemandReportExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchFutureDemandReportExcelFailure(error));
	}
}

export function* resetGetFutureDemandExcelListResponse() {
	yield put(fetchFutureDemandReportResponseExcelResetChanged());
}

export function* onGetFutureDemandExcelListDetails() {
	yield takeLatest(
		futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_EXCEL_START,
		getFutureDemandExcelList,
	);
}

export function* onGetFutureDemandExcelListResponseReset() {
	yield takeLatest(
		futureDemandReportTypes.FETCH_FUTURE_DEMAND_REPORT_RESPONSE_EXCEL_RESET_START,
		resetGetFutureDemandExcelListResponse,
	);
}
/** *********************  END GET FUTURE DEMAND EXCEL REPORT LIST ******************** */



export function* futureDemandReportSaga() {
	yield all([
		call(onFetchFutureDemandReportDetails), 
        call(onFetchFutureDemandReportResponseReset),
		call(onGetFutureDemandExcelListDetails),
		call(onGetFutureDemandExcelListResponseReset),
	]);
}
