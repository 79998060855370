const ProgressReportActionTypes = {

    GET_PROGRESS_REPORT_LIST_START: 'get_progress_report_start',
	GET_PROGRESS_REPORT_LIST_SUCCESS: 'get_progress_report_success',
	GET_PROGRESS_REPORT_LIST_FAILURE: 'get_progress_report_failure',
	GET_PROGRESS_REPORT_LIST_RESPONSE_RESET: 'get_progress_report_response_reset',
	GET_PROGRESS_REPORT_LIST_RESPONSE_CHANGED: 'get_progress_report_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',
    
}
export default ProgressReportActionTypes;