/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const attendanceReportListGet = (listData) => {
	const data = {
		GetAttendanceReportToken: listData,
	};
	const url = `${API_URL}AttendanceBasedOfficerReport`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};