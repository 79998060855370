/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import FunderActionTypes from './funder.types';

/** ***************** ADD FUNDER DETAILS START *********************** */
export const addFunderDetailsStart = (addData) => ({
	type: FunderActionTypes.ADD_FUNDER_DETAILS_START,
	payload: addData,
});

export const addFunderDetailsSuccess = (successData) => ({
	type: FunderActionTypes.ADD_FUNDER_DETAILS_SUCCESS,
	payload: successData,
});

export const addFunderDetailsFailure = (failureData) => ({
	type: FunderActionTypes.ADD_FUNDER_DETAILS_FAILURE,
	payload: failureData,
});

export const addFunderDetailsResponseResetStart = () => ({
	type: FunderActionTypes.ADD_FUNDER_DETAILS_RESPONSE_RESET,
});

export const addFunderDetailsResponseChanged = () => ({
	type: FunderActionTypes.ADD_FUNDER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** ADD FUNDER DETAILS END *********************** */

/** ***************** UPDATE FUNDER DETAILS START *********************** */
export const updateFunderDetailsStart = (updateData) => ({
	type: FunderActionTypes.UPDATE_FUNDER_DETAILS_START,
	payload: updateData,
});

export const updateFunderDetailsSuccess = (successData) => ({
	type: FunderActionTypes.UPDATE_FUNDER_DETAILS_SUCCESS,
	payload: successData,
});

export const updateFunderDetailsFailure = (failureData) => ({
	type: FunderActionTypes.UPDATE_FUNDER_DETAILS_FAILURE,
	payload: failureData,
});

export const updateFunderDetailsResponseResetStart = () => ({
	type: FunderActionTypes.UPDATE_FUNDER_DETAILS_RESPONSE_RESET,
});

export const updateFunderDetailsResponseChanged = () => ({
	type: FunderActionTypes.UPDATE_FUNDER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** UPDATE FUNDER DETAILS END *********************** */

/** ***************** GET FUNDER DETAILS START *********************** */
export const getFunderDetailsStart = (getData) => ({
	type: FunderActionTypes.GET_FUNDER_LIST_START,
	payload: getData,
});

export const getFunderDetailsSuccess = (successData) => ({
	type: FunderActionTypes.GET_FUNDER_LIST_SUCCESS,
	payload: successData,
});

export const getFunderDetailsFailure = (failureData) => ({
	type: FunderActionTypes.GET_FUNDER_LIST_FAILURE,
	payload: failureData,
});

export const getFunderDetailsResponseResetStart = () => ({
	type: FunderActionTypes.GET_FUNDER_LIST_RESPONSE_RESET,
});

export const getFunderDetailsResponseChanged = () => ({
	type: FunderActionTypes.GET_FUNDER_LIST_RESPONSE_CHANGED,
});
/** ***************** GET FUNDER DETAILS END *********************** */

/** ***************** FETCH FUNDER DETAILS START *********************** */
export const fetchFunderDetailsStart = (fetchData) => ({
	type: FunderActionTypes.FETCH_FUNDER_DETAILS_START,
	payload: fetchData,
});

export const fetchFunderDetailsSuccess = (successData) => ({
	type: FunderActionTypes.FETCH_FUNDER_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchFunderDetailsFailure = (failureData) => ({
	type: FunderActionTypes.FETCH_FUNDER_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchFunderDetailsResponseResetStart = () => ({
	type: FunderActionTypes.FETCH_FUNDER_DETAILS_RESPONSE_RESET,
});

export const fetchFunderDetailsResponseChanged = () => ({
	type: FunderActionTypes.FETCH_FUNDER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH FUNDER DETAILS END *********************** */

/** ***************** DELETE FUNDER DETAILS START *********************** */
export const deleteFunderDetailsStart = (deleteData) => ({
	type: FunderActionTypes.DELETE_FUNDER_DETAILS_START,
	payload: deleteData,
});

export const deleteFunderDetailsSuccess = (successData) => ({
	type: FunderActionTypes.DELETE_FUNDER_DETAILS_SUCCESS,
	payload: successData,
});

export const deleteFunderDetailsFailure = (failureData) => ({
	type: FunderActionTypes.DELETE_FUNDER_DETAILS_FAILURE,
	payload: failureData,
});

export const deleteFunderDetailsResponseResetStart = () => ({
	type: FunderActionTypes.DELETE_FUNDER_DETAILS_RESPONSE_RESET,
});

export const deleteFunderDetailsResponseChanged = () => ({
	type: FunderActionTypes.DELETE_FUNDER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** DELETE FUNDER DETAILS END *********************** */
