/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import BusinessLoanDisbursementActionTypes from './business-loan-disbmt.types';

/** ***************** FETCH BUSINESS LOAN INDEX START *********************** */
export const fetchBusinessLoanIndexStart = (addData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_START,
	payload: addData,
});

export const fetchBusinessLoanIndexSuccess = (successData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_SUCCESS,
	payload: successData,
});

export const fetchBusinessLoanIndexFailure = (failureData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_FAILURE,
	payload: failureData,
});

export const fetchBusinessLoanIndexResponseResetStart = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_RESPONSE_RESET,
});

export const fetchBusinessLoanIndexResponseChanged = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INDEX_RESPONSE_CHANGED,
});

/** ***************** FETCH BUSINESS LOAN INDEX END *********************** */

/** ***************** FETCH INSTRUMENT LIST START *********************** */
export const fetchInstumentListStart = (addData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_START,
	payload: addData,
});

export const fetchInstumentListSuccess = (successData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_SUCCESS,
	payload: successData,
});

export const fetchInstumentListFailure = (failureData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_FAILURE,
	payload: failureData,
});

export const fetchInstumentListResponseResetStart = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_RESPONSE_RESET,
});

export const fetchInstumentListResponseChanged = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_INSTRUMENT_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH INSTRUMENT LIST END *********************** */

/** ***************** FETCH FINANCE BASED ACCOUNT TYPE START *********************** */
export const fetchFinanceBaseAccountTypeStart = (addData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_START,
	payload: addData,
});

export const fetchFinanceBaseAccountTypeSuccess = (successData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_SUCCESS,
	payload: successData,
});

export const fetchFinanceBaseAccountTypeFailure = (failureData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_FAILURE,
	payload: failureData,
});

export const fetchFinanceBaseAccountTypeResponseResetStart = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_RESPONSE_RESET,
});

export const fetchFinanceBaseAccountTypeResponseChanged = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_FINANCE_BASED_ACCOUNT_TYPE_RESPONSE_CHANGED,
});

/** ***************** FETCH FINANCE BASED ACCOUNT TYPE END *********************** */

/** ***************** CREATE BUSINESS LOAN DISBURSEMENT START *********************** */
export const businessLoanDisbursmentStart = (disbuseData) => ({
	type: BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_START,
	payload: disbuseData,
});

export const businessLoanDisbursmentSuccess = (successData) => ({
	type: BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_SUCCESS,
	payload: successData,
});

export const businessLoanDisbursmentFailure = (failureData) => ({
	type: BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_FAILURE,
	payload: failureData,
});

export const businessLoanDisbursmentResponseResetStart = () => ({
	type: BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_RESPONSE_RESET,
});

export const businessLoanDisbursmentResponseChanged = () => ({
	type: BusinessLoanDisbursementActionTypes.BUSINESS_LOAN_DISBURSEMENT_RESPONSE_CHANGED,
});

/** ***************** CREATE BUSINESS LOAN DISBURSEMENT END *********************** */

/** ***************** START FETCH VOUCHAR COUNT *********************** */
export const fetchVoucharCountStart = (voucharData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_START,
	payload: voucharData,
});

export const fetchVoucharCountSuccess = (successData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_SUCCESS,
	payload: successData,
});

export const fetchVoucharCountFailure = (failureData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_FAILURE,
	payload: failureData,
});

export const fetchVoucharCountResponseResetStart = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_RESPONSE_RESET,
});

export const fetchVoucharCountResponseChanged = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_VOUCHAR_COUNT_RESPONSE_CHANGED,
});

/** ***************** END FETCH VOUCHAR COUNT *********************** */

/** ***************** START FETCH BUSINESS LOAN INFO DETAILS *********************** */
export const fetchBusinessLoanInfoStart = (infoData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_START,
	payload: infoData,
});

export const fetchBusinessLoanInfoSuccess = (successData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchBusinessLoanInfoFailure = (failureData) => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchBusinessLoanInfoResponseResetStart = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_RESPONSE_RESET,
});

export const fetchBusinessLoanInfoResponseChanged = () => ({
	type: BusinessLoanDisbursementActionTypes.FETCH_BUSINESS_LOAN_INFO_DETAILS_RESPONSE_CHANGED,
});

/** ***************** END FETCH BUSINESS LOAN INFO DETAILS *********************** */
