const LoanDisbursementActionTypes = {
	GET_LOAN_DISBURSEMENT_LIST_START: 'get_loan_disbursement_list_start',
	GET_LOAN_DISBURSEMENT_LIST_SUCCESS: 'get_loan_disbursement_list_success',
	GET_LOAN_DISBURSEMENT_LIST_FAILURE: 'get_loan_disbursement_list_failure',
	GET_LOAN_DISBURSEMENT_LIST_RESPONSE_RESET: 'get_loan_disbursement_list_response_reset',
	GET_LOAN_DISBURSEMENT_LIST_RESPONSE_CHANGED: 'get_loan_disbursement_list_response_changed',

	GET_OFFICER_BRANCH_LIST_START: 'get_officer_branch_start',
	GET_OFFICER_BRANCH_LIST_SUCCESS: 'get_officer_branch_success',
	GET_OFFICER_BRANCH_LIST_FAILURE: 'get_officer_branch_failure',
	GET_OFFICER_BRANCH_LIST_RESPONSE_RESET: 'get_officer_branch_response_reset',
	GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED: 'get_officer_branch_response_changed',

	GET_LOAN_DISBURSEMENT_EXCEL_LIST_START: 'get_loan_disbursement_excel_start',
	GET_LOAN_DISBURSEMENT_EXCEL_LIST_SUCCESS: 'get_loan_disbursement_excel_success',
	GET_LOAN_DISBURSEMENT_EXCEL_LIST_FAILURE: 'get_loan_disbursement_excel_failure',
	GET_LOAN_DISBURSEMENT_EXCEL_LIST_RESPONSE_RESET: 'get_loan_disbursement_excel_response_reset',
	GET_LOAN_DISBURSEMENT_EXCEL_LIST_RESPONSE_CHANGED:
		'get_loan_disbursement_excel_response_changed',

	GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_START:
		'get_loan_disbursement_bank_details_excel_start',
	GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_SUCCESS:
		'get_loan_disbursement_bank_details_excel_success',
	GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_FAILURE:
		'get_loan_disbursement_bank_details_excel_failure',
	GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_RESPONSE_RESET:
		'get_loan_disbursement_bank_details_excel_response_reset',
	GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_RESPONSE_CHANGED:
		'get_loan_disbursement_excel_response_changed',
};
export default LoanDisbursementActionTypes;
