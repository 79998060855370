/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const partnerDetailsAdd = (partnerData) => {
	const data = {
		AddBusinessTokenPartner: partnerData,
	};
	const url = `${API_URL}addBusinessPartner`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchPartnerDetails = (fetchData) => {
	const data = {
		GetBusinessTokenPartner: fetchData,
	};
	const url = `${API_URL}getBusinessPartner`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updatePartnerDetails = (updateData) => {
	const data = {
		UpdatePartnerDetailsToken: updateData,
	};
	const url = `${API_URL}updateBusinessPartner`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
