/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import questionnaireTypes from './questionnaire.types';

/** ***************** GET QUESTIONNAIRE START***************** */
export const getQuestionnaireStart = (getData) => ({
	type: questionnaireTypes.GET_QUESTIONNAIRE_START,
	payload: getData,
});

export const getQuestionnaireSuccess = (successData) => ({
	type: questionnaireTypes.GET_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const getQuestionnaireFailure = (failureData) => ({
	type: questionnaireTypes.GET_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const getQuestionnaireResponseReset = () => ({
	type: questionnaireTypes.GET_QUESTIONNAIRE_RESPONSE_RESET,
});

export const getQuestionnaireResponseChanged = () => ({
	type: questionnaireTypes.GET_QUESTIONNAIRE_RESPONSE_CHANGED,
});

/** ***************** GET QUESTIONNAIRE END ************ */


/** ***************** DELETE QUESTIONNAIRE START***************** */
export const deleteQuestionnaireStart = (getData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_START,
	payload: getData,
});

export const deleteQuestionnaireSuccess = (successData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const deleteQuestionnaireFailure = (failureData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const deleteQuestionnaireResponseReset = () => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_RESET,
});

export const deleteQuestionnaireResponseChanged = () => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_CHANGED,
});

/** ***************** DELETE QUESTIONNAIRE END ************ */

/** ***************** DELETE QUESTION START***************** */
export const deleteQuestionStart = (getData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_START,
	payload: getData,
});

export const deleteQuestionSuccess = (successData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const deleteQuestionFailure = (failureData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const deleteQuestionResponseReset = () => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_RESET,
});

export const deleteQuestionResponseChanged = () => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_CHANGED,
});

/** ***************** DELETE QUESTION END ************ */

/** ***************** DELETE QUESTION CHOICE START***************** */
export const deleteQuestionChoiceStart = (getData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_START,
	payload: getData,
});

export const deleteQuestionChoiceSuccess = (successData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const deleteQuestionChoiceFailure = (failureData) => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const deleteQuestionChoiceResponseReset = () => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_RESET,
});

export const deleteQuestionChoiceResponseChanged = () => ({
	type: questionnaireTypes.DELETE_QUESTIONNAIRE_RESPONSE_CHANGED,
});

/** ***************** DELETE QUESTION CHOICE END ************ */


/** ***************** ADD QUESTIONNAIRE START***************** */
export const addQuestionnaireStart = (getData) => ({
	type: questionnaireTypes.ADD_QUESTIONNAIRE_START,
	payload: getData,
});

export const addQuestionnaireSuccess = (successData) => ({
	type: questionnaireTypes.ADD_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const addQuestionnaireFailure = (failureData) => ({
	type: questionnaireTypes.ADD_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const addQuestionnaireResponseReset = () => ({
	type: questionnaireTypes.ADD_QUESTIONNAIRE_RESPONSE_RESET,
});

export const addQuestionnaireResponseChanged = () => ({
	type: questionnaireTypes.ADD_QUESTIONNAIRE_RESPONSE_CHANGED,
});

/** ***************** ADD QUESTIONNAIRE END ************ */

/** ***************** FETCH QUESTIONNAIRE START***************** */
export const fetchQuestionnaireStart = (getData) => ({
	type: questionnaireTypes.FETCH_QUESTIONNAIRE_START,
	payload: getData,
});

export const fetchQuestionnaireSuccess = (successData) => ({
	type: questionnaireTypes.FETCH_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const fetchQuestionnaireFailure = (failureData) => ({
	type: questionnaireTypes.FETCH_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const fetchQuestionnaireResponseReset = () => ({
	type: questionnaireTypes.FETCH_QUESTIONNAIRE_RESPONSE_RESET,
});

export const fetchQuestionnaireResponseChanged = () => ({
	type: questionnaireTypes.FETCH_QUESTIONNAIRE_RESPONSE_CHANGED,
});

/** ***************** FETCH QUESTIONNAIRE END ************ */

/** ***************** UPDATE QUESTIONNAIRE START***************** */
export const updateQuestionnaireStart = (getData) => ({
	type: questionnaireTypes.UPDATE_QUESTIONNAIRE_START,
	payload: getData,
});

export const updateQuestionnaireSuccess = (successData) => ({
	type: questionnaireTypes.UPDATE_QUESTIONNAIRE_SUCCESS,
	payload: successData,
});

export const updateQuestionnaireFailure = (failureData) => ({
	type: questionnaireTypes.UPDATE_QUESTIONNAIRE_FAILURE,
	payload: failureData,
});

export const updateQuestionnaireResponseReset = () => ({
	type: questionnaireTypes.UPDATE_QUESTIONNAIRE_RESPONSE_RESET,
});

export const updateQuestionnaireResponseChanged = () => ({
	type: questionnaireTypes.UPDATE_QUESTIONNAIRE_RESPONSE_CHANGED,
});

/** ***************** FETCH QUESTIONNAIRE END ************ */