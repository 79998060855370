import BusinessDetailsActionTypes from './business-details.types';

export const businessDetailsEditStart = (isEdit) => ({
	type: BusinessDetailsActionTypes.IS_EDIT_BUSINESS_DETAILS,
	payload: isEdit,
});

export const updateTabValueStart = (updatedTab) => ({
	type: BusinessDetailsActionTypes.BUSINESS_SUBMIT_TAB_VALUE_CHANGED,
	payload: updatedTab,
});

export const updateCanvasValueStart = (canvas) => ({
	type: BusinessDetailsActionTypes.BUSINESS_IS_OPEN_OFFCANVAS,
	payload: canvas,
});

/** ***************** ADD BUSINESS DETAILS START *********************** */
export const addBusinessDetailsStart = (businessData) => ({
	type: BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_START,
	payload: businessData,
});

export const addBusinessDetailsSuccess = (successValue) => ({
	type: BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_SUCCESS,
	payload: successValue,
});

export const addBusinessDetailsFailure = (failureData) => ({
	type: BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_FAILURE,
	payload: failureData,
});

export const addBusinessDetailsResponseResetStart = () => ({
	type: BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_RESPONSE_RESET,
});

export const addBusinessDetailsResponseChanged = () => ({
	type: BusinessDetailsActionTypes.ADD_BUSINESS_DETAILS_RESPONSE_CHANGED,
});
/** ***************** ADD BUSINESS DETAILS END *********************** */

/** ***************** GET ALL BUSINESS LIST START *********************** */
export const updateBusinessDetailsStart = (updateData) => ({
	type: BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_START,
	payload: updateData,
});

export const updateBusinessDetailsSuccess = (updateSuccess) => ({
	type: BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_SUCCESS,
	payload: updateSuccess,
});

export const updateBusinessDetailsFailure = (updateFailure) => ({
	type: BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_FAILURE,
	payload: updateFailure,
});

export const updateBusinessDetailsResponseResetStart = () => ({
	type: BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_RESPONSE_RESET,
});

export const updateBusinessDetailsResponseChanged = () => ({
	type: BusinessDetailsActionTypes.UPDATE_BUSINESS_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ALL BUSINESS LIST END *********************** */

/** ***************** GET ALL BUSINESS LIST START *********************** */
export const fetchBusinessDetailsStart = (fetchData) => ({
	type: BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_START,
	payload: fetchData,
});

export const fetchBusinessDetailsSuccess = (fetchSuccess) => ({
	type: BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_SUCCESS,
	payload: fetchSuccess,
});

export const fetchBusinessDetailsFailure = (fetchFailure) => ({
	type: BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_FAILURE,
	payload: fetchFailure,
});

export const fetchBusinessDetailsResponseResetStart = () => ({
	type: BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_RESPONSE_RESET,
});

export const fetchBusinessDetailsResponseChanged = () => ({
	type: BusinessDetailsActionTypes.FETCH_BUSINESS_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ALL BUSINESS LIST END *********************** */

/** ***************** GET ALL BUSINESS LIST START *********************** */
export const deleteBusinessStart = (deleteData) => ({
	type: BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_START,
	payload: deleteData,
});

export const deleteBusinessSuccess = (deleteSuccess) => ({
	type: BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_SUCCESS,
	payload: deleteSuccess,
});

export const deleteBusinessFailure = (deleteFailure) => ({
	type: BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_FAILURE,
	payload: deleteFailure,
});

export const deleteBusinessResponseResetStart = () => ({
	type: BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_RESPONSE_RESET,
});

export const deleteBusinessResponseChanged = () => ({
	type: BusinessDetailsActionTypes.DELETE_BUSINESS_DETAILS_RESPONSE_CHANGED,
});
/** ***************** GET ALL BUSINESS LIST END *********************** */

/** ***************** GET ALL BUSINESS LIST START *********************** */
export const getAllBusinessListStart = (fetchData) => ({
	type: BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_START,
	payload: fetchData,
});

export const getAllBusinessListSuccess = (fetchSuccess) => ({
	type: BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_SUCCESS,
	payload: fetchSuccess,
});

export const getAllBusinessListFailure = (fetchFailure) => ({
	type: BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_FAILURE,
	payload: fetchFailure,
});

export const getAllBusinessListResponseResetStart = () => ({
	type: BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_RESPONSE_RESET,
});

export const getAllBusinessListResponseChanged = () => ({
	type: BusinessDetailsActionTypes.GET_ALL_BUSINESS_LIST_RESPONSE_CHANGED,
});
/** ***************** GET ALL BUSINESS LIST END *********************** */
