/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import RelatedPartiesActionTypes from './related-parties.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addBusinessPartiesResponse: null,
	updateBusinessPartiesResponse: null,
	fetchBusinessPartiesResponse: null,
	fetchBusinessPartiesList: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case RelatedPartiesActionTypes.ADD_RELATED_PARTIES_SUCCESS:
			return { ...state, addBusinessPartiesResponse: action.payload };

		case RelatedPartiesActionTypes.ADD_RELATED_PARTIES_FAILURE:
			return { ...state, addBusinessPartiesResponse: action.payload };

		case RelatedPartiesActionTypes.ADD_RELATED_PARTIES_RESPONSE_CHANGED:
			return { ...state, addBusinessPartiesResponse: null };

		case RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_SUCCESS:
			return { ...state, updateBusinessPartiesResponse: action.payload };

		case RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_FAILURE:
			return { ...state, updateBusinessPartiesResponse: action.payload };

		case RelatedPartiesActionTypes.UPDATE_RELATED_PARTIES_RESPONSE_CHANGED:
			return { ...state, updateBusinessPartiesResponse: null };

		case RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_SUCCESS:
			return {
				...state,
				fetchBusinessPartiesResponse: action.payload,
				fetchBusinessPartiesList: docodeResponse(
					action.payload,
					'GetBusinessReletedPartiesKey',
				),
			};

		case RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_FAILURE:
			return { ...state, fetchBusinessPartiesResponse: action.payload };

		case RelatedPartiesActionTypes.FETCH_RELATED_PARTIES_RESPONSE_CHANGED:
			return { ...state, fetchBusinessPartiesResponse: null };

		default:
			return state;
	}
};
