/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const thriftDetailsAdd = (addData) => {
	const data = {
		AddThriftDetailsToken: addData,
	};
	const url = `${API_URL}addThriftDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const thriftDetailsUpdate = (updateData) => {
	const data = {
		UpdateThriftDetailsToken: updateData,
	};
	const url = `${API_URL}updateThriftDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const thriftListGet = (listData) => {
	const data = {
		GetThriftListToken: listData,
	};
	const url = `${API_URL}getThriftList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const thriftDetailsFetch = (fetchData) => {
	const data = {
		FetchThriftDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchThriftDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const thriftDetailsDelete = (deleteData) => {
	const data = {
		DeleteThriftDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteThriftDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allThriftsFetch = (fetchAllThriftData) => {
	const data = {
		AllThriftListFetchToken: fetchAllThriftData,
	};
	const url = `${API_URL}fetchAllThriftList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
