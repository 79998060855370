/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import GroupActionTypes from './group.types';
import {
	groupDetailsAdd,
	groupListGet,
	allGroupFetch,
	allApprovedMemberFetch,
	allOfficerGet,
	allGroupDetailsGet,
	branchOfficerListGet,
} from './group.service';

import {
	addGroupDetailsSuccess,
	addGroupDetailsFailure,
	addGroupDetailsResponseChanged,
	fetchAllGroupSuccess,
	fetchAllGroupFailure,
	fetchAllGroupResponseChanged,
	fetchAllApprovedMemberSuccess,
	fetchAllApprovedMemberFailure,
	fetchAllApprovedMemberResponseChanged,
	getGroupListSuccess,
	getGroupListFailure,
	getGroupListResponseChanged,
	getAllOfficerSuccess,
	getAllOfficerFailure,
	getAllOfficerResponseChanged,
	getAllGroupDetailsSuccess,
	getAllGroupDetailsFailure,
	getAllGroupDetailsResponseChanged,
	getBranchOfficerSuccess,
	getBranchOfficerFailure,
	getBranchOfficerResponseChanged,
} from './group.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START ADD GROUP DETAILS  ******************** */
export function* addGroupDetails({ payload }) {
	try {
		const key = 'AddGroupDetailsKey';
		const jsonData = {
			GroupName: payload.groupName,
			GroupLocationID: payload.locationID,
			GroupOfficerID: payload.officerID,
			CompanyID: payload.companyID,
			UniqueGroupID: payload.uniqueGroupID,
			ProvisionType: payload.provisionType,
			Prefix: payload.OfficerUniqueID,
			IsSubGroup: payload.isSubGroup,
			ParentGroupID: payload.parentGroupID,
			CreatedBy: payload.userID,
			UserAccessBranchID: payload.branchID,
			AccountArr: payload.accountDetailsArr,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addGroupDetailsFailure(responseData.data));
		} else {
			yield put(addGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addGroupDetailsFailure(error));
	}
}

export function* resetAddGroupResponse() {
	yield put(addGroupDetailsResponseChanged());
}

export function* onAddGroupDetails() {
	yield takeLatest(GroupActionTypes.ADD_GROUP_DETAILS_START, addGroupDetails);
}

export function* onAddGroupDetailsResponseReset() {
	yield takeLatest(GroupActionTypes.ADD_GROUP_DETAILS_RESPONSE_RESET, resetAddGroupResponse);
}

/** *********************  START ADD GROUP DETAILS  ******************** */

/** *********************  START GET GROUP LIST  ******************** */
export function* GetAllGroupListBasedOnLocation({ payload }) {
	try {
		const key = 'GetAllGroupListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			InputSearch: payload.inputSearch,
			Limit: payload.limit,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(groupListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getGroupListFailure(responseData.data));
		} else {
			yield put(getGroupListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getGroupListFailure(error));
	}
}

export function* resetGetGroupListResponse() {
	yield put(getGroupListResponseChanged());
}

export function* onGetGroupListDetails() {
	yield takeLatest(GroupActionTypes.GET_GROUP_LIST_START, GetAllGroupListBasedOnLocation);
}

export function* onGetGroupListResponseReset() {
	yield takeLatest(GroupActionTypes.GET_GROUP_LIST_RESPONSE_RESET, resetGetGroupListResponse);
}
/** *********************  END GET GROUP LIST ******************** */

/** *********************  START FETCH ALL GROUP LIST  ******************** */
export function* fetchAllGroupList({ payload }) {
	try {
		const key = 'FetchAllGroupListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UniqueGroupID: payload.uniqueGroupID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			GroupID: payload.groupID,
			LocationID: payload.centerID,
			ProvisionType: payload.provisionType,
			GroupLocationPinCode: payload.locationPinCode,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allGroupFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllGroupFailure(responseData.data));
		} else {
			yield put(fetchAllGroupSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllGroupFailure(error));
	}
}

export function* resetFetchAllGroupListResponse() {
	yield put(fetchAllGroupResponseChanged());
}

export function* onFetchAllGroupListDetails() {
	yield takeLatest(GroupActionTypes.FETCH_ALL_GROUP_START, fetchAllGroupList);
}

export function* onFetchAllGroupResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_ALL_GROUP_RESPONSE_RESET_START,
		resetFetchAllGroupListResponse,
	);
}
/** *********************  END FETCH ALL GROUP LIST ******************** */

/** *********************  START FETCH ALL APPROVED MEMBER LIST  ******************** */
export function* fetchAllApprovedMemberList({ payload }) {
	try {
		const key = 'GetBranchAllOfficerListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			DatabaseName: payload.databaseName,
			GroupID: payload.groupID,
			BranchID: payload.BranchID,
			CenterID: payload.CenterID,
			SearchData: payload.SearchData,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allApprovedMemberFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAllApprovedMemberFailure(responseData.data));
		} else {
			yield put(fetchAllApprovedMemberSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAllApprovedMemberFailure(error));
	}
}

export function* resetFetchAllApprovedMemberListResponse() {
	yield put(fetchAllApprovedMemberResponseChanged());
}

export function* onFetchAllApprovedMemberListDetails() {
	yield takeLatest(GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_START, fetchAllApprovedMemberList);
}

export function* onFetchAllApprovedMemberResponseReset() {
	yield takeLatest(
		GroupActionTypes.FETCH_ALL_APPROVED_MEMBER_RESPONSE_RESET_START,
		resetFetchAllApprovedMemberListResponse,
	);
}
/** *********************  END FETCH ALL APPROVED MEMBER LIST ******************** */

/** *********************  START GET ALL OFFICER DETAILS  ******************** */
export function* GetAllOfficerLocation({ payload }) {
	try {
		const key = 'GetAllOfficerKey';
		const jsonData = {
			OfficerID: payload.userID,
			OfficerName: payload.userName,
			OfficerUniqueID: payload.uniqueID,
			CompanyID: payload.companyID,
			LocationID: payload.centerID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allOfficerGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllOfficerFailure(responseData.data));
		} else {
			yield put(getAllOfficerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllOfficerFailure(error));
	}
}

export function* resetGetAllOfficerResponse() {
	yield put(getAllOfficerResponseChanged());
}

export function* onGetAllOfficerDetails() {
	yield takeLatest(GroupActionTypes.GET_ALL_OFFICER_START, GetAllOfficerLocation);
}

export function* onGetAllOfficerResponseReset() {
	yield takeLatest(GroupActionTypes.GET_ALL_OFFICER_RESPONSE_RESET, resetGetAllOfficerResponse);
}
/** *********************  END GET ALL OFFICER LIST ******************** */

/** *********************  START GET ALL OFFICER DETAILS  ******************** */
export function* GetAllGroupDetailsLocation({ payload }) {
	try {
		const key = 'GetAllGroupDetailsKey';
		const jsonData = {
			DatabaseName: payload.databaseName,
			GroupID: payload.groupID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(allGroupDetailsGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllGroupDetailsFailure(responseData.data));
		} else {
			yield put(getAllGroupDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllGroupDetailsFailure(error));
	}
}

export function* resetGetAllGroupDetailsResponse() {
	yield put(getAllGroupDetailsResponseChanged());
}

export function* onGetAllGroupDetailsDetails() {
	yield takeLatest(GroupActionTypes.GET_ALL_GROUP_DETAILS_START, GetAllGroupDetailsLocation);
}

export function* onGetAllGroupDetailsResponseReset() {
	yield takeLatest(
		GroupActionTypes.GET_ALL_GROUP_DETAILS_RESPONSE_CHANGED,
		resetGetAllGroupDetailsResponse,
	);
}

export function* GetBranchOfficerList({ payload }) {
	try {
		const key = 'CENTEROFFICERLISTBASEDONCOMPANY';
		const jsonData = {
			CompanyID: payload.CompanyID,
			UserID: payload.UserID,
			UserLevel: payload.UserLevel,
			UserRole: payload.UserRole,
			BranchID: payload.BranchID,
			DatabaseName: payload.DatabaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchOfficerListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchOfficerFailure(responseData.data));
		} else {
			yield put(getBranchOfficerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchOfficerFailure(error));
	}
}

export function* resetGetBranchOfficerResponse() {
	yield put(getBranchOfficerResponseChanged());
}

export function* onGetBranchOfficerDetails() {
	yield takeLatest(GroupActionTypes.GET_BRANCH_OFFICER_START, GetBranchOfficerList);
}

export function* onGetBranchOfficerResponseReset() {
	yield takeLatest(
		GroupActionTypes.GET_BRANCH_OFFICER_RESPONSE_RESET,
		resetGetBranchOfficerResponse,
	);
}
/** *********************  END GET ALL OFFICER LIST ******************** */

export function* groupMasterSaga() {
	yield all([
		call(onAddGroupDetails),
		call(onAddGroupDetailsResponseReset),
		call(onGetGroupListDetails),
		call(onGetGroupListResponseReset),
		call(onFetchAllGroupListDetails),
		call(onFetchAllGroupResponseReset),
		call(onFetchAllApprovedMemberListDetails),
		call(onFetchAllApprovedMemberResponseReset),
		call(onGetAllOfficerDetails),
		call(onGetAllOfficerResponseReset),
		call(onGetAllGroupDetailsDetails),
		call(onGetAllGroupDetailsResponseReset),
		call(onGetBranchOfficerDetails),
		call(onGetBranchOfficerResponseReset),
	]);
}
