const LoanApplicationTypes = {
	ADD_LOAN_APPLICATION_START: 'add_loan-application_start',
	ADD_LOAN_APPLICATION_SUCCESS: 'add_loan-application_success',
	ADD_LOAN_APPLICATION_FAILURE: 'add_loan-application_failure',
	ADD_LOAN_APPLICATION_RESPONSE_RESET: 'add_loan-application_response_reset',
	ADD_LOAN_APPLICATION_RESPONSE_CHANGED: 'add_loan-application_response_changed',

	UPDATE_LOAN_APPLICATION_START: 'update_loan-application_start',
	UPDATE_LOAN_APPLICATION_SUCCESS: 'update_loan-application_success',
	UPDATE_LOAN_APPLICATION_FAILURE: 'update_loan-application_failure',
	UPDATE_LOAN_APPLICATION_RESPONSE_RESET: 'update_loan-application_response_reset',
	UPDATE_LOAN_APPLICATION_RESPONSE_CHANGED: 'update_loan-application_response_changed',

	GET_LOAN_LIST_START: 'get_loan_list_start',
	GET_LOAN_LIST_SUCCESS: 'get_loan_list_success',
	GET_LOAN_LIST_FAILURE: 'get_loan_list_failure',
	GET_LOAN_LIST_RESPONSE_RESET: 'get_loan_list_response_reset',
	GET_LOAN_LIST_RESPONSE_CHANGED: 'get_loan_list_response_changed',

	FETCH_LOAN_APPLICATION_START: 'fetch_loan-application_start',
	FETCH_LOAN_APPLICATION_SUCCESS: 'fetch_loan-application_success',
	FETCH_LOAN_APPLICATION_FAILURE: 'fetch_loan-application_failure',
	FETCH_LOAN_APPLICATION_RESPONSE_RESET: 'fetch_loan-application_response_reset',
	FETCH_LOAN_APPLICATION_RESPONSE_CHANGED: 'fetch_loan-application_response_changed',

	DELETE_LOAN_APPLICATION_START: 'delete_loan-application_start',
	DELETE_LOAN_APPLICATION_SUCCESS: 'delete_loan-application_success',
	DELETE_LOAN_APPLICATION_FAILURE: 'delete_loan-application_failure',
	DELETE_LOAN_APPLICATION_RESPONSE_RESET: 'delete_loan-application_response_reset',
	DELETE_LOAN_APPLICATION_RESPONSE_CHANGED: 'delete_loan-application_response_changed',

	FETCH_ALL_LOAN_START: 'fetch_all_loan_start',
	FETCH_ALL_LOAN_SUCCESS: 'fetch_all_loan_success',
	FETCH_ALL_LOAN_FAILURE: 'fetch_all_loan_failure',
	FETCH_ALL_LOAN_RESPONSE_RESET: 'fetch_all_loan_response_reset',
	FETCH_ALL_LOAN_RESPONSE_CHANGED: 'fetch_all_loan_response_changed',

	STATUS_UPDATE_LOAN_APPLICATION_START: 'status-update-loan-application-start',
	STATUS_UPDATE_LOAN_APPLICATION_SUCCESS: 'status-update-loan-application-success',
	STATUS_UPDATE_LOAN_APPLICATION_FAILURE: 'status-update-loan-application-failure',
	STATUS_UPDATE_LOAN_APPLICATION_RESPONSE_RESET_START:
		'status-update-loan-application-response-reset',
	STATUS_UPDATE_LOAN_APPLICATION_RESPONSE_CHANGED:
		'status-update-loan-application-response-changed',
};
export default LoanApplicationTypes;
