/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import InActiveMemberActionTypes from './in-active-member.types';

/** ***************** GET INACTIVE MEMBER START *********************** */
export const getInActiveMemberDetailsStart = (getData) => ({
	type: InActiveMemberActionTypes.GET_INACTIVE_MEMBER_LIST_START,
	payload: getData,
});

export const getInActiveMemberDetailsSuccess = (successData) => ({
	type: InActiveMemberActionTypes.GET_INACTIVE_MEMBER_LIST_SUCCESS,
	payload: successData,
});

export const getInActiveMemberDetailsFailure = (failureData) => ({
	type: InActiveMemberActionTypes.GET_INACTIVE_MEMBER_LIST_FAILURE,
	payload: failureData,
});

export const getInActiveMemberDetailsResponseResetStart = () => ({
	type: InActiveMemberActionTypes.GET_INACTIVE_MEMBER_LIST_RESPONSE_RESET,
});

export const getInActiveMemberDetailsResponseChanged = () => ({
	type: InActiveMemberActionTypes.GET_INACTIVE_MEMBER_LIST_RESPONSE_CHANGED,
});
/** ***************** GET INACTIVE MEMBER END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: InActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: InActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: InActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: InActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: InActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

