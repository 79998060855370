/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import staffActionTypes from './staff.types';

const INITIAL_STATE = {
	staffStatus: null,
	staffResponse: null,
	staffCreateStatus: null,
	staffCreateResponse: null,
	staffDeleteStatus: null,
	staffDeleteResponse: null,
	staffTeamIDStatus: null,
	staffTeamIDResponse: null,
};

const staffReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case staffActionTypes.STAFF_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				staffCreateResponse: action.payload,
				staffCreateStatus: 'Success',
			};
		case staffActionTypes.STAFF_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				staffCreateResponse: action.payload,
				staffCreateStatus: 'Failure',
			};
		case staffActionTypes.STAFF_CREATE_REPSONSE_CHANGED:
			return { ...state, staffCreateResponse: null, staffCreateStatus: null };

		case staffActionTypes.STAFF_DELETE_REPSONSE_SUCCESS:
			return {
				...state,
				staffDeleteResponse: action.payload,
				staffDeleteStatus: 'Success',
			};
		case staffActionTypes.STAFF_DELETE_REPSONSE_FAILURE:
			return {
				...state,
				staffDeleteResponse: action.payload,
				staffDeleteStatus: 'Failure',
			};
		case staffActionTypes.STAFF_DELETE_REPSONSE_CHANGED:
			return { ...state, staffDeleteResponse: null, staffDeleteStatus: null };
		case staffActionTypes.STAFF_LIST_SUCCESS:
			return {
				...state,
				staffStatus: 'Success',
				staffResponse: action.payload,
			};
		case staffActionTypes.STAFF_LIST_FAILURE:
			return {
				...state,
				staffStatus: 'Failure',
				staffResponse: action.payload,
			};
		case staffActionTypes.STAFF_LIST_CHANGED:
			return {
				...state,
				staffStatus: null,
				staffResponse: null,
			};
		case staffActionTypes.STAFF_GET_BASED_ON_TEAM_SUCCESS:
			return {
				...state,
				staffTeamIDStatus: 'Success',
				staffTeamIDResponse: action.payload,
			};
		case staffActionTypes.STAFF_GET_BASED_ON_TEAM_FAILURE:
			return {
				...state,
				staffTeamIDStatus: 'Failure',
				staffTeamIDResponse: action.payload,
			};
		case staffActionTypes.STAFF_GET_BASED_ON_TEAM_CHANGED:
			return {
				...state,
				staffTeamIDStatus: null,
				staffTeamIDResponse: null,
			};

		case staffActionTypes.GET_MANAGER_LIST_SUCCESS:
			return {
				...state,
				managerStatus: 'Success',
				managerResponse: action.payload,
			};
		case staffActionTypes.GET_MANAGER_LIST_FAILURE:
			return {
				...state,
				managerStatus: 'Failure',
				managerResponse: action.payload,
			};
		case staffActionTypes.GET_MANAGER_LIST_CHANGED:
			return {
				...state,
				managerStatus: null,
				managerResponse: null,
			};
		default:
			return state;
	}
};

export default staffReducer;
