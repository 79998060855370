import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/login.sagas';
import { teamSaga } from './operations/team/team.sagas';
import { staffSaga } from './operations/staff/staff.sagas';
import { staffListSaga } from './operations/staffAllList/staffAllList.sagas';
import { executiveSaga } from './operations/executive/executive.sagas';
import { repaymentCollectionReportSaga } from './reports/RepaymentCollectionReport/RepaymentCollectionReport.sagas';
import { progressReportSaga } from './reports/ProgressReport/ProgressReport.sagas';

// General Saga
import { generalSaga } from './general/general.saga';

// Business Saga
import { businessDetailsSaga } from './operations/business/business-details/business-details.sagas';
import { branchDetailsSaga } from './operations/business/branch-details/branch-details.sagas';
import { partnerDetailsSaga } from './operations/business/partner-details/partner-details.sagas';
import { bankDetailsSaga } from './operations/business/bank-details/bank-details.sagas';
import { proofDetailsSaga } from './operations/business/proof-details/proof-details.sagas';
import { verificationDetailsSaga } from './operations/business/verification-details/verification-details.sagas';
import { partiesDetailsSaga } from './operations/business/related-parties/related-parties.sagas';

// Masters
import { branchMasterSaga } from './masters/branch/branch.saga';
import { memberMasterSaga } from './masters/member/member.saga';
import { centerMasterSaga } from './masters/center/center.saga';
import { roleMasterSaga } from './masters/role/role.saga';
import { loanMasterSaga } from './masters/loanApplication/loanApplication.saga';
import { staffMasterSaga } from './masters/staff/staff.saga';
import { groupMasterSaga } from './masters/group/group.saga';
import { thriftMasterSaga } from './masters/thrift/thrift.saga';
import { productTypeMasterSaga } from './masters/product-type/product-type.saga';
import { insuranceCompanySaga } from './masters/insurance-company/insurance-company.saga';
import { productMasterSaga } from './masters/product/product.saga';
import { economicActivityTypeMasterSaga } from './masters/economic-activity-type/economic-activity-type.saga';
import { economicActivityMasterSaga } from './masters/economic-activity/economic-activity.saga';
import { funderMasterSaga } from './masters/funder/funder.saga';
import { businessfundMasterSaga } from './masters/business-fund/business-fund.saga';
import { questionnaireSaga } from './masters/questionnaire/questionnaire.saga';
import { purposeofLoanMasterSaga } from './masters/purpose-of-loan/purposeofloan.saga';

// Operations - Collection
import { collectionSaga } from './operations/collection/collection.saga';
import { memberCollectionSaga } from './operations/collection/memberCollection/memberCollection.saga';
import { advanceCollectionSaga } from './operations/collection/advanceCollection/advanceCollection.saga';
import { todayCollectionSaga } from './operations/todayCollection/todayCollection.saga';


// Business Loan Proposal
import { businessLoanProposalSaga } from './operations/loan-proposal/business-loan-proposal/business-loan-proposal.saga';

// Gold Loan Proposal
import { goldLoanProposalSaga } from './operations/loan-proposal/goldLoanProposal/goldLoanProposal.saga';

// Auto Loan Proposal
// import { autoLoanProposalSaga } from './loan-proposal/auto-loan-proposal/autoloanproposal.saga'

// Business Loan Disbursement
import { businessLoanDisbursementSaga } from './operations/loan-disbursement/business-loan-disbursment/business-loan-disbmt.saga';

// Reports
import { tatReportSaga } from './reports/tat-report/tat-report.saga';
import { requestReportSaga } from './reports/requestReport/requestReport.saga';
import { attendanceReportSaga } from './reports/attendanceReport/attendanceReport.saga';
import { reportProgressSaga } from './reports/reportProgress/reportProgress.saga';
import { monthlyProgressSaga } from './reports/monthlyProgress/monthlyProgress.saga';
import { branchReportSaga } from './reports/branchReport/branchReport.saga';
import { branchProfileReportSaga } from './reports/branchProfileReport/branchProfileReport.saga';
import { accountDayBookReportSaga } from './reports/accountDayBookReport/accountDayBookReport.saga';
import { postAuditReportSaga } from './reports/postauditingReport/postauditingReport.saga';
import { reportPrecloseSaga } from './reports/preclose-loan/preclose-loan.saga';
import { reportClosedLoanSaga } from './reports/closed-loan-details/closed-loan-details.saga';
import { walletReportSaga } from './reports/walletReport/walletReport.saga';
import { branchCollectionReportSaga } from './reports/branchCollectionReport/branchCollectionReport.saga';
import { branchDCBReportSaga } from './reports/branchDCBReport/branchDCBReport.saga';
import { dailyCollectionReportSaga } from './reports/dailyCollectionReport/dailyCollectionReport.saga';
import { futureDemandReportSaga } from './reports/future-demand-report/future-demand-report.saga';
import { reportDemandCollectionSaga } from './reports/demand-collection-report/demand-collection-report.saga';
import { reportDemandFollowupSaga } from './reports/demand-followup-report/demand-followup-report.saga';
import { regularDemandReportSaga } from './reports/regular-demand-report/regular-demand-report.saga';
import { sanctionReportSaga } from './reports/sanction-report/sanction-report.saga';
import { kycSourcedReportSaga } from './reports/kyc-sourced-report/kyc-sourced-report.saga';
import { insuranceReportSaga } from './reports/insurance-report/insurance-report.saga';
import { LTVReportSaga } from './reports/ltv-report/ltv-report.saga';
import { NPAReportSaga } from './reports/npa-report/npa-report.saga';
import { portfolioAtRiskReportSaga } from './reports/portflioAtRistReport/portflioAtRiskReport.saga';
import { reportActiveMemberSaga } from './reports/active-member/active-member.saga';
import { reportDataVerificationSaga } from './reports/data-verification/data-verification.saga';
import { reportAuditingToolSaga } from './reports/auditing-tool/auditing-tool.saga';
import { reportChangeMeetingSaga } from './reports/change-meeting/change-meeting.saga';
import { reportInActiveMemberSaga } from './reports/in-active-member/in-active-member.saga';
import { reportMonthWiseInterestSaga } from './reports/MonthWiseInterestReport/MonthWiseInterestReport.saga';
import { reportLoanDisbursementSaga } from './reports/loan-disbursement/loan-disbursement.saga';
import { overDueReportSaga } from './reports/overDueReport/overDueReport.sagas';
import { auditTrailReportSaga } from './reports/auditTrailReport/auditTrailReport.saga';
import { StaffReportSaga } from './reports/staffReport/staffReport.saga';

// verifications

import { kYCVerificationSaga } from './operations/business/kyc-verification/kyc-verification.saga';

export default function* rootSaga() {
	yield all([
		call(loginSaga),
		call(teamSaga),
		call(staffSaga),
		call(staffListSaga),
		call(executiveSaga),
		call(repaymentCollectionReportSaga),
		call(progressReportSaga),
		call(overDueReportSaga),
		call(generalSaga),
		call(businessDetailsSaga),
		call(branchDetailsSaga),
		call(partnerDetailsSaga),
		call(bankDetailsSaga),
		call(proofDetailsSaga),
		call(verificationDetailsSaga),
		call(partiesDetailsSaga),
		call(branchMasterSaga),
		call(memberMasterSaga),
		call(centerMasterSaga),
		call(loanMasterSaga),
		call(roleMasterSaga),
		call(staffMasterSaga),
		call(groupMasterSaga),
		call(thriftMasterSaga),
		call(productTypeMasterSaga),
		call(insuranceCompanySaga),
		call(productMasterSaga),
		call(economicActivityTypeMasterSaga),
		call(economicActivityMasterSaga),
		call(businessfundMasterSaga),
		call(questionnaireSaga),
		call(tatReportSaga),
		call(requestReportSaga),
		call(attendanceReportSaga),
		call(reportProgressSaga),
		call(collectionSaga),
		call(todayCollectionSaga),
		call(memberCollectionSaga),
		call(advanceCollectionSaga),
		call(businessLoanProposalSaga),
		call(businessLoanDisbursementSaga),
		call(goldLoanProposalSaga),
		call(funderMasterSaga),
		call(purposeofLoanMasterSaga),
		call(monthlyProgressSaga),
		call(branchReportSaga),
		call(branchProfileReportSaga),
		call(accountDayBookReportSaga),
		call(postAuditReportSaga),
		call(reportPrecloseSaga),
		call(reportClosedLoanSaga),
		call(walletReportSaga),
		call(branchCollectionReportSaga),
		call(branchDCBReportSaga),
		call(dailyCollectionReportSaga),
		call(futureDemandReportSaga),
		call(reportDemandCollectionSaga),
		call(reportDemandFollowupSaga),
		call(regularDemandReportSaga),
		call(sanctionReportSaga),
		call(kycSourcedReportSaga),
		call(insuranceReportSaga),
		call(LTVReportSaga),
		call(reportActiveMemberSaga),
		call(reportDataVerificationSaga),
		call(reportAuditingToolSaga),
		call(reportChangeMeetingSaga),
		call(reportInActiveMemberSaga),
		call(NPAReportSaga),
		call(portfolioAtRiskReportSaga),
		call(reportMonthWiseInterestSaga),
		call(reportLoanDisbursementSaga),
		call(kYCVerificationSaga),
		call(auditTrailReportSaga),
		call(StaffReportSaga),
	]);
}
