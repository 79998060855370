/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const closedloanDetailsListGet = (listData) => {
	const data = {
		GetMemberClosedLoanToken: listData,
	};
	const url = `${API_URL}PostAllClosedLoanDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const officerBranchListGet = (listData) => {
	const data = {
		GetOfficerListBranchToken: listData,
	};
	const url = `${API_URL}GetAllOfficerUserBasedonBranch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};