/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import staffReportActionTypes from './staffReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	fetchStaffDetailResponse: null,
	fetchStaffDetailArr: [],
	fetchStaffOfficerDetailResponse: null,
	fetchStaffOfficerDetailArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case staffReportActionTypes.GET_STAFF_DETAILS_SUCCESS:
			return {
				...state,
				fetchStaffDetailResponse: action.payload,
				fetchStaffDetailArr: docodeResponse(action.payload, 'STAFFDETAILSREPORTKEY'),
			};

		case staffReportActionTypes.GET_STAFF_DETAILS_FAILURE:
			return { ...state, fetchStaffDetailResponse: action.payload };

		case staffReportActionTypes.GET_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchStaffDetailResponse: null };

		case staffReportActionTypes.GET_STAFF_OFFICER_SUCCESS:
			return {
				...state,
				fetchStaffOfficerDetailResponse: action.payload,
				fetchStaffOfficerDetailArr: docodeResponse(action.payload, 'STAFFOFFICERKEY'),
			};

		case staffReportActionTypes.GET_STAFF_OFFICER_FAILURE:
			return { ...state, fetchStaffOfficerDetailResponse: action.payload };

		case staffReportActionTypes.GET_STAFF_OFFICER_RESPONSE_CHANGED:
			return { ...state, fetchStaffOfficerDetailResponse: null };

		default:
			return state;
	}
};
