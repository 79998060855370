/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import LTVReportTypes from "./ltv-report.types";

/** ***************** FETCH LTV DETAILS START *********************** */
export const getLTVReportStart = (addData) => ({
	type: LTVReportTypes.GET_LTV_REPORT_START,
	payload: addData,
});

export const getLTVReportSuccess = (successData) => ({
	type: LTVReportTypes.GET_LTV_REPORT_SUCCESS,
	payload: successData,
});

export const getLTVReportFailure = (failureData) => ({
	type: LTVReportTypes.GET_LTV_REPORT_FAILURE,
	payload: failureData,
});

export const getLTVReportResponseResetStart = () => ({
	type: LTVReportTypes.GET_LTV_REPORT_RESPONSE_RESET_START,
});

export const getLTVReportResponseResetChanged = () => ({
	type: LTVReportTypes.GET_LTV_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH LTV DETAILS END *********************** */
