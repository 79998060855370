/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import BranchActionTypes from './branch.types';
import {
	branchDetailsAdd,
	branchDetailsUpdate,
	branchListGet,
	branchDetailsFetch,
	branchDetailsDelete,
} from './branch.service';

import {
	addBranchDetailsSuccess,
	addBranchDetailsFailure,
	addBranchDetailsResponseChanged,
	updateBranchDetailsSuccess,
	updateBranchDetailsFailure,
	updateBranchDetailsResponseChanged,
	getBranchDetailsSuccess,
	getBranchDetailsFailure,
	getBranchDetailsResponseChanged,
	fetchBranchDetailsSuccess,
	fetchBranchDetailsFailure,
	fetchBranchDetailsResponseChanged,
	deleteBranchDetailsSuccess,
	deleteBranchDetailsFailure,
	deleteBranchDetailsResponseChanged,
} from './branch.action';
import { encodeJson } from '../../enode-decode';

/** *********************  START ADD BRANCH DETAILS  ******************** */
export function* addBranchDetails({ payload }) {
	try {
		const key = 'AddBranchDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchName: payload.branchName,
			BranchCity: payload.branchCity,
			BranchState: payload.branchState,
			BranchCountry: payload.branchCountry,
			BranchDistrict: payload.branchDistrict,
			NextUniqueAreaID: payload.nextUniqueAreaID,
			BranchAddress: payload.branchAddress,
			StartDate: payload.branchStartDate,
			BranchLocation: payload.branchLocation,
			BranchLatitude: payload.branchMapLatitude,
			BranchLongitude: payload.branchMapLongitude,
			BranchPinCode: payload.branchPincode,
			Village: payload.village,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			InfogCompanyID: payload.infogCompanyID,
			DatabaseName: payload.databaseName,
			IsHeadOffice: payload.isHeadOffice ? 1 : 0,
			IsNbfcLogin: payload.isNbfcLogin,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsAdd, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addBranchDetailsFailure(responseData.data));
		} else {
			yield put(addBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addBranchDetailsFailure(error));
	}
}

export function* resetAddBranchResponse() {
	yield put(addBranchDetailsResponseChanged());
}

export function* onAddBranchDetails() {
	yield takeLatest(BranchActionTypes.ADD_BRANCH_DETAILS_START, addBranchDetails);
}

export function* onAddBranchDetailsResponseReset() {
	yield takeLatest(BranchActionTypes.ADD_BRANCH_DETAILS_RESPONSE_RESET, resetAddBranchResponse);
}
/** *********************  END ADD BRANCH DETAILS ******************** */

/** *********************  START UPDATE BRANCH DETAILS  ******************** */
export function* updateBranchDetails({ payload }) {
	try {
		const key = 'UpdateBranchDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			BranchName: payload.branchName,
			BranchCity: payload.branchCity,
			BranchState: payload.branchState,
			BranchCountry: payload.branchCountry,
			BranchDistrict: payload.branchDistrict,
			NextUniqueAreaID: payload.nextUniqueAreaID,
			BranchAddress: payload.branchAddress,
			StartDate: payload.branchStartDate,
			BranchLocation: payload.branchLocation,
			BranchLatitude: payload.branchMapLatitude,
			BranchLongitude: payload.branchMapLongitude,
			BranchPinCode: payload.branchPincode,
			Village: payload.village,
			CreatedBy: payload.userID,
			UserName: payload.userName,
			UserAccessBranchID: payload.userAccessBranchID,
			InfogCompanyID: payload.infogCompanyID,
			DatabaseName: payload.databaseName,
			IsHeadOffice: payload.isHeadOffice ? 1 : 0,
			IsNbfcLogin: payload.isNbfcLogin,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateBranchDetailsFailure(responseData.data));
		} else {
			yield put(updateBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateBranchDetailsFailure(error));
	}
}

export function* resetUpdateBranchDetailsResponse() {
	yield put(updateBranchDetailsResponseChanged());
}

export function* onUpdateBranchDetails() {
	yield takeLatest(BranchActionTypes.UPDATE_BRANCH_DETAILS_START, updateBranchDetails);
}

export function* onUpdateBranchResponseReset() {
	yield takeLatest(
		BranchActionTypes.UPDATE_BRANCH_DETAILS_RESPONSE_RESET,
		resetUpdateBranchDetailsResponse,
	);
}
/** *********************  END UPDATE BRANCH DETAILS ******************** */

/** *********************  START GET BRANCH LIST  ******************** */
export function* getBranchList({ payload }) {
	try {
		const key = 'GetBranchListKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userRole,
			Level: payload.userLevel,
			DatabaseName: payload.databaseName,
			BranchID: payload.userAccessBranchID,
			searchValueSet:payload.searchValueSet,
			Limit: payload.limit,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getBranchDetailsFailure(responseData.data));
		} else {
			yield put(getBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getBranchDetailsFailure(error));
	}
}

export function* resetGetBranchListResponse() {
	yield put(getBranchDetailsResponseChanged());
}

export function* onGetBranchListDetails() {
	yield takeLatest(BranchActionTypes.GET_BRANCH_LIST_START, getBranchList);
}

export function* onGetBranchListResponseReset() {
	yield takeLatest(BranchActionTypes.GET_BRANCH_LIST_RESPONSE_RESET, resetGetBranchListResponse);
}
/** *********************  END GET BRANCH LIST ******************** */

/** *********************  START FETCH BRANCH DETAILS  ******************** */
export function* fetchBranchDetails({ payload }) {
	try {
		const key = 'FetchBranchDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			DatabaseName: payload.databaseName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsFetch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchBranchDetailsFailure(responseData.data));
		} else {
			yield put(fetchBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchBranchDetailsFailure(error));
	}
}

export function* resetFetchBranchDetailsResponse() {
	yield put(fetchBranchDetailsResponseChanged());
}

export function* onFetchBranchDetails() {
	yield takeLatest(BranchActionTypes.FETCH_BRANCH_DETAILS_START, fetchBranchDetails);
}

export function* onFetchBranchDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.FETCH_BRANCH_DETAILS_RESPONSE_RESET,
		resetFetchBranchDetailsResponse,
	);
}
/** *********************  END FETCH BRANCH DETAILS ******************** */

/** *********************  START DELETE BRANCH DETAILS  ******************** */
export function* deleteBranch({ payload }) {
	try {
		const key = 'DeleteBranchDetailsKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			DatabaseName: payload.databaseName,
			DeletedBy: payload.userID,
			UserAccessBranchID: payload.userAccessBranchID,
			UserName: payload.userName,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(branchDetailsDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteBranchDetailsFailure(responseData.data));
		} else {
			yield put(deleteBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteBranchDetailsFailure(error));
	}
}

export function* resetDeleteBranchDetailsResponse() {
	yield put(deleteBranchDetailsResponseChanged());
}

export function* onDeleteBranchDetails() {
	yield takeLatest(BranchActionTypes.DELETE_BRANCH_DETAILS_START, deleteBranch);
}

export function* onDeleteBranchDetailsResponseReset() {
	yield takeLatest(
		BranchActionTypes.DELETE_BRANCH_DETAILS_RESPONSE_RESET,
		resetDeleteBranchDetailsResponse,
	);
}
/** *********************  END DELETE BRANCH DETAILS ******************** */

export function* branchMasterSaga() {
	yield all([
		call(onAddBranchDetails),
		call(onAddBranchDetailsResponseReset),
		call(onUpdateBranchDetails),
		call(onUpdateBranchResponseReset),
		call(onGetBranchListDetails),
		call(onGetBranchListResponseReset),
		call(onFetchBranchDetails),
		call(onFetchBranchDetailsResponseReset),
		call(onDeleteBranchDetails),
		call(onDeleteBranchDetailsResponseReset),
	]);
}
