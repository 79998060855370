/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PostAuditingReportActionTypes from './postauditingReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getPostAuditingReportListResponse: null,
	getPostAuditingReportListArr: [],
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_SUCCESS:
			return {
				...state,
				getPostAuditingReportListResponse: action.payload,
				getPostAuditingReportListArr: docodeResponse(action.payload, 'GetPostAuditingToolReportKey'),
			};

		case PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_FAILURE:
			return { ...state, getPostAuditingReportListResponse: action.payload };

		case PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getPostAuditingReportListResponse: null };
        default:
            return state;
    }
}