const teamActionTypes = {
	TEAM_GET_ALL_LIST: 'team_get_all_list',
	TEAM_LIST_START: 'team_list_start',
	TEAM_LIST_SUCCESS: 'team_list_success',
	TEAM_LIST_FAILURE: 'team_list_failure',
	TEAM_LIST_RESET: 'team_list_reset',
	TEAM_LIST_CHANGED: 'team_list_changed',

	TEAM_CREATE_REPSONSE_START: 'team_create_response_start',
	TEAM_CREATE_REPSONSE_SUCCESS: 'team_create_response_success',
	TEAM_CREATE_REPSONSE_FAILURE: 'team_create_response_failure',
	TEAM_CREATE_REPSONSE_CHANGED: 'team_create_response_changed',
	TEAM_CREATE_REPSONSE_RESET: 'team_create_response_reset',

	TEAM_UPDATE_REPSONSE_START: 'team_update_response_start',
	TEAM_UPDATE_REPSONSE_SUCCESS: 'team_update_response_success',
	TEAM_UPDATE_REPSONSE_FAILURE: 'team_update_response_failure',
	TEAM_UPDATE_REPSONSE_CHANGED: 'team_update_response_changed',
	TEAM_UPDATE_REPSONSE_RESET: 'team_update_response_reset',

	TEAM_DELETE_REPSONSE_START: 'team_delete_response_start',
	TEAM_DELETE_REPSONSE_SUCCESS: 'team_delete_response_success',
	TEAM_DELETE_REPSONSE_FAILURE: 'team_delete_response_failure',
	TEAM_DELETE_REPSONSE_CHANGED: 'team_delete_response_changed',
	TEAM_DELETE_REPSONSE_RESET: 'team_delete_response_reset',

	TEAM_SELECTED_ID: 'team_selected_id',

	TEAM_GET_TEAM_LEAD: 'team_get_team_lead',
	TEAM_GET_TEAM_LEAD_START: 'team_get_team_lead_start',
	TEAM_GET_TEAM_LEAD_SUCCESS: 'team_get_team_lead_success',
	TEAM_GET_TEAM_LEAD_FAILURE: 'team_get_team_lead_failure',
	TEAM_GET_TEAM_LEAD_RESET: 'team_get_team_lead_reset',
	TEAM_GET_TEAM_LEAD_CHANGED: 'team_get_team_lead_changed',
};
export default teamActionTypes;
