/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import AttendanceReportActionTypes from './attendanceReport.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getAttendanceReportListResponse: null,
	getAttendanceReportListArr: [],
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_SUCCESS:
			return {
				...state,
				getAttendanceReportListResponse: action.payload,
				getAttendanceReportListArr: docodeResponse(action.payload, 'GetAttendanceReportKey'),
			};

		case AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_FAILURE:
			return { ...state, getAttendanceReportListResponse: action.payload };

		case AttendanceReportActionTypes.GET_ATTENDANCE_REPORT_LIST_RESPONSE_CHANGED:
			return { ...state, getAttendanceReportListResponse: null };
        default:
            return state;
    }
}