const CollectionActionTypes = {
	GET_OFFICER_LIST_CENTERS_START: 'GET_OFFICER_LIST_CENTERS_START',
	GET_OFFICER_LIST_CENTERS_SUCCESS: 'GET_OFFICER_LIST_CENTERS_SUCCESS',
	GET_OFFICER_LIST_CENTERS_FAILURE: 'GET_OFFICER_LIST_CENTERS_FAILURE',
	GET_OFFICER_LIST_CENTERS_RESPONSE_RESET: 'GET_OFFICER_LIST_CENTERS_RESPONSE_RESET',
	GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED: 'GET_OFFICER_LIST_CENTERS_RESPONSE_CHANGED',

	GET_OFFICER_LIST_ARREAR_CENTERS_START: 'GET_OFFICER_LIST_ARREAR_CENTERS_START',
	GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS: 'GET_OFFICER_LIST_ARREAR_CENTERS_SUCCESS',
	GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE: 'GET_OFFICER_LIST_ARREAR_CENTERS_FAILURE',
	GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET:
		'GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_RESET',
	GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED:
		'GET_OFFICER_LIST_ARREAR_CENTERS_RESPONSE_CHANGED',

	GET_BRANCH_COLLECTION_USERS_LIST_START: 'GET_BRANCH_COLLECTION_USERS_LIST_START',
	GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS: 'GET_BRANCH_COLLECTION_USERS_LIST_SUCCESS',
	GET_BRANCH_COLLECTION_USERS_LIST_FAILURE: 'GET_BRANCH_COLLECTION_USERS_LIST_FAILURE',
	GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET:
		'GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_RESET',
	GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED:
		'GET_BRANCH_COLLECTION_USERS_LIST_RESPONSE_CHANGED',

	GET_CENTER_LIST_OFFICER_COLLECTIONS_START: 'GET_CENTER_LIST_OFFICER_COLLECTIONS_START',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS: 'GET_CENTER_LIST_OFFICER_COLLECTIONS_SUCCESS',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE: 'GET_CENTER_LIST_OFFICER_COLLECTIONS_FAILURE',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET:
		'GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_RESET',
	GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED:
		'GET_CENTER_LIST_OFFICER_COLLECTIONS_RESPONSE_CHANGED',
};
export default CollectionActionTypes;
