/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import AuditingToolActionTypes from './auditing-tool.types';

/** ***************** GET PROGRESS REPORT DETAILS START *********************** */
export const getAuditingToolDetailsStart = (getData) => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_START,
	payload: getData,
});

export const getAuditingToolDetailsSuccess = (successData) => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_SUCCESS,
	payload: successData,
});

export const getAuditingToolDetailsFailure = (failureData) => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_FAILURE,
	payload: failureData,
});

export const getAuditingToolDetailsResponseResetStart = () => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_RESET,
});

export const getAuditingToolDetailsResponseChanged = () => ({
	type: AuditingToolActionTypes.GET_AUDITING_TOOL_LIST_RESPONSE_CHANGED,
});
/** ***************** GET PROGRESS REPORT DETAILS END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: AuditingToolActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

