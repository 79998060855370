import BankDetailsActionTypes from './bank-details.types';

/** ***************** ADD BUSINESS BANK START *********************** */
export const addBusinessBankStart = (bankData) => ({
	type: BankDetailsActionTypes.ADD_BUSINESS_BANK_START,
	payload: bankData,
});

export const addBusinessBankSuccess = (successValue) => ({
	type: BankDetailsActionTypes.ADD_BUSINESS_BANK_SUCCESS,
	payload: successValue,
});

export const addBusinessBankFailure = (failureData) => ({
	type: BankDetailsActionTypes.ADD_BUSINESS_BANK_FAILURE,
	payload: failureData,
});

export const addBusinessBankResponseResetStart = () => ({
	type: BankDetailsActionTypes.ADD_BUSINESS_BANK_RESPONSE_RESET,
});

export const addBusinessBankResponseChanged = () => ({
	type: BankDetailsActionTypes.ADD_BUSINESS_BANK_RESPONSE_CHANGED,
});

/** ***************** ADD BUSINESS BANK END *********************** */

/** ***************** FETCH BUSINESS BANK START *********************** */
export const fetchBusinessBankStart = (fetchData) => ({
	type: BankDetailsActionTypes.FETCH_BUSINESS_BANK_START,
	payload: fetchData,
});

export const fetchBusinessBankSuccess = (successData) => ({
	type: BankDetailsActionTypes.FETCH_BUSINESS_BANK_SUCCESS,
	payload: successData,
});

export const fetchBusinessBankFailure = (failureData) => ({
	type: BankDetailsActionTypes.FETCH_BUSINESS_BANK_FAILURE,
	payload: failureData,
});

export const fetchBusinessBankResponseResetStart = () => ({
	type: BankDetailsActionTypes.FETCH_BUSINESS_BANK_RESPONSE_RESET,
});

export const fetchBusinessBankResponseChanged = () => ({
	type: BankDetailsActionTypes.FETCH_BUSINESS_BANK_RESPONSE_CHANGED,
});

/** ***************** FETCH BUSINESS BANK END *********************** */

/** ***************** UPDATE BUSINESS BANK START *********************** */
export const updateBusinessBankStart = (updateData) => ({
	type: BankDetailsActionTypes.UPDATE_BUSINESS_BANK_START,
	payload: updateData,
});

export const updateBusinessBankSuccess = (successData) => ({
	type: BankDetailsActionTypes.UPDATE_BUSINESS_BANK_SUCCESS,
	payload: successData,
});

export const updateBusinessBankFailure = (failureData) => ({
	type: BankDetailsActionTypes.UPDATE_BUSINESS_BANK_FAILURE,
	payload: failureData,
});

export const updateBusinessBankResponseResetStart = () => ({
	type: BankDetailsActionTypes.UPDATE_BUSINESS_BANK_RESPONSE_RESET,
});

export const updateBusinessBankResponseChanged = () => ({
	type: BankDetailsActionTypes.UPDATE_BUSINESS_BANK_RESPONSE_CHANGED,
});

/** ***************** UPDATE BUSINESS BANK END *********************** */
