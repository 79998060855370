/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import memberCollectionActionTypes from './memberCollection.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getSearchMemberLoanListResponse: null,
	getSearchMemberLoanListArr: [],
	getMemberLoanInfoResponse: null,
	getMemberLoanInfoArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS:
			return {
				...state,
				getSearchMemberLoanListResponse: action.payload,
				getSearchMemberLoanListArr: docodeResponse(
					action.payload,
					'searchMemberLoanListKey',
				),
			};

		case memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_FAILURE:
			return { ...state, getSearchMemberLoanListResponse: action.payload };

		case memberCollectionActionTypes.GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED:
			return { ...state, getSearchMemberLoanListResponse: null };

		case memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_SUCCESS:
			return {
				...state,
				getMemberLoanInfoResponse: action.payload,
				getMemberLoanInfoArr: docodeResponse(action.payload, 'LoanInfoMemberLoanListKey'),
			};

		case memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_FAILURE:
			return { ...state, getMemberLoanInfoResponse: action.payload };

		case memberCollectionActionTypes.GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED:
			return { ...state, getMemberLoanInfoResponse: null };

		default:
			return state;
	}
};
