/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const economicActivityTypeDetailsAdd = (addData) => {
	const data = {
		AddEconomicActivityTypeDetailsToken: addData,
	};
	const url = `${API_URL}addEconomicActivityTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityTypeDetailsUpdate = (updateData) => {
	const data = {
		UpdateEconomicActivityTypeDetailsToken: updateData,
	};
	const url = `${API_URL}updateEconomicActivityTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityTypeListGet = (listData) => {
	const data = {
		GetEconomicActivityTypeListToken: listData,
	};
	const url = `${API_URL}getEconomicActivityTypeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityTypeDetailsFetch = (fetchData) => {
	const data = {
		FetchEconomicActivityTypeDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchEconomicActivityTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const economicActivityTypeDetailsDelete = (deleteData) => {
	const data = {
		DeleteEconomicActivityTypeDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteEconomicActivityTypeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const allEconomicActivityTypeFetch = (fetchBranchesData) => {
	const data = {
		FetchAllEconomicActivityTypeListData: fetchBranchesData,
	};
	const url = `${API_URL}fetchAllEconomicActivityTypeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
