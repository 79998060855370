/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import LoanActionTypes from './goldLoanProposal.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	getGoldProposalResponse: null,
	getGoldProposalListArr: [],
	deleteGoldProposalResponse: null,
	updateGoldLoanProposalResponse: null,
	fetchProposalArr: [],
	fetchGoldLoanPropsalResponse: null,
	addGoldLoanPropsalResponse: null,
	approveGoldLoanPropsalResponse: null,
	fetchGoldLoanProposalGroupArr: [],
	fetchGoldLoanProposalGroupResponse: null,
	fetchGoldLoanProposalGroupMemberResponse: null,
	fetchGoldLoanProposalGroupMemberArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_SUCCESS:
			return { ...state, addGoldLoanPropsalResponse: action.payload };

		case LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_FAILURE:
			return { ...state, addGoldLoanPropsalResponse: action.payload };

		case LoanActionTypes.ADD_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, addGoldLoanPropsalResponse: null };

		case LoanActionTypes.UPDATE_LOAN_PROPOSAL_SUCCESS:
			return { ...state, updateGoldLoanProposalResponse: action.payload };

		case LoanActionTypes.UPDATE_LOAN_PROPOSAL_FAILURE:
			return { ...state, updateGoldLoanProposalResponse: action.payload };

		case LoanActionTypes.UPDATE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, updateGoldLoanProposalResponse: null };

		case LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				getGoldProposalResponse: action.payload,
				getGoldProposalListArr: docodeResponse(action.payload, 'GetGoldLoanProposalKey'),
			};

		case LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_FAILURE:
			return { ...state, getGoldProposalResponse: action.payload };

		case LoanActionTypes.GET_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, getGoldProposalResponse: null };

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				fetchGoldLoanProposalResponse: action.payload,
				fetchGoldProposalArr: docodeResponse(action.payload, 'FetchGoldLoanProposalKey'),
			};

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_FAILURE:
			return { ...state, fetchGoldLoanPropsalResponse: action.payload };

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, fetchGoldLoanPropsalResponse: null, fetchProposalArr: [] };

		case LoanActionTypes.DELETE_LOAN_PROPOSAL_SUCCESS:
			return { ...state, deleteGoldProposalResponse: action.payload };

		case LoanActionTypes.DELETE_LOAN_PROPOSAL_FAILURE:
			return { ...state, deleteGoldProposalResponse: action.payload };

		case LoanActionTypes.DELETE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, deleteGoldProposalResponse: null };

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_SUCCESS:
			return {
				...state,
				fetchGoldLoanProposalGroupOfficerResponse: action.payload,
				fetchGoldProposalGroupOfficerArr: docodeResponse(
					action.payload,
					'GetAllOfficerListKey',
				),
			};

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_FAILURE:
			return { ...state, fetchGoldLoanProposalGroupOfficerResponse: action.payload };

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_OFFICER_RESPONSE_CHANGED:
			return {
				...state,
				fetchGoldLoanProposalGroupOfficerResponse: null,
				fetchGoldProposalGroupOfficerArr: [],
			};

		case LoanActionTypes.APPROVE_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				approveGoldLoanPropsalResponse: action.payload,
			};

		case LoanActionTypes.APPROVE_LOAN_PROPOSAL_FAILURE:
			return { ...state, approveGoldLoanPropsalResponse: action.payload };

		case LoanActionTypes.APPROVE_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, approveGoldLoanPropsalResponse: null };

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_SUCCESS:
			return {
				...state,
				fetchGoldLoanProposalGroupResponse: action.payload,
				fetchGoldLoanProposalGroupArr: docodeResponse(
					action.payload,
					'GetAllGroupsListkey',
				),
			};

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_FAILURE:
			return { ...state, fetchGoldLoanProposalGroupResponse: action.payload };

		case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_RESPONSE_CHANGED:
			return {
				...state,
				fetchGoldLoanProposalGroupResponse: null,
				fetchGoldLoanProposalGroupArr: [],
			};

			case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_SUCCESS:
				return {
					...state,
					fetchGoldLoanProposalGroupMemberResponse: action.payload,
					fetchGoldLoanProposalGroupMemberArr: docodeResponse(
						action.payload,
						'GroupMemberListKey',
					),
				};
	
			case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_FAILURE:
				return { ...state, fetchGoldLoanProposalGroupMemberResponse: action.payload };
	
			case LoanActionTypes.FETCH_GOLD_LOAN_PROPOSAL_GROUP_MEMBER_RESPONSE_CHANGED:
				return {
					...state,
					fetchGoldLoanProposalGroupMemberResponse: null,
					fetchGoldLoanProposalGroupMemberArr: [],
				};

		default:
			return state;
	}
};
