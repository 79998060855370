/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import teamActionTypes from './team.types';

export const createTeamSuccess = (Team) => ({
	type: teamActionTypes.TEAM_CREATE_REPSONSE_SUCCESS,
	payload: Team,
});

export const createTeamFailure = (error) => ({
	type: teamActionTypes.TEAM_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createTeamStart = (TeamDetails) => ({
	type: teamActionTypes.TEAM_CREATE_REPSONSE_START,
	payload: TeamDetails,
});

export const teamCreateChanged = () => ({
	type: teamActionTypes.TEAM_CREATE_REPSONSE_CHANGED,
});

export const teamCreateReset = () => ({
	type: teamActionTypes.TEAM_CREATE_REPSONSE_RESET,
});

export const updateTeamSuccess = (Team) => ({
	type: teamActionTypes.TEAM_UPDATE_REPSONSE_SUCCESS,
	payload: Team,
});

export const updateTeamFailure = (error) => ({
	type: teamActionTypes.TEAM_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateTeamStart = (TeamDetails) => ({
	type: teamActionTypes.TEAM_UPDATE_REPSONSE_START,
	payload: TeamDetails,
});

export const teamUpdateChanged = () => ({
	type: teamActionTypes.TEAM_UPDATE_REPSONSE_CHANGED,
});

export const teamUpdateReset = () => ({
	type: teamActionTypes.TEAM_UPDATE_REPSONSE_RESET,
});

export const deleteTeamSuccess = (Team) => ({
	type: teamActionTypes.TEAM_DELETE_REPSONSE_SUCCESS,
	payload: Team,
});

export const deleteTeamFailure = (error) => ({
	type: teamActionTypes.TEAM_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteTeamStart = (TeamDetails) => ({
	type: teamActionTypes.TEAM_DELETE_REPSONSE_START,
	payload: TeamDetails,
});

export const teamDeleteChanged = () => ({
	type: teamActionTypes.TEAM_DELETE_REPSONSE_CHANGED,
});

export const teamDeleteReset = () => ({
	type: teamActionTypes.TEAM_DELETE_REPSONSE_RESET,
});

export const getSelectedID = (TeamData) => ({
	type: teamActionTypes.TEAM_SELECTED_ID,
	payload: TeamData,
});

export const getAllTeamList = (TeamData) => ({
	type: teamActionTypes.TEAM_LIST_START,
	payload: TeamData,
});

export const getAllTeamListSuccess = (TEAM) => ({
	type: teamActionTypes.TEAM_LIST_SUCCESS,
	payload: TEAM,
});

export const getAllTeamListFailure = (TEAM) => ({
	type: teamActionTypes.TEAM_LIST_FAILURE,
	payload: TEAM,
});
export const getAllTeamListResetAction = () => ({
	type: teamActionTypes.TEAM_LIST_RESET,
});

export const getAllTeamListChangedAction = () => ({
	type: teamActionTypes.TEAM_LIST_CHANGED,
});

export const getAllTeamLeadsList = (TEAM) => ({
	type: teamActionTypes.TEAM_GET_TEAM_LEAD_START,
	payload: TEAM,
});

export const getAllTeamLeadsSuccess = (TEAM) => ({
	type: teamActionTypes.TEAM_GET_TEAM_LEAD_SUCCESS,
	payload: TEAM,
});

export const getAllTeamLeadsFailure = (TEAM) => ({
	type: teamActionTypes.TEAM_GET_TEAM_LEAD_FAILURE,
	payload: TEAM,
});
export const getAllTeamLeadsResetAction = () => ({
	type: teamActionTypes.TEAM_GET_TEAM_LEAD_RESET,
});

export const getAllTeamLeadsChangedAction = () => ({
	type: teamActionTypes.TEAM_GET_TEAM_LEAD_CHANGED,
});
