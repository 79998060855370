/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import teamActionTypes from './team.types';

const INITIAL_STATE = {
	teamStatus: null,
	teamResponse: null,
	teamCreateStatus: null,
	teamCreateResponse: null,
	teamUpdateStatus: null,
	teamUpdateResponse: null,
	teamDeleteStatus: null,
	teamDeleteResponse: null,
	teamSelectedData: null,
};

const teamReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case teamActionTypes.TEAM_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				teamCreateResponse: action.payload,
				teamCreateStatus: 'Success',
			};
		case teamActionTypes.TEAM_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				teamCreateResponse: action.payload,
				teamCreateStatus: 'Failure',
			};
		case teamActionTypes.TEAM_CREATE_REPSONSE_CHANGED:
			return { ...state, teamCreateResponse: null, teamCreateStatus: null };

		case teamActionTypes.TEAM_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				teamUpdateResponse: action.payload,
				teamUpdateStatus: 'Success',
			};
		case teamActionTypes.TEAM_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				teamUpdateResponse: action.payload,
				teamUpdateStatus: 'Failure',
			};
		case teamActionTypes.TEAM_UPDATE_REPSONSE_CHANGED:
			return { ...state, teamUpdateResponse: null, teamUpdateStatus: null };

		case teamActionTypes.TEAM_DELETE_REPSONSE_SUCCESS:
			return {
				...state,
				teamDeleteResponse: action.payload,
				teamDeleteStatus: 'Success',
			};
		case teamActionTypes.TEAM_DELETE_REPSONSE_FAILURE:
			return {
				...state,
				teamDeleteResponse: action.payload,
				teamDeleteStatus: 'Failure',
			};

		case teamActionTypes.TEAM_DELETE_REPSONSE_CHANGED:
			return { ...state, teamDeleteResponse: null, teamDeleteStatus: null };
		case teamActionTypes.TEAM_SELECTED_ID:
			return { ...state, teamSelectedData: action.payload };
		case teamActionTypes.TEAM_LIST_SUCCESS:
			return {
				...state,
				teamStatus: 'Success',
				teamResponse: action.payload,
			};
		case teamActionTypes.TEAM_LIST_FAILURE:
			return {
				...state,
				teamStatus: 'Failure',
				teamResponse: action.payload,
			};
		case teamActionTypes.TEAM_LIST_CHANGED:
			return {
				...state,
				teamStatus: null,
				teamResponse: null,
			};

		case teamActionTypes.TEAM_GET_TEAM_LEAD_SUCCESS:
			return {
				...state,
				teamLeadStatus: 'Success',
				teamLeadSelected: action.payload,
			};
		case teamActionTypes.TEAM_GET_TEAM_LEAD_FAILURE:
			return {
				...state,
				teamLeadStatus: 'Failure',
				teamLeadSelected: action.payload,
			};
		case teamActionTypes.TEAM_GET_TEAM_LEAD_CHANGED:
			return {
				...state,
				teamLeadStatus: null,
				teamLeadSelected: null,
			};
		default:
			return state;
	}
};

export default teamReducer;
