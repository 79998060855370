/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';
import overdueReportActionTypes from './overDueReport.types';
import { overdueReportDetailsGet, fetchOverDueExcel } from './overDueReport.services';
import {
	getoverdueReportDetailsSuccess,
	getoverdueReportDetailsFailure,
	getoverdueReportDetailsResponseChanged,
	fetchOverDueExcelSuccess,
	fetchOverDueExcelFailure,
	fetchOverDueExcelResponseChanged,
} from './overDueReport.actions';
import { encodeJson } from '../../enode-decode';
/** *********************  START GET MONTHLY PROGRESS DETAILS  ******************** */
export function* getoverdueReportList({ payload }) {
	// debugger;
	try {
		const key = 'GetoverdueReportKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			UserRole: payload.userRole,
			DateFilter: payload.datefilter,
			OverDueListBy: payload.OverDueListBy,
			IsThriftExist: payload.IsThriftExist,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(overdueReportDetailsGet, encodeData, payload.serviceName);
		if (responseData.data.statusCode === '100') {
			yield put(getoverdueReportDetailsFailure(responseData.data));
		} else {
			yield put(getoverdueReportDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getoverdueReportDetailsFailure(error));
	}
}

export function* resetGetOverDueReportListResponse() {
	yield put(getoverdueReportDetailsResponseChanged());
}

export function* onGetoverdueReportListDetails() {
	yield takeLatest(overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_START, getoverdueReportList);
}

export function* onGetoverdueReportListResponseReset() {
	yield takeLatest(
		overdueReportActionTypes.GET_OVERDUE_REPORT_LIST_RESPONSE_RESET,
		resetGetOverDueReportListResponse,
	);
}
/** *********************  END GET MONTHLY PROGRESS LIST ******************** */
export function* fetchOverDueExcelCall({ payload }) {
	try {
		const key = 'OverdueReportExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			AreaID: payload.branchID,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			CenterID: payload.centerID,
			GroupID: payload.groupID,
			UserRole: payload.userRole,
			DateFilter: payload.datefilter,
			OverDueListBy: payload.OverDueListBy,
			IsThriftExist: payload.IsThriftExist,
			RequestID: payload.RequestID,
			ProvisionType: payload.ProvisionType,
		};
		const encodeDate = encodeJson(jsonData, key);
		const responseData = yield call(fetchOverDueExcel, encodeDate, payload.serviceName);
		if (responseData.data.statusCode === '500') {
			yield put(fetchOverDueExcelFailure(responseData.data));
		} else {
			yield put(fetchOverDueExcelSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchOverDueExcelFailure(error));
	}
}

export function* onSelectOverDueExcel() {
	yield takeLatest(overdueReportActionTypes.FETCH_OVERDUE_EXCEL_START, fetchOverDueExcelCall);
}

export function* fetchOverDueExcelReset() {
	yield put(fetchOverDueExcelResponseChanged());
}

export function* onSelectOverDueExcelReset() {
	yield takeLatest(
		overdueReportActionTypes.FETCH_OVERDUE_EXCEL_RESPONSE_RESET,
		fetchOverDueExcelReset,
	);
}

export function* overDueReportSaga() {
	yield all([
		call(onGetoverdueReportListDetails),
		call(onGetoverdueReportListResponseReset),
		call(onSelectOverDueExcel),
		call(onSelectOverDueExcelReset),
	]);
}
