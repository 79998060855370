/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import DemandFollowupActionTypes from './demand-followup-report.types';

/** ***************** GET LOAN DISBURSEMENT START *********************** */
export const getDemandFollowupDetailsStart = (getData) => ({
	type: DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_START,
	payload: getData,
});

export const getDemandFollowupDetailsSuccess = (successData) => ({
	type: DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_SUCCESS,
	payload: successData,
});

export const getDemandFollowupDetailsFailure = (failureData) => ({
	type: DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_FAILURE,
	payload: failureData,
});

export const getDemandFollowupDetailsResponseResetStart = () => ({
	type: DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_RESPONSE_RESET,
});

export const getDemandFollowupDetailsResponseChanged = () => ({
	type: DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_RESPONSE_CHANGED,
});

export const getDemandFollowupDetailsResponseArrayChanged = () => ({
	type: DemandFollowupActionTypes.GET_DEMAND_FOLLOWUP_LIST_RESPONSE_ARRAY_CHANGED,
});
/** ***************** GET DEMAND FOLLOWUP END *********************** */
