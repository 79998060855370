/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../../const';

export const fetchAdvancehMemberLoanList = (listData) => {
	const data = {
		FetchAdvanceMemberListDataToken: listData,
	};
	const url = `${API_URL}GetDemandForAdvanceCollectionsToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const advanceCollectionUpdateAPI = (listData) => {
	const data = {
		UpdateAdvanceMemberListData: listData,
	};
	const url = `${API_URL}UpdateAdvanceLoanPaidForSelectedMembersToken`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
