/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ActiveMemberActionTypes from './active-member.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
    TotalLoanAmount: 0,
    TotalLoanInterestAmount: 0,
    TotalLoanAmountInterestReceived: 0,
    TotalLoanAmountReceived: 0,
    TotalLoanOLBAmount: 0,
    TotalLoanOLBPrincipal: 0,
    TotalLoanOLBInterest: 0,
    TotalLoanAmountArrear: 0,
    TotalLoanInterestAmountArrear: 0,

	getActiveMemberListResponse: null,
	getActiveMemberListArr: [],
    getActiveMemberListStatus: null,

    getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],

	getActiveMemberExcelListResponse: null,
	getActiveMemberExcelListArr: [],
    getActiveMemberExcelListStatus: null,
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_SUCCESS:
			return {
				...state,
				getActiveMemberListResponse: action.payload,
				getActiveMemberListArr: docodeResponse(action.payload, 'GetActiveMemberReportKey'),
                TotalLoanAmount: action.payload.TotalLoanAmount,
                TotalLoanInterestAmount: action.payload.TotalLoanInterestAmount,
                TotalLoanAmountReceived: action.payload.TotalLoanAmountReceived,
                TotalLoanAmountInterestReceived: action.payload.TotalLoanAmountInterestReceived,
                TotalLoanOLBAmount: action.payload.TotalLoanOLBAmount,
                TotalLoanOLBPrincipal: action.payload.TotalLoanOLBPrincipal,
                TotalLoanOLBInterest: action.payload.TotalLoanOLBInterest,
                TotalLoanAmountArrear: action.payload.TotalLoanAmountArrear,
                TotalLoanInterestAmountArrear: action.payload.TotalLoanInterestAmountArrear,
                getActiveMemberListStatus: 'Success',
			};

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_FAILURE:
			return { ...state, getActiveMemberListStatus: action.payload };

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_LIST_RESPONSE_CHANGED:
			return { ...state, getActiveMemberListStatus: null };

        case ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
            return {
                ...state,
                getOfficerBranchListResponse: action.payload,
                getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
            };

        case ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
            return { ...state, getOfficerBranchListResponse: action.payload };

        case ActiveMemberActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
            return { ...state, getOfficerBranchListResponse: null };


		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_EXCEL_LIST_SUCCESS:
			return {
				...state,
				getActiveMemberExcelListResponse: action.payload,
				getActiveMemberExcelListArr: docodeResponse(action.payload, 'FutureDemandExcelKey'),
				getActiveMemberExcelListStatus: 'Success',
			};

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_EXCEL_LIST_FAILURE:
			return { ...state, getActiveMemberExcelListStatus: action.payload };

		case ActiveMemberActionTypes.GET_ACTIVE_MEMBER_EXCEL_LIST_RESPONSE_CHANGED:
			return { ...state, getActiveMemberExcelListStatus: null };
	
        default:
            return state;
    }
}