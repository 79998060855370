const AuditTrailReportActionTypes = {
	GET_AUDIT_TRAIL_REPORT_LIST_START: 'GET_AUDIT_TRAIL_REPORT_LIST_START',
	GET_AUDIT_TRAIL_REPORT_LIST_SUCCESS: 'GET_AUDIT_TRAIL_REPORT_LIST_SUCCESS',
	GET_AUDIT_TRAIL_REPORT_LIST_FAILURE: 'GET_AUDIT_TRAIL_REPORT_LIST_FAILURE',
	GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_RESET: 'GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_RESET',
	GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_CHANGED: 'GET_AUDIT_TRAIL_REPORT_LIST_RESPONSE_CHANGED',

	/** ***************** AUDIT TRAIL REPORT REPORT EXCEL  *********************** */

	FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_START: 'FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_START',
	FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_SUCCESS: 'FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_SUCCESS',
	FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_FAILURE: 'FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_FAILURE',
	FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_RESET: 'FETCH_POST_AUDIT_TRAIL_REPORT_EXCEL_RESET',
	FETCH_POST_TRAIL_REPORT_LIST_RESPONSE_CHANGED: 'FETCH_POST_TRAIL_REPORT_LIST_RESPONSE_CHANGED',

	/** ***************** AUDIT TRAIL REPORT REPORT JSON  *********************** */

	FETCH_POST_AUDIT_TRAIL_REPORT_JSON_START: 'FETCH_POST_AUDIT_TRAIL_REPORT_JSON_START',
	FETCH_POST_AUDIT_TRAIL_REPORT_JSON_SUCCESS: 'FETCH_POST_AUDIT_TRAIL_REPORT_JSON_SUCCESS',
	FETCH_POST_AUDIT_TRAIL_REPORT_JSON_FAILURE: 'FETCH_POST_AUDIT_TRAIL_REPORT_JSON_FAILURE',
	FETCH_POST_AUDIT_TRAIL_REPORT_JSON_RESET: 'FETCH_POST_AUDIT_TRAIL_REPORT_JSON_RESET',
	FETCH_POST_TRAIL_REPORT_LIST_JSON_RESPONSE_CHANGED:
		'FETCH_POST_TRAIL_REPORT_LIST_JSON_RESPONSE_CHANGED',
};
export default AuditTrailReportActionTypes;
