/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import DataVerificationActionTypes from './data-verification.types';
import { docodeResponse } from '../../common/common.utils';

const INITIAL_STATE = {
	getDataVerificationListResponse: null,
    getDataVerificationListStatus: null,
    getDataVerificationListArr:[],

    getOfficerBranchListResponse: null,
	getOfficerBranchListArr: [],
};

export default(state = INITIAL_STATE, action) =>{
    switch (action.type){
        case DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_SUCCESS:
			return {
				...state,
                getDataVerificationListResponse: action.payload,
				getDataVerificationListArr: docodeResponse(action.payload, 'GetDataVerificationReportKey'),
                getDataVerificationListStatus: 'Success',
			};

		case DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_FAILURE:
			return { ...state, getDataVerificationListStatus: action.payload };

		case DataVerificationActionTypes.GET_DATA_VERIFICATION_LIST_RESPONSE_CHANGED:
			return { ...state, getDataVerificationListStatus: null };

        case DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS:
            return {
                ...state,
                getOfficerBranchListResponse: action.payload,
                getOfficerBranchListArr: docodeResponse(action.payload, 'GetOfficerListBranchKey'),
            };

        case DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE:
            return { ...state, getOfficerBranchListResponse: action.payload };

        case DataVerificationActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED:
            return { ...state, getOfficerBranchListResponse: null };
        default:
            return state;
    }
}