const memberCollectionActionTypes = {
	GET_SEARCH_MEMBER_LOAN_LIST_START: 'GET_SEARCH_MEMBER_LOAN_LIST_START',
	GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS: 'GET_SEARCH_MEMBER_LOAN_LIST_SUCCESS',
	GET_SEARCH_MEMBER_LOAN_LIST_FAILURE: 'GET_SEARCH_MEMBER_LOAN_LIST_FAILURE',
	GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET: 'GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_RESET',
	GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED: 'GET_SEARCH_MEMBER_LOAN_LIST_RESPONSE_CHANGED',

	GET_MEMBER_LOAN_INFO_START: 'GET_MEMBER_LOAN_INFO_START',
	GET_MEMBER_LOAN_INFO_SUCCESS: 'GET_MEMBER_LOAN_INFO_SUCCESS',
	GET_MEMBER_LOAN_INFO_FAILURE: 'GET_MEMBER_LOAN_INFO_FAILURE',
	GET_MEMBER_LOAN_INFO_RESPONSE_RESET: 'GET_MEMBER_LOAN_INFO_RESPONSE_RESET',
	GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED: 'GET_MEMBER_LOAN_INFO_RESPONSE_CHANGED',
};
export default memberCollectionActionTypes;
