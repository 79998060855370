/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import regularDemandReportTypes from './regular-demand-report.types';

/** ***************** FETCH REGULAR DEMAND DETAILS START *********************** */
export const getRegularDemandReportStartReportStart = (addData) => ({
	type: regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_START,
	payload: addData,
});

export const getRegularDemandReportSuccess = (successData) => ({
	type: regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_SUCCESS,
	payload: successData,
});

export const getRegularDemandReportFailure = (failureData) => ({
	type: regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_FAILURE,
	payload: failureData,
});

export const getRegularDemandReportResponseResetStart = () => ({
	type: regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_RESPONSE_RESET_START,
});

export const getRegularDemandReportResponseResetChanged = () => ({
	type: regularDemandReportTypes.GET_REGULAR_DEMAND_REPORT_RESPONSE_CHANGED,
});

/** ***************** FETCH REGULAR DEMAND DETAILS END *********************** */
