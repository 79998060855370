/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import BankDetailsActionTypes from './bank-details.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addBusinessBankResponse: null,
	fetchBusinessBankResponse: null,
	fetchBusinessBankData: [],
	updateBusinessBankResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BankDetailsActionTypes.ADD_BUSINESS_BANK_SUCCESS:
			return { ...state, addBusinessBankResponse: action.payload };

		case BankDetailsActionTypes.ADD_BUSINESS_BANK_FAILURE:
			return { ...state, addBusinessBankResponse: action.payload };

		case BankDetailsActionTypes.ADD_BUSINESS_BANK_RESPONSE_CHANGED:
			return { ...state, addBusinessBankResponse: null };

		case BankDetailsActionTypes.FETCH_BUSINESS_BANK_SUCCESS:
			return {
				...state,
				fetchBusinessBankResponse: action.payload,
				fetchBusinessBankData: docodeResponse(action.payload, 'GetBankDetailsKey'),
			};

		case BankDetailsActionTypes.FETCH_BUSINESS_BANK_FAILURE:
			return { ...state, fetchBusinessBankResponse: action.payload };

		case BankDetailsActionTypes.FETCH_BUSINESS_BANK_RESPONSE_CHANGED:
			return { ...state, fetchBusinessBankResponse: null };

		case BankDetailsActionTypes.UPDATE_BUSINESS_BANK_SUCCESS:
			return { ...state, updateBusinessBankResponse: action.payload };

		case BankDetailsActionTypes.UPDATE_BUSINESS_BANK_FAILURE:
			return { ...state, updateBusinessBankResponse: action.payload };

		case BankDetailsActionTypes.UPDATE_BUSINESS_BANK_RESPONSE_CHANGED:
			return { ...state, updateBusinessBankResponse: null };

		default:
			return state;
	}
};
