const BranchActionTypes = {
	ADD_BRANCH_DETAILS_START: 'add_branch_details_start',
	ADD_BRANCH_DETAILS_SUCCESS: 'add_branch_details_success',
	ADD_BRANCH_DETAILS_FAILURE: 'add_branch_details_failure',
	ADD_BRANCH_DETAILS_RESPONSE_RESET: 'add_branch_details_response_reset',
	ADD_BRANCH_DETAILS_RESPONSE_CHANGED: 'add_branch_details_response_changed',

	UPDATE_BRANCH_DETAILS_START: 'update_branch_details_start',
	UPDATE_BRANCH_DETAILS_SUCCESS: 'update_branch_details_success',
	UPDATE_BRANCH_DETAILS_FAILURE: 'update_branch_details_failure',
	UPDATE_BRANCH_DETAILS_RESPONSE_RESET: 'update_branch_details_response_reset',
	UPDATE_BRANCH_DETAILS_RESPONSE_CHANGED: 'update_branch_details_response_changed',

	GET_BRANCH_LIST_START: 'get_branch_branch_start',
	GET_BRANCH_LIST_SUCCESS: 'get_branch_branch_success',
	GET_BRANCH_LIST_FAILURE: 'get_branch_branch_failure',
	GET_BRANCH_LIST_RESPONSE_RESET: 'get_branch_branch_response_reset',
	GET_BRANCH_LIST_RESPONSE_CHANGED: 'get_branch_branch_response_changed',

	FETCH_BRANCH_DETAILS_START: 'fetch_branch_details_start',
	FETCH_BRANCH_DETAILS_SUCCESS: 'fetch_branch_details_success',
	FETCH_BRANCH_DETAILS_FAILURE: 'fetch_branch_details_failure',
	FETCH_BRANCH_DETAILS_RESPONSE_RESET: 'fetch_branch_details_response_reset',
	FETCH_BRANCH_DETAILS_RESPONSE_CHANGED: 'fetch_branch_details_response_changed',

	DELETE_BRANCH_DETAILS_START: 'delete_branch_details_start',
	DELETE_BRANCH_DETAILS_SUCCESS: 'delete_branch_details_success',
	DELETE_BRANCH_DETAILS_FAILURE: 'delete_branch_details_failure',
	DELETE_BRANCH_DETAILS_RESPONSE_RESET: 'delete_branch_details_response_reset',
	DELETE_BRANCH_DETAILS_RESPONSE_CHANGED: 'delete_branch_details_response_changed',
};
export default BranchActionTypes;
