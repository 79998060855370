/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../../const';

export const centerDetailsAdd = (addData) => {
	const data = {
		AddCenterDetailsToken: addData,
	};
	const url = `${API_URL}addCenterDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerDetailsUpdate = (updateData) => {
	const data = {
		UpdateCenterDetailsToken: updateData,
	};
	const url = `${API_URL}updateCenterDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerListGet = (listData) => {
	const data = {
		GetCenterListToken: listData,
	};
	const url = `${API_URL}getCenterList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerDetailsFetch = (fetchData) => {
	const data = {
		FetchCenterDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchCenterDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const centerDetailsDelete = (deleteData) => {
	const data = {
		DeleteCenterDetailsToken: deleteData,
	};
	const url = `${API_URL}deleteCenterDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchCenterUniqueDetails = (fetchCenterUniqueData) => {
	const data = {
		CenterUniqueIdFetchToken: fetchCenterUniqueData,
	};
	const url = `${API_URL}getCenterUniqueID`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
