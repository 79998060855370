/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import BusinessLoanProposalActionTypes from './business-loan-propsal.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addBusinessPropsalResponse: null,
	updateBusinessProposalResponse: null,
	getBusinessProposalResponse: null,
	getBusinessProposalListArr: [],
	approveBusinessPropsalResponse: null,
	fetchBusinessPropsalResponse: null,
	fetchBusinessProposalArr: [],
	deleteBusinessPropsalResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_SUCCESS:
			return { ...state, addBusinessPropsalResponse: action.payload };

		case BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_FAILURE:
			return { ...state, addBusinessPropsalResponse: action.payload };

		case BusinessLoanProposalActionTypes.ADD_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, addBusinessPropsalResponse: null };

		case BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_SUCCESS:
			return { ...state, updateBusinessProposalResponse: action.payload };

		case BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_FAILURE:
			return { ...state, updateBusinessProposalResponse: action.payload };

		case BusinessLoanProposalActionTypes.UPDATE_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, updateBusinessProposalResponse: null };

		case BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_SUCCESS:
			return {
				...state,
				getBusinessProposalResponse: action.payload,
				getBusinessProposalListArr: docodeResponse(
					action.payload,
					'GetBusinessLoanProposalListKey',
				),
			};

		case BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_FAILURE:
			return { ...state, getBusinessProposalResponse: action.payload };

		case BusinessLoanProposalActionTypes.GET_BUSINESS_LOAN_PROPOSAL_LIST_RESPONSE_CHANGED:
			return { ...state, getBusinessProposalResponse: null };

		case BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_SUCCESS:
			return {
				...state,
				approveBusinessPropsalResponse: action.payload,
			};

		case BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_FAILURE:
			return { ...state, approveBusinessPropsalResponse: action.payload };

		case BusinessLoanProposalActionTypes.APPROVE_BUSINESS_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, approveBusinessPropsalResponse: null };

		case BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_SUCCESS:
			return {
				...state,
				fetchBusinessPropsalResponse: action.payload,
				fetchBusinessProposalArr: docodeResponse(
					action.payload,
					'FetchBusinessLoanProposalKey',
				),
			};

		case BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_FAILURE:
			return { ...state, fetchBusinessPropsalResponse: action.payload };

		case BusinessLoanProposalActionTypes.FETCH_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, fetchBusinessPropsalResponse: null, fetchBusinessProposalArr: [] };

		case BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_SUCCESS:
			return { ...state, deleteBusinessPropsalResponse: action.payload };

		case BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_FAILURE:
			return { ...state, deleteBusinessPropsalResponse: action.payload };

		case BusinessLoanProposalActionTypes.DELETE_BUSINESS_LOAN_PROPOSAL_RESPONSE_CHANGED:
			return { ...state, deleteBusinessPropsalResponse: null };

		// default:
		// 	return state;
			default:
				return state;
	}
};
