/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import BranchDetailsActionTypes from './branch-details.types';
import { docodeResponse } from '../../../common/common.utils';

const INITIAL_STATE = {
	addBusinessBranchResponse: null,
	fetchBusinessBranchResponse: null,
	fetchBusinessBranchList: [],
	updateBusinessBranchResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_SUCCESS:
			return { ...state, addBusinessBranchResponse: action.payload };

		case BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_FAILURE:
			return { ...state, addBusinessBranchResponse: action.payload };

		case BranchDetailsActionTypes.ADD_BUSINESS_BRANCH_RESPONSE_CHANGED:
			return { ...state, addBusinessBranchResponse: null };

		case BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_SUCCESS:
			return {
				...state,
				fetchBusinessBranchResponse: action.payload,
				fetchBusinessBranchList: docodeResponse(action.payload, 'GetBusinessBranchKey'),
			};

		case BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_FAILURE:
			return { ...state, fetchBusinessBranchResponse: action.payload };

		case BranchDetailsActionTypes.FETCH_BUSINESS_BRANCH_RESPONSE_CHANGED:
			return { ...state, fetchBusinessBranchResponse: null };

		case BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_SUCCESS:
			return { ...state, updateBusinessBranchResponse: action.payload };

		case BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_FAILURE:
			return { ...state, updateBusinessBranchResponse: action.payload };

		case BranchDetailsActionTypes.UPDATE_BUSINESS_BRANCH_RESPONSE_CHANGED:
			return { ...state, updateBusinessBranchResponse: null };

		default:
			return state;
	}
};
