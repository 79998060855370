/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import LoanDisbursementActionTypes from './loan-disbursement.types';

/** ***************** GET LOAN DISBURSEMENT START *********************** */
export const getLoanDisbursementDetailsStart = (getData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_START,
	payload: getData,
});

export const getLoanDisbursementDetailsSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_SUCCESS,
	payload: successData,
});

export const getLoanDisbursementDetailsFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_FAILURE,
	payload: failureData,
});

export const getLoanDisbursementDetailsResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_RESPONSE_RESET,
});

export const getLoanDisbursementDetailsResponseChanged = () => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET LOAN DISBURSEMEN END *********************** */

/** ***************** GET OFFICER BASED ON BRANCH DETAILS START *********************** */
export const getOfficerBranchDetailsStart = (getData) => ({
	type: LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_START,
	payload: getData,
});

export const getOfficerBranchDetailsSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getOfficerBranchDetailsFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getOfficerBranchDetailsResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
});

export const getOfficerBranchDetailsResponseChanged = () => ({
	type: LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_CHANGED,
});
/** ***************** GET OFFICER BASED ON BRANCH DETAILS END *********************** */

/** ***************** GET LOAN DISBURSEMENT EXCEL START *********************** */
export const getLoanDisbursementExcelDetailsStart = (getData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_START,
	payload: getData,
});

export const getLoanDisbursementExcelDetailsSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_SUCCESS,
	payload: successData,
});

export const getLoanDisbursementExcelDetailsFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_FAILURE,
	payload: failureData,
});

export const getLoanDisbursementExcelDetailsResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_RESPONSE_RESET,
});

export const getLoanDisbursementExcelDetailsResponseChanged = () => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_RESPONSE_CHANGED,
});

export const getLoanDisbursementBankDetailsExcelDetailsStart = (getData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_START,
	payload: getData,
});

export const getLoanDisbursementBankDetailsExcelDetailsSuccess = (successData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_SUCCESS,
	payload: successData,
});

export const getLoanDisbursementBankDetailsExcelDetailsFailure = (failureData) => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_FAILURE,
	payload: failureData,
});

export const getLoanDisbursementBankDetailsExcelDetailsResponseResetStart = () => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_RESPONSE_RESET,
});

export const getLoanDisbursementBankDetailsExcelDetailsResponseChanged = () => ({
	type: LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_RESPONSE_CHANGED,
});
