/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */

import { takeLatest, put, all, call } from 'redux-saga/effects';

import LoanDisbursementActionTypes from './loan-disbursement.types';

import {
	LoanDisbursementListGet,
	officerBranchListGet,
	LoanDisbursementExcelListGet,
	LoanDisbursementBankDetailsExcelListGet
} from './loan-disbursement.service';

import {
	getLoanDisbursementDetailsSuccess,
	getLoanDisbursementDetailsFailure,
	getLoanDisbursementDetailsResponseChanged,
	getOfficerBranchDetailsSuccess,
	getOfficerBranchDetailsFailure,
	getOfficerBranchDetailsResponseChanged,
	getLoanDisbursementExcelDetailsSuccess,
	getLoanDisbursementExcelDetailsFailure,
	getLoanDisbursementExcelDetailsResponseChanged,
	getLoanDisbursementBankDetailsExcelDetailsSuccess,
	getLoanDisbursementBankDetailsExcelDetailsFailure,
	getLoanDisbursementBankDetailsExcelDetailsResponseChanged,
} from './loan-disbursement.action';

import { encodeJson } from '../../enode-decode';

/** *********************  START GET LOAN DISBURSEMENT REPORT DETAILS  ******************** */
export function* getLoanDisbursementList({ payload }) {
	try {
		const key = 'LoanDisbursementKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			UserRole: payload.userName,
			Location: payload.userAccessBranchID,
			OfficerID: payload.officerID,
			BranchID: payload.branchID,
			GroupID: payload.groupID,
			FunderID: payload.funderID,
			PurposeOfLoanID: payload.purposeOfLoanID,
			EconomicActivityID: payload.economicActivityID,
			LoanStatus: payload.loanstatus,
			LoanApprove: payload.loanapprove,
			LoanCycle: payload.cycle,
			LoanType: payload.LoanType,
			SortByMethod: payload.DateType,
			CenterType: payload.CenterType,
			DatabaseName: payload.databaseName,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			IsThriftActive: payload.IsThriftActive,
			UserLevel: payload.userLevel,
			Limit: payload.limit,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LoanDisbursementListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanDisbursementDetailsFailure(responseData.data));
		} else {
			yield put(getLoanDisbursementDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanDisbursementDetailsFailure(error));
	}
}

export function* resetGetLoanDisbursementListResponse() {
	yield put(getLoanDisbursementDetailsResponseChanged());
}

export function* onGetLoanDisbursementListDetails() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_START,
		getLoanDisbursementList,
	);
}

export function* onGetLoanDisbursementListResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_LIST_RESPONSE_RESET,
		resetGetLoanDisbursementListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT REPORT LIST ******************** */

/** *********************  START GET OFFICER BASED ON BRANCH DETAILS  ******************** */
export function* getOfficerBranchList({ payload }) {
	try {
		const key = 'GetOfficerListBranchKey';
		const jsonData = {
			CompanyID: payload.companyID,
			BranchID: payload.branchID,
			CenterID: payload.centerID,
			DatabaseName: payload.databaseName,
			UserID: payload.userID,
			UserLevel: payload.userLevel,
			UserRole: payload.userRole,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(officerBranchListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getOfficerBranchDetailsFailure(responseData.data));
		} else {
			yield put(getOfficerBranchDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOfficerBranchDetailsFailure(error));
	}
}

export function* resetGetOfficerBranchListResponse() {
	yield put(getOfficerBranchDetailsResponseChanged());
}

export function* onGetOfficerBranchListDetails() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_START,
		getOfficerBranchList,
	);
}

export function* onGetOfficerBranchListResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_OFFICER_BRANCH_LIST_RESPONSE_RESET,
		resetGetLoanDisbursementListResponse,
	);
}
/** *********************  END GET OFFICER BASED ON BRANCH LIST ******************** */


/** *********************  START GET LOAN DISBURSEMENT EXCEL REPORT DETAILS  ******************** */
export function* getLoanDisbursementExcelList({ payload }) {
	try {
		const key = 'LoanDisbursementExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			Location: payload.userAccessBranchID,
			BranchID: payload.branchID,
			InvestorID: payload.funderID,
			PurposeOfLoanID: payload.purposeOfLoanID,
			EconomicActivityID: payload.economicActivityID,
			LoanStatus: payload.loanstatus,
			LoanApprove: payload.loanapprove,
			LoanCycle: payload.cycle,
			LoanType: payload.LoanType,
			SortByMethod: payload.DateType,
			CenterType: payload.CenterType,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			IsThriftExist: payload.isThrift,
			Is_IDBI: payload.Is_IDBI,
			RequestID: payload.RequestID,
			GeneralCompanyID: payload.generalCompanyID,
			ProvisionType: payload.ProvisionType,
			UserLevel: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LoanDisbursementExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanDisbursementExcelDetailsFailure(responseData.data));
		} else {
			yield put(getLoanDisbursementExcelDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanDisbursementExcelDetailsFailure(error));
	}
}

export function* resetGetLoanDisbursementExcelListResponse() {
	yield put(getLoanDisbursementExcelDetailsResponseChanged());
}

export function* onGetLoanDisbursementExcelListDetails() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_START,
		getLoanDisbursementExcelList,
	);
}

export function* onGetLoanDisbursementExcelListResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_EXCEL_LIST_RESPONSE_RESET,
		resetGetLoanDisbursementExcelListResponse,
	);
}

export function* getLoanDisbursementBankDetailsExcelList({ payload }) {
	try {
		const key = 'LoanMemberBankDetailsListExcelKey';
		const jsonData = {
			CompanyID: payload.companyID,
			UserID: payload.userID,
			Location: payload.userAccessBranchID,
			BranchID: payload.branchID,
			InvestorID: payload.funderID,
			PurposeOfLoanID: payload.purposeOfLoanID,
			EconomicActivityID: payload.economicActivityID,
			LoanStatus: payload.loanstatus,
			LoanApprove: payload.loanapprove,
			LoanCycle: payload.cycle,
			LoanType: payload.LoanType,
			SortByMethod: payload.DateType,
			CenterType: payload.CenterType,
			DatabaseName: payload.databaseName,
			FromDate: payload.FromDate,
			ToDate: payload.ToDate,
			IsThriftExist: payload.isThrift,
			Is_IDBI: payload.Is_IDBI,
			RequestID: payload.RequestID,
			GeneralCompanyID: payload.generalCompanyID,
			ProvisionType: payload.ProvisionType,
			UserLevel: payload.userLevel,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(LoanDisbursementBankDetailsExcelListGet, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getLoanDisbursementBankDetailsExcelDetailsFailure(responseData.data));
		} else {
			yield put(getLoanDisbursementBankDetailsExcelDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getLoanDisbursementBankDetailsExcelDetailsFailure(error));
	}
}

export function* resetGetLoanDisbursementBankDetailsExcelListResponse() {
	yield put(getLoanDisbursementBankDetailsExcelDetailsResponseChanged());
}

export function* onGetLoanDisbursementBankDetailsExcelListDetails() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_START,
		getLoanDisbursementBankDetailsExcelList,
	);
}

export function* onGetLoanDisbursementBankDetailsExcelListResponseReset() {
	yield takeLatest(
		LoanDisbursementActionTypes.GET_LOAN_DISBURSEMENT_BANK_DETAILS_EXCEL_LIST_RESPONSE_RESET,
		resetGetLoanDisbursementBankDetailsExcelListResponse,
	);
}
/** *********************  END GET LOAN DISBURSEMENT EXCEL REPORT LIST ******************** */

export function* reportLoanDisbursementSaga() {
	yield all([
		call(onGetLoanDisbursementListDetails),
		call(onGetLoanDisbursementListResponseReset),
		call(onGetOfficerBranchListDetails),
		call(onGetOfficerBranchListResponseReset),
		call(onGetLoanDisbursementExcelListDetails),
		call(onGetLoanDisbursementExcelListResponseReset),
		call(onGetLoanDisbursementBankDetailsExcelListDetails),
		call(onGetLoanDisbursementBankDetailsExcelListResponseReset),
	]);
}
