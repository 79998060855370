/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */

import PostAuditingReportActionTypes from './postauditingReport.types';

/** ***************** GET POST AUDITING TOOL REPORT DETAILS START *********************** */
export const getPostAuditingReportDetailsStart = (getData) => ({
	type: PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_START,
	payload: getData,
});

export const getPostAuditingReportDetailsSuccess = (successData) => ({
	type: PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_SUCCESS,
	payload: successData,
});

export const getPostAuditingReportDetailsFailure = (failureData) => ({
	type: PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_FAILURE,
	payload: failureData,
});

export const getPostAuditingReportDetailsResponseResetStart = () => ({
	type: PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_RESPONSE_RESET,
});

export const getPostAuditingReportDetailsResponseChanged = () => ({
	type: PostAuditingReportActionTypes.GET_POST_AUDITING_REPORT_LIST_RESPONSE_CHANGED,
});
/** ***************** GET POST AUDITING TOOL REPORT DETAILS END *********************** */

