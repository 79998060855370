const KYCVerificationActionTypes = {
	CALL_KYC_ADDHAR_START: 'call_kyc_addhar_start',
	CALL_KYC_ADDHAR_SUCCESS: 'call_kyc_addhar_success',
	CALL_KYC_ADDHAR_FAILURE: 'call_kyc_addhar_failure',
	CALL_KYC_ADDHAR_RESPONSE_RESET: 'call_kyc_addhar_response_reset',
	CALL_KYC_ADDHAR_RESPONSE_CHANGED: 'call_kyc_addhar_response_changed',

	CALL_KYC_ADDHAR_OTP_START: 'call_kyc_addhar_otp_start',
	CALL_KYC_ADDHAR_OTP_SUCCESS: 'call_kyc_addhar_otp_success',
	CALL_KYC_ADDHAR_OTP_FAILURE: 'call_kyc_addhar_otp_failure',
	CALL_KYC_ADDHAR_OTP_RESPONSE_RESET: 'call_kyc_addhar_otp_response_reset',
	CALL_KYC_ADDHAR_OTP_RESPONSE_CHANGED: 'call_kyc_addhar_otp_response_changed',

	CALL_KYC_PAN_START: 'call_kyc_pan_start',
	CALL_KYC_PAN_SUCCESS: 'call_kyc_pan_success',
	CALL_KYC_PAN_FAILURE: 'call_kyc_pan_failure',
	CALL_KYC_PAN_RESPONSE_RESET: 'call_kyc_pan_response_reset',
	CALL_KYC_PAN_RESPONSE_CHANGED: 'call_kyc_pan_response_changed',

	CALL_KYC_GST_START: 'call_kyc_gst_start',
	CALL_KYC_GST_SUCCESS: 'call_kyc_gst_success',
	CALL_KYC_GST_FAILURE: 'call_kyc_gst_failure',
	CALL_KYC_GST_RESPONSE_RESET: 'call_kyc_gst_response_reset',
	CALL_KYC_GST_RESPONSE_CHANGED: 'call_kyc_gst_response_changed',

	CALL_KYC_CIN_START: 'call_kyc_cin_start',
	CALL_KYC_CIN_SUCCESS: 'call_kyc_cin_success',
	CALL_KYC_CIN_FAILURE: 'call_kyc_cin_failure',
	CALL_KYC_CIN_RESPONSE_RESET: 'call_kyc_cin_response_reset',
	CALL_KYC_CIN_RESPONSE_CHANGED: 'call_kyc_cin_response_changed',

	CALL_KYC_ITR_START: 'call_kyc_itr_start',
	CALL_KYC_ITR_SUCCESS: 'call_kyc_itr_success',
	CALL_KYC_ITR_FAILURE: 'call_kyc_itr_failure',
	CALL_KYC_ITR_RESPONSE_RESET: 'call_kyc_itr_response_reset',
	CALL_KYC_ITR_RESPONSE_CHANGED: 'call_kyc_itr_response_changed',

	CALL_KYC_MCA_START: 'call_kyc_mca_start',
	CALL_KYC_MCA_SUCCESS: 'call_kyc_mca_success',
	CALL_KYC_MCA_FAILURE: 'call_kyc_mca_failure',
	CALL_KYC_MCA_RESPONSE_RESET: 'call_kyc_mca_response_reset',
	CALL_KYC_MCA_RESPONSE_CHANGED: 'call_kyc_mca_response_changed',
};
export default KYCVerificationActionTypes;
