const RelatedPartiesActionTypes = {
	ADD_RELATED_PARTIES_START: 'add_releted_parties_start',
	ADD_RELATED_PARTIES_SUCCESS: 'add_releted_parties_success',
	ADD_RELATED_PARTIES_FAILURE: 'add_releted_parties_failure',
	ADD_RELATED_PARTIES_RESPONSE_RESET: 'add_releted_parties_response_reset',
	ADD_RELATED_PARTIES_RESPONSE_CHANGED: 'add_releted_parties_response_changed',

	UPDATE_RELATED_PARTIES_START: 'update_releted_parties_start',
	UPDATE_RELATED_PARTIES_SUCCESS: 'update_releted_parties_success',
	UPDATE_RELATED_PARTIES_FAILURE: 'update_releted_parties_failure',
	UPDATE_RELATED_PARTIES_RESPONSE_RESET: 'update_releted_parties_response_reset',
	UPDATE_RELATED_PARTIES_RESPONSE_CHANGED: 'update_releted_parties_response_changed',

	FETCH_RELATED_PARTIES_START: 'fetch_releted_parties_start',
	FETCH_RELATED_PARTIES_SUCCESS: 'fetch_releted_parties_success',
	FETCH_RELATED_PARTIES_FAILURE: 'fetch_releted_parties_failure',
	FETCH_RELATED_PARTIES_RESPONSE_RESET: 'fetch_releted_parties_response_reset',
	FETCH_RELATED_PARTIES_RESPONSE_CHANGED: 'fetch_releted_parties_response_changed',
};
export default RelatedPartiesActionTypes;
